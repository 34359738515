import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import { CardHeader, Link, styled, Skeleton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import NoteCardDocInfoAction from './NoteCardDocInfoAction';

const NoteCardDocInfoHeader = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-action': {
    margin: 0,
  },
  '& .MuiCardHeader-avatar': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  padding: theme.spacing(1, 2),
}));

export type NoteCardDocInfoProps = {
  docData: RetrievalUnitData;
  isLoading?: boolean;
};

const NoteCardDocInfo = ({ docData, isLoading }: NoteCardDocInfoProps) => {
  if (isLoading) {
    return (
      <NoteCardDocInfoHeader
        avatar={
          <StyledAvatar size="small">
            <DescriptionIcon fontSize="small" />
          </StyledAvatar>
        }
        data-testid="noteCardDocInfoSkeleton"
        title={<Skeleton width="70%" />}
      />
    );
  }

  return (
    <NoteCardDocInfoHeader
      action={<NoteCardDocInfoAction docData={docData} />}
      avatar={
        <StyledAvatar size="small">
          <DescriptionIcon fontSize="small" />
        </StyledAvatar>
      }
      title={
        <Link
          component={RouterLink}
          to={`/documents/${docData.organizeDocId}`}
          underline="hover"
        >
          {docData.title}
        </Link>
      }
    />
  );
};

export default NoteCardDocInfo;
