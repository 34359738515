import {
  DocumentItem,
  TaggableResourceTagItem,
  TaggedDocumentItem,
  TagType,
} from '@zarn/vendor/dist/saved-results';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { HitType } from 'common/enums';
import { deserializeResourcePermission } from 'containers/Sharing/sharing.utils';

import { DocumentUserData, TaggedDocumentResponse } from './DocumentsApi.types';

// Workaround
export const toTagTypeParam = (
  tagType: TagType
): 'own' | 'shared' | 'following' => {
  switch (tagType) {
    case TagType.Following:
      return 'following';
    case TagType.Shared:
      return 'shared';
    case TagType.Favourites:
    case TagType.Own:
      return 'own';
  }
};

const deserializeDocumentsUserTag = (
  tag: TaggableResourceTagItem
): TagDetails => ({
  canChangeDocOrder: !!tag.add_docs_url,
  canDeleteDoc: !!tag.delete_docs_url,
  color: tag.color ?? undefined,
  content: { numberOfDocuments: 0 },
  dateCreated: tag.date_tagged,
  dateModified: null,
  id: tag.tag_id,
  name: tag.name,
  newDocuments: 0,
  newTagNotes: 0,
  owner: {
    fullName: null,
    id: tag.owner,
    organizationName: null,
  },
  permission: deserializeResourcePermission(tag.permission),
  shareSettings: { orgs: [], users: [] },
  type: tag.tag_type,
  userOrder: null,
});

export const deserializeDocType = (docType: string): HitType => {
  switch (docType) {
    case 'private_document':
      return HitType.PrivateDocument;
    case 'external_document':
      return HitType.ExternalDocument;
    case 'citation':
      return HitType.Citation;
    case 'chunk':
      return HitType.Chunk;
    case 'sentence':
      return HitType.Sentence;
    default:
      return HitType.Document;
  }
};

export const deserializeDocumentUserData = ({
  document_id,
  document_type,
  notes,
  tags,
}: DocumentItem): DocumentUserData => ({
  docId: document_id,
  docType: deserializeDocType(document_type),
  favorite: tags.results.find((tag) => tag.favorite) ? true : false,
  notesCount: notes.count,
  tags: tags.results
    .filter((tag) => !tag.favorite)
    .map(deserializeDocumentsUserTag),
});

export const deserializeTaggedDocumentResponse = ({
  date_tagged,
  document_id,
  document_type,
  permission,
  user_order,
}: TaggedDocumentItem): TaggedDocumentResponse => ({
  dateTagged: date_tagged,
  docId: document_id,
  docType: deserializeDocType(document_type),
  permission: deserializeResourcePermission(permission),
  userOrder: user_order,
});
