import React, { useContext } from 'react';
import { useHighlightMode, useZoomLevel } from '../../../hooks';

import { Highlight } from '../../../types';
import AreaHighlighting from '../../AreaHighlighting';
import { HighlightingContext } from '../../Highlighting/Highlighting.context';
import { PDFPageContext } from '../../PDFPage/PDFPage.context';
import IntermediateHighlightItem from '../IntermediateHighlightItem';
import { useStyles } from './style';

export type HighlightsLayerProps = {
  children: React.ReactNode;
};

const HighlightsLayer = ({ children }: HighlightsLayerProps) => {
  const classes = useStyles();
  const {
    state: { pageNumber },
  } = useContext(PDFPageContext);
  const { zoomLevel } = useZoomLevel();
  const { highlightMode } = useHighlightMode();
  const {
    state: { highlight, groupedHighlight, status, mousePos },
    customHighlightActions,
    onClearHighlight,
    onHighlightAction,
  } = useContext(HighlightingContext);

  const intHighlight: Highlight | undefined = groupedHighlight[pageNumber];

  return (
    <div className={classes.root}>
      {intHighlight && highlight && (
        <IntermediateHighlightItem
          zoomLevel={zoomLevel}
          highlight={highlight}
          highlightPos={mousePos}
          onHighlightAction={onHighlightAction}
          onClearHighlight={onClearHighlight}
          highlightingStatus={status}
          customHighlightActions={customHighlightActions}
        />
      )}

      {highlightMode === 'area' && <AreaHighlighting />}

      {children}
    </div>
  );
};

HighlightsLayer.displayName = 'HighlightsLayer';

export default HighlightsLayer;
