import { useCallback, useContext } from 'react';

import { PDFViewerContext } from '../components/PDFViewer/PDFViewer.context';

export const useDownloadUri = () => {
  const {
    state: { downloadUri },
    dispatch,
  } = useContext(PDFViewerContext);

  const setDownloadUri = useCallback(
    (payload: string) => {
      dispatch({ type: 'setDownloadUri', payload });
    },
    [dispatch]
  );

  return {
    downloadUri,
    setDownloadUri,
  };
};
