import { useCallback } from 'react';

import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { userDocumentDelete } from 'api/privateDocsApi/privateDocsApi';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const useDeletePrivateDoc = (
  id: string,
  onPrivateDocDelete?: (id: string) => void
): UseMutationResult<void, unknown, void, unknown> => {
  const { t } = useTranslation(['privateDocs']);
  const { tenant } = useParsedHostname();

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(async () => {
    try {
      await userDocumentDelete(id, tenant);

      if (onPrivateDocDelete) {
        onPrivateDocDelete(id);
      }

      enqueueSnackbar(t('privateDocs:delete.successMessage'));
    } catch (error) {
      captureException(error);
      enqueueSnackbar(deserializeAxiosError(error).message);

      throw error;
    }
  }, [id, t, enqueueSnackbar]);

  const queryClient = useQueryClient();

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['privateDocs', 'results']);
    },
    retry: 0,
  });

  return mutation;
};
