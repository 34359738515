import React from 'react';

import { PDFViewerProvider } from '@zarn/pdf-viewer';

// TODo: move this HOC to the @zarn/pdf-viewer
export const withPDFViewer =
  <T extends object>(WrappedComponent: React.ComponentType<T>) =>
  (props: T) =>
    (
      <PDFViewerProvider>
        <WrappedComponent {...props} />
      </PDFViewerProvider>
    );
