import { useCallback, useEffect, useReducer } from 'react';

import {
  AnnotationHighlight,
  ListNotesPayloadParams,
} from 'api/notesApi/notesApi.types';
import { useDocNotes } from 'containers/DocNotes/hooks/useDocNotes';

export interface DocPDFViewerHighlightsState {
  activeHighlight: number | null;
  highlights: AnnotationHighlight[];
}

export type DocPDFViewerHighlightsAction =
  | { payload: number | null; type: 'setActiveHighlight' }
  | { payload: AnnotationHighlight[]; type: 'setHighlights' };

const reducer = (
  state: DocPDFViewerHighlightsState,
  action: DocPDFViewerHighlightsAction
): DocPDFViewerHighlightsState => {
  switch (action.type) {
    case 'setActiveHighlight':
      return {
        ...state,
        activeHighlight: action.payload,
      };
    case 'setHighlights':
      return {
        ...state,
        highlights: action.payload,
      };
    default:
      return state;
  }
};

export const initialState: DocPDFViewerHighlightsState = {
  activeHighlight: null,
  highlights: [],
};

export type UseDocPDFViewerHighlightsProps = {
  docId: string;
  notesPayload: ListNotesPayloadParams;
};

export const useDocPDFViewerHighlights = ({
  docId,
  notesPayload,
}: UseDocPDFViewerHighlightsProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data } = useDocNotes({
    docId,
    ...notesPayload,
  });

  useEffect(() => {
    const payload = (data?.items ?? []).reduce(
      (acc, cur) =>
        !cur.annotationHighlight ? acc : [...acc, cur.annotationHighlight],
      [] as AnnotationHighlight[]
    );

    dispatch({ payload, type: 'setHighlights' });
  }, [data]);

  const setActiveHighlight = useCallback((payload: number | null) => {
    dispatch({ payload, type: 'setActiveHighlight' });
  }, []);

  return {
    ...state,
    setActiveHighlight,
  };
};
