import { FC, ReactElement } from 'react';

import { RoleEnum } from 'containers/Auth/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';

type Props = {
  accessRoles: RoleEnum[];
  children: ReactElement;
  fallback?: JSX.Element | null;
};

export const AccessRole: FC<Props> = ({
  accessRoles,
  children,
  fallback = null,
}) => {
  const { hasAccess } = useAuth();

  return hasAccess(accessRoles) ? children : fallback;
};

export default AccessRole;
