import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { User } from 'oidc-client-ts';
import posthog from 'posthog-js';
import { hasAuthParams, useAuth } from 'react-oidc-context';

import { PageLoader } from 'common/components/Loaders/PageLoader/PageLoader';
import { windowReload } from 'common/utils/dom.utils';

import { setupOidcAxiosInterceptorFor401 } from './Auth.service';
import { parseAccessTokenPayload } from './Auth.utils';
import { Me } from './models/Me.interface';

const isLoadingPageFromSSO = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const sessionState = urlParams.get('session_state');
  return !!state && !!sessionState;
};

interface Props {
  children: ReactNode;
  isAuthenticated: boolean;
  setMe: (me: Me | null) => void;
  setTokens: (accessToken: string | null, refreshToken: string | null) => void;
  tokens: { accessToken: string | null; refreshToken: string | null };
}

export const AuthProviderOidc = ({
  children,
  isAuthenticated,
  setMe,
  setTokens,
  tokens,
}: Props) => {
  const auth = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const signOff = useCallback(() => {
    setMe(null);
    setTokens(null, null);
    setIsLoggingOut(true);
    posthog.reset(true);
  }, [setMe, setTokens]);

  const signIn = useCallback(
    (user: User) => {
      const newMe = parseAccessTokenPayload(user.access_token);

      if (!newMe) {
        signOff();
        return;
      }
      setTokens(user.access_token, user.refresh_token ?? null);
      setMe(newMe);
      posthog.identify(newMe.sub, {
        roles: newMe.roles,
      });
    },
    [setMe, setTokens, signOff]
  );

  const signinSilent = useCallback(async () => {
    try {
      const user = await auth.signinSilent();
      if (user) {
        signIn(user);
      } else {
        signOff();
      }
    } catch (error) {
      captureException(error);
      signOff();
    }
  }, [auth, signIn, signOff]);

  const tryAutoLogin = useCallback(
    async () => async () => {
      if (
        !hasAuthParams() &&
        !auth.isAuthenticated &&
        !auth.activeNavigator &&
        !auth.isLoading &&
        isAuthenticated
      ) {
        await signinSilent();
      }
    },
    [
      auth.isAuthenticated,
      auth.activeNavigator,
      auth.isLoading,
      isAuthenticated,
      signinSilent,
    ]
  );

  const isTheSameTokens = useCallback(
    (user: User | null = null) =>
      tokens.accessToken === user?.access_token &&
      tokens.refreshToken === user?.refresh_token,
    [tokens.accessToken, tokens.refreshToken]
  );

  const logout = useCallback(() => {
    setMe(null);
    setTokens(null, null);
    windowReload();
  }, [setMe, setTokens]);

  const signInOrLogout = useCallback(async () => {
    try {
      const user = await auth.signinSilent();
      if (user) {
        signIn(user);
      } else {
        logout();
      }
    } catch (error) {
      captureException(error);
      logout();
      throw error;
    }
    // avoid auth.signinSilent() to be called multiple times
  }, [logout, signIn]);

  const tryToAutoLogin = useCallback(async () => {
    try {
      await signInOrLogout();
    } catch (error) {
      windowReload();
    }
  }, [signInOrLogout]);

  useEffect(() => {
    if (isLoggingOut || isTheSameTokens(auth.user)) {
      return;
    }

    if (auth.activeNavigator === 'signoutRedirect') {
      signOff();
      return;
    } else if (
      !isAuthenticated &&
      auth.isAuthenticated &&
      auth.user?.access_token
    ) {
      signIn(auth.user);
    } else {
      void tryAutoLogin();
    }
  }, [
    isAuthenticated,
    auth.isAuthenticated,
    auth.isLoading,
    auth.activeNavigator,
    auth.user,
  ]);

  useEffect(() => {
    setupOidcAxiosInterceptorFor401(signInOrLogout);
    auth.events.addAccessTokenExpiring(signinSilent);
  }, []);

  useEffect(() => {
    window.addEventListener('focus', tryAutoLogin);
    return () => {
      window.removeEventListener('focus', tryAutoLogin);
    };
  }, []);

  useEffect(() => {
    if (auth.error) {
      captureException(auth.error);
      void tryToAutoLogin();
    }
  }, [auth.error]);

  if (
    auth.error ||
    ((isLoadingPageFromSSO() || auth.isLoading) && !auth.isAuthenticated)
  ) {
    return <PageLoader />;
  }

  return <>{children}</>;
};
