import React from 'react';

import { Box } from '@mui/material';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { PageMainContent } from 'common/components/PageWrapper/PageMainContent';
import { RoleEnum } from 'containers/Auth/enums';
import { MainSidebar } from 'containers/MainSidebar';
import { MainToolbar } from 'containers/MainToolbar';

import { PrivateRoute } from './PrivateRoute';
import { LocationState } from './routes.config';

const HomePage = React.lazy(() => import('pages/HomePage/HomePage'));
const DocumentPage = React.lazy(() => import('pages/DocumentPage'));
const PDFDialogPage = React.lazy(() => import('pages/PDFDialogPage'));
const TagsPage = React.lazy(() => import('pages/TagsPage/TagsPage'));
const SettingsPage = React.lazy(() => import('pages/SettingsPage'));
const ProductPage = React.lazy(() => import('pages/ProductPage'));
const FavouritesPage = React.lazy(() => import('pages/FavouritesPage'));
const NotesPage = React.lazy(() => import('pages/NotesPage'));
const ManagementPage = React.lazy(() => import('pages/ManagementPage'));
const PeoplePage = React.lazy(() => import('pages/PeoplePage'));
const MyDocumentsPage = React.lazy(() => import('pages/MyDocumentsPage'));
const RecommendationsPage = React.lazy(
  () => import('pages/RecommendationsPage')
);
const ChatPage = React.lazy(() => import('pages/ChatPage'));

export const InnerPages = () => {
  const location = useLocation<LocationState>();

  return (
    <Box alignItems="flex-start" display="flex" sx={{ minHeight: '100vh' }}>
      <MainToolbar />
      <MainSidebar />

      <PageMainContent>
        <Switch location={location}>
          <Route component={ProductPage} path="/product" />
          <PrivateRoute component={SettingsPage} path="/settings" />
          <Route component={TagsPage} path="/tags" />
          <PrivateRoute component={FavouritesPage} path="/favorites" />
          <PrivateRoute component={PeoplePage} path="/people" />
          <PrivateRoute component={MyDocumentsPage} path="/my-documents" />
          <PrivateRoute
            component={ManagementPage}
            path="/management"
            roles={[RoleEnum.Admin, RoleEnum.Manager]}
          />
          <PrivateRoute component={NotesPage} path="/notes" exact />
          <PrivateRoute
            component={RecommendationsPage}
            path="/recommendations"
            exact
          />
          <PrivateRoute component={ChatPage} path="/chat" exact />
          <PrivateRoute component={PDFDialogPage} path="/pdf/:docId" />
          <Route component={DocumentPage} path="/documents/:docId" exact />
          <Route component={HomePage} path="/" exact />
          <Redirect from="*" to="/" />
        </Switch>
      </PageMainContent>
    </Box>
  );
};
