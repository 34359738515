import React from 'react';

import { List, Popover, PopoverProps } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import ShareLinkDialog from 'common/components/ShareDocuments/ShareLinkDialog/ShareLinkDialog';
import ShareViaEmailDialog from 'common/components/ShareDocuments/ShareViaEmailDialog/ShareViaEmailDialog';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { ShareEnum } from 'common/enums';

import DocActionItem from '../DocActions/DocActionItem/DocActionItem';
import { DocAction } from '../DocActions/DocActions.interface';
import { useDocShareActions } from '../hooks/useDocShareActions';
import { RetrievalUnitAuthor } from '../RetrievalUnitData.interface';

export type DocSharePopoverProps = {
  docAuthors?: RetrievalUnitAuthor[];
  docId: string;
  docTitle: string;
  open: boolean;
  resultType?: ResultType;
  searchId?: string;
  shareUri?: string;
} & Partial<PopoverProps>;

const DocSharePopover = ({
  docAuthors,
  docId,
  docTitle,
  resultType,
  searchId,
  shareUri,
  ...rest
}: DocSharePopoverProps) => {
  const {
    docShareActions,
    getShareLink,
    notesLoading,
    onNotesInclude,
    onShare,
    setShareDialog,
    shareDialog,
  } = useDocShareActions({
    authorList: docAuthors,
    id: docId,
    resultType,
    searchId,
    shareUri,
    title: docTitle,
  });

  return (
    <>
      <Popover
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        aria-label="document share popover"
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        {...rest}
      >
        <List data-testid="docShareActions">
          {docShareActions.map((action: DocAction) => (
            <DocActionItem
              key={action.title}
              {...action}
              eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
            />
          ))}
        </List>
      </Popover>

      <ShareLinkDialog
        getShareLink={getShareLink}
        open={shareDialog === ShareEnum.Link}
        setOpen={setShareDialog}
        onShare={onShare}
      />

      <ShareViaEmailDialog
        getShareLink={getShareLink}
        notesLoading={notesLoading}
        open={shareDialog === ShareEnum.Email}
        setOpen={setShareDialog}
        onNotesInclude={onNotesInclude}
        onShare={onShare}
      />
    </>
  );
};

export default DocSharePopover;
