import React from 'react';
import { CircularProgress } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    spinner: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
    },
  })
);

const PDFPageSpinner = () => {
  const classes = useStyles();

  return <CircularProgress className={classes.spinner} />;
};

export default PDFPageSpinner;
