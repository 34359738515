import React, { FC, useMemo } from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { defined, isExist, Optional } from 'common/utils/assert';

import { Parser } from '../../hooks/useConversation/parsers/Parser';
import { useEvidenceHit } from '../../hooks/useEvidenceHit';

import { EvidenceContent } from './EvidenceContent';

interface EvidenceItemProps {
  docId: Optional<string>;
  documentHitUrl?: string;
  evidence: string;
  label: string;
  onChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: Optional<string>
  ) => void;
  onEvidenceItemClick?: () => void;
}

export const EvidenceItem: FC<EvidenceItemProps> = ({
  docId,
  documentHitUrl,
  evidence,
  label,
  onChunkClick,
  onEvidenceItemClick,
}) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const isInsideDocument = history?.location?.pathname?.includes('/pdf');

  const { chunkHighlights, isLoading, title } = useEvidenceHit({
    documentHitUrl,
  });

  const handleClick = () =>
    onEvidenceItemClick
      ? onEvidenceItemClick()
      : onChunkClick?.(
          defined(chunkHighlights, 'EvidenceHit.chunkHighlights'),
          docId
        );

  const preparedLabel = useMemo(() => Parser.prepareLabel(label), [label]);

  const disabled = isExist(onEvidenceItemClick)
    ? false
    : !chunkHighlights?.length;

  const tooltip = disabled
    ? t('chat.evidence.notClickable')
    : t('chat.evidence.clickable');

  return (
    <Tooltip
      data-testid="EvidenceItem-Tooltip"
      placement="left"
      title={tooltip}
    >
      <div>
        <EvidenceContent
          disabled={disabled}
          evidence={evidence}
          isInsideDocument={isInsideDocument}
          isLoading={isLoading}
          label={preparedLabel}
          title={title}
          onClick={handleClick}
        />
      </div>
    </Tooltip>
  );
};
