import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Chip, Popover, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    maxWidth: '80vw',
    padding: theme.spacing(2),
    width: 300,
  },
}));

type BetaButtonProps = {
  bodyText: string;
  headerText: string;
  idValue: string;
};

const BetaButton = ({ bodyText, headerText, idValue }: BetaButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? idValue : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        color="secondary"
        deleteIcon={<InfoIcon fontSize="small" />}
        label="Beta"
        size="small"
        sx={{ alignSelf: 'flex-start', margin: 1 }}
        onDelete={handleClick}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        classes={{ paper: classes.popoverPaper }}
        id={id}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={handleClose}
      >
        <Typography variant="h6" gutterBottom>
          {headerText}
        </Typography>

        <Typography
          dangerouslySetInnerHTML={{ __html: bodyText }}
          variant="body2"
        />
      </Popover>
    </>
  );
};
export default BetaButton;
