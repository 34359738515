import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions } from 'react-query';

import { SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

interface useEvidenceTitleProps {
  documentHitUrl?: string;
  options?: Omit<
    UseQueryOptions<RetrievalUnitData | null, BaseError>,
    'queryKey' | 'queryFn'
  >;
}

export const useEvidenceHit = ({
  documentHitUrl,
  options = {},
}: useEvidenceTitleProps) => {
  const { t } = useTranslation('common');

  const getDocumentData = async (): Promise<
    AxiosResponse<RetrievalUnitData | null>
  > => {
    // TODO: Chang it to use API from vendors
    const response = await axiosInstance.get(
      `${SERVICE_URL}${documentHitUrl!}`
    );
    const { data, ...rest } = response;
    const hit = data.hits[0];
    return {
      ...rest,
      data: hit ? deserializeSearchHit(hit) : null,
    };
  };

  const query = useQuery<RetrievalUnitData | null, BaseError>(
    ['getDocumentData', documentHitUrl],

    async () => {
      if (documentHitUrl && !documentHitUrl.startsWith('custom://')) {
        const response = await getDocumentData();
        return response.data;
      }
      return null;
    },
    {
      ...QUERY_OPTIONS,
      ...options,
      retry: 1,
      staleTime: Infinity,
    }
  );

  return {
    ...query,
    chunkHighlights: query.data?.representations.chunkHighlights ?? null,
    title: (query.data?.title ?? t('chat.evidence.title.notFound')) as string,
  };
};
