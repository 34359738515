import React from 'react';
import { Tooltip } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FormatColorTextIcon from '@mui/icons-material/FormatColorTextRounded';
import CropOriginalIcon from '@mui/icons-material/CropOriginalRounded';

import { HighlightMode } from '../../../types';

export type HighlightModeControlProps = {
  highlightMode: HighlightMode;
  onHighlightModeChange: (highlightMode: HighlightMode) => void;
};

const HighlightModeControl = React.memo(
  ({ onHighlightModeChange, highlightMode }: HighlightModeControlProps) => {
    const handleChange = (
      _: React.MouseEvent<HTMLElement, MouseEvent>,
      value: HighlightMode | null
    ) => {
      if (value === null) {
        onHighlightModeChange('none');
        return;
      }

      onHighlightModeChange(value);
    };

    return (
      <ToggleButtonGroup
        exclusive
        size="small"
        value={highlightMode}
        onChange={handleChange}
        aria-label="Highlights mode"
      >
        <ToggleButton value="text" aria-label="Highlight text">
          <Tooltip title="Highlight text">
            <FormatColorTextIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value="area" aria-label="Highlight image">
          <Tooltip title="Highlight image">
            <CropOriginalIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
);

HighlightModeControl.displayName = 'HighlightModeControl';

export default HighlightModeControl;
