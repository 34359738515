import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LoadingInfo: FC = () => {
  const { t } = useTranslation(['chat']);

  return (
    <Typography fontSize="small" sx={{ color: 'gray', marginLeft: '1rem' }}>
      {t(`message.context.loading`)}
    </Typography>
  );
};
