import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import DownloadIcon from '@mui/icons-material/GetAppRounded';
import MoreIcon from '@mui/icons-material/MoreHorizRounded';

export type PDFViewerMoreActionsProps = {
  onPrint: () => void;
  onDownload: () => void;
};

const PDFViewerMoreActions = ({
  onPrint,
  onDownload,
}: PDFViewerMoreActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="Actions" onClick={handleOpen} size="large">
        <MoreIcon fontSize="small" />
      </IconButton>

      <Menu
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onDownload}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>

          <ListItemText primary="Download" aria-label="Download PDF" />
        </MenuItem>

        <MenuItem onClick={onPrint}>
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>

          <ListItemText primary="Print" aria-label="Print PDF" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default PDFViewerMoreActions;
