import React, { useRef } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import {
  PageDrawerMenuItem,
  PageDrawerMenuItemProps,
} from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import {
  DocumentDragItem,
  DocumentDragItemEnum,
} from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import { selectSavedDocuments } from 'containers/SavedDocuments/savedDocuments.slice';

import { TagDragItemEnum } from './TagDragItem.enum';
import { TagDragItem, TagDropItem } from './TagDragItem.interface';

export const useStyles = makeStyles((theme: Theme) => ({
  activeDropItem: {
    '& $dragIndicator': {
      opacity: 1,
    },
    boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.light}`,
  },
  container: {
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& a': {
      paddingBottom: 0,
      paddingTop: 0,
    },
    '&:hover $dragIndicator': {
      opacity: 1,
    },
    padding: 0,
  },
  dragIndicator: {
    left: 2,
    opacity: 0.5,
    position: 'absolute',
  },
  dragPlaceholder: {
    '& > *': {
      opacity: 0,
      visibility: 'hidden',
    },
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  hoveredDropItem: {
    boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}, ${theme.shadows[10]}`,
  },
  tagListEl: {
    '& a': {
      paddingBottom: 0,
      paddingTop: 0,
    },
    padding: 0,
  },
}));

export type Props = {
  dragType: TagDragItemEnum;
  draggable?: boolean;
  dropTypes: string[];
  onDocumentDrop?: (item: DocumentDragItem, tag: TagDetails) => void;
  onMove: (draggedId: number, id: number) => void;
  onTagDrop: (item: TagDragItem, id: number) => void;
  tag: TagDetails;
} & PageDrawerMenuItemProps;

export const TagListDragItem = ({
  children,
  draggable = true,
  dragType,
  dropTypes,
  onDocumentDrop,
  onMove,
  onTagDrop,
  tag,
  ...rest
}: Props) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const savedDocuments = useSelector(selectSavedDocuments);
  const [{ isDragging }, connectDrag] = useDrag<
    TagDragItem,
    unknown,
    { isDragging: boolean }
  >(() => ({
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    item: {
      id: tag.id,
      tag,
      type: dragType,
    },
    type: dragType,
  }));
  const [{ canDrop, draggingType, isOver }, connectDrop] = useDrop({
    accept: dropTypes,
    canDrop(dropItem: TagDropItem) {
      if (
        dropItem.type === DocumentDragItemEnum.Document ||
        dragType !== TagDragItemEnum.Following
      ) {
        return !savedDocuments[dropItem.id]?.some(
          (saved) => saved.tag.id === tag.id
        );
      }

      return draggable;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      draggingType: monitor.getItemType(),
      isOver: monitor.isOver(),
    }),
    drop(dropItem: TagDropItem) {
      if (dropItem.type === DocumentDragItemEnum.Document && onDocumentDrop) {
        onDocumentDrop(dropItem, tag);
      }

      if (dropItem.type !== DocumentDragItemEnum.Document) {
        onTagDrop(dropItem, tag.id);
      }
    },
    hover(dropItem: TagDropItem) {
      if (
        dropItem.type !== DocumentDragItemEnum.Document &&
        dropItem.id !== tag.id &&
        (dropTypes.includes(TagDragItemEnum.Own) ||
          dropTypes.includes(TagDragItemEnum.Following))
      ) {
        onMove(dropItem.id, tag.id);
      }
    },
  });
  connectDrag(ref);
  connectDrop(ref);

  if (!draggable) {
    return (
      <PageDrawerMenuItem className={classes.tagListEl} {...rest}>
        {children}
      </PageDrawerMenuItem>
    );
  }

  return (
    <PageDrawerMenuItem
      className={clsx(
        classes.container,
        isDragging && classes.dragPlaceholder,
        draggingType === 'document' && canDrop && classes.activeDropItem,
        draggingType === 'document' &&
          canDrop &&
          isOver &&
          classes.hoveredDropItem
      )}
      ref={ref}
      {...rest}
    >
      <DragIndicatorIcon className={classes.dragIndicator} fontSize="small" />
      {children}
    </PageDrawerMenuItem>
  );
};
