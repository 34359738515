import React, { FC, useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';

import { getPaperImgBySource } from 'common/utils/documents';
import { getInitialsFromName } from 'common/utils/stringsHelpers';

import { AdaptiveImage } from './components/AdaptiveImage';
import { DocImageProps } from './DocImageProps';
import { DocMediaImage } from './DocMediaImage';
import { DocMediaLogo } from './DocMediaLogo';
import { useStyles } from './useStyles';

export const DocImage: FC<DocImageProps> = ({
  cardComposition,
  data: { authors, documentId, image, logo, source },
}) => {
  const classes = useStyles();

  const initials = useMemo(
    () => getInitialsFromName(authors?.[0]?.fullName ?? ''),
    [authors]
  );

  const fallback = useMemo(() => getPaperImgBySource(source), [source]);

  const getContent = useCallback(() => {
    if (image) {
      return (
        <DocMediaImage
          cardComposition={cardComposition}
          documentId={documentId}
          fallback={fallback}
          image={image}
        />
      );
    }

    if (logo) {
      return (
        <DocMediaLogo
          cardComposition={cardComposition}
          documentId={documentId}
          fallback={fallback}
          initials={initials}
          logo={logo}
        />
      );
    }

    return (
      <AdaptiveImage
        cardComposition={cardComposition}
        fallback={fallback}
        src={fallback}
      />
    );
  }, [image, logo, documentId, initials, fallback, cardComposition]);

  return (
    <Grid className={classes.grid} md={2} xs={3} item>
      <div aria-label="paper image" className={classes.wrapper}>
        {getContent()}
      </div>
    </Grid>
  );
};

DocImage.displayName = 'DocImage';
