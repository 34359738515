import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { AnalyticsRequest } from '@zarn/vendor/dist/search';

import { createAnalyticsGraph } from 'api/analyticsGraphApi';
import { AnalyticsGraphNameEnum } from 'common/enums';
import { deserializeAxiosError } from 'common/utils/error';

import { AnalyticsGraph } from './AnalyticsGraph.interface';
import { mapAnalyticsResponse } from './analyticsGraph.utils';

export type AnalyticsGraphState = {
  data: {
    [AnalyticsGraphNameEnum.Interest]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Publications]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Organizations]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Countries]: AnalyticsGraph | null;
  };
  error: string | null;
  fetchState: FiniteStatesType;
};

export const initialState: AnalyticsGraphState = {
  data: {
    [AnalyticsGraphNameEnum.Interest]: null,
    [AnalyticsGraphNameEnum.Publications]: null,
    [AnalyticsGraphNameEnum.Organizations]: null,
    [AnalyticsGraphNameEnum.Countries]: null,
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export type GetAnalyticsArg = {
  graphName: AnalyticsGraphNameEnum;
  payload: AnalyticsRequest;
};

export const getAnalyticsGraph = createAsyncThunk(
  'analyticsGraph/getAnalyticsGraph',
  async ({ graphName, payload }: GetAnalyticsArg) => {
    try {
      const { data } = await createAnalyticsGraph(payload);
      return {
        data: mapAnalyticsResponse(data),
        graphName,
      };
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

const analyticsGraph = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsGraph.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getAnalyticsGraph.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message ?? 'unexpected error';
    });
    builder.addCase(getAnalyticsGraph.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data[payload.graphName] = payload.data;
      state.error = null;
    });
  },
  initialState,
  name: 'analyticsGraph',
  reducers: {
    resetAnalyticsGraphState: (
      state,
      { payload }: PayloadAction<AnalyticsGraphNameEnum>
    ) => {
      state.data[payload] = null;
      state.fetchState = initialState.fetchState;
      state.error = initialState.error;
    },
  },
});

const getAnalyticsGraphSelector = (state: RootState) => state.analyticsGraph;

export const analyticsGraphDataSelector = (state: RootState) =>
  state.analyticsGraph.data;

export const selectAnalytics = (target: AnalyticsGraphNameEnum) => {
  return createSelector(analyticsGraphDataSelector, (data) => data[target]);
};

export const selectAnalyticsGraphLoading = createSelector(
  getAnalyticsGraphSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const { resetAnalyticsGraphState } = analyticsGraph.actions;

export default analyticsGraph.reducer;
