import { captureException } from '@sentry/react';

import { UserPayload } from '@zarn/vendor/dist/auth';

import { isJWTValid } from 'common/utils/jwt-tokens';
import {
  getAccessTokenFromStore,
  getRefreshTokenFromStore,
  removeAccessTokenFromStore,
  removeRefreshTokenFromStore,
  storeOrRemoveAccessToken,
  storeOrRemoveRefreshToken,
} from 'common/utils/store';

import { OidcAccessTokenPayloadInterface } from './interfaces/OidcAccessTokenPayload.interface';
import { createMe } from './models/createMe';
import { Me } from './models/Me.interface';

export const parseJwt = <T>(token: string): T | null => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as T;
  } catch (error) {
    captureException(error);
    return null;
  }
};

/**
 * Parse and decode access token `Payload` part.
 */
export const parseAccessTokenPayload = (
  accessToken: string | null
): Me | null => {
  if (!accessToken) return null;

  const tokenContent = parseJwt<UserPayload | OidcAccessTokenPayloadInterface>(
    accessToken
  );

  const me = tokenContent ? createMe(tokenContent) : null;

  return me;
};

export const isValidTokens = () => {
  const accessToken = getAccessTokenFromStore();
  const refreshToken = getRefreshTokenFromStore();

  return (
    !!accessToken &&
    isJWTValid(accessToken) &&
    !!refreshToken &&
    isJWTValid(refreshToken)
  );
};

export const isInvalidAccessTokenAndValidRefreshToken = () => {
  const accessToken = getAccessTokenFromStore();
  const refreshToken = getRefreshTokenFromStore();

  return (
    !!accessToken &&
    !isJWTValid(accessToken) &&
    !!refreshToken &&
    isJWTValid(refreshToken)
  );
};

export const getPayloadPartFromStoredAccessToken = () => {
  return parseAccessTokenPayload(getAccessTokenFromStore());
};

export const removeTokensFromStore = () => {
  removeAccessTokenFromStore();
  removeRefreshTokenFromStore();
};

export const storeOrRemoveTokens = (
  accessToken: string | null,
  refreshToken: string | null
) => {
  storeOrRemoveAccessToken(accessToken);
  storeOrRemoveRefreshToken(refreshToken);
};
