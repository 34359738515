import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { listRecommendations } from 'api/recommendationsApi/recommendationsApi';
import { listDocuments } from 'api/searchApi';
import { listTags } from 'api/tagsApi/tagsApi';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { getSeenRecommendationsByID } from 'containers/SeenRecommendations/seenRecommendations.slice';

import {
  GetRecommendationsResultsArgs,
  RecommendationsData,
} from './Recommendations.interface';
import {
  getGroupedRecommendationResults,
  getIdsByResourceType,
} from './recommendations.utils';

export const getRecommendationsResultsThunk: AsyncThunkPayloadCreator<
  RecommendationsData,
  GetRecommendationsResultsArgs
> = async ({ indexCluster, page, recId, tagId, tenant }, { dispatch }) => {
  const { data } = await listRecommendations({
    findRecInContext: recId,
    page,
    tagId: tagId !== undefined ? parseInt(tagId) : undefined,
  });

  if (data.count === 0) {
    return {
      count: data.count,
      groupedResults: [],
      page: data.page,
      pageSize: data.pageSize,
      results: data.items,
    };
  }

  const { docIds, tagIds } = getIdsByResourceType(data.items);

  let tags: TagDetails[] = [];

  const {
    data: { items: docs },
  } = await listDocuments({
    docIds,
    indexCluster: indexCluster,
    tenant: tenant,
  });

  if (tagIds.length > 0) {
    tags = (await listTags({ pageSize: tagIds.length, tagIds, tenant })).data
      .items;
  }

  const groupedResults = getGroupedRecommendationResults(
    data.items,
    docs,
    tags
  );
  const recIds = groupedResults.map(({ id }) => id);

  await dispatch(getSeenRecommendationsByID(recIds));

  return {
    count: data.count,
    groupedResults,
    page: data.page,
    pageSize: data.pageSize,
    results: data.items,
  };
};
