import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';

import { authRefresh } from 'api/authApi';
import axiosInstance from 'api/axiosInstance';
import { APIError } from 'common/models/Error.interface';
import { isJWTValid } from 'common/utils/jwt-tokens';
import {
  getAccessTokenFromStore,
  getRefreshTokenFromStore,
  storeAccessToken,
} from 'common/utils/store';

import { isInvalidAccessTokenAndValidRefreshToken } from './Auth.utils';

type RequestsQueueItem = {
  reject: Function;
  resolve: Function;
};

type RequestsQueue = RequestsQueueItem[];

/**
 * Add new item with Promise methods `resolve` and `reject` into a queue
 */
const addToRequestsQueue = (queue: RequestsQueueItem[]) =>
  new Promise((resolve, reject) => queue.push({ reject, resolve }));

/**
 * Invoke all queue items with Promise `resolve` or `reject` by passing `token` or `error`
 */
const processRequestsQueue = (
  queue: RequestsQueueItem[],
  error: Error | null,
  token: string | null = null
) =>
  // TODO: await Promise.all();
  queue.map((item: RequestsQueueItem) =>
    error ? item.reject(error) : item.resolve(token)
  );

type AxiosErrorConfigRetry = AxiosError<APIError> & {
  config: {
    retry: boolean; // `retry` field is responsible for preventing double call the same request config
  };
};

const requestNewAccessTokenAndStore = async (refreshToken: string | null) => {
  if (!refreshToken) {
    throw new Error('Auth: Refresh Token does not exist');
  }

  let newAccessToken: string;

  try {
    const response = await authRefresh({ jwt: refreshToken });
    newAccessToken = response.data.jwt;
  } catch (error) {
    captureException(error);
    throw new Error('Auth: Refresh Token is not valid');
  }

  if (!isJWTValid(newAccessToken)) {
    throw new Error('Auth: A new Access Token is not valid');
  }

  storeAccessToken(newAccessToken);

  return newAccessToken;
};

/**
 * Setup interceptor callback for handling 401 response status code
 * If a first response has 401 then next request will be to fetch a new access token and then refetch original request with a new token
 * All next requests after the first with 401 will be collected into `RequestsQueue` list and then recalled with a new access token.
 */
export const setupAxiosInterceptorFor401 = (showLoginDialog: () => void) => {
  let isRefreshingAccessToken = false;
  const failedRequestsQueue: RequestsQueue = [];

  axiosInstance.interceptors.response.use(
    (response) => {
      /**
       * Retry failed requests when user logged in the system
       */
      if (
        response.config.url?.includes('auth/login') &&
        failedRequestsQueue.length > 0
      ) {
        processRequestsQueue(failedRequestsQueue, null, response.data.jwt);
        failedRequestsQueue.length = 0;
        isRefreshingAccessToken = false;
      }

      return response;
    },
    async (error) => {
      const axiosError: AxiosErrorConfigRetry = error;

      // If 400 and error is reuqester-uuid (better do it in api gateway?)
      if (axiosError.response?.status === 401 && !axiosError.config.retry) {
        const storedAccessToken = getAccessTokenFromStore();
        const refreshToken = getRefreshTokenFromStore();
        const isRefreshError = axiosError.config.url?.includes('auth/refresh');

        if (
          (!refreshToken && storedAccessToken) ||
          (refreshToken && !isJWTValid(refreshToken)) ||
          isRefreshError
        ) {
          showLoginDialog();
          return Promise.reject(error);
        }

        if (isRefreshingAccessToken) {
          const accessToken = await addToRequestsQueue(failedRequestsQueue);
          if (axiosError.config.headers) {
            axiosError.config.headers.Authorization = `Bearer ${accessToken}`;
          }
          return axiosInstance(axiosError.config);
        }

        axiosError.config.retry = true;
        isRefreshingAccessToken = true;

        try {
          const newAccessToken = await requestNewAccessTokenAndStore(
            refreshToken
          );
          // TODO: check async/void
          processRequestsQueue(failedRequestsQueue, null, newAccessToken);
          failedRequestsQueue.length = 0;
          isRefreshingAccessToken = false;
          if (axiosError.config.headers) {
            axiosError.config.headers.Authorization = `Bearer ${newAccessToken}`;
          }
          return axiosInstance(axiosError.config);
        } catch (err: any) {
          captureException(err);
          // TODO: check async/void
          processRequestsQueue(failedRequestsQueue, err, null);
          failedRequestsQueue.length = 0;
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );
};

export const setupOidcAxiosInterceptorFor401 = (onError401: () => void) => {
  axiosInstance.interceptors.response.use(undefined, async (error) => {
    const axiosError: AxiosErrorConfigRetry = error;

    if (
      (axiosError.response?.status === 401 ||
        isInvalidAccessTokenAndValidRefreshToken()) &&
      !axiosError.config.retry
    ) {
      onError401();
    }

    return Promise.reject(error);
  });
};

type RefreshAccessTokenResponse = [Error | null, string | null];

/**
 * Check the validity of the refresh and access tokens. If access token is no longer valid make a request to update it.
 */
export const updateAccessToken =
  async (): Promise<RefreshAccessTokenResponse> => {
    const refreshToken = getRefreshTokenFromStore();
    const accessToken = getAccessTokenFromStore();

    if (!refreshToken) {
      return [null, null];
    }

    if (!isJWTValid(refreshToken)) {
      return [
        new Error('Auth: Refresh token does not exist or not valid'),
        null,
      ];
    }

    if (!isJWTValid(accessToken)) {
      try {
        const newAccessToken = await requestNewAccessTokenAndStore(
          refreshToken
        );
        return [null, newAccessToken];
      } catch (error: any) {
        captureException(error);
        return [error, null];
      }
    }

    return [null, accessToken];
  };
