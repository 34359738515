import React, { AnchorHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import { ReferenceLink } from './ReferenceLink';

type Props = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & { href: string };

export const LinkRenderer: FC<Props> = ({ children, href = '#' }) => {
  // Check if the link is a reference link with this format [[1]](http://example.com)
  const isReference = /^\[\d+\]$/.test(children?.toString() || '');
  if (isReference) {
    const id = parseInt(children?.toString().replace(/\[|\]/g, '') || '0', 10);
    return <ReferenceLink href={href} id={id} />;
  }

  return (
    <a
      href={href}
      rel="noopener noreferrer"
      style={{ color: 'blue', textDecoration: 'underline' }}
      target="_blank"
    >
      {children}
    </a>
  );
};
