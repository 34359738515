import React from 'react';

import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';
import FollowingIcon from '@mui/icons-material/SupervisedUserCircle';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import EditTagIcon from 'common/components/icons/EditTagIcon';
import { MenuItemTitle } from 'common/components/PageDrawer/MenuItemTitle';
import TagChip from 'common/components/TagChip/TagChip';
import { ZETA_ALPHA_EDITORIAL_ORG } from 'common/constants';
import TagPreviewTooltip from 'containers/Tags/TagPreviewTooltip/TagPreviewTooltip';
import { tagIsShared } from 'containers/Tags/tags.utils';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';

import { useScrollToActiveTag } from '../hooks/useScrollToActiveTag';

export const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    '& > svg': {
      marginLeft: theme.spacing(0.5),
    },
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  tooltipContent: {
    flex: 'auto',
    overflow: 'hidden',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(0.5),
  },
}));

export type TagItemTitleProps = {
  following?: boolean;
  tag: TagDetails;
};

const TagItemTitle = ({ following, tag }: TagItemTitleProps) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const { newDocuments, newTagNotes } = tag;
  const { data: tagOwner } = useUserDetails({
    memberId: tag?.owner?.id ?? '',
    options: { enabled: !!tag && !!tag.owner },
  });
  const organizationName = tagOwner?.organization;
  const [ref] = useScrollToActiveTag(tag);
  const hasNewDocuments = !!newDocuments;
  const hasNewNotes = !!newTagNotes;
  const showPublicIcon =
    !!tag.shareSettings.public || organizationName === ZETA_ALPHA_EDITORIAL_ORG;
  const showEditorPermissionIcon =
    tag.permission !== 'read' &&
    (tag.type === TagType.Shared || tag.type === TagType.Following);

  const isShared = tagIsShared(tag);
  const newContentText = [
    hasNewDocuments && t('followingTags.newDocuments', { newDocuments }),
    hasNewNotes && t('followingTags.newNotes', { newTagNotes }),
  ]
    .filter((str) => str)
    .join(' | ');

  return (
    <MenuItemTitle
      chip={hasNewDocuments || hasNewNotes}
      chipLabel={tag.newDocuments + tag.newTagNotes}
      chipTooltip={newContentText}
      data-testid="TagItemTitle"
      ref={ref}
    >
      <TagPreviewTooltip placement="right-start" tag={tag}>
        <div className={classes.tooltipContent}>
          <div className={classes.titleText}>
            <TagChip color={tag.color} label={tag.name} />
            <div>
              {showPublicIcon && <PublicIcon color="action" />}
              {isShared && (
                <GroupIcon color="action" data-testid="tagIsShared" />
              )}
              {showEditorPermissionIcon && (
                <EditTagIcon
                  color="action"
                  data-testid="tagHasEditorPermissions"
                />
              )}
              {following && (
                <FollowingIcon color="action" data-testid="tagIsFollowing" />
              )}
            </div>
          </div>
        </div>
      </TagPreviewTooltip>
    </MenuItemTitle>
  );
};

export default TagItemTitle;
