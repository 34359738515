import {
  useAvailableCustomHighlightActions,
  useHighlightTooltip,
} from '../../../hooks';
import React, { useCallback, useEffect } from 'react';
import { HighlightActionPayloadType } from '../../../types';
import { IntermediateHighlightItemProps } from './types';

export const useIntermediateHighlightItem = <T extends string>({
  zoomLevel,
  highlight,
  highlightingStatus,
  onHighlightAction,
  onClearHighlight,
  highlightPos,
  customHighlightActions,
}: IntermediateHighlightItemProps<T>) => {
  const { showTooltip, onShowTooltip, onHideTooltip, anchorPos } =
    useHighlightTooltip();
  const hasCopy = highlight.content.type === 'text';
  const customActions = useAvailableCustomHighlightActions(
    highlight.content.type,
    customHighlightActions
  );

  useEffect(() => {
    if (highlightingStatus !== 'end' || showTooltip) return;

    onShowTooltip(highlightPos.x, highlightPos.y);
  }, [highlightingStatus, highlightPos, onShowTooltip, showTooltip]);

  const handleClearHighlight = useCallback(
    (createOnClose?: boolean) => {
      const selection = document.getSelection();

      if (selection?.rangeCount && selection?.removeAllRanges) {
        selection.removeAllRanges();
      }

      if (createOnClose) {
        onHighlightAction({
          action: 'create',
          data: highlight,
          withAnnotation: false,
        });
      }

      onClearHighlight();
    },
    [onClearHighlight, highlight, onHighlightAction]
  );

  const handleClose = useCallback(() => {
    onHideTooltip();

    handleClearHighlight(false);
  }, [handleClearHighlight, onHideTooltip]);

  const handleCreate = useCallback(() => {
    onHideTooltip();

    handleClearHighlight(true);
  }, [handleClearHighlight, onHideTooltip]);

  const handleCopy = useCallback(async () => {
    onHighlightAction({ action: 'copy', data: highlight });
  }, [onHighlightAction, highlight]);

  const handleAddNote = useCallback(() => {
    onHideTooltip();

    handleClearHighlight();

    onHighlightAction({
      action: 'create',
      data: highlight,
      withAnnotation: true,
    });
  }, [highlight, onHighlightAction, onHideTooltip, handleClearHighlight]);

  const handleDelete = useCallback(() => {
    onHideTooltip();

    handleClearHighlight();
  }, [onHideTooltip, handleClearHighlight]);

  const handleActionClick = useCallback(
    async (
      _: React.MouseEvent<HTMLButtonElement>,
      action: HighlightActionPayloadType<T>
    ) => {
      onHideTooltip();

      handleClearHighlight();

      onHighlightAction({ action, data: highlight });
    },
    [handleClearHighlight, onHighlightAction, highlight, onHideTooltip]
  );

  return {
    highlight,
    highlightingStatus,
    zoomLevel,
    handleClose,
    showTooltip,
    anchorPos,
    handleCreate,
    handleAddNote,
    hasCopy,
    handleCopy,
    customActions,
    handleActionClick,
    handleDelete,
  };
};
