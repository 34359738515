import React from 'react';

import { Box, BoxProps } from '@mui/material';

type TabContentProps<T extends string> = {
  activeTab: T;
  children?: React.ReactNode;
  tabId: T;
} & BoxProps;

const TabContent = <T extends string>({
  activeTab,
  children,
  tabId,
  ...boxProps
}: TabContentProps<T>) => {
  return (
    <Box hidden={activeTab !== tabId} id={tabId} role="tabpanel" {...boxProps}>
      {activeTab === tabId && children}
    </Box>
  );
};

export default TabContent;
