import React, { FC, useMemo } from 'react';

import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';

import AddPrivateDocs from '../AddPrivateDocs/AddPrivateDocs';
import { AddPrivateDocsMethodEnum } from '../AddPrivateDocsMethod.enum';
import BatchUploadPrivateDocsForm from '../BatchUploadPrivateDocs/BatchUploadPrivateDocsForm/BatchUploadPrivateDocsForm';

import { AddPrivateDocsDialogState } from './useAddPrivateDocsDialog';

interface AddPrivateDocsDialogProps {
  onClose: () => void;
  state: AddPrivateDocsDialogState;
}

export const AddPrivateDocsDialog: FC<AddPrivateDocsDialogProps> = ({
  onClose,
  state,
}) => {
  const { t } = useTranslation('privateDocs');
  const dialog = useMemo(
    () =>
      ({
        [AddPrivateDocsMethodEnum.SinglePaper]: {
          content: <AddPrivateDocs onFormSubmit={onClose} />,
          title: t('addDialog.singlePaperTitle'),
        },
        [AddPrivateDocsMethodEnum.BatchUpload]: {
          content: <BatchUploadPrivateDocsForm onFormSubmit={onClose} />,
          title: t('batchUploadDialog.batchUploadTitle'),
        },
      }[state.method]),
    [state.method, onClose, t]
  );

  if (!dialog) {
    return null;
  }

  return (
    <Dialog
      aria-label={t('addDialog.dialog.ariaLabel')}
      data-testid="AddPrivateDocsDialog"
      maxWidth="sm"
      open={state.open}
      scroll="body"
      fullWidth
      onClose={onClose}
    >
      <DialogActionTitle onClose={onClose}>{dialog.title}</DialogActionTitle>
      {dialog.content}
    </Dialog>
  );
};
