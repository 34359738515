import { useCallback } from 'react';

import { LoginDialogState } from 'containers/Auth/Auth.context';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export type LoggedInFeatureReturn<T = undefined> = (e: T) => void;

export function useLoggedInFeature<T>(
  action: (e: T) => void,
  state: LoginDialogState = LoginDialogState.SignUp
): LoggedInFeatureReturn<T> {
  const { me, setLoginDialog } = useAuth();

  return useCallback(me ? action : () => setLoginDialog(state), [
    me,
    action,
    state,
  ]);
}
