/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { FiniteStatesType, FiniteStates } from 'app/state/finiteStates.enum';
import { RootState, AsyncThunkConfig } from 'app/state/store';

import { SeenRec } from '@zarn/vendor/dist/feedback';

import { getRecommendations } from 'api/recommendationsApi/recommendationsApi';
import {
  getSeenRecommendations,
  markRecommendationsAsSeen,
} from 'api/recommendationsFeedbackApi';

import { SeenRecommendations } from './SeenRecommendations.interface';
import {
  getSeenRecIDs,
  getUniqueRecIDs,
  getUnseenRecIDs,
} from './seenRecommendations.utils';

export type SeenRecommendationsState = {
  data: SeenRecommendations;
  error: string | null;
  fetchState: FiniteStatesType;
};

export const initialState: SeenRecommendationsState = {
  data: {
    allRecCount: 0,
    hasNewRecs: false,
    seenRecCount: 0,
    seenRecommendations: [],
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getInitSeenRecommendations = createAsyncThunk(
  'seenRecommendations/getInitSeen',
  async () => {
    const { data: recData } = await getRecommendations(1);

    if (recData.results.length === 0) {
      return initialState.data;
    }

    const recIds = [
      ...new Set(recData.results.map((rec) => rec.recommendation_uuid)),
    ];
    const { data: seenData } = await getSeenRecommendations(recIds);

    return {
      allRecCount: recData.count,
      hasNewRecs: seenData.results.length < recIds.length,
      seenRecCount: seenData.paper_count,
      seenRecommendations: getSeenRecIDs(seenData.results),
    };
  }
);

export const getSeenRecommendationsByID = createAsyncThunk(
  'seenRecommendations/getSeenByID',
  async (recIds: string[]) => {
    const { data: seenData } = await getSeenRecommendations(recIds);

    return getSeenRecIDs(seenData.results);
  }
);

export const setRecommendationsAsSeen = createAsyncThunk<
  number,
  SeenRec[],
  AsyncThunkConfig
>(
  'seenRecommendations/setRecommendationsAsSeen',
  async (recIds: SeenRec[], helpers) => {
    const { seenRecommendations } = helpers.getState().seenRecommendations.data;
    const unseenRecs = getUnseenRecIDs(recIds, seenRecommendations);

    await markRecommendationsAsSeen({
      seen_recommendations: unseenRecs,
    });

    const { data } = await getSeenRecommendations(
      unseenRecs.map(({ uuid }) => uuid)
    );

    return data.paper_count;
  },
  {
    // eslint-disable-next-line consistent-return
    condition: (recIds: SeenRec[], { getState }) => {
      const { seenRecommendations } = getState().seenRecommendations.data;
      const unseenIDs = getUnseenRecIDs(recIds, seenRecommendations);

      if (unseenIDs.length === 0) {
        return false;
      }
    },
  }
);

const seenRecommendations = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getInitSeenRecommendations.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getInitSeenRecommendations.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Sorry, unexpected error happened';
    });
    builder.addCase(getInitSeenRecommendations.fulfilled, (state, action) => {
      state.fetchState = FiniteStates.Success;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(
      getSeenRecommendationsByID.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.seenRecommendations = getUniqueRecIDs(
          state.data.seenRecommendations,
          payload
        );
        state.error = null;
      }
    );
    builder.addCase(
      setRecommendationsAsSeen.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.hasNewRecs = false;
        state.data.seenRecCount = payload;
        state.error = null;
      }
    );
  },
  initialState,
  name: 'seenRecommendations',
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
});

const seenRecommendationsSelector = (state: RootState) =>
  state.seenRecommendations;

export const selectSeenRecommendations = createSelector(
  seenRecommendationsSelector,
  ({ data }) => data
);

export const selectSeenRecommendationsLoading = createSelector(
  seenRecommendationsSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const selectSeenRecommendationsFailure = createSelector(
  seenRecommendationsSelector,
  ({ error, fetchState }) => fetchState === FiniteStates.Failure && error
);

export default seenRecommendations.reducer;
