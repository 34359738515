import { Expert } from '@zarn/vendor/dist/search';

import { RetrievalUnitAuthor } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export const DEFAULT_INITIALS = 'NA';

export const mapAuthor = (data: Expert): RetrievalUnitAuthor => ({
  firstName: data.first_name,
  fullName: data.full_name,
  id: data.uid,
  lastName: data.last_name,
});

export const getInitialsFromFullName = (
  fullName: string | undefined
): string => {
  if (fullName) {
    const nameArray = fullName.split(' ');
    const firstNameIn = nameArray[0].charAt(0).toUpperCase();
    const lastNameIn =
      nameArray.length > 1
        ? nameArray[nameArray.length - 1].charAt(0).toUpperCase()
        : '';
    return `${firstNameIn}${lastNameIn}`;
  }
  return DEFAULT_INITIALS;
};

export const getAuthorInitials = (name: {
  first?: string | null;
  full: string;
  last?: string | null;
}): string => {
  if (name.first && name.last) {
    return `${name.first[0]}${name.last[0]}`;
  }

  return getInitialsFromFullName(name.full);
};
