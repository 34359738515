import { createContext } from 'react';

import { initialHighlightingState } from '../../hooks';
import {
  Highlight,
  HighlightActionItem,
  HighlightingMousePos,
  HighlightingState,
  OnHighlightAction,
} from '../../types';

export interface HighlightingContextValue<T = void> {
  state: HighlightingState;
  customHighlightActions?: HighlightActionItem<T>[];
  onUpdateHighlight: (payload: Highlight) => void;
  onClearHighlight: () => void;
  onStartHighlight: () => void;
  onEndHighlight: (payload?: HighlightingMousePos) => void;
  onHighlightAction: OnHighlightAction<T>;
}

export const initContext: HighlightingContextValue<string> = {
  state: initialHighlightingState,
  onHighlightAction: () => {},
  onUpdateHighlight: () => {},
  onStartHighlight: () => {},
  onEndHighlight: () => {},
  onClearHighlight: () => {},
};

export const HighlightingContext =
  createContext<HighlightingContextValue<string>>(initContext);
