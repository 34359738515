import { captureException } from '@sentry/react';
import { useQuery, UseQueryResult } from 'react-query';

import { userDocumentRetrieve } from 'api/privateDocsApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export interface PrivateDocument {
  downloadUrl?: string;
  fileSize?: number;
  filename?: string;
  privateDocumentId: string;
}

export const usePrivateDocumentRetrieve = (
  privateDocumentId: string | null
): UseQueryResult<PrivateDocument | undefined, unknown> => {
  const { tenant } = useParsedHostname();
  const fetchPrivateDocument = async () => {
    try {
      if (privateDocumentId) {
        const { data } = await userDocumentRetrieve(privateDocumentId, tenant);

        return {
          downloadUrl: data.content_url,
          fileSize: data.content_file_size,
          filename: data.content_file_name,
          privateDocumentId: data.id,
        };
      }
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  };
  const privateDocument = useQuery(
    ['privateDocument', privateDocumentId],
    fetchPrivateDocument,
    {
      ...QUERY_OPTIONS,
      enabled: !!privateDocumentId,
    }
  );

  return privateDocument;
};
