import { ComponentsOverrides } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';

export const buttonOverrides: ComponentsOverrides<Theme>['MuiButton'] = {
  contained: ({ theme }) => ({
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
    boxShadow: 'none',
  }),
  outlinedNeutral: ({ theme }) => ({
    '&:hover': {
      backgroundColor: alpha(theme.palette.neutral.main, 0.3),
    },
    color: theme.palette.neutral.contrastText,
  }),
  root: ({ theme }) => ({
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    fontWeight: theme.typography
      .fontWeightRegular as CSSProperties['fontWeight'],
    padding: '6px 12px',
    textTransform: 'initial',
    whiteSpace: 'nowrap',
  }),
  sizeSmall: ({ theme }) => ({
    padding: theme.spacing(0.5, 1.25),
  }),
  sizeXSmall: ({ theme }) => ({
    fontSize: '0.75rem',
    padding: theme.spacing(0, 0.75),
  }),
  textNeutral: ({ theme }) => ({
    '&:hover': {
      backgroundColor: alpha(theme.palette.neutral.main, 0.3),
    },
    color: theme.palette.neutral.contrastText,
  }),
};

export const iconButtonOverrides: ComponentsOverrides<Theme>['MuiIconButton'] =
  {
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.75),
    }),
  };

export const toggleButtonOverrides: ComponentsOverrides<Theme>['MuiToggleButton'] =
  {
    root: {
      padding: 7,
    },
    sizeSmall: {
      padding: 4,
    },
  };

export const buttonGroupOverrides: ComponentsOverrides<Theme>['MuiButtonGroup'] =
  {
    groupedTextHorizontal: ({ theme }) => ({
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    }),
    groupedTextVertical: ({ theme }) => ({
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    }),
  };
