import React from 'react';

import { FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';

import Recaptcha, { RecaptchaProps } from 'common/components/Recaptcha';

type FormCaptchaFieldProps = {
  helperText?: string;
  name: string;
} & RecaptchaProps;

const FormCaptchaField = ({
  helperText,
  id,
  name,
  onExpired,
  onVerify,
  ...recaptchaProps
}: FormCaptchaFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta, fieldHelpers] = useField<string>({ name, type: 'text' });
  const formHelperText =
    (meta.touched && meta.error && meta.error) || helperText;

  const handleVerify = (token: string) => {
    void fieldHelpers.setValue(token);
    onVerify?.(token);
  };

  const handleExpired = () => {
    void fieldHelpers.setValue('');
    onExpired?.();
  };

  return (
    <FormControl>
      <Recaptcha
        id={id}
        onExpired={handleExpired}
        onVerify={handleVerify}
        {...recaptchaProps}
      />
      {formHelperText && (
        <FormHelperText id={`${id}-helper-text`}>
          {formHelperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormCaptchaField;
