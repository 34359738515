import { useContext, useCallback } from 'react';
import { PDFViewerContext } from '../components/PDFViewer/PDFViewer.context';
import { HighlightMode } from '../types';

export const useHighlightMode = () => {
  const {
    state: { highlightMode },
    dispatch,
  } = useContext(PDFViewerContext);

  const setHighlightMode = useCallback(
    (mode: HighlightMode) => {
      dispatch({ type: 'setHighlightMode', payload: mode });
    },
    [dispatch]
  );

  return {
    highlightMode,
    setHighlightMode,
  };
};
