import React from 'react';

import { CardHeader, Link, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';

import { usePageInfo } from './hooks/usePageInfo';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  root: {
    padding: theme.spacing(1, 2),
  },
}));

interface NoteCardInfoProps {
  originalUrl: string;
}

export const CardFooterWithChatOriginalPage = ({
  originalUrl,
}: NoteCardInfoProps) => {
  const classes = useStyles();

  const { linkIcon, linkText, linkUrl } = usePageInfo({ originalUrl });

  return (
    <CardHeader
      avatar={<StyledAvatar size="small">{linkIcon}</StyledAvatar>}
      classes={classes}
      title={
        <Link component={RouterLink} to={linkUrl} underline="hover">
          {linkText}
        </Link>
      }
    />
  );
};
