import React from 'react';

import { Alert, Box, BoxProps, Typography } from '@mui/material';

export type EmptyStateProps = {
  children?: React.ReactNode;
  prependButton?: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
} & Omit<BoxProps, 'title'>;

const EmptyState = ({
  children,
  prependButton,
  subtitle,
  title,
  ...boxProps
}: EmptyStateProps) => {
  return (
    <Alert
      data-testid="EmptyState"
      severity="info"
      sx={{ flexDirection: 'column', mb: 0, mt: 0 }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth={400}
        width="100%"
        {...boxProps}
      >
        <Typography align="center" variant="body1" gutterBottom>
          {title}
        </Typography>

        {subtitle && (
          <Typography
            align="center"
            color="textSecondary"
            sx={{ whiteSpace: 'pre-line' }}
            variant="body2"
            gutterBottom
          >
            {subtitle}
          </Typography>
        )}

        {children && <Box mt={2}>{children}</Box>}

        {prependButton && <Box my={0}>{prependButton}</Box>}
      </Box>
    </Alert>
  );
};

export default EmptyState;
