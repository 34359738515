import React, { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Nullable } from 'common/utils/assert';

export interface EvidenceTitleProps {
  isLoading: boolean;
  title: Nullable<string>;
}

export const EvidenceTitle: FC<EvidenceTitleProps> = ({ isLoading, title }) => {
  const { t } = useTranslation('common');

  if (isLoading) {
    return (
      <Box sx={{ marginLeft: 1 }}>
        <CircularProgress size={14} disableShrink />
      </Box>
    );
  }

  return (
    <Typography color="grey.600" sx={{ paddingLeft: '0.5rem' }} variant="body2">
      {title ?? t('chat.evidence.sameDocument.label')}
    </Typography>
  );
};
