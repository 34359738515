import React from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RetrievalUnitSkeleton } from 'containers/RetrievalUnit/RetrievalUnitSkeleton';

type DocResultsContainerProps = {
  children: React.ReactNode;
  error?: string;
  isError?: boolean;
  isLoading?: boolean;
  noResults?: boolean;
};

const DocResultsContainer = ({
  children,
  error,
  isError,
  isLoading,
  noResults,
}: DocResultsContainerProps) => {
  const { t } = useTranslation('search');

  if (isLoading) {
    return (
      <>
        <RetrievalUnitSkeleton />
        <RetrievalUnitSkeleton />
        <RetrievalUnitSkeleton />
      </>
    );
  }

  return (
    <>
      {isError && (
        <Alert severity="error">
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}

      {noResults && (
        <Alert severity="info">
          <AlertTitle>{t('results.noPapers.title')}</AlertTitle>
          {t('results.noPapers.body')}
        </Alert>
      )}

      {children}
    </>
  );
};

export default DocResultsContainer;
