import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarInput: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      textAlign: 'center',
      width: 30,
    },
  })
);
