import { useEffect } from 'react';

import { AppDispatch } from 'app/state/store';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from 'containers/Auth/hooks/useAuth';
import {
  getInitSeenRecommendations,
  selectSeenRecommendations,
} from 'containers/SeenRecommendations/seenRecommendations.slice';

export interface UseRecommendationsUpdatesReturn {
  hasNewRecs: boolean;
  unseenRecsCount: number;
}

export const useRecommendationsUpdates =
  (): UseRecommendationsUpdatesReturn => {
    const dispatch = useDispatch<AppDispatch>();
    const { allRecCount, hasNewRecs, seenRecCount } = useSelector(
      selectSeenRecommendations
    );
    const { isAuthenticated } = useAuth();

    useEffect(() => {
      if (!isAuthenticated) return;
      const interval = setInterval(
        () => dispatch(getInitSeenRecommendations()),
        3600000
      );
      const getInitSeenRecs = dispatch(getInitSeenRecommendations());
      return () => {
        getInitSeenRecs.abort();
        clearInterval(interval);
      };
    }, [dispatch, isAuthenticated]);

    return {
      hasNewRecs,
      unseenRecsCount: allRecCount - seenRecCount,
    };
  };
