import { RectBase } from '../types';
import { isHTMLCanvasElement } from './highlights.utils';

export const makeCanvasScreenshot = (
  canvas: HTMLCanvasElement,
  position: RectBase
): string => {
  try {
    const { x1, y1, width, height } = position;

    const newCanvas = canvas?.ownerDocument?.createElement('canvas');

    if (!newCanvas || !isHTMLCanvasElement(newCanvas)) {
      return '';
    }

    const dpr: number = window.devicePixelRatio;

    newCanvas.width = width * dpr;
    newCanvas.height = height * dpr;

    const newCanvasContext = newCanvas.getContext('2d');

    if (!newCanvasContext || !canvas) {
      return '';
    }

    newCanvasContext.drawImage(
      canvas,
      x1 * dpr,
      y1 * dpr,
      width * dpr,
      height * dpr,
      0,
      0,
      width * dpr,
      height * dpr
    );

    return newCanvas.toDataURL('image/png');
  } catch (error) {
    console.error('makeCanvasScreenshot.error', error);
    return '';
  }
};
