import React, { FC, PropsWithChildren } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton } from '@mui/material';

import { Nullable } from '../../../../utils/assert';

interface Props {
  status: Nullable<'loading' | 'success' | 'error'>;
}

export const StatusIcon: FC<PropsWithChildren<Props>> = ({
  children,
  status,
}) => {
  switch (status) {
    case 'success':
      return (
        <IconButton color="success" edge="end" size="large" disabled>
          <CheckIcon />
        </IconButton>
      );
    case 'error':
      return (
        <IconButton color="error" edge="end" size="large" disabled>
          <ErrorIcon />
        </IconButton>
      );
    case 'loading':
    default:
      return <>{children}</>;
  }
};

export default StatusIcon;
