import React, { useMemo } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { FileUploadField } from 'common/components/Fields/FileUploadField/FileUploadField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import TagsAutocompleteSingle from 'containers/Tagging/TagsAutocomplete/TagsAutocompleteSingle';
import { selectOwnTags } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { getTagNames } from 'containers/Tags/tags.utils';

import { BatchUploadPrivateDocsFormValues } from '../BatchUploadPrivateDocs.interface';
import { useBatchUploadPrivateDocsForm } from '../hooks/useBatchUploadPrivateDocsForm';
import { useBatchUploadPrivateDocsValidation } from '../hooks/useBatchUploadPrivateDocsValidation';

type BatchUploadPrivateDocsFormProps = {
  onFormSubmit: () => void;
};

const BatchUploadPrivateDocsForm = ({
  onFormSubmit,
}: BatchUploadPrivateDocsFormProps) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const validationSchema = useBatchUploadPrivateDocsValidation();
  const allTags = useSelector(selectOwnTags);
  const tagOptions = useMemo(() => getTagNames(allTags), [allTags]);
  const { initialValues, onSubmit } =
    useBatchUploadPrivateDocsForm(onFormSubmit);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onReset={onFormSubmit}
      onSubmit={onSubmit}
    >
      {(helpers: FormikProps<BatchUploadPrivateDocsFormValues>) => (
        <Form>
          <DialogContent>
            <DialogContentText>
              {t('privateDocs:batchUploadDialog.explanation')}
            </DialogContentText>

            <FileUploadField
              accept={{ 'text/ris': ['.ris'] }}
              label={t('privateDocs:form.batchUploadFile.label')}
              name="file"
            />

            <TagsAutocompleteSingle
              helperText={t('privateDocs:form.batchUploadSelectTag.helperText')}
              label={t('privateDocs:form.batchUploadSelectTag.label')}
              margin="normal"
              name="selectedTag"
              tags={tagOptions}
            />
          </DialogContent>

          <DialogActions>
            <Grid justifyContent="flex-end" spacing={2} container>
              <Grid item>
                <Button disabled={helpers.isSubmitting} type="reset">
                  {t('common:buttons.cancel')}
                </Button>
              </Grid>

              <Grid item>
                <SubmitButton
                  disabled={helpers.isSubmitting}
                  eventName={TrackEventName.PrivateDocumentBatchUploaded}
                  isSubmitting={helpers.isSubmitting}
                  text={t('privateDocs:form.submitButton.text')}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

BatchUploadPrivateDocsForm.displayName = 'BatchUploadPrivateDocsForm';

export default BatchUploadPrivateDocsForm;
