import { useCallback, useState } from 'react';
import { LoadingProcessData } from 'react-pdf';
import { useMounted } from './useMounted';

export type UseLoadProgressReturn = {
  loadProgress: number;
  onLoadProgress: ({ loaded, total }: LoadingProcessData) => void;
};

export const useLoadProgress = () => {
  const mounted = useMounted();
  const [progress, setProgress] = useState(0);

  const handleLoadProgress = useCallback(
    ({ loaded, total }: LoadingProcessData) => {
      const percentage = (loaded / total) * 100;

      if (!mounted) return;

      setProgress(Math.min(percentage, 100));
    },
    [mounted]
  );

  return {
    loadProgress: progress,
    onLoadProgress: handleLoadProgress,
  };
};
