import React from 'react';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface ReferenceLinkProps {
  href: string;
  id: number;
}
const useStyles = makeStyles((theme: Theme) => ({
  colorReferenceLink: {
    backgroundColor: theme.palette.neutral.light,
    color: theme.palette.primary.main,
  },
}));

export const ReferenceLink: React.FC<ReferenceLinkProps> = ({ href, id }) => {
  const classes = useStyles();
  return (
    <a
      className={classes.colorReferenceLink}
      href={href}
      rel="noopener noreferrer"
      style={{
        borderRadius: '6px',
        display: 'inline-block',
        fontSize: '0.85em',
        fontWeight: 'bold',
        margin: '0 2px',
        padding: '1px 4px',
        textDecoration: 'none',
      }}
      target="_blank"
    >
      {id}
    </a>
  );
};
