import React, { FC, useState } from 'react';

import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { addTag } from 'containers/Tags/ownTagsSlice/ownTags.slice';

import { useTagsAutocompleteFilterOptions } from './hooks/useTagsAutocompleteFilterOptions';
import { useTagsAutocompleteOptions } from './hooks/useTagsAutocompleteOptions';
import { useTagsAutocompleteOptionSelected } from './hooks/useTagsAutocompleteOptionSelected';
import { useTagsAutocompleteRenderInput } from './hooks/useTagsAutocompleteRenderInput';
import { useTagsAutocompleteRenderOption } from './hooks/useTagsAutocompleteRenderOption';
import { useTagsAutocompleteRenderTags } from './hooks/useTagsAutocompleteRenderTags';
import { NewTagsAutocompleteOptionValue } from './models/NewTagsAutocompleteOption';
import { TagsAutocompleteOptionValue } from './TagsAutocomplete.types';

const DEFAULT_SUGGESTED_TAGS: TagDetailsBase[] = [];

interface TagsAutocompleteProps {
  autoFocus?: boolean;
  helperText?: string;
  label?: string;
  loading?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  name: string;
  onBlur?: () => void;
  onChange?: (value: TagsAutocompleteOptionValue[]) => void;
  suggestedTags?: TagDetailsBase[];
}

const TagsAutocomplete: FC<TagsAutocompleteProps> = ({
  autoFocus,
  helperText,
  label,
  loading,
  margin,
  name,
  onBlur = () => null,
  onChange,
  suggestedTags = DEFAULT_SUGGESTED_TAGS,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('tags');
  const [creating, setCreating] = useState<boolean>(false);
  const [field, meta, fieldHelpers] =
    useField<TagsAutocompleteOptionValue[]>(name);
  const hasError = !!(meta.touched && meta.error);
  const { tenant } = useParsedHostname();
  const { options, ownTags } = useTagsAutocompleteOptions();

  const renderOption = useTagsAutocompleteRenderOption(ownTags);

  const filterOptions = useTagsAutocompleteFilterOptions();

  const renderTags = useTagsAutocompleteRenderTags(suggestedTags);

  const getOptionSelected = useTagsAutocompleteOptionSelected();

  const renderInput = useTagsAutocompleteRenderInput({
    autoFocus,
    error: hasError,
    helperText: hasError ? meta.error : helperText,
    label,
    loading,
    margin,
  });

  const handleChange = async (
    event: React.ChangeEvent<boolean>,
    val: Array<TagsAutocompleteOptionValue | string>,
    reason: AutocompleteChangeReason
  ): Promise<void> => {
    const newValue = val as TagsAutocompleteOptionValue[];

    onChange?.(newValue);

    const newTag = newValue.find(
      (v: TagsAutocompleteOptionValue) =>
        v instanceof NewTagsAutocompleteOptionValue
    );

    const fixedOptions =
      reason === 'clear'
        ? field.value.filter(
            (v) => v.permission === 'read' && v.type === TagType.Shared
          )
        : [];

    if (!newTag) {
      await fieldHelpers.setValue([...fixedOptions, ...newValue]);
      return;
    }

    setCreating(true);

    const resultAction = await dispatch(addTag({ payload: newTag, tenant }));

    setCreating(false);

    if (addTag.rejected.match(resultAction)) {
      enqueueSnackbar(resultAction.error.message, {
        variant: 'error',
      });

      return;
    }

    await fieldHelpers.setValue(
      newValue.map((v) =>
        v instanceof NewTagsAutocompleteOptionValue ? resultAction.payload : v
      )
    );

    enqueueSnackbar(
      t('tags:addNewTag.successMessage', {
        tagName: newTag.name,
      })
    );
  };

  return (
    <Autocomplete
      data-testid="TagsAutocomplete"
      filterOptions={filterOptions}
      getOptionLabel={(option: TagsAutocompleteOptionValue | string) =>
        typeof option === 'string' ? option : option.name
      }
      isOptionEqualToValue={getOptionSelected}
      ListboxProps={{ 'aria-label': 'tagsAutocompleteList' }}
      loading={loading || creating}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      renderTags={renderTags}
      size="small"
      value={field.value}
      autoHighlight
      clearOnBlur
      filterSelectedOptions
      freeSolo
      multiple
      onBlur={onBlur}
      // @ts-ignore
      onChange={handleChange}
    />
  );
};

TagsAutocomplete.displayName = 'TagsAutocomplete';

export default TagsAutocomplete;
