export class PaginatedResults<T> {
  readonly count: number;
  readonly items: T[];
  readonly page: number;
  readonly pageSize: number;
  readonly nextPage?: number;
  readonly prevPage?: number;

  constructor(
    count: number,
    items: T[],
    nextPage?: number,
    prevPage?: number,
    page: number = 1,
    pageSize: number = 10
  ) {
    this.count = count;
    this.items = items;
    this.page = page;
    this.pageSize = pageSize;
    this.nextPage = nextPage;
    this.prevPage = prevPage;
  }
}
