import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const listItemIconOverrides: ComponentsOverrides<Theme>['MuiListItemIcon'] =
  {
    root: {
      minWidth: 26,
    },
  };

export const listItemTextOverrides: ComponentsOverrides<Theme>['MuiListItemText'] =
  {
    dense: {
      margin: 0,
    },
  };
