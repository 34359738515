import React, { FC, useContext } from 'react';

import { AuthContext } from '../../Auth/Auth.context';

import { NavbarProfileLogoutCustomButtons } from './NavbarProfileLogoutCustomButtons';
import { NavbarProfileLogoutOidcButtons } from './NavbarProfileLogoutOidcButtons';

export const NavbarProfileLogoutButtons: FC = () => {
  const { oidcConfig } = useContext(AuthContext);

  return oidcConfig?.enabled ? (
    <NavbarProfileLogoutOidcButtons />
  ) : (
    <NavbarProfileLogoutCustomButtons />
  );
};
