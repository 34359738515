import { useCallback, useReducer } from 'react';

import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';

import { deserializeAxiosError } from 'common/utils/error';

export type SimpleState<T> = {
  data: T;
  error: string | null;
  fetchState: FiniteStatesType;
};

export enum SimpleStateActionEnum {
  ResetState,
}

export type SimpleAction<T> =
  | { payload: T; type: FiniteStates.Success }
  | { payload: T; type: FiniteStates.Updated }
  | { payload: string; type: FiniteStates.Failure }
  | { type: FiniteStates.Loading }
  | { type: FiniteStates.Idle }
  | { payload: SimpleState<T>; type: SimpleStateActionEnum.ResetState };

export type SimpleStateReturnValue<T> = {
  resetFetchState: () => void;
  resetState: () => void;
  setFailure: (e: any) => void;
  setLoading: () => void;
  setValue: (
    data: T,
    type?: FiniteStates.Success | FiniteStates.Updated
  ) => void;
  state: SimpleState<T>;
};

const simpleStateReducer = <T>(
  state: SimpleState<T>,
  action: SimpleAction<T>
): SimpleState<T> => {
  switch (action.type) {
    case SimpleStateActionEnum.ResetState:
      return action.payload;
    case FiniteStates.Idle:
      return { ...state, fetchState: FiniteStates.Idle };
    case FiniteStates.Loading:
      return { ...state, fetchState: FiniteStates.Loading };
    case FiniteStates.Success:
    case FiniteStates.Updated:
      return {
        data: action.payload,
        error: null,
        fetchState: action.type,
      };
    case FiniteStates.Failure:
      return {
        ...state,
        error: action.payload,
        fetchState: FiniteStates.Failure,
      };
    default:
      throw new Error();
  }
};

export const useSimpleState = <T>(
  initialState: SimpleState<T>
): SimpleStateReturnValue<T> => {
  const [state, action] = useReducer<
    (_state: SimpleState<T>, _action: SimpleAction<T>) => SimpleState<T>
  >(simpleStateReducer, initialState);

  const setLoading = useCallback(() => {
    action({ type: FiniteStates.Loading });
  }, [action]);

  const setFailure = useCallback(
    (e: any) => {
      action({
        payload: deserializeAxiosError(e).message,
        type: FiniteStates.Failure,
      });
    },
    [action]
  );

  const setValue = useCallback(
    (
      payload: T,
      type: FiniteStates.Success | FiniteStates.Updated = FiniteStates.Success
    ) => {
      action({ payload, type });
    },
    [action]
  );

  const resetState = useCallback(() => {
    action({
      payload: initialState,
      type: SimpleStateActionEnum.ResetState,
    });
  }, [action, initialState]);

  const resetFetchState = useCallback(() => {
    action({ type: FiniteStates.Idle });
  }, [action, initialState]);

  return {
    resetFetchState,
    resetState,
    setFailure,
    setLoading,
    setValue,
    state,
  };
};
