import React from 'react';

import { Chip, Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { errorChipStyles } from 'app/theme/styles/chip';

export const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    ...errorChipStyles(theme),
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  children?: React.ReactNode;
  chip?: boolean;
  chipLabel?: React.ReactText;
  chipTooltip?: string;
};

export const MenuItemTitle = React.forwardRef<HTMLDivElement, Props>(
  ({ children, chip, chipLabel, chipTooltip = '' }, ref) => {
    const classes = useStyles();

    return (
      <div className={classes.wrapper} ref={ref}>
        {children}

        {chip && (
          <Tooltip data-testid="menuItemChip" title={chipTooltip}>
            <Chip className={classes.chip} label={chipLabel} size="small" />
          </Tooltip>
        )}
      </div>
    );
  }
);
