import { InfiniteData, useQueryClient } from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { taggedDocsQueryKeys } from '../taggedDocs.utils';

export const useOnAddTaggedDoc = () => {
  const queryClient = useQueryClient();

  return (
    tagId: number,
    addedDoc: RetrievalUnitData,
    queryFilters: Omit<QueryFilters, 'queryKey'> = { active: true }
  ) => {
    queryClient.setQueriesData<
      InfiniteData<PaginatedResults<RetrievalUnitData>> | undefined
    >(
      { queryKey: taggedDocsQueryKeys.list(tagId), ...queryFilters },
      (oldResults) => {
        if (!oldResults || oldResults.pages.length === 0) return oldResults;

        const [firstPage, ...restPages] = oldResults.pages;

        return {
          pageParams: oldResults.pageParams,
          pages: [
            {
              ...firstPage,
              count: firstPage.items.length + 1,
              items: [addedDoc, ...firstPage.items],
            },
            ...restPages,
          ],
        };
      }
    );
  };
};
