import { useSelector } from 'react-redux';

import { TagDetails } from 'api/tagsApi/tagsApi.types';

import { selectOwnTagsByIds } from './ownTagsSlice/ownTags.slice';
import { selectSharedTagsByIds } from './sharedTagsSlice/sharedTags.slice';

export type UseTagDetailsReturn = {
  data: TagDetails | null;
};

export const useTagDetails = (tagId: number): UseTagDetailsReturn => {
  const ownTags = useSelector(selectOwnTagsByIds([tagId]));
  const sharedTags = useSelector(selectSharedTagsByIds([tagId]));

  return {
    data: ownTags[0] ?? sharedTags[0] ?? null,
  };
};
