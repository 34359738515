import { Theme, ComponentsOverrides } from '@mui/material';

export const toolbarOverrides: ComponentsOverrides<Theme>['MuiToolbar'] = {
  gutters: ({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
};
