export enum SearchTermEnum {
  AuthoredBy = 'authored_by',
  CitedBy = 'cited_by',
  Cites = 'cites',
  HasConcept = 'has_concept',
}

export type SearchTermEnumType =
  | SearchTermEnum.AuthoredBy
  | SearchTermEnum.CitedBy
  | SearchTermEnum.Cites
  | SearchTermEnum.HasConcept;
