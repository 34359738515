import { useState, useEffect } from 'react';

import { AsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/state/store';
import { useDispatch } from 'react-redux';

import { TagDetails, TagOrderUpdatePayload } from 'api/tagsApi/tagsApi.types';
import { useFilterTagsByName } from 'common/hooks/useFilterTagsByName';
import { arrayMove } from 'common/utils/arrayHelpers';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';

import { TagDragItem } from './TagDragItem.interface';

export interface UseOrderedTagsListReturnValue {
  handleDrop: (item: TagDragItem, id: number) => Promise<void>;
  handleMove: (draggedId: number, newId: number) => void;
  listItems: TagDetails[];
}

export interface UseOrderedTagsListArgs {
  filteredTagName: string;
  sortBy: TagsSortByEnum;
  tags: TagDetails[];
  updateTagOrderAction: AsyncThunk<
    TagDetails[],
    TagOrderUpdatePayload,
    {
      state: RootState;
    }
  >;
}

export const useTagsListOrdering = ({
  filteredTagName,
  tags,
  updateTagOrderAction,
}: UseOrderedTagsListArgs): UseOrderedTagsListReturnValue => {
  const dispatch = useDispatch<AppDispatch>();
  const filteredTags = useFilterTagsByName(filteredTagName, tags);
  const [listItems, setListItems] = useState(filteredTags);
  const { tenant } = useParsedHostname();
  useEffect(() => {
    setListItems(filteredTags);
  }, [filteredTags]);

  const handleMove = (draggedId: number, newId: number) => {
    const oldIndex = listItems.findIndex(({ id }) => draggedId === id);
    const newIndex = listItems.findIndex(({ id }) => newId === id);

    setListItems(arrayMove(listItems, oldIndex, newIndex));
  };

  const handleDrop = async (item: TagDragItem, tagId: number) => {
    const oldIndex = tags.findIndex(({ id }) => item.id === id);
    const newIndex = listItems.findIndex(({ id }) => tagId === id);

    if (oldIndex !== -1 && newIndex !== -1) {
      await dispatch(
        updateTagOrderAction({ id: item.id, newIndex, oldIndex, tenant })
      );
    }
  };

  return {
    handleDrop,
    handleMove,
    listItems,
  };
};
