import React, { FC } from 'react';

import NoteIcon from '@mui/icons-material/Notes';
import { CardHeader, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { TagDetails } from 'api/tagsApi';
import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import NoteCardDocInfo from '../NoteCardDocInfo';
import NoteCardTagInfo from '../NoteCardTagInfo';

import { CardFooterWithChatOriginalPage } from './CardHeaderWithChatOriginalPage/CardFooterWithChatOriginalPage';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
}));

interface NoteFooterProps {
  docData: RetrievalUnitData | null | undefined;
  isDocDataLoading: boolean;
  originalUrl: string | undefined;
  tagData: TagDetails | undefined | null;
}

export const NoteFooter: FC<NoteFooterProps> = ({
  docData,
  isDocDataLoading,
  originalUrl,
  tagData,
}) => {
  const classes = useStyles();
  const history = useHistory();

  if (originalUrl) {
    return <CardFooterWithChatOriginalPage originalUrl={originalUrl} />;
  }

  if (docData) {
    return <NoteCardDocInfo docData={docData} isLoading={isDocDataLoading} />;
  }

  if (tagData) {
    const handleTagClick = () => {
      if (tagData) {
        history.push(
          tagData.type !== TagType.Favourites
            ? `/tags/${tagData.id}`
            : `/favorites`
        );
      }
    };
    return <NoteCardTagInfo tag={tagData} onTagClick={handleTagClick} />;
  }

  return (
    <CardHeader
      avatar={
        <StyledAvatar size="small">
          <NoteIcon fontSize="small" />
        </StyledAvatar>
      }
      className={classes.cardHeader}
    />
  );
};
