import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TagSharingPermission } from 'api/tagsApi';

export type TagPermissionTooltipProps = {
  permission: TagSharingPermission;
} & Omit<TooltipProps, 'title'>;

const TagPermissionTooltip = ({
  children,
  permission,
  ...rest
}: TagPermissionTooltipProps) => {
  const { t } = useTranslation('tags');

  return (
    <Tooltip title={t(`permissions.${permission}.tooltip`)} {...rest}>
      {children}
    </Tooltip>
  );
};

TagPermissionTooltip.displayName = 'TagPermissionTooltip';

export default TagPermissionTooltip;
