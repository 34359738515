import React from 'react';

import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { useUserDetails } from 'containers/Users/hooks/useUserDetails';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';

const useStyles = makeStyles((theme: Theme) => ({
  owner: {
    color: theme.palette.text.secondary,
    flex: 'none',
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  tagName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export type TagsAutocompleteOptionProps = {
  isNew?: boolean;
  option: TagsAutocompleteOptionValue;
  owner: string;
};

const TagsAutocompleteOption = ({
  isNew,
  option,
}: TagsAutocompleteOptionProps) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const isShared = option.type === TagType.Shared;
  const { data } = useUserDetails({
    memberId: option.ownerId ?? '',
    options: { enabled: !!option.ownerId, staleTime: Infinity },
  });

  if (isNew) {
    return (
      <Typography title={option.name} noWrap>
        <strong>
          {t('tagsAutocomplete.option.createNewTag', { tagName: option.name })}
        </strong>
      </Typography>
    );
  }

  return (
    <Typography className={classes.root} title={option.name}>
      <span className={classes.tagName}>{option.name}</span>

      {isShared && data?.firstName && (
        <i className={classes.owner}>
          {t('tagsAutocomplete.option.tagOwner', { owner: data.firstName })}
        </i>
      )}
    </Typography>
  );
};

export default TagsAutocompleteOption;
