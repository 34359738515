import React, { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ContentLoader from 'common/components/Loaders/ContentLoader/ContentLoader';

interface Props {
  greetings?: ReactNode;
  isLoading?: boolean;
}

export const Greetings: FC<Props> = ({ greetings, isLoading }) => {
  const { t } = useTranslation('chat');

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {isLoading ? (
        <ContentLoader />
      ) : greetings ? (
        greetings
      ) : (
        <>
          <Typography
            sx={{ color: '#eee', m: 1, textAlign: 'center' }}
            variant="h2"
          >
            {t('start.label')}
          </Typography>
          <Typography
            sx={{ color: '#eee', m: 1, textAlign: 'center' }}
            variant="h1"
          >
            ⤵
          </Typography>
        </>
      )}
    </Box>
  );
};
