import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const MIN_TAG_LENGTH = 1;
export const MAX_TAG_LENGTH = 128;

export const useTagSettingsValidation = () => {
  const { t } = useTranslation('tags');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tagName: Yup.string()
          .min(MIN_TAG_LENGTH, t('tags:errors.minLength'))
          .max(MAX_TAG_LENGTH, t('tags:errors.maxLength'))
          .required(t('common:errors.required')),
      }),
    []
  );

  return validationSchema;
};
