import { SearchFilterSettings } from '@zarn/vendor/dist/search';

import { defined, isExist } from 'common/utils/assert';

import {
  CheckboxDynamicSettings,
  DynamicSettings,
  SearchFilterDynamicsType,
} from './tenantSettingsApi.types';

export const isNotDynamicFilter = (filter: SearchFilterSettings) =>
  !filter.url_param;

export const isDynamicFilter = (filter: SearchFilterSettings) =>
  !!filter.url_param;

const deserializeCheckboxDynamicFilters = (
  filter: SearchFilterSettings
): CheckboxDynamicSettings => ({
  faceted: !!filter.faceted,
  fieldName: defined(filter.field_name, 'filter.field_name'),
  filterType: SearchFilterDynamicsType.Checkbox,
  filterTypeSettings: {
    values: defined(
      defined(
        defined(filter.filter_type_settings, 'filter.filter_type_settings')
          .checkbox,
        'filter.filter_type_settings.checkbox'
      ).values,
      'filter.filter_type_settings.checkbox.values'
    ),
  },
  label: filter.display_name,
  urlParam: defined(filter.url_param, 'filter.url_param'),
});

const deserializeDynamicFilter = (
  filter: SearchFilterSettings
): DynamicSettings | null => {
  switch (filter['filter_type']) {
    case 'checkbox':
      return deserializeCheckboxDynamicFilters(filter);
    default:
      return null;
  }
};

export const deserializeDynamicFilters = (
  allFilters: SearchFilterSettings[]
): DynamicSettings[] =>
  allFilters
    .filter(isDynamicFilter)
    .map(deserializeDynamicFilter)
    .filter(isExist);
