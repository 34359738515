import React, { useState } from 'react';

import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';

import { AddPrivateDocsDialog } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocsDialog/AddPrivateDocsDialog';
import { useAddPrivateDocsDialog } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocsDialog/useAddPrivateDocsDialog';
import { AddPrivateDocsMethodEnum } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocsMethod.enum';
import { useAddPrivateDocsOptions } from 'containers/PrivateDocs/hooks/useAddPrivateDocsOptions';

export type WithPrivateDocsMenuProps<T = {}> = {
  onMenuOpen: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & T;

export function withPrivateDocsMenu<T>(
  WrappedComponent: React.ComponentType<WithPrivateDocsMenuProps<T>>,
  menuProps?: Partial<MenuProps>
) {
  const Component = ({ ...props }: Omit<T, 'onMenuOpen'>) => {
    const dialog = useAddPrivateDocsDialog();
    const options = useAddPrivateDocsOptions();
    const [popover, setPopover] = useState<HTMLButtonElement | null>(null);

    const handleOptionClick = (method: AddPrivateDocsMethodEnum) => {
      setPopover(null);
      dialog.onOpen(method);
    };

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopover(event.currentTarget);
    };

    const handleClose = () => {
      setPopover(null);
    };

    return (
      <>
        <WrappedComponent {...(props as T)} onMenuOpen={handleOpen} />

        <Menu
          anchorEl={popover}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={!!popover}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleClose}
          {...menuProps}
        >
          {options.map(({ icon: Icon, ...option }) => (
            <MenuItem
              key={option.method}
              onClick={() => handleOptionClick(option.method)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>

              <ListItemText
                primary={option.primaryText}
                secondary={option.secondaryText}
              />
            </MenuItem>
          ))}
        </Menu>

        <AddPrivateDocsDialog state={dialog.state} onClose={dialog.onClose} />
      </>
    );
  };

  Component.displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return Component;
}
