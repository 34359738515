import * as React from 'react';

import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';

import { RoleEnum } from 'containers/Auth/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';

type Props = {
  component: React.ComponentClass<any> | React.ComponentType<any>;
  roles?: RoleEnum[];
} & RouteProps;

export const PrivateRoute = ({
  component: Component,
  roles = [],
  ...rest
}: Props) => {
  const { hasAccess, isAuthenticated } = useAuth();

  const Render = (props: RouteComponentProps<any>) => {
    if (!isAuthenticated) {
      const from =
        (rest.location && `${rest.location.pathname}${rest.location.search}`) ??
        '';
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from, signUp: true },
          }}
        />
      );
    }

    if (roles.length > 0 && !hasAccess(roles)) {
      return <Redirect to={{ pathname: '/403' }} />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={Render} />;
};
