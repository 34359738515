import React from 'react';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  positionLeft: {
    left: 0,
    right: 'auto',
  },
  resizeHandle: {
    '&:hover, &:active': {
      backgroundColor: theme.palette.secondary.main,
      width: 4,
    },
    background: theme.palette.grey[100],
    cursor: 'col-resize',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 1,
    zIndex: 1,
  },
}));

type Props = {
  onResizeStart: React.MouseEventHandler<HTMLButtonElement>;
  positionLeft?: boolean;
};

export const DrawerResize = ({ onResizeStart, positionLeft }: Props) => {
  const classes = useStyles();

  return (
    <button
      aria-hidden="true"
      aria-label="resize drawer"
      className={clsx(
        classes.resizeHandle,
        positionLeft && classes.positionLeft
      )}
      type="button"
      onMouseDown={onResizeStart}
    />
  );
};
