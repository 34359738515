import {
  DynamicSettings,
  SearchFilterDynamicsType,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { DynamicFilterValue } from 'containers/Search/DynamicSearchPayload.interface';

const deserializeDynamicFilter = (
  value: string | null,
  filterSettings: DynamicSettings
): DynamicFilterValue | null => {
  if (!value) {
    return null;
  }

  if (filterSettings.filterType === SearchFilterDynamicsType.Checkbox) {
    return {
      ...filterSettings,
      value: value.split(','),
    };
  }

  return null;
};

export const deserializeDynamicFiltersSearchQuery = (
  searchParams: URLSearchParams,
  dynamicFiltersSettings: DynamicSettings[] | null
): DynamicFilterValue[] | undefined =>
  dynamicFiltersSettings?.reduce((acc, cur) => {
    const value = searchParams.get(cur.urlParam);
    const filter = deserializeDynamicFilter(value, cur);
    if (filter) {
      acc.push(filter);
    }
    return acc;
  }, [] as DynamicFilterValue[]);

export const isDynamicFilter = (
  id: string,
  dynamicFiltersSettings: DynamicSettings[] | null | undefined
): boolean => {
  return (
    dynamicFiltersSettings?.some(
      ({ fieldName, urlParam }) => fieldName === id || urlParam === id
    ) ?? false
  );
};
