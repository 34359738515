import React, { FC, useState } from 'react';

import InventoryIcon from '@mui/icons-material/Inventory';
import { useTranslation } from 'react-i18next';

import TrackedButton from 'common/components/Buttons/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { DocsContextDialog } from 'containers/Chat/DocsContext/DocsContextDialog';

interface DynamicContextButtonWithDialogProps {
  docsIds: string[];
}

export const DynamicContextButtonWithDialog: FC<
  DynamicContextButtonWithDialogProps
> = ({ docsIds }) => {
  const { t } = useTranslation(['chat']);

  const [isContextDialogOpen, setIsContextDialogOpen] = useState(false);

  const openContextDialog = () => {
    setIsContextDialogOpen(true);
  };

  const closeContextDialog = () => {
    setIsContextDialogOpen(false);
  };

  return (
    <>
      <TrackedButton
        endIcon={<InventoryIcon />}
        eventName={TrackEventName.MessageContextInChatClicked}
        size="small"
        sx={{ color: 'gray' }}
        onClick={openContextDialog}
      >
        {t(`message.context.label`)}
      </TrackedButton>
      <DocsContextDialog
        closeDialog={closeContextDialog}
        dialogOpen={isContextDialogOpen}
        docsIds={docsIds}
      />
    </>
  );
};
