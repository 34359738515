import React from 'react';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Chip } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataFollowersProps = {
  numberOfFollowers: number;
};

const TagMetadataFollowers = ({
  numberOfFollowers,
}: TagMetadataFollowersProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagMetadataItem
      icon={SupervisedUserCircleIcon}
      translation={
        <Trans i18nKey="tagMetadata.numberOfFollowers.text" t={t}>
          <Chip label={numberOfFollowers} size="small" />
        </Trans>
      }
    />
  );
};

TagMetadataFollowers.displayName = 'TagMetadataFollowers';

export default TagMetadataFollowers;
