import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';

import { NoteDetails, UpdateNotePayload } from 'api/notesApi/notesApi.types';
import { savedNotesActionsFactory } from 'containers/SavedNotes/savedNotes.actions';
import {
  SavedNote,
  SavedNotes,
} from 'containers/SavedNotes/SavedNotes.interface';

import {
  createTagNoteThunk,
  getTagNoteListThunk,
  updateTagNoteThunk,
} from './tagsNotesSlice.utils';

const SLICE_NAME = 'tagsNotes';

const SavedNotesActions = savedNotesActionsFactory(
  SLICE_NAME,
  NoteObjectType.Tag
);

export type TagNotesState = {
  data: SavedNotes;
  error: string | null;
  fetchState: FiniteStatesType;
};

export const initialState: TagNotesState = {
  data: {},
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getTagNoteList = createAsyncThunk(
  `${SLICE_NAME}/getTagNoteList`,
  getTagNoteListThunk
);

export const { deleteNote: deleteTagNote } = SavedNotesActions;

export const createTagNote = createAsyncThunk(
  `${SLICE_NAME}/create`,
  createTagNoteThunk
);

export const updateTagNote = createAsyncThunk<
  { data: NoteDetails; id: string },
  UpdateNotePayload,
  { state: RootState }
>(`${SLICE_NAME}/update`, updateTagNoteThunk);

const tagsNotes = createSlice({
  extraReducers: (builder) => {
    // Get saved tag note list
    builder
      .addCase(getTagNoteList.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getTagNoteList.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error = action.error.message || 'Unexpected error';
      })
      .addCase(getTagNoteList.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data = {
          ...state.data,
          ...payload,
        };
        state.error = null;
      });
    // Create note
    builder.addCase(createTagNote.fulfilled, (state, { payload }) => {
      const { data, id } = payload;
      state.fetchState = FiniteStates.Success;
      if (id in state.data) {
        state.data[id].numberOfNotes += 1;
        state.data[id].notesData.unshift(data);
      } else {
        state.data[id] = { id, notesData: [data], numberOfNotes: 1 };
      }
      state.error = null;
    });
    // Update note
    builder.addCase(updateTagNote.fulfilled, (state, { payload }) => {
      const { data, id } = payload;
      state.fetchState = FiniteStates.Success;
      state.data[id].notesData = state.data[id].notesData.map((note) => {
        if (note.id === data.id) return data;
        return note;
      });
      state.error = null;
    });
    // Delete note
    builder.addCase(deleteTagNote.fulfilled, (state, { payload }) => {
      const { noteId, objectId } = payload;
      state.fetchState = FiniteStates.Success;
      state.data[objectId].numberOfNotes -= 1;
      state.data[objectId].notesData = state.data[objectId].notesData.filter(
        ({ id }) => id !== noteId
      );
      state.error = null;
    });
  },
  initialState,
  name: SLICE_NAME,
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
});

const tagsNotesSelector = (state: RootState) => state.tagsNotes;

export const selectTagsNotes = createSelector(
  tagsNotesSelector,
  ({ data }) => data
);

export const selectTagNotesById = (tagId: string) =>
  createSelector(
    tagsNotesSelector,
    ({ data }): SavedNote | null => data[tagId] ?? null
  );

export const selectTagsNotesLoading = createSelector(
  tagsNotesSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export default tagsNotes.reducer;
