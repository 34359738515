import { useCallback, useContext } from 'react';

import { PDFViewerContext } from '../../components/PDFViewer/PDFViewer.context';
import { PDFPageDetails } from '../../types';

export const usePages = () => {
  const {
    state: { activePage, pages, numberOfPages },
    dispatch,
  } = useContext(PDFViewerContext);

  const setActivePage = useCallback(
    (payload: number) => {
      dispatch({ type: 'setActivePage', payload: payload });
    },
    [dispatch]
  );

  const setNumberOfPages = useCallback(
    (numPages: number) => {
      dispatch({ type: 'setNumberOfPages', payload: numPages });
    },
    [dispatch]
  );

  const setPages = useCallback(
    (pages: PDFPageDetails[]) => {
      dispatch({ type: 'setPages', payload: pages });
    },
    [dispatch]
  );

  return {
    pages,
    activePage,
    numberOfPages,
    setActivePage,
    setNumberOfPages,
    setPages,
  };
};
