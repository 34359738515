import { useEffect, useState } from 'react';
import { useTheFirstScrollableParent } from '../../../hooks/useTheFirstScrollableParent';
import { useSelectionVerticalScroll } from './useSelectionVerticalScroll';
import { useSelectionHorizonalScroll } from './useSelectionHorizonalScroll';
import Hammer from 'hammerjs';
import {
  areaSelectionToRect,
  getTouchEventItem,
  isMinSelectionSize,
} from '../../../utils';
import { AreaSelectionProps, AreaSelectionRect } from '../types';

export const useAreaSelection = ({
  selectionColor = 'yellow',
  parentEl = document.body,
  minSelectionSize = 10,
  onSelectionStart,
  onSelectionEnd,
  onSelectionChange,
  onSelectionCancel,
}: AreaSelectionProps) => {
  const [selection, setSelection] = useState<AreaSelectionRect | null>(null);
  const scrollableParent = useTheFirstScrollableParent(parentEl);
  const selectionVerticalScroll = useSelectionVerticalScroll(scrollableParent);
  const selectionHorizonalScroll =
    useSelectionHorizonalScroll(scrollableParent);
  const [mc, setMc] = useState<HammerManager | null>(null);

  useEffect(() => {
    if (!parentEl) return;

    const mc1 = new Hammer.Manager(parentEl, {
      recognizers: [[Hammer.Press, { enable: true }]],
    });

    setMc(mc1);

    return () => {
      mc1.destroy();
    };
  }, [parentEl]);

  useEffect(() => {
    if (!parentEl) return;

    function selectionStartHandler(x: number, y: number) {
      if (!parentEl) return;

      const { left, top } = parentEl.getBoundingClientRect();
      const x1 = x - left;
      const y1 = y - top;

      setSelection({ x1, y1, x2: x1, y2: y1 });

      if (!onSelectionStart) return;

      onSelectionStart();
    }

    const pressListener = (e: HammerInput) => {
      selectionStartHandler(e.center.x, e.center.y);
    };

    mc?.on('press', pressListener);

    const mouseDownListener = (e: MouseEvent) => {
      selectionStartHandler(e.clientX, e.clientY);
    };

    parentEl.addEventListener('mousedown', mouseDownListener);

    return () => {
      parentEl.removeEventListener('mousedown', mouseDownListener);
      mc?.off('press', pressListener);
    };
  }, [mc, parentEl, onSelectionStart]);

  useEffect(() => {
    if (!parentEl || !selection?.x1 || !selection?.y1) return;

    function setSelectionHandler(x: number, y: number) {
      if (!parentEl || !selection?.x1 || !selection?.y1) return;

      const { left, right, top, bottom } = parentEl.getBoundingClientRect();
      const x2 = (x <= left ? left : x >= right ? right : x) - left;
      const y2 = (y <= top ? top : y >= bottom ? bottom : y) - top;

      selectionVerticalScroll(Math.max(y, top), Math.min(y, bottom));

      selectionHorizonalScroll(Math.max(x, left), Math.min(x, right));

      const selectionRect = {
        x1: selection.x1,
        y1: selection.y1,
        x2,
        y2,
      };

      setSelection(selectionRect);

      if (
        !onSelectionChange ||
        isMinSelectionSize(selectionRect, minSelectionSize)
      )
        return;

      onSelectionChange(areaSelectionToRect(selectionRect));
    }

    function keyDownListener(e: KeyboardEvent) {
      if (e.code !== 'Escape') return;

      setSelection(null);

      document.removeEventListener('mousemove', mouseMoveListener);

      onSelectionCancel?.('Escape');
    }

    const moveListener = (e: TouchEvent) => {
      e.preventDefault();

      const touchItem = getTouchEventItem(e);
      if (!touchItem) return;

      setSelectionHandler(touchItem.clientX, touchItem.clientY);
    };

    const pressUpListener = () => {
      document.removeEventListener('touchmove', moveListener);

      onSelectionCancel?.('Escape');
    };

    document.addEventListener('touchmove', moveListener, { passive: false });

    mc?.on('pressup', pressUpListener);

    const mouseMoveListener = ({ clientX, clientY }: MouseEvent) => {
      setSelectionHandler(clientX, clientY);
    };

    document.addEventListener('mousemove', mouseMoveListener);
    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('mousemove', mouseMoveListener);
      document.removeEventListener('keydown', keyDownListener);
      document.removeEventListener('touchmove', moveListener);
      mc?.off('pressup', pressUpListener);
    };
  }, [
    parentEl,
    selectionVerticalScroll,
    selectionHorizonalScroll,
    onSelectionChange,
    onSelectionCancel,
    minSelectionSize,
    mc,
    selection?.x1,
    selection?.y1,
  ]);

  useEffect(() => {
    if (!selection) return;

    function selectionEndHandler(x: number, y: number) {
      setSelection(null);

      if (!selection) return;

      if (!onSelectionEnd || isMinSelectionSize(selection, minSelectionSize))
        return;

      onSelectionEnd({ x, y, selection: areaSelectionToRect(selection) });
    }

    const touchEndListener = (e: TouchEvent) => {
      const touchItem = getTouchEventItem(e);

      if (!touchItem) return;

      selectionEndHandler(touchItem.clientX, touchItem.clientY);
    };

    const mouseUpListener = (e: MouseEvent) => {
      selectionEndHandler(e.clientX, e.clientY);
    };

    document.addEventListener('touchend', touchEndListener);
    document.addEventListener('mouseup', mouseUpListener);

    return () => {
      document.removeEventListener('mouseup', mouseUpListener);
      document.removeEventListener('touchend', touchEndListener);
    };
  }, [onSelectionEnd, selection, minSelectionSize]);

  useEffect(() => {
    if (!selection?.x1) return;
    document.body.style.userSelect = 'none';

    return () => {
      document.body.style.userSelect = '';
    };
  }, [selection?.x1]);

  return { selection, selectionColor };
};
