import React, { FC } from 'react';

import { Box } from '@mui/material';

import { SignInMobileButton } from 'common/components/Buttons/SignInMobileButton';
import { useOidcSignIn } from 'common/hooks/useSignIn/useOidcSignIn';

export const MainSidebarOidcSignInButton: FC = () => {
  const { handleSignIn } = useOidcSignIn();

  return (
    <Box mb={2} px={2}>
      <SignInMobileButton onSignIn={handleSignIn} />
    </Box>
  );
};
