import { TagType } from '@zarn/vendor/dist/saved-results';

import { SharingPermission } from 'containers/Sharing/Sharing.types';

export enum TaggableResourceCollectionType {
  Persons = 'persons',
  Queries = 'queries',
}

export enum TaggableResourceType {
  Person = 'person',
  Query = 'query',
}

export interface TaggedResourceDetails {
  dateTagged: string;
  permission: SharingPermission;
  resourceId: string;
  resourceType: TaggableResourceType;
}

export interface TaggedResourcesFilterPayload {
  externalResourceIds?: string[];
  page: number;
  pageSize: number;
  resourceType: TaggableResourceCollectionType;
  tagId: number;
  tagType: TagType;
  tenant: string;
}

export type FavoriteResourcesFilterPayload = Omit<
  TaggedResourcesFilterPayload,
  'tagType' | 'tagId'
>;

export interface CreateTaggedResourcePayload {
  resourceId: string;
  resourceType: TaggableResourceCollectionType;
  tagId: number;
  tagType: TagType;
  tenant: string;
}

export type CreateFavoriteResourcePayload = Omit<
  CreateTaggedResourcePayload,
  'tagType' | 'tagId'
>;

export interface DeleteTaggedResourcePayload {
  resourceId: string;
  resourceType: TaggableResourceCollectionType;
  tagId: number;
  tagType: TagType;
  tenant: string;
}

export type DeleteFavoriteResourcePayload = Omit<
  CreateTaggedResourcePayload,
  'tagType' | 'tagId'
>;
