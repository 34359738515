import { captureException } from '@sentry/react';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { getBibtexList } from 'api/entityApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { deserializeBibtexList } from 'common/utils/documents';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const useBibtexList = (
  ids: string[],
  options?: UseQueryOptions<string, BaseError, string, QueryKey>
): UseQueryResult<string, BaseError> => {
  const parsedHostname = useParsedHostname();
  const fetchBibtex = async () => {
    try {
      const { data } = await getBibtexList(ids, parsedHostname.tenant);
      return deserializeBibtexList(data);
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  };

  const result = useQuery<string, BaseError>(
    ['bibtexList', ids.join(',')],
    fetchBibtex,
    {
      ...QUERY_OPTIONS,
      ...options,
      retry: 1,
    }
  );

  return result;
};
