export const isTouchDevice = (): boolean => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.maxTouchPoints > 0
  );
};

export const isMobile = (): boolean => {
  return (
    window.matchMedia('only screen and (max-width: 760px)').matches ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );
};

export const isMobileTouchDevice = () => isTouchDevice() && isMobile();

export const getTouchEventItem = (e: TouchEvent): false | Touch | null => {
  return e.changedTouches.length > 0 && e.changedTouches.item(0);
};

export const isScrollable = (ele: HTMLElement) => {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  const overflowYStyle = window.getComputedStyle(ele).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

export const getScrollableParent = (
  ele: HTMLElement | null
): HTMLElement | null => {
  if (!ele || ele === document.body) return document.body;

  return isScrollable(ele) ? ele : getScrollableParent(ele.parentElement);
};
