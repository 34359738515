import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Diversity3Icon from '@mui/icons-material/Diversity3';
import PersonIcon from '@mui/icons-material/Person';
import { AppDispatch } from 'app/state/store';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PageDrawerMenu } from 'common/components/PageDrawer/PageDrawerMenu';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { getFollowingTags } from '../followingTagsSlice/followingTags.slice';
import { getOwnTags } from '../ownTagsSlice/ownTags.slice';
import { getSharedTags } from '../sharedTagsSlice/sharedTags.slice';

import { FollowingTagsList } from './FollowingTagsList/FollowingTagsList';
import { OwnTagsList } from './OwnTagsList/OwnTagsList';
import { OwnTagsListActions } from './OwnTagsList/OwnTagsListActions';
import { SharedTagsList } from './SharedTagsList/SharedTagsList';
import { SharedTagsListActions } from './SharedTagsList/SharedTagsListActions';
import { useStyles } from './styles';
import { TagsListContext, TagsListExpandedState } from './TagsList.context';
import { TagsListAnchors } from './TagsListAnchors';
import { TagsListSearch } from './TagsListSearch';
import { TagsListType } from './TagsListType.enum';

export const TagsList = () => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const [filteredValue, setFilteredValue] = useState<string>('');
  const [searchVisibility, setSearchVisibility] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const tagsListWrapper = useRef<HTMLDivElement | null>(null);
  const ownTagsRef = useRef<HTMLDivElement | null>(null);
  const followingTagsRef = useRef<HTMLDivElement | null>(null);
  const sharedTagsRef = useRef<HTMLDivElement | null>(null);
  const [expandedListState, setExpandedListState] =
    useState<TagsListExpandedState>({
      following: true,
      own: true,
      shared: false,
    });
  const hasCollapsedSections = useMemo(
    () => Object.values(expandedListState).some((s) => !s),
    [expandedListState]
  );
  const { tenant } = useParsedHostname();

  const handleExpandedListState = useCallback(
    async (listType: TagsListType) => {
      if (!expandedListState[listType] && listType === TagsListType.Shared)
        await dispatch(getSharedTags(tenant));

      if (!expandedListState[listType] && listType === TagsListType.Following)
        await dispatch(getFollowingTags(tenant));

      setExpandedListState({
        ...expandedListState,
        [listType]: !expandedListState[listType],
      });
    },
    [expandedListState, dispatch, tenant]
  );

  const handleOwnListStateChange = useCallback(async () => {
    await handleExpandedListState(TagsListType.Own);
  }, [handleExpandedListState]);

  const handleSharedListStateChange = useCallback(async () => {
    await handleExpandedListState(TagsListType.Shared);
  }, [handleExpandedListState]);

  useEffect(() => {
    const getOwnTagsAction = dispatch(getOwnTags(tenant));
    const getFollowingTagsAction = dispatch(getFollowingTags(tenant));
    const getSharedTagsAction = dispatch(getSharedTags(tenant));

    return () => {
      getOwnTagsAction.abort();
      getFollowingTagsAction.abort();
      getSharedTagsAction.abort();
    };
  }, [dispatch, tenant]);

  useEffect(() => {
    if (filteredValue && hasCollapsedSections) {
      const getSharedTagsAction = dispatch(getSharedTags(tenant));

      setExpandedListState({
        following: true,
        own: true,
        shared: true,
      });

      return getSharedTagsAction.abort;
    }

    return undefined;
  }, [
    filteredValue,
    setExpandedListState,
    dispatch,
    hasCollapsedSections,
    tenant,
  ]);

  return (
    <TagsListContext.Provider
      value={{
        anchorsRefs: {
          following: followingTagsRef,
          own: ownTagsRef,
          shared: sharedTagsRef,
          wrapper: tagsListWrapper,
        },
        expandedListState,
        searchVisibility,
        toggleExpandedListState: handleExpandedListState,
      }}
    >
      <div
        className={clsx(
          classes.searchToolbar,
          searchVisibility && classes.searchToolbarActive
        )}
        data-testid="TagsList-1"
      >
        <TagsListSearch
          searchVisibility={searchVisibility}
          setFilteredValue={setFilteredValue}
          setSearchVisibility={setSearchVisibility}
        />

        <TagsListAnchors />
      </div>

      <div
        className={classes.tagListsWrapper}
        data-testid="TagsList-2"
        ref={tagsListWrapper}
      >
        <TrackedActionContext.Provider
          value={{ actionContext: 'Sidebar menu: own tags section' }}
        >
          <div ref={ownTagsRef}>
            <PageDrawerMenu
              expanded={expandedListState.own}
              icon={<PersonIcon />}
              sectionTitle={t('myTags.text')}
              subheaderActions={<OwnTagsListActions />}
              onValueChange={handleOwnListStateChange}
            >
              <OwnTagsList filteredTagName={filteredValue} />
            </PageDrawerMenu>
          </div>
        </TrackedActionContext.Provider>

        <TrackedActionContext.Provider
          value={{ actionContext: 'Sidebar menu: following tags section' }}
        >
          <div ref={followingTagsRef}>
            <FollowingTagsList
              filteredTagName={filteredValue}
              listExpanded={expandedListState.following}
              onToggleListStateChange={handleExpandedListState}
            />
          </div>
        </TrackedActionContext.Provider>

        <TrackedActionContext.Provider
          value={{ actionContext: 'Sidebar menu: shared tags section' }}
        >
          <div ref={sharedTagsRef}>
            <PageDrawerMenu
              expanded={expandedListState.shared}
              icon={<Diversity3Icon />}
              sectionTitle={t('sharedTags.text')}
              subheaderActions={<SharedTagsListActions />}
              onValueChange={handleSharedListStateChange}
            >
              <SharedTagsList filteredTagName={filteredValue} />
            </PageDrawerMenu>
          </div>
        </TrackedActionContext.Provider>
      </div>
    </TagsListContext.Provider>
  );
};
