import React from 'react';

import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type ButtonWithLoaderProps = {
  isFetching?: boolean;
} & ButtonProps<ButtonTypeMap['defaultComponent'], {}>;

const ButtonWithLoader = ({
  isFetching,
  onAction,
  onClick,
  startIcon,
  ...rest
}: WithTrackedActionWrappedProps<ButtonWithLoaderProps>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button
      startIcon={
        !isFetching ? startIcon : <CircularProgress color="inherit" size={18} />
      }
      {...rest}
      onClick={handleClick}
    />
  );
};

ButtonWithLoader.displayName = 'ButtonWithLoader';

export default withTrackedAction<
  ButtonWithLoaderProps & WithTrackedActionProps
>(ButtonWithLoader);
