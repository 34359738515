import { useLocation } from 'react-router-dom';

export const useIsActivePage = (url: string): { isActive: boolean } => {
  const location = useLocation();
  const href = `${url}`;

  if (href === '/' || href.startsWith('/?')) {
    return {
      isActive:
        location.pathname === href ||
        (!!location.search && `/${location.search}` === href),
    };
  }

  return {
    isActive:
      location.pathname.startsWith(href) || location.search.startsWith(href),
  };
};
