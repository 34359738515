import React from 'react';

import { AppDispatch } from 'app/state/store';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectSharedTagsSortSettings,
  updateSharedTagsSortSettings,
} from 'containers/Tags/sharedTagsSlice/sharedTags.slice';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';
import { TagsSortSelect } from 'containers/Tags/TagsSort/TagsSortSelect';
import { useTagsSortOptions } from 'containers/Tags/TagsSort/useTagsSortOptions';

export const SharedTagsListActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const sortSettings = useSelector(selectSharedTagsSortSettings);
  const sortOptions = useTagsSortOptions(true);

  const handleSortChange = async (sortBy: TagsSortByEnum) => {
    await dispatch(updateSharedTagsSortSettings(sortBy));
  };

  return (
    <TagsSortSelect
      initialValue={sortSettings.sortBy}
      options={sortOptions}
      onChange={handleSortChange}
    />
  );
};
