/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { FiniteStatesType, FiniteStates } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { RecommendedExplanationData } from 'api/recommendationsApi/RecommendationsApi.types';
import { TagDetails, TagOrderUpdatePayload } from 'api/tagsApi/tagsApi.types';
import { RecommendationExplanationTypeEnum } from 'containers/Recommendations/RecommendationExplanationType.enum';

import { OwnTagsState } from '../tags.types';
import { getTagsByIds, tagIsShared } from '../tags.utils';
import { DEFAULT_OWN_TAGS_SORTING } from '../TagsSort/tagsSort.const';
import { sortTagItems } from '../TagsSort/tagsSort.utils';

import {
  addMultipleTagsThunk,
  addTagThunk,
  deleteTagThunk,
  getOwnTagsThunk,
  updateFavouriteTagThunk,
  updateOwnTagOrderThunk,
  updateOwnTagsSortSettingsThunk,
  updateTagThunk,
} from './ownTagsSlice.utils';

export const initialState: OwnTagsState = {
  data: {
    favouriteTag: null,
    items: [],
    sortSettings: DEFAULT_OWN_TAGS_SORTING,
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getOwnTags = createAsyncThunk('ownTags/get', getOwnTagsThunk);

export const addTag = createAsyncThunk('ownTags/add', addTagThunk);

export const addMultipleTags = createAsyncThunk(
  'ownTags/addMultipleTags',
  addMultipleTagsThunk
);

export const updateTag = createAsyncThunk('ownTags/update', updateTagThunk);

export const updateFavouriteTag = createAsyncThunk(
  'ownTags/updateFavouriteTag',
  updateFavouriteTagThunk
);

export const updateOwnTagOrder = createAsyncThunk(
  'ownTags/updateOwnTagOrder',
  updateOwnTagOrderThunk,
  {
    condition: ({ newIndex, oldIndex }: TagOrderUpdatePayload) => {
      if (oldIndex === newIndex) {
        return false;
      }
      return undefined;
    },
  }
);

export const updateOwnTagsSortSettings = createAsyncThunk(
  'ownTags/updateSortSettings',
  updateOwnTagsSortSettingsThunk
);

export const deleteTag = createAsyncThunk('ownTags/delete', deleteTagThunk);

const ownTags = createSlice({
  extraReducers: (builder) => {
    // Get tags
    builder
      .addCase(getOwnTags.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getOwnTags.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error = action.error.message || 'Unexpected error';
      })
      .addCase(getOwnTags.fulfilled, (state, action) => {
        state.fetchState = FiniteStates.Success;
        state.data = action.payload;
        state.error = null;
      });
    // Add tag
    builder.addCase(addTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = sortTagItems(
        [...state.data.items, payload],
        state.data.sortSettings
      );
      state.error = null;
    });
    // Add add multiple tags
    builder.addCase(addMultipleTags.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = sortTagItems(
        [...state.data.items, ...payload],
        state.data.sortSettings
      );
      state.error = null;
    });
    // Update tag
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items[payload.index] = payload.data;
      state.error = null;
    });
    // Update favourite tag
    builder.addCase(updateFavouriteTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.favouriteTag = payload;
      state.error = null;
    });
    // Update tag order
    builder.addCase(updateOwnTagOrder.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = payload;
      state.error = null;
    });
    // Update tag sort settings
    builder.addCase(
      updateOwnTagsSortSettings.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.sortSettings = payload.sortSettings;
        state.data.items = payload.items;
        state.error = null;
      }
    );
    // Delete tag
    builder.addCase(deleteTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = state.data.items.filter(({ id }) => id !== payload);
      state.error = null;
    });
  },
  initialState,
  name: 'ownTags',
  reducers: {
    replaceTagDetails: (state, { payload }: PayloadAction<TagDetails>) => {
      const tagIndex = state.data.items.findIndex((t) => t.id === payload.id);
      if (tagIndex === -1) return;
      state.data.items[tagIndex] = payload;
    },
    resetOwnTagsState: (state) => {
      state.fetchState = initialState.fetchState;
      state.data = initialState.data;
      state.error = initialState.error;
    },
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
});

const ownTagsSelector = (state: RootState) => state.tags.own;

export const selectOwnTags = createSelector(
  ownTagsSelector,
  ({ data }) => data.items
);

export const selectOwnSharedTags = createSelector(ownTagsSelector, ({ data }) =>
  data.items.filter(tagIsShared)
);

export const selectFavouriteTag = createSelector(
  ownTagsSelector,
  ({ data }) => data.favouriteTag
);

export const selectOwnTagsIds = createSelector(ownTagsSelector, ({ data }) =>
  data.items.map(({ id }) => id)
);

export const selectOwnTagsSortSettings = createSelector(
  ownTagsSelector,
  ({ data }) => data.sortSettings
);

export const selectOwnTagsLoading = createSelector(
  ownTagsSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const selectOwnTagsByIds = (ids: number[]) =>
  createSelector(ownTagsSelector, ({ data }) => getTagsByIds(ids, data.items));

export const selectOwnTagsByExplanations = (
  explanationIds: RecommendedExplanationData[] = []
) =>
  createSelector(ownTagsSelector, ({ data }) => {
    const ids = explanationIds
      .filter(
        (explanation) =>
          explanation.explanationType === RecommendationExplanationTypeEnum.Tag
      )
      .map(({ explanationId }) => Number(explanationId));

    return getTagsByIds(ids, data.items);
  });

export const { replaceTagDetails, resetOwnTagsState } = ownTags.actions;

export default ownTags.reducer;
