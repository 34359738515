import React, { FC } from 'react';

import { Box } from '@mui/system';
import { useSelector } from 'react-redux';

import { Evidence } from 'api/chatApi/chatApi.types';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectUser } from 'containers/User/user.slice';

interface EvidenceDocProps {
  doc: RetrievalUnitData;
  evidences: Evidence[];
  i: number;
}

export const EvidenceDoc: FC<EvidenceDocProps> = ({ doc, evidences, i }) => {
  const userSettings = useSelector(selectUser);

  const evidence = evidences.find((hit) => hit.documentId === doc.document.id);
  if (!evidence) return null;

  const textExtract = evidence.textExtract || '';
  const replacedTextExtract = textExtract
    .replace(/<b>/g, '<em>')
    .replace(/<\/b>/g, '</em>');

  const data = {
    ...doc,
    abstractContent: '',
    highlight: replacedTextExtract,
  };

  return (
    <Box key={doc.document.id}>
      <RetrievalUnit
        cardComposition={userSettings?.cardStyle}
        data={data}
        evidenceNumber={i + 1}
        isEvidence={true}
        key={doc.document.id}
        withImage={true}
      />
    </Box>
  );
};
