import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

type NoteItemShareableTooltipTitleProps = {
  shareable: boolean;
};

const NoteItemShareableTooltipTitle = ({
  shareable,
}: NoteItemShareableTooltipTitleProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div>
        <b>
          {shareable
            ? t('notes.shareable.sharedTooltip.title')
            : t('notes.shareable.privateTooltip.title')}
        </b>
      </div>

      <em>
        {shareable ? (
          <Trans i18nKey={'notes.shareable.sharedTooltip.explanation'} t={t}>
            <br />
          </Trans>
        ) : (
          <Trans i18nKey={'notes.shareable.privateTooltip.explanation'} t={t}>
            <strong />
            <br />
          </Trans>
        )}
      </em>
    </>
  );
};

export default NoteItemShareableTooltipTitle;
