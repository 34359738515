import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  disclaimer: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  navLinkActive: {
    color: theme.palette.primary.main,
  },
  navigation: {
    '& > a + a': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    marginTop: theme.spacing(3),
    position: 'sticky',
    top: '100%',
  },
}));
