import { useEffect, useState } from 'react';

import { isArray } from 'lodash';

import { useSavedPropertyFromNote } from 'common/hooks/useSavedPropertyFromNote';

interface UseNoteOriginalUrlProps {
  noteContent: string;
}

export const useNoteOriginalUrl = ({
  noteContent,
}: UseNoteOriginalUrlProps) => {
  const { getSavedPropFromNote } = useSavedPropertyFromNote(noteContent);
  const [originalUrl, setOriginalUrl] = useState<string | undefined>();

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      const url = await getSavedPropFromNote('originalUrl');
      const urlStr = isArray(url) ? url[0] : url;
      setOriginalUrl(urlStr);
    };

    void fetchOriginalUrl();
  }, [getSavedPropFromNote]);

  return { originalUrl };
};
