import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { deleteNote, listNotes, updateNote } from 'api/notesApi/notesApi';
import {
  ListNotesPayload,
  NoteDetails,
  UpdateNotePayload,
} from 'api/notesApi/notesApi.types';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import {
  notesQueryKeys,
  paginatedNotesOptimisticDelete,
  paginatedNotesOptimisticUpdate,
} from '../savedNotes.utils';

export type UseNotesListReturn = {
  deleteNoteMutation: UseMutationResult<
    number,
    BaseError,
    number,
    {
      previousNotes?: PaginatedResults<NoteDetails> | undefined;
    }
  >;
  query: UseQueryResult<PaginatedResults<NoteDetails>, BaseError>;
  updateNoteMutation: UseMutationResult<
    UpdateNotePayload,
    BaseError,
    UpdateNotePayload,
    {
      previousNotes?: PaginatedResults<NoteDetails> | undefined;
    }
  >;
};

export type UseNotesListProps = {
  options?: Omit<
    UseQueryOptions<PaginatedResults<NoteDetails>, BaseError>,
    'queryKey' | 'queryFn'
  >;
} & ListNotesPayload;

export const useNotesList = ({
  options,
  ...payload
}: UseNotesListProps): UseNotesListReturn => {
  const queryKey = notesQueryKeys.list(payload);
  const { tenant } = useParsedHostname();

  const queryClient = useQueryClient();

  const query = useQuery<PaginatedResults<NoteDetails>, BaseError>(
    queryKey,
    async () => {
      const { data } = await listNotes(payload);

      return data;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      ...options,
    }
  );

  const updateNoteMutation = useMutation<
    UpdateNotePayload,
    BaseError,
    UpdateNotePayload,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >(
    async (notePayload) => {
      await updateNote(notePayload);

      return notePayload;
    },
    {
      onError: (_, __, context) => {
        queryClient.setQueryData(queryKey, context?.previousNotes);
      },
      onMutate(newPayload) {
        const previousNotes =
          queryClient.getQueryData<PaginatedResults<NoteDetails>>(queryKey);

        queryClient.setQueryData<PaginatedResults<NoteDetails> | undefined>(
          queryKey,
          (oldResults) => paginatedNotesOptimisticUpdate(newPayload, oldResults)
        );

        return { previousNotes };
      },
      onSettled() {
        queryClient.invalidateQueries<PaginatedResults<NoteDetails>>(queryKey);
      },
    }
  );

  const deleteNoteMutation = useMutation<
    number,
    BaseError,
    number,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >(
    async (noteId) => {
      await deleteNote(noteId, tenant);

      return noteId;
    },
    {
      onError: (_, __, context) => {
        queryClient.setQueryData(queryKey, context?.previousNotes);
      },
      onMutate(noteId) {
        const previousNotes =
          queryClient.getQueryData<PaginatedResults<NoteDetails>>(queryKey);

        queryClient.setQueryData<PaginatedResults<NoteDetails> | undefined>(
          queryKey,
          (oldResults) => paginatedNotesOptimisticDelete(noteId, oldResults)
        );

        return { previousNotes };
      },
      onSettled() {
        queryClient.invalidateQueries<PaginatedResults<NoteDetails>>(queryKey);
      },
    }
  );

  return {
    deleteNoteMutation,
    query,
    updateNoteMutation,
  };
};
