import React from 'react';

import {
  Tooltip,
  IconButton,
  IconButtonProps,
  IconButtonTypeMap,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  tooltipTitle: string;
} & IconButtonProps<IconButtonTypeMap['defaultComponent'], {}>;

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
}));

export const PageDrawerMenuAction = ({
  children,
  tooltipTitle,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle} disableInteractive>
      <IconButton className={classes.iconButton} size="large" {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
