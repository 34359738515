import React, { FC, MouseEventHandler } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './useStyles';

export interface MoreLessButtonProps {
  className?: string;
  isLess: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  wrapperClassName?: string;
}

export const MoreLessButton: FC<MoreLessButtonProps> = ({
  className,
  isLess,
  onClick,
  wrapperClassName,
}) => {
  const { t } = useTranslation('search');
  const classes = useStyles();

  return (
    <Box className={clsx(wrapperClassName, classes.moreBtnWrapper)}>
      <Chip
        className={clsx(className, classes.moreBtn)}
        color="secondary"
        icon={isLess ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        label={
          isLess ? t('filters.expandBtn.less') : t('filters.expandBtn.more')
        }
        size="small"
        onClick={onClick}
      />
    </Box>
  );
};
