import React from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { DocCardComposition } from 'containers/User/User.enum';

type RetrievalUnitSkeletonProps = {
  cardComposition?: DocCardComposition;
};

export const RetrievalUnitSkeleton = ({
  cardComposition,
}: RetrievalUnitSkeletonProps) => {
  const actionSkeletons = [1, 2, 3, 4];

  return (
    <Box mb={2}>
      <Card aria-label="retrieval unit skeleton">
        <Grid spacing={0} container>
          <Grid md={2} xs={3} item>
            <CardContent style={{ paddingRight: 0 }}>
              <Skeleton height={100} variant="rectangular" />
            </CardContent>
          </Grid>

          <Grid md={10} xs={9} item>
            <CardHeader
              subheader={<Skeleton height={15} />}
              title={<Skeleton height={30} width={200} />}
            />

            <CardContent>
              <Skeleton height={15} />
              <Skeleton height={15} />
              <Skeleton height={15} width="70%" />
            </CardContent>
          </Grid>
        </Grid>
        {(cardComposition === DocCardComposition.Default ||
          cardComposition === undefined) && (
          <>
            <Divider />
            <CardActions>
              <Grid alignItems="center" container>
                <Grid xs={6} item>
                  <Skeleton height={20} width={40} />
                </Grid>

                <Grid
                  justifyContent="flex-end"
                  spacing={1}
                  xs={6}
                  container
                  item
                >
                  {actionSkeletons.map((action) => (
                    <Grid key={action} item>
                      <Skeleton height={24} variant="circular" width={24} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>
    </Box>
  );
};
