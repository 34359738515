import React, { FC, MutableRefObject, useMemo } from 'react';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  ThemeProvider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  HighlightActionItem,
  PDFViewer,
  PDFViewerProps,
  theme,
} from '@zarn/pdf-viewer';

import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import { useChatAvailability } from 'common/hooks/useChatAvailability';

import { useDocPDFAsset } from '../../RetrievalUnit/hooks/useDocPDFAsset';
import { RetrievalUnitData } from '../../RetrievalUnit/RetrievalUnitData.interface';
import { usePDFViewerActions } from '../hooks/usePDFViewerActions';

export interface LoadingErrorDataProps {
  docData?: RetrievalUnitData | null;
  docId: string;
  documentRef: MutableRefObject<HTMLDivElement | null>;
  highlights: AnnotationHighlight[];
  onHighlight: PDFViewerProps<string>['onHighlightAction'];
}

export const LoadingErrorData: FC<LoadingErrorDataProps> = ({
  docData,
  docId,
  documentRef,
  highlights,
  onHighlight,
}) => {
  const { t } = useTranslation('common');
  const { isChatAvailable } = useChatAvailability();
  const docAsset =
    docData?.publicAsset ||
    docData?.privateAsset ||
    docData?.externalPublicAsset;

  const {
    data: pdfData,
    error: pdfError,
    isLoading: pdfLoading,
  } = useDocPDFAsset({
    asset: docAsset ?? {
      assetType: DocAssetTypeEnum.PublicAsset,
      assetValue: '',
    },
    docId: docData?.documentId ?? '',
    options: {
      enabled: !!(docData?.documentId && docAsset),
    },
    ownerUuid: docData?.ownerUuid,
  });
  const onPDFViewerAction = usePDFViewerActions(docId);

  const customHighlightActions = useMemo<HighlightActionItem<string>[]>(() => {
    if (isChatAvailable) {
      return [
        {
          action: 'explain',
          highlightTypes: isFeatureEnabled('ff-multimodal-chat')
            ? ['text', 'image']
            : ['text'],
          icon: () => <OrangeGradientOpenAiIcon />,
          title: t('pdfViewer.actions.addToChat'),
        },
        {
          action: 'discover',
          highlightTypes: ['text'],
          icon: () => <PlagiarismIcon />,
          title: t('pdfViewer.actions.discover'),
        },
      ];
    }

    return [
      {
        action: 'discover',
        highlightTypes: ['text'],
        icon: () => <OrangeGradientOpenAiIcon />,
        title: t('pdfViewer.actions.search'),
      },
    ];
  }, [isChatAvailable, t]);

  if (pdfLoading) {
    return (
      <Box display="flex" justifyContent="center" m="auto">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (pdfError) {
    return (
      <Alert severity="error">
        <AlertTitle>{pdfError.status}</AlertTitle>
        {pdfError.message}
      </Alert>
    );
  }

  if (pdfData) {
    return (
      <ThemeProvider theme={theme}>
        <PDFViewer
          content={pdfData.content}
          customHighlightActions={customHighlightActions}
          documentRef={documentRef}
          highlights={highlights}
          style={{ height: '100%' }}
          onHighlightAction={onHighlight}
          onPDFViewerAction={onPDFViewerAction}
        />
      </ThemeProvider>
    );
  }

  return null;
};
