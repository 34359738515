import { useEffect, useState } from 'react';

export const useSingleEvidenceDialog = () => {
  const [evidenceDialogOpen, setEvidenceDialogOpen] = useState(false);
  const [chunkId, setChunkId] = useState<string | null>(null);

  const closeEvidenceDialog = () => {
    setEvidenceDialogOpen(false);
  };

  useEffect(() => {
    if (chunkId) {
      setEvidenceDialogOpen(true);
    }
  }, [chunkId]);

  return {
    chunkId,
    closeEvidenceDialog,
    evidenceDialogOpen,
    setChunkId,
    setEvidenceDialogOpen,
  };
};
