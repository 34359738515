import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditTagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17,3H7C5.9,3,5,3.9,5,5v16l7-3l7,3V5C19,3.9,18.1,3,17,3z M9.9,14.5H8v-1.9l4.6-4.4l1.9,1.9C14.4,10.1,9.9,14.5,9.9,14.5z
	 M15.9,8.6l-0.9,0.9l-1.9-1.9L14,6.7c0.1-0.3,0.5-0.3,0.7,0l1.2,1.2C16,8.1,16,8.3,15.9,8.6z"
      />
    </SvgIcon>
  );
};

EditTagIcon.displayName = 'EditTagIcon';

export default EditTagIcon;
