import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import IconButtonLink from 'common/components/Buttons/IconButtonLink/IconButtonLink';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';

export type SearchPopperHeaderProps = {
  children?: React.ReactNode;
  onClose: () => void;
  searchQuery: string;
};

const SearchPopperHeader = React.memo(
  ({ children, onClose, searchQuery }: SearchPopperHeaderProps) => {
    const { t } = useTranslation('search');

    return (
      <DialogActionTitle onClose={onClose}>
        {children}
        <Typography pt={2} variant="body1">
          <Trans i18nKey="searchPopper.title" t={t}></Trans>
          <IconButtonLink href={`/${searchQuery}`}>
            <LaunchIcon />
          </IconButtonLink>
        </Typography>
      </DialogActionTitle>
    );
  }
);

SearchPopperHeader.displayName = 'SearchPopperHeader';

export default SearchPopperHeader;
