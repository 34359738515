import { useMemo } from 'react';
import { getScrollableParent } from '../../utils';

export const useTheFirstScrollableParent = (
  el: HTMLElement | null
): HTMLElement | null => {
  return useMemo(() => {
    return getScrollableParent(el);
  }, [el]);
};
