import { get } from 'lodash';

export const getField = (obj: object, path: string) => {
  const value = get(obj, path);
  if (value !== undefined) {
    return value;
  }
  const fields = path.split('.');

  const listIndex = fields.findIndex((field, index) => {
    const partPath = fields.slice(0, index + 1).join('.');
    return Array.isArray(get(obj, partPath));
  });

  if (listIndex === -1) {
    return value;
  }

  const listPath = fields.slice(0, listIndex + 1).join('.');
  const list = get(obj, listPath);

  const listObjectsPath = fields.slice(listIndex + 1).join('.');

  return list.map((el: object) => getField(el, listObjectsPath));
};
