import { DRAWER_WIDTH } from 'app/theme';

import {
  useDrawerResize,
  UseDrawerResizeReturn,
} from 'common/hooks/useDrawerResize';

export const LS_MAIN_SIDEBAR_WIDTH_KEY = 'mainSidebarWidth';
export const MIN_WIDTH = 150;

export const setSidebarWidthToLS = (width: number): void => {
  localStorage.setItem(LS_MAIN_SIDEBAR_WIDTH_KEY, String(width));
};

const getSidebarWidthFromLS = (): number => {
  const width = Number(localStorage.getItem(LS_MAIN_SIDEBAR_WIDTH_KEY));
  return (Number.isInteger(width) && width) || DRAWER_WIDTH;
};

export const useMainSidebarResize = (): UseDrawerResizeReturn =>
  useDrawerResize({
    initWidth: getSidebarWidthFromLS(),
    minWidth: MIN_WIDTH,
    onResizeEnd: setSidebarWidthToLS,
  });
