import React from 'react';

import GroupIcon from '@mui/icons-material/Group';
import { useTranslation, Trans } from 'react-i18next';

import { SharingPolicies } from 'containers/Sharing/Sharing.types';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataSharedProps = {
  shareSettings: SharingPolicies;
};

const TagMetadataShared = ({ shareSettings }: TagMetadataSharedProps) => {
  const { t } = useTranslation('tags');

  if (!shareSettings.orgs.length) return null;

  return (
    <TagMetadataItem
      icon={GroupIcon}
      translation={
        <Trans i18nKey="tagMetadata.shared.text" t={t}>
          <strong />
        </Trans>
      }
    />
  );
};

TagMetadataShared.displayName = 'TagMetadataShared';

export default TagMetadataShared;
