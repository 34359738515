// Workaround
// Doc ids don't get serialized correctly.
// Instead of the `docIds=1,2,3` it return `docIds=1&docIds=2&docIds=3`
export const serializeIds = (ids: string[]) =>
  ids.length > 0 ? [ids.toString()] : [];

export interface SuccessResponse<T> {
  data: T;
  isSuccess: true;
}

export interface FailureResponse {
  error: Error;
  isSuccess: false;
}

export type ApiResponse<T> = SuccessResponse<T> | FailureResponse;
