import { useCallback, useState } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';

import { useNoteApi } from '../../DocNotes/hooks/useNoteApi';

interface Props {
  docId: string;
  onNoteCreated: (note: NoteDetails) => void;
}

export const useSaveChat = ({ docId, onNoteCreated }: Props) => {
  const noteApi = useNoteApi(docId);

  const [isSaving, setIsSaving] = useState(false);

  const saveChat = useCallback(
    async (content: string, note: NoteDetails | { content: string } | null) => {
      try {
        setIsSaving(true);
        if (note && 'id' in note) {
          const newNote = {
            ...note,
            content,
          };
          await noteApi.update(newNote);
          return newNote;
        } else {
          const newNote = await noteApi.create(content);
          onNoteCreated(newNote);
          return newNote;
        }
      } catch (err) {
        setIsSaving(false);
        throw new Error(
          `Something went wrong with saving chat in note: ${err}`
        );
      } finally {
        setIsSaving(false);
      }
    },
    [noteApi, onNoteCreated]
  );

  return {
    isSaving,
    saveChat,
  };
};
