import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Highlight, HighlightActionPayload } from '@zarn/pdf-viewer';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { posthogAnnotations } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import {
  getAnnotationSearchQuery,
  getTextAnnotationContent,
} from 'containers/DocNotes/docNotes.utils';
import { useDocNotes } from 'containers/DocNotes/hooks/useDocNotes';

import { useNoteApi } from '../../DocNotes/hooks/useNoteApi';

export interface UseHighlightActionProps {
  docId: string;
  onAddNote: (highlight: Highlight) => void;
  onClick: (highlight: Highlight) => void;
  onCreate: (note: NoteDetails) => void;
  onExplain: (highlight: Highlight) => void;
  onSearch: (searchQuery: string) => void;
}

export const useHighlightAction = ({
  docId,
  onAddNote,
  onClick,
  onCreate,
  onExplain,
  onSearch,
}: UseHighlightActionProps) => {
  const { tenant } = useParsedHostname();
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const noteApi = useNoteApi(docId);

  const { data } = useDocNotes({
    docId,
    sortBy: 'annotation_highlight_position:asc',
    withAnnotations: true,
  });

  const onHighlight = useCallback(
    async (payload: HighlightActionPayload<'explain' | 'discover'>) => {
      const highlight = payload.data;

      switch (payload.action) {
        case 'copy': {
          enqueueSnackbar(t('notes.highlights.copyMessage'));
          break;
        }
        case 'click': {
          onClick(payload.data);
          break;
        }
        case 'addNote': {
          onAddNote(payload.data);
          break;
        }
        case 'explain': {
          onExplain(payload.data);
          break;
        }
        case 'discover': {
          const note = data?.items?.find(
            ({ id }: NoteDetails) => id === highlight.id
          );
          const annotationContent = getTextAnnotationContent(
            highlight.content.type,
            highlight.content.body
          );

          if (!annotationContent) return;

          onSearch(
            getAnnotationSearchQuery(annotationContent, note?.content ?? '')
          );
          break;
        }
        case 'create': {
          posthogAnnotations.create(tenant, highlight.content.type);

          const createdNote = await noteApi.create('', highlight);

          onCreate(createdNote);
          break;
        }
        case 'delete': {
          await noteApi.remove(highlight.id);
          break;
        }
        case 'update': {
          const note = data?.items?.find(
            ({ id }: NoteDetails) => id === highlight.id
          );

          if (!note) return;

          posthogAnnotations.update(tenant, highlight.content.type);
          await noteApi.update(note, highlight);
          break;
        }
        default:
          break;
      }
    },
    [
      tenant,
      enqueueSnackbar,
      onSearch,
      data,
      noteApi.create,
      noteApi.update,
      noteApi.remove,
    ]
  );

  return { onHighlight };
};
