import React from 'react';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  img: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    display: 'block',
    maxWidth: '100%',
  },
}));

type OnboardingWizardImgProps = {
  alt: string;
  src: string;
};

const OnboardingWizardImg = ({ alt, src }: OnboardingWizardImgProps) => {
  const classes = useStyles();

  return <img alt={alt} className={classes.img} src={src} />;
};

OnboardingWizardImg.displayName = 'OnboardingWizardImg';

export default OnboardingWizardImg;
