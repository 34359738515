import React, { FC, useEffect, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, Popover, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { HitType } from 'common/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import BibtexDialog from 'containers/Bibtex/BibtexDialog/BibtexDialog';
import DeletePrivateDocDialog from 'containers/PrivateDocs/DeletePrivateDoc/DeletePrivateDocDialog/DeletePrivateDocDialog';
import { EditPrivateDocDialog } from 'containers/PrivateDocs/EditPrivateDoc/EditPrivateDocDialog/EditPrivateDocDialog';

import { RetrievalUnitData } from '../RetrievalUnitData.interface';

import DocActionItem from './DocActionItem/DocActionItem';
import { DocActionsContext } from './docActions.context';
import { DocAction } from './DocActions.interface';
import { useDocActions } from './hooks/useDocActions';
import { usePrivateDocActions } from './hooks/usePrivateDocActions';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& + $list': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export interface DocActionsProps {
  data: RetrievalUnitData;
  moreActions?: DocAction[];
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
}

const DocActions: FC<DocActionsProps> = ({
  data,
  moreActions,
  onPrivateDocDelete,
  onPrivateDocEdit,
}) => {
  const classes = useStyles();
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { me } = useAuth();

  const { bibtexDialog, docActions, setBibtexDialog } = useDocActions(data);

  const {
    handlePrivateDocDelete,
    handlePrivateDocEdit,
    privateDocActions,
    privateDocDialog,
    setPrivateDocDialog,
  } = usePrivateDocActions({ onPrivateDocDelete, onPrivateDocEdit });

  const isPrivateDoc = data.document.type === HitType.PrivateDocument;
  const renderPrivateDocActions =
    isPrivateDoc && !!privateDocActions.length && isOwner;
  const renderMoreActions = !!moreActions?.length;
  const renderDocActions = !!docActions.length;
  const noActions =
    !renderPrivateDocActions && !renderMoreActions && !renderDocActions;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsOwner(data.ownerUuid === me?.sub);
  }, [data.ownerUuid, me?.sub]);

  if (noActions) return null;

  const isEditDialog = privateDocDialog === 'edit';
  const isDeleteDialog = privateDocDialog === 'delete';

  return (
    <DocActionsContext.Provider value={{ anchorEl, open, setAnchorEl }}>
      <IconButton
        aria-controls="actions"
        aria-haspopup="true"
        aria-label="doc actions button"
        size="small"
        onClick={handleOpen}
      >
        <MoreVertIcon color="primary" />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        aria-label="doc actions popover"
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClose}
      >
        {renderPrivateDocActions && (
          <List className={classes.list} data-testid="privateDocActions">
            {privateDocActions.map((action: DocAction) => (
              <DocActionItem
                key={action.title}
                {...action}
                eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
              />
            ))}
          </List>
        )}

        {renderDocActions && (
          <List className={classes.list} data-testid="docActions">
            {docActions.map((action: DocAction) => (
              <DocActionItem
                key={action.title}
                {...action}
                eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
              />
            ))}
          </List>
        )}

        {renderMoreActions && (
          <List className={classes.list} data-testid="docMoreActions">
            {moreActions.map((action: DocAction) => (
              <DocActionItem
                key={action.title}
                {...action}
                eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
              />
            ))}
          </List>
        )}
      </Popover>

      {data.getBibtexId && (
        <BibtexDialog
          ids={[data.getBibtexId]}
          open={bibtexDialog}
          setOpen={setBibtexDialog}
        />
      )}

      {isEditDialog && (
        <EditPrivateDocDialog
          data={data}
          open={isEditDialog}
          onClose={() => setPrivateDocDialog(false)}
          onFormSubmit={handlePrivateDocEdit}
        />
      )}

      {isDeleteDialog && (
        <DeletePrivateDocDialog
          id={data.privateDocId || ''}
          open={isDeleteDialog}
          setOpen={setPrivateDocDialog}
          onPrivateDocDelete={handlePrivateDocDelete}
        />
      )}
    </DocActionsContext.Provider>
  );
};

DocActions.displayName = 'DocActions';

export default DocActions;
