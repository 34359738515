import { UserPayload } from '@zarn/vendor/dist/auth';

import { deserializeUserRole } from '../Auth2.utils';

import { Me } from './Me.interface';
import { MeCommonModel } from './MeCommon.model';

export class MeCustomModel extends MeCommonModel implements Me {
  constructor(token: UserPayload) {
    super(token);
    this.userRolesData = token.user_roles.role_data.map(deserializeUserRole);
    this.organization = this.getOrganization();
  }
}
