import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import {
  GetOrganizationMembersPayload,
  OrganizationMemberData,
} from 'api/organizationApi/OrganizationMembers.interface';
import { getOrganizationMember } from 'api/organizationApi/organizationMembersApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';

import { usersQueryKeys } from '../userDetails.utils';

export type UseUserDetails = OrganizationMemberData & {
  fullName?: string;
};
export type UseUserDetailsReturn = UseQueryResult<UseUserDetails, BaseError>;

export interface UseUserDetailsProps
  extends Omit<GetOrganizationMembersPayload, 'organizationId' | 'tenant'> {
  options?: Omit<
    UseQueryOptions<UseUserDetails, BaseError>,
    'queryKey' | 'queryFn'
  >;
}

export const useUserDetails = ({
  options = {},
  ...payload
}: UseUserDetailsProps): UseUserDetailsReturn => {
  const { tenant } = useParsedHostname();
  const { accountId } = useAuth();
  const { data, ...rest } = useQuery<UseUserDetails, BaseError>(
    usersQueryKeys.detailsItem(payload.memberId),
    async () =>
      (
        await getOrganizationMember({
          ...payload,
          organizationId: accountId ?? -1,
          tenant,
        })
      ).data,
    {
      ...QUERY_OPTIONS,
      retry: 1,
      staleTime: Infinity,
      ...options,
      enabled: !!accountId && (options.enabled ?? true),
    }
  );
  const fullName =
    data && !data.fullName ? `${data.firstName} ${data.lastName}` : undefined;

  return {
    ...rest,
    data: data ? { ...data, fullName } : undefined,
  } as UseUserDetailsReturn;
};
