import { useSelector } from 'react-redux';

import { TagDetails, TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { selectOwnTagsByIds } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { selectSharedTagsByIds } from 'containers/Tags/sharedTagsSlice/sharedTags.slice';

import { SavedDocument } from './SavedDocuments.interface';
import { selectSavedDocByOrganizeDocId } from './savedDocuments.slice';

export interface UseSavedDocumentReturn {
  docTags: TagDetails[];
  savedDocuments: SavedDocument[];
  suggestedTagItems: TagDetails[];
}

export const useSavedDocument = (
  id: string,
  suggestedTags: TagDetailsBase[] = []
): UseSavedDocumentReturn => {
  const savedDocuments = useSelector(selectSavedDocByOrganizeDocId(id));
  const documentTagIds = savedDocuments.map(({ tag }) => tag.id);
  const suggestedTagIds = suggestedTags
    .map((tag) => tag.id)
    .filter((tagId) => !documentTagIds.includes(tagId));
  const ownTags = useSelector(selectOwnTagsByIds(documentTagIds));
  const sharedTags = useSelector(selectSharedTagsByIds(documentTagIds));
  const suggestedTagItems = useSelector(selectOwnTagsByIds(suggestedTagIds));

  return {
    docTags: [...ownTags, ...sharedTags],
    savedDocuments,
    suggestedTagItems,
  };
};
