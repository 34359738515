import React, { useCallback, useMemo } from 'react';

import { useStyles } from './style';
import {
  useFallbackRef,
  useHighlightMode,
  useHighlightsVisibility,
  usePages,
  usePagesToRender,
  useZoomLevel,
} from '../../hooks';
import PDFPage from '../PDFPage';
import PDFViewerToolbar from '../PDFViewerToolbar';
import HighlightsLayer from '../Highlights/HighlightsLayer';
import {
  Highlight,
  HighlightActionItem,
  OnHighlightAction,
  OnPDFViewerAction,
} from '../../types';
import HighlightItem from '../Highlights/HighlightItem';
import Highlighting from '../Highlighting';
import TextHighlighting from '../TextHighlighting';
import PDFViewerDocument from '../PDFViewerDocument';
import PDFPageShadow from '../PDFPageShadow';
import { groupHighlightsByPage, isMobile } from '../../utils';
import { PAGE_LIMIT, PAGE_LIMIT_MOBILE } from '../../const/defaults.const';

const defaultHighlights: Highlight[] = [];
const defaultHighlightAction = () => {};

export type PDFViewerProps<T extends string> = {
  content:
    | string
    | {
        data?: Uint8Array;
        url?: string;
        httpHeaders?: Map<string, string>;
        withCredentials?: boolean;
      };
  highlights?: Highlight[];
  customHighlightActions?: HighlightActionItem<T>[];
  onHighlightAction?: OnHighlightAction<T>;
  onPDFViewerAction?: OnPDFViewerAction;
  style?: React.CSSProperties;
  documentRef?: React.RefObject<HTMLDivElement | null>;
};

export const PDFViewer = <T extends string>({
  content,
  style,
  documentRef,
  onPDFViewerAction,
  customHighlightActions,
  highlights = defaultHighlights,
  onHighlightAction = defaultHighlightAction,
}: PDFViewerProps<T>) => {
  const classes = useStyles();
  const { pages, numberOfPages } = usePages();
  const { zoomLevel } = useZoomLevel();
  const { highlightMode } = useHighlightMode();
  const { highlightsVisible } = useHighlightsVisibility();
  const pagesLimit = isMobile() ? PAGE_LIMIT_MOBILE : PAGE_LIMIT;
  const pagesToRender = usePagesToRender(pagesLimit);
  const docContainerRef = useFallbackRef<HTMLDivElement | null>(documentRef);

  const groupedHighlights = useMemo(
    () => groupHighlightsByPage(highlights),
    [highlights]
  );

  const handleScrollToPage = useCallback(
    (newPage: number) => {
      const el = docContainerRef.current;

      if (el && numberOfPages !== 0) {
        const pageHeight = el.scrollHeight / numberOfPages;

        el.scrollTo({
          top: Math.floor(pageHeight * (newPage - 1)),
        });
      }
    },
    [docContainerRef, numberOfPages]
  );

  return (
    <div className={classes.root} style={style}>
      <PDFViewerToolbar
        onScrollToPage={handleScrollToPage}
        onPDFViewerAction={onPDFViewerAction}
      />

      <div ref={docContainerRef} className={classes.documentContainer}>
        <Highlighting
          onHighlightAction={onHighlightAction}
          customHighlightActions={customHighlightActions}
        >
          <PDFViewerDocument
            file={content}
            onScrollToPage={handleScrollToPage}
            docContainerRef={docContainerRef}
          >
            <TextHighlighting />

            {pages.map(({ pageNumber, originalHeight, originalWidth }) =>
              pagesToRender.includes(pageNumber) ? (
                <PDFPage
                  key={`page_${pageNumber}`}
                  pageNumber={pageNumber}
                  highlightMode={highlightMode}
                  originalHeight={originalHeight}
                  originalWidth={originalWidth}
                  zoomLevel={zoomLevel}
                >
                  {
                    <HighlightsLayer>
                      {(
                        (highlightsVisible && groupedHighlights[pageNumber]) ||
                        []
                      ).map((h) => (
                        <HighlightItem
                          key={h.id}
                          highlight={h}
                          zoom={zoomLevel}
                          onHighlightAction={onHighlightAction}
                          customHighlightActions={customHighlightActions}
                        />
                      ))}
                    </HighlightsLayer>
                  }
                </PDFPage>
              ) : (
                <PDFPageShadow
                  key={`page_${pageNumber}`}
                  originalHeight={originalHeight}
                  originalWidth={originalWidth}
                  zoomLevel={zoomLevel}
                />
              )
            )}
          </PDFViewerDocument>
        </Highlighting>
      </div>
    </div>
  );
};

PDFViewer.displayName = 'PDFViewer';

export default PDFViewer;
