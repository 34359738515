import { useEffect, useState } from 'react';

import { useQuerySearchParams } from './useQuerySearchParams';

export interface UseDrawerResizeReturn {
  onResizeStart: () => void;
  setWidth: (width: number) => void;
  width: number;
}

export interface UseDrawerResizeProps {
  direction?: 'left' | 'right';
  initWidth: number;
  minWidth?: number;
  onResizeEnd?: (width: number) => void;
}

// TODO: test
export const useDrawerResize = ({
  direction = 'left',
  initWidth,
  minWidth = 0,
  onResizeEnd,
}: UseDrawerResizeProps): UseDrawerResizeReturn => {
  const [width, setWidth] = useState<number>(initWidth);
  const [resizing, setResizing] = useState<boolean>(false);

  const { deleteParams } = useQuerySearchParams();

  const handleResizeStart = () => {
    setResizing(true);
  };

  useEffect(() => {
    if (!resizing) {
      return;
    }

    let newWidth: number;

    const onMouseUp = () => {
      setResizing(false);
      if (onResizeEnd) {
        onResizeEnd(newWidth);
      }

      document.body.removeEventListener('mouseup', onMouseUp);
    };

    const resizingEvt = (event: MouseEvent) => {
      const w =
        direction === 'left' ? event.pageX : window.innerWidth - event.pageX;
      newWidth = w >= minWidth ? w : minWidth;

      setWidth(newWidth);

      deleteParams(['drawerWidth']);

      document.body.addEventListener('mouseup', onMouseUp);
    };

    document.body.addEventListener('mousemove', resizingEvt);

    return () => {
      document.body.removeEventListener('mousemove', resizingEvt);
      document.body.removeEventListener('mouseup', onMouseUp);
    };
  }, [resizing, setWidth, deleteParams, onResizeEnd, direction, minWidth]);

  return {
    onResizeStart: handleResizeStart,
    setWidth,
    width,
  };
};
