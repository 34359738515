import { Properties } from 'posthog-js';

export type TrackedActionEventProps = Properties;
export type TrackedActionProps = {
  eventName?: string;
  eventProps?: TrackedActionEventProps;
};

// Don't change the value once the code has been deployed.
// Changing it will mean that new events for the same action have different names,
// and will not be picked up by existing dashboards.
export enum TrackEventName {
  AboutLinkClicked = 'Clicked on about link',
  AboutProductClicked = 'Clicked product about button',
  AuthorNameClicked = 'Clicked author name',
  AuthorPublicationsClicked = 'Clicked publications by author',
  BibtexClicked = 'Clicked on tag BibTeX button',
  ChatEvidenceClicked = 'Clicked on Evidence button in chat answer',
  ChatInfoTooltipClicked = 'Clicked on infor button on chat on tag',
  CitedByButtonClicked = 'Clicked cited by button',
  ClickOnChatInPDF = 'Clicked on Chat button in PDF Viewer',
  ClickOnExplainInNote = 'Clicked on Explain button in note',
  ClickOnExplainInNoteWithImage = 'Clicked on Explain button in note with image',
  ClickOnInfoInPDF = 'Clicked on Info button in PDF Viewer',
  ClickOnOpenChatNote = 'Clicked on Open chat note',
  DocumentBibtexClicked = 'Clicked on document BibTeX button',
  DocumentFavorited = 'Added document to favorites',
  DocumentNotesToggleClicked = 'Clicked on document notes toggle',
  DocumentSourceClicked = 'Clicked on document source',
  DocumentTagsEdited = 'Edited document tags',
  DocumentTitleWithPDFClicked = 'Clicked on document title with PDF',
  DocumentURLClicked = 'Clicked on document URL',
  DocumentUnfavorited = 'Removed document from favorites',
  DocumentUntagged = 'Untagged document',
  EmailShared = 'Shared via email',
  ExpertEvidenceClicked = 'Clicked on expert evidence',
  ExplainLessClicked = 'Clicked on explain less button on VOS viewer',
  ExplainMoreClicked = 'Clicked on explain more button on VOS viewer',
  ExploreLessClicked = 'Clicked on explore less button',
  ExploreMoreClicked = 'Clicked on explore more button',
  FeedbackSubmitted = 'Submitted feedback',
  FileExport = 'Clicked export data button',
  FilterTagsClicked = 'Clicked on filter tags by name',
  FindSimilarClicked = 'Clicked Find similar',
  InlineDocumentTagEdited = 'Edited document tags inline',
  InvitationEmailResent = 'Resent invitation email',
  InviteUserSubmitClicked = 'Clicked on invite user submit button',
  IrrelevantDocumentExpanded = 'Clicked on expand irrelevant document',
  LinkedInSocialClicked = 'Clicked on social media button: LinkedIn',
  LoginButtonClicked = 'Clicked login button',
  MenuItemClicked = 'Clicked on',
  MessageContextInChatClicked = 'Clicked on Message Context in the Chat',
  MoreRecommendationsRequested = 'Requested more recommendations',
  NewPasswordTokenRequested = 'Requested new password token',
  NoteCreated = 'Created note',
  NoteDeleted = 'Deleted note',
  NoteUpdated = 'Updated note',
  PDFExplainHighlightEvidenceClicked = 'Clicked in PDF Viewer in Explain highlight on evidence button',
  PasswordChanged = 'Changed password',
  PasswordCreated = 'Created password',
  PasswordReset = 'Reset password',
  PersonFavorite = 'Person added to favorites',
  PersonRemovedFromFavorite = 'Person removed from favorites',
  PersonTagsEdited = 'Edited person tags',
  PreferencesClicked = 'Clicked on preferences',
  PrivateDocumentBatchUploaded = 'Uploaded private documents batch',
  PrivateDocumentDeleted = 'Deleted private document',
  PrivateDocumentEdited = 'Edited private document',
  PrivateDocumentImported = 'Imported private document',
  PrivateNoteMarked = 'Marked note as private',
  ProductFeaturesClicked = 'Clicked product features button',
  ProductReleaseNotesClicked = 'Clicked product release notes button',
  ProfileContactClicked = 'Clicked on profile contact',
  ProfileFeaturesClicked = 'Clicked on profile features',
  ProfileRecommendationsSettingsClicked = 'Clicked on profile recommendations settings',
  ProfileReleaseNotesClicked = 'Clicked on profile release notes',
  ProfileSettingsClicked = 'Clicked on profile settings',
  QABestEvidenceClicked = 'Clicked on QA best result evidence button',
  QAEvidenceClicked = 'Clicked on QA evidence button',
  QAKEvidenceClicked = 'Clicked on QA k result evidence button',
  QueryAnalysisConceptClicked = 'Clicked on concept in query analysis',
  QueryDeleted = 'Deleted tagged query',
  QueryTagged = 'Tagged query',
  QuizbotStartClicked = 'Clicked on Quizbot start button',
  RISExportButtonClicked = 'Export RIS file',
  RecommendationsNotificationClicked = 'Clicked on recommendations notification',
  ReferencesButtonClicked = 'Clicked references button',
  ReleaseNotesButtonClicked = 'Clicked on release notes button',
  ReportNotificationClicked = 'Clicked on report notification',
  RequestAccountClicked = 'Clicked on request demo account',
  ResetInitialQuery = 'Clicked on reset initial query',
  ResetPasswordClicked = 'Clicked reset password',
  SavedResultsSettingsUpdated = 'Updated saved results settings',
  SearchWithNotesClicked = 'Clicked on search with notes button',
  SeeMoreAuthorsClicked = 'Clicked see more authors (et al)',
  SeeMoreClicked = 'Clicked more on truncated content',
  SetTagPublicAccess = 'Set public access to the tag',
  ShareDocumentClicked = 'Clicked on share document button',
  SharedNoteMarked = 'Marked note as shareable',
  SharedTagSettingsUpdated = 'Updated shared tag settings',
  ShowInviteUserPopoverClicked = 'Clicked on invite user popover button',
  SignUpButtonClicked = 'Clicked sign up button',
  SignUpCancelButtonClicked = 'Clicked sign up cancel button',
  SummarybotStartClicked = 'Clicked on Summarize bot start button',
  TagChatToggleClicked = 'Clicked on tag chat toggle button',
  TagClickFindSimilarButton = 'Clicked tag find similar',
  TagClickSearchInTagButton = 'Clicked search in tag button on tag',
  TagCreated = 'Created tag',
  TagDeleted = 'Deleted tag',
  TagDescriptionCreated = 'Created tag description',
  TagDescriptionUpdated = 'Updated tag description',
  TagFollowed = 'Followed tag',
  TagNotesDrawerClosed = 'Closed tag notes drawer',
  TagNotesToggleClicked = 'Clicked on tag notes toggle button',
  TagOnboardingStartClicked = 'Clicked on Tag onboarding chat start button',
  TagOnboardingSuggestedDocsClicked = 'Clicked on the Suggested docs button recommended by the in tag onboarding chat',
  TagSettingsUpdated = 'Updated tag settings',
  TagSuggestionsRefreshed = 'Refreshed tag suggestions after tagging a document',
  TagToggleSearchButton = 'Clicked search button on tag',
  TagUnfollowed = 'Unfollowed tag',
  TagUpdateNotificationClicked = 'Clicked on tag update notification',
  TagUpdateTestEmailClicked = 'Clicked on send tag update test email button',
  TaggedQueryTitleClicked = 'Clicked tagged query title',
  TransformerSearchTooltipClicked = 'Clicked on transformer search tooltip',
  TwitterHandleClicked = 'Clicked twitter handle',
  TwitterSocialClicked = 'Clicked on social media button: Twitter',
  UpdatePDFViewerExplainHighlightQuestion = 'Clicked in PDF Viewer in Explain highlight update question',
  UpdateRefreshButtonClicked = 'Clicked on refresh update button',
  UpdateSearchAnswerQuestion = 'Clicked on QA update question button',
  UserCreated = 'Created user',
  UserDeleted = 'Deleted user',
  UserSettingsUpdated = 'Updated user settings',
  UserStatsDownload = 'Clicked download user stats button',
  UserUpdated = 'Updated user',
  VOSExplanationClicked = 'Clicked on VOS explanation',
  WizardLastStepCompleted = 'Completed wizard: last step',
  WizardSecondStepCompleted = 'Completed wizard: second step',
  WizardSkipped = 'Skipped wizard',
  ZetaAlphaLogoClicked = 'Clicked on Zeta Alpha logo',
}
