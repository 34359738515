import React, { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TextField } from 'common/components/TextField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import { useStyles } from './styles';

type Props = {
  searchVisibility: boolean;
  setFilteredValue: (value: string) => void;
  setSearchVisibility: (value: boolean) => void;
};

export const TagsListSearch = ({
  searchVisibility,
  setFilteredValue,
  setSearchVisibility,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const debouncedSetFilteredValue = useCallback(
    debounce(setFilteredValue, 500),
    []
  );
  const handleChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    debouncedSetFilteredValue(value);
  };

  const handleToggleSearchVisibility = () => {
    setSearchVisibility(!searchVisibility);
    setFilteredValue('');
  };

  return (
    <>
      <Tooltip title={t<string>('tagsFilterInput.tooltip')}>
        <TrackedIconButton
          eventName={TrackEventName.FilterTagsClicked}
          size="small"
          onClick={handleToggleSearchVisibility}
        >
          {searchVisibility ? <CloseIcon /> : <SearchIcon />}
        </TrackedIconButton>
      </Tooltip>

      {searchVisibility && (
        <TextField
          className={classes.tagSearchBox}
          placeholder={t('tagsFilterInput.placeholder')}
          size="small"
          variant="outlined"
          autoFocus
          onChange={handleChange}
        />
      )}
    </>
  );
};
