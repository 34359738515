import React, { ComponentType, FC, useRef } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppDispatch, RootState } from 'app/state/store';
import clsx from 'clsx';
import type { XYCoord } from 'dnd-core';
import { useSnackbar } from 'notistack';
import { useTagPageDetails } from 'pages/TagPage/hooks/useTagPageDetails';
import { useTagsPageParams } from 'pages/TagsPage/hooks/useTagsPageParams';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { HitType } from 'common/enums';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import {
  addTagToDocument,
  selectSavedDocuments,
} from 'containers/SavedDocuments/savedDocuments.slice';
import { TagDragItemEnum } from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.enum';
import {
  TagDragItem,
  TagDropItem,
} from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.interface';
import { DocCardComposition } from 'containers/User/User.enum';

import RetrievalUnit from './RetrievalUnit';
import { tagDragItemToTagType } from './retrievalUnit.utils';
import { RetrievalUnitData } from './RetrievalUnitData.interface';

export interface DocumentDragItem {
  docIndex?: number;
  docType: HitType;
  id: string;
  type: DocumentDragItemEnum;
}

export enum DocumentDragItemEnum {
  Document = 'document',
}

interface WithDraggableProps {
  cardComposition?: DocCardComposition;
  data: RetrievalUnitData;
  docIndex?: number;
  mapDocIndex?: number;
  onDocumentDrop?: (item: DocumentDragItem, id: string) => void;
  onMove?: (draggedId: string, id: string) => void;
  pageNumber?: number;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  dragIndicator: {
    '&:hover': {
      '& + $draggable': {
        boxShadow: `${theme.shadows[10]}`,
      },
      opacity: 1,
    },
    cursor: 'move',
    height: '100%',
    left: -16,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    width: 30,
    zIndex: 1,
  },
  dragIndicatorDragging: {
    opacity: 0,
    visibility: 'hidden',
  },
  dragPlaceholder: {
    '& > *': {
      opacity: 0,
    },
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  draggable: {},
  draggingTagToDrop: {
    '& + $draggable': {
      boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.light}`,
    },
    opacity: 1,
  },
  hoveredDrop: {
    '& + $draggable': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}, ${theme.shadows[10]}`,
    },
  },
}));

const withDraggable =
  <P extends object>(Component: ComponentType<P>): FC<P & WithDraggableProps> =>
  ({
    cardComposition,
    data,
    docIndex,
    mapDocIndex,
    onDocumentDrop,
    onMove,
    pageNumber,
    ...props
  }: WithDraggableProps) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const dispatch = useDispatch<AppDispatch>();
    const tags = useSelector((state: RootState) => state.tags);
    const { enqueueSnackbar } = useSnackbar();
    const savedDocuments = useSelector(selectSavedDocuments);
    const routeMatch = useTagsPageParams();
    const pageTagId = routeMatch?.params?.tagId;
    const { data: tag } = useTagPageDetails(Number(pageTagId), {
      enabled: !!pageTagId,
    });

    const { tenant } = useParsedHostname();

    const docRef = useRef<HTMLDivElement | null>(null);

    const [collected, connectDrag, connectDragPreview] = useDrag<
      DocumentDragItem,
      unknown,
      { isDragging: boolean }
    >({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: {
        docIndex,
        docType: data.document.type as HitType,
        id: data.organizeDocId,
        type: DocumentDragItemEnum.Document,
      },
      type: DocumentDragItemEnum.Document,
    });

    const attachTagToDocument = async (item: TagDragItem) => {
      if (item.type === TagDragItemEnum.Own) {
        const selectedTag = tags[item.type].data.items.find(
          ({ id }: TagDetails) => id === item.id
        );

        if (!selectedTag) return;

        await dispatch(
          addTagToDocument({
            docId: data.organizeDocId,
            docType: data.document.type as HitType,
            tagId: selectedTag.id,
            tagType: tagDragItemToTagType(item.type),
            tenant,
            userOrder: 'first',
          })
        );
        enqueueSnackbar(
          t('tagDocument.addSingle.successMessage', {
            tagName: selectedTag.name,
          })
        );
      }
    };

    const [
      { canDrop, handlerId, isDraggingDroppable, isTagOver },
      connectDrop,
    ] = useDrop({
      accept: [TagDragItemEnum.Own, DocumentDragItemEnum.Document],
      canDrop: (dropItem: TagDropItem, monitor) => {
        const item = monitor.getItem();
        if (dropItem.type === DocumentDragItemEnum.Document && routeMatch) {
          if (pageTagId) {
            // This case is when we're in a tag page, we need to have permission to change the order
            if (!tag) {
              return false;
            }
            return tag.canChangeDocOrder;
          }
          // This case is when we're in the favorites page so we always have permission
          return true;
        } else if (
          Object.values(TagDragItemEnum).includes(
            dropItem.type as TagDragItemEnum
          )
        ) {
          return !savedDocuments[data.organizeDocId]?.some(
            ({ tag: { id } }) => id === item.id
          );
        }
        return false;
      },
      collect: (monitor) => {
        const itemType = monitor.getItemType();
        const isTag = Object.values(TagDragItemEnum).includes(
          itemType as TagDragItemEnum
        );
        const isDoc = itemType === DocumentDragItemEnum.Document;
        return {
          canDrop: monitor.canDrop(),
          handlerId: monitor.getHandlerId(),
          isDraggingDroppable: isTag || isDoc,
          isTagOver: monitor.isOver() && isTag,
        };
      },
      drop(dropItem: TagDropItem) {
        if (dropItem.type !== DocumentDragItemEnum.Document) {
          void attachTagToDocument(dropItem);
        } else if (onDocumentDrop) {
          onDocumentDrop(dropItem, data.organizeDocId);
        }
      },
      hover(dropItem: TagDropItem, monitor) {
        if (
          dropItem.type === DocumentDragItemEnum.Document &&
          dropItem.id !== data.organizeDocId &&
          onMove &&
          routeMatch
        ) {
          if (pageTagId && (!tag || !tag.canChangeDocOrder)) return;

          if (!docRef.current) {
            return;
          }

          const dragIndex = dropItem.docIndex;
          const hoverIndex = docIndex;

          if (dragIndex === undefined || hoverIndex === undefined) return;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return;
          }

          // Determine rectangle on screen
          const hoverBoundingRect = docRef.current?.getBoundingClientRect();
          // Get vertical middle
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

          // Determine mouse position
          const clientOffset = monitor.getClientOffset();

          // Get pixels to the top
          const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%

          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }

          onMove(dropItem.id, data.organizeDocId);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          dropItem.docIndex = hoverIndex;
        }
      },
    });

    connectDragPreview(docRef);
    connectDrop(docRef);

    return (
      <div
        aria-label="retrieval unit draggable"
        className={clsx(
          classes.container,
          collected.isDragging && classes.dragPlaceholder
        )}
        data-documentId={data.document.id}
        data-handler-id={handlerId}
        data-testid="documentDraggable"
        id={data.organizeDocId}
        ref={docRef}
      >
        <div
          className={clsx(
            classes.dragIndicator,
            collected.isDragging && classes.dragIndicatorDragging,
            isDraggingDroppable && canDrop && classes.draggingTagToDrop,
            isTagOver && canDrop && classes.hoveredDrop
          )}
          data-testid="dragIndicator"
          ref={connectDrag}
        >
          <DragIndicatorIcon fontSize="small" />
        </div>
        <Component
          data={data}
          {...(props as P)}
          cardComposition={cardComposition}
          classes={{ root: classes.draggable }}
          mapDocIndex={mapDocIndex}
          pageNumber={pageNumber}
        />
      </div>
    );
  };

export default withDraggable(RetrievalUnit);
