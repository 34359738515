import React, { FC } from 'react';

import ContentLoader from 'common/components/Loaders/ContentLoader/ContentLoader';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { DocCardComposition } from 'containers/User/User.enum';

import { useChunkDetails } from './hooks/useChunkDetails';

interface ChunkCardProps {
  chunkId: string;
}
export const ChunkCard: FC<ChunkCardProps> = ({ chunkId }) => {
  const { data, isError, isLoading } = useChunkDetails({ chunkId });

  if (isLoading) return <ContentLoader size={18} />;

  const unit = data?.[0];
  if (!unit || isError) return null;

  return (
    <RetrievalUnit
      cardComposition={DocCardComposition.Compressed}
      cardProps={{ square: true }}
      data={unit}
      key={unit.document.id}
    />
  );
};
