import React from 'react';

import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, ListItemSecondaryAction, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { MenuItemTitle } from 'common/components/PageDrawer/MenuItemTitle';
import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { useIsActivePage } from 'common/hooks/useIsActivePage';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { selectSeenRecommendations } from 'containers/SeenRecommendations/seenRecommendations.slice';

type RecommendationsNavItemProps = {
  minified?: boolean;
};

const RecommendationsNavItem = ({ minified }: RecommendationsNavItemProps) => {
  const { t } = useTranslation('pages');
  const { allRecCount, hasNewRecs, seenRecCount } = useSelector(
    selectSeenRecommendations
  );
  const unseenRecCount = allRecCount - seenRecCount;
  const tooltipText = t<string>('recommendations.newRecommendations', {
    unseenRecCount,
  });

  const theme = useTheme<AppTheme>();

  const { isActive } = useIsActivePage('/recommendations');

  const { isRecommendationsDisabled, isRecommendationsHidden } =
    useTenantFeatures();
  if (isRecommendationsHidden) {
    return null;
  }

  return (
    <PageDrawerMenuItem
      icon={
        <NewReleasesIcon
          sx={{
            color: isActive
              ? theme.palette.sideDrawer.icons.highlight
              : theme.palette.sideDrawer.icons.defaultColor,
          }}
        />
      }
      isDisabled={isRecommendationsDisabled}
      minified={minified}
      title={
        <MenuItemTitle
          chip={hasNewRecs}
          chipLabel={unseenRecCount}
          chipTooltip={tooltipText}
        >
          {t('recommendations.navTitle')}
        </MenuItemTitle>
      }
      to="/recommendations"
      tooltip={t('recommendations.navTitle')}
    >
      {!minified && (
        <ListItemSecondaryAction>
          <IconButton
            aria-label="recommendations settings"
            component={RouterLink}
            data-testid="recommendationsSettings"
            disabled={isRecommendationsDisabled}
            edge="end"
            size="small"
            to="/settings/recommendations"
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </PageDrawerMenuItem>
  );
};

export default RecommendationsNavItem;
