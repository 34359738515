import React, { useMemo } from 'react';

import Autocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField';
import { ClassNameMap } from '@mui/styles';
import { useField } from 'formik';

import { TextField } from '../../TextField';

type Props = {
  classes?: Partial<ClassNameMap<string>>;
  freeSolo?: boolean;
  helperText?: string;
  label?: string;
  limitTags?: number;
  name: string;
  onBlur?: () => void;
  onChange?: (tags: string[]) => void;
  options: string[];
  placeholder?: string;
  renderTags?: (
    value: string[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => React.ReactNode;
  size?: 'small' | 'medium';
  textFieldProps?: TextFieldProps;
};

export const FormAutocomplete = ({
  classes: inputClasses,
  freeSolo,
  helperText,
  label,
  name,
  onBlur = () => null,
  onChange = () => null,
  options,
  placeholder,
  renderTags,
  size,
  textFieldProps,
  ...rest
}: Props) => {
  const [field, meta, fieldHelpers] = useField<string[]>(name);
  const hasError = !!(meta.touched && meta.error);
  const helperMessage = useMemo(() => {
    return hasError ? meta.error : helperText;
  }, [meta.error, hasError, helperText]);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      {...textFieldProps}
      data-testid="autocompleteFieldInput"
      error={hasError}
      helperText={helperMessage}
      label={label}
      placeholder={placeholder}
    />
  );

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string[]
  ): void => {
    fieldHelpers.setValue(newValue);
    onChange(newValue);
  };

  return (
    <Autocomplete
      ChipProps={{ size: 'small' }}
      classes={{ ...inputClasses }}
      freeSolo={freeSolo}
      options={options}
      renderInput={renderInput}
      renderTags={renderTags}
      size={size}
      value={field.value}
      clearOnBlur
      filterSelectedOptions
      multiple
      onBlur={onBlur}
      // @ts-ignore
      onChange={handleChange}
      {...rest}
    />
  );
};
