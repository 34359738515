import { InfoPagesKeys } from 'api/tenantSettingsApi/WhitelabelSettings.types';

import { useAssertTenantSettings } from './useAssertTenantSettings';

export const useInfoPages = (key: InfoPagesKeys) => {
  const { tenantSettings } = useAssertTenantSettings();
  if (!tenantSettings.whitelabel?.pages) {
    return undefined;
  }
  return tenantSettings.whitelabel?.pages[key] ?? null;
};
