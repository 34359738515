import { useCallback, useContext } from 'react';

import { posthogCapture } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { TrackedActionContext } from '../trackedAction.context';
import {
  TrackedActionEventProps,
  TrackEventName,
} from '../withTrackedAction.interface';

export interface UseTrackedActionProps {
  eventName?: TrackEventName | string;
  eventProps?: TrackedActionEventProps;
}

export const useTrackedAction = (defaults: UseTrackedActionProps = {}) => {
  const actionContext = useContext(TrackedActionContext);
  const { tenant } = useParsedHostname();

  const onAction = useCallback(
    (
      eventName: TrackEventName | string | undefined = defaults.eventName,
      eventProps: TrackedActionEventProps | undefined = defaults.eventProps
    ) => {
      if (!eventName) return;

      posthogCapture(eventName, {
        tenant,
        ...actionContext,
        ...eventProps,
      });
    },
    [actionContext, defaults.eventName, defaults.eventProps, tenant]
  );

  return {
    actionContext,
    onAction,
  };
};
