import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { darken, Radio, RadioProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { color: string }>((theme: Theme) => ({
  icon: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    color: theme.palette.secondary.main,
    opacity: 0,
    position: 'absolute',
    right: -5,
    top: -5,
    transition: theme.transitions.create('opacity'),
  },
  iconActive: {
    boxShadow: theme.shadows[3],
    opacity: 1,
  },
  root: {
    '& + $root': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: ({ color }) => darken(color, 0.2),
    },
    backgroundColor: ({ color }) => color,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    height: 30,
    margin: 0,
    padding: 0,
    position: 'relative',
    width: 30,
  },
}));

export type ColorRadioProps = {
  radioColor: string;
  value: string;
} & RadioProps;

const ColorRadio = ({
  className,
  radioColor,
  value,
  ...props
}: ColorRadioProps) => {
  const classes = useStyles({ color: radioColor });

  return (
    <Radio
      checkedIcon={
        <CheckCircleIcon
          className={clsx(classes.icon, classes.iconActive)}
          fontSize="small"
        />
      }
      className={clsx(classes.root, className)}
      color="default"
      icon={<CheckCircleIcon className={classes.icon} fontSize="small" />}
      value={value}
      {...props}
    />
  );
};

export default ColorRadio;
