import {
  Configuration,
  FeedbackApi,
  FeedbackForm,
} from '@zarn/vendor/dist/query-logging';

import { FeedbackListPayload } from 'containers/Feedback/Feedback.interface';

import { SERVICE_URL, BASE_HEADERS } from '../apiConfig';
import axiosInstance from '../axiosInstance';

export const feedbackApi = new FeedbackApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const postFeedback = (tenant: string, feedbackForm: FeedbackForm) =>
  feedbackApi.feedbackPost({
    feedbackForm,
    requesterUuid: '',
    tenant,
  });

export const getFeedbackList = (payload: FeedbackListPayload) =>
  feedbackApi.feedbackList({
    ...payload,
    requesterUuid: '',
  });
