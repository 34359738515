import { useCallback, useMemo } from 'react';

import {
  getReportId,
  getReportLabel,
  getReportLink,
} from 'containers/NotesReportsLoading/reports.utils';

import { useAsyncLoading } from '../../../NotesReportsLoading/NotesReportsLoadingContext';
import { SubItem } from '../../NotificationLinkItemExpandable';

export const useReportsNotifications = (onClose?: () => void) => {
  const { readReport, state } = useAsyncLoading();

  const notificationTasks = useMemo(
    () =>
      state.tasks.filter((task) => !task.status || task.status === 'success'),
    [state.tasks]
  );

  const reportsNotifications: SubItem[] = useMemo(
    () =>
      notificationTasks.map((task) => ({
        id: getReportId(task),
        label: getReportLabel(task),
        notifications: task.status || 'loading',
        to: getReportLink(task.chatNoteId),
      })),
    [notificationTasks]
  );

  const onClick = useCallback(
    (index: number) => () => {
      const note = notificationTasks[index];
      readReport(note.chatNoteId, note.reportNoteId);
      onClose?.();
    },
    [notificationTasks, onClose, readReport]
  );

  return {
    onClick,
    reportsNotifications,
  };
};
