import type { NoteDetails } from 'api/notesApi/notesApi.types';

export const getReportId = ({
  chatNoteId,
  reportNoteId,
}: {
  chatNoteId: number;
  reportNoteId: number;
}) => `${chatNoteId}_${reportNoteId}`;

export const getReportLabel = ({
  chatNoteId,
  note,
  reportNoteId,
}: {
  chatNoteId: number;
  note?: NoteDetails;
  reportNoteId: number;
}) => {
  if (!note) {
    return `Report #${chatNoteId}_${reportNoteId}`;
  }
  const lines = note.content.split('\n');
  return lines[0].replaceAll('#', '');
};

export const getReportLink = (chatNoteId: number) =>
  `/?tab=chat&noteId=${chatNoteId}`;
