import React, { useCallback, useState } from 'react';

import { Box, BoxProps, Card } from '@mui/material';
import { captureException } from '@sentry/react';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { NoteForm } from 'common/components/Notes/NoteForm';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';

import { TextField } from '../../TextField';

import { useNoteFormValidation } from './useNoteFormValidation';

const initialValues: NoteFormValue = {
  content: '',
};

type Props = {
  initEditMode?: boolean;
  onAdd: (content: string) => Promise<void>;
} & BoxProps;

export const NoteCreate = ({
  initEditMode = false,
  onAdd,
  ...boxProps
}: Props) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { validationSchema } = useNoteFormValidation();
  const [editMode, setEditMode] = useState<boolean>(initEditMode);

  const editModeToggle = () => {
    setEditMode(!editMode);
  };

  const handleSubmit = useCallback(
    async (
      { content }: NoteFormValue,
      { resetForm, setSubmitting }: FormikHelpers<NoteFormValue>
    ) => {
      try {
        setSubmitting(true);
        await onAdd(content);
        enqueueSnackbar(t('notes.create.successMessage'));
        resetForm();
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
        setSubmitting(false);
      }
    },
    [t, enqueueSnackbar, onAdd]
  );

  return (
    <Box {...boxProps}>
      {!editMode && (
        <Card>
          <TextField
            label={t('notes.form.yourNote')}
            fullWidth
            onClick={editModeToggle}
          />
        </Card>
      )}

      {editMode && (
        <Card>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onReset={editModeToggle}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <NoteForm
                {...props}
                eventName={TrackEventName.NoteCreated}
                submitButtonText={t('saved:tagDocuments.buttons.create')}
                onClickAway={editModeToggle}
              />
            )}
          </Formik>
        </Card>
      )}
    </Box>
  );
};
