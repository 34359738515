import { combineReducers } from 'redux';

import analyticsGraph from 'containers/Analytics/analyticsGraph.slice';
import documentSources from 'containers/DocumentSources/documentSources.slice';
import queryEntities from 'containers/QueryEntities/queryEntities.slice';
import recommendations from 'containers/Recommendations/recommendations.slice';
import savedDocuments from 'containers/SavedDocuments/savedDocuments.slice';
import documentsNotes from 'containers/SavedNotes/DocumentsNotes/documentsNotes.slice';
import tagsNotes from 'containers/SavedNotes/TagsNotes/tagsNotes.slice';
import search from 'containers/Search/search.slice';
import searchResults from 'containers/SearchResults/searchResults.slice';
import seenRecommendations from 'containers/SeenRecommendations/seenRecommendations.slice';
import taggedResources from 'containers/TaggedResources/taggedResources.reducer';
import tags from 'containers/Tags/tags.reducers';
import user from 'containers/User/user.slice';

export default combineReducers({
  analyticsGraph,
  documentSources,
  documentsNotes,
  queryEntities,
  recommendations,
  savedDocuments,
  search,
  searchResults,
  seenRecommendations,
  taggedResources,
  tags,
  tagsNotes,
  user,
});
