import React, { useCallback, useEffect, useRef } from 'react';
import { pdfjs, Document, DocumentProps } from 'react-pdf';
import clsx from 'clsx';
import { LinearProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { useStyles } from './style';
import {
  usePages,
  useActivePageListener,
  useLoadProgress,
  usePinchToZoomListener,
  useDownloadUri,
  usePDFMetadata,
  useZoomLevel,
  useKeepScrollPosition,
} from '../../hooks';
import {
  getAdjustedInitZoomLevel,
  calcMaxZoomLevel,
  getPagesFromDocument,
  isMobile,
} from '../../utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const defaultOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

export type PDFViewerDocumentProps = {
  onScrollToPage: (page: number) => void;
  docContainerRef: React.RefObject<HTMLDivElement | null>;
} & DocumentProps;

export const PDFViewerDocument = ({
  file,
  children,
  onScrollToPage,
  docContainerRef,
}: PDFViewerDocumentProps) => {
  const classes = useStyles();
  const docRef = useRef<HTMLDivElement | null>(null);
  const { setDownloadUri } = useDownloadUri();
  const { setMetadata } = usePDFMetadata();
  const { zoomLevel, minZoomLevel, setZoomLevel, setMaxZoomLevel } =
    useZoomLevel();
  const { numberOfPages, setActivePage, setPages } = usePages();
  const { loadProgress, onLoadProgress } = useLoadProgress();
  const visibleActivePage = useActivePageListener({
    ref: docContainerRef,
    numberOfPages,
  });

  usePinchToZoomListener(docContainerRef);
  useKeepScrollPosition(docContainerRef);

  const onDocumentLoadSuccess = (pdfDocProxy: any) => {
    (async () => {
      const { info } = await pdfDocProxy.getMetadata();

      const pages = await getPagesFromDocument(pdfDocProxy);

      const data = await pdfDocProxy.getData();

      const blob = new Blob([data], { type: 'application/pdf' });

      const { originalWidth, originalHeight } = pages[0];

      setDownloadUri(URL.createObjectURL(blob));

      setMetadata(info);

      setPages(pages);

      setInitZoomLevel(originalWidth);

      setMaxZoomLevel(calcMaxZoomLevel(originalWidth, originalHeight));
    })();
  };

  const setInitZoomLevel = (pageWidth: number) => {
    const viewportWidth: number | undefined =
      docContainerRef.current?.getBoundingClientRect().width;

    if (!viewportWidth) return;

    setZoomLevel(
      getAdjustedInitZoomLevel({
        viewportWidth,
        pageWidth,
        zoomLevel,
        minZoomLevel,
      })
    );
  };

  const handleItemClick = useCallback(
    ({ pageNumber }: { pageNumber: string }) => {
      const refPage = parseInt(pageNumber);

      if (Number.isNaN(refPage)) return;

      setActivePage(refPage);

      onScrollToPage(refPage);
    },
    [setActivePage, onScrollToPage]
  );

  useEffect(() => {
    setActivePage(visibleActivePage);
  }, [visibleActivePage, setActivePage]);

  return (
    <Document
      file={file}
      inputRef={docRef}
      options={defaultOptions}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadProgress={onLoadProgress}
      className={clsx(classes.document, !isMobile() && classes.mobileDevice)}
      onItemClick={handleItemClick}
      externalLinkTarget="_blank"
      onLoadError={(error) => console.log(error)}
      onSourceError={(error) => console.log('srcError', error)}
      loading={
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={loadProgress}
          className={classes.loadProgress}
        />
      }
    >
      {children}
    </Document>
  );
};

export default PDFViewerDocument;
