import {
  RecommendationItem,
  RecommendationTaskItem,
  TaskStatus,
} from '@zarn/vendor/dist/recommendations';

import {
  RecTask,
  RecommendedItem,
  RecTaskStatus,
} from './RecommendationsApi.types';

export const deserializeRecommendation = (
  rec: RecommendationItem
): RecommendedItem => ({
  dateCreated: rec.date_created,
  explanation: rec.explanation,
  explanations: rec.explanations.map((ex) => ({
    explanationId: ex.explanation_id,
    explanationType: ex.explanation_type,
  })),
  recCount: rec.rec_count,
  recId: rec.recommendation_uuid,
  resourceId: rec.identifier.resource_id,
  resourceType: rec.identifier.resource_type,
  score: rec.score,
});

export const taskStatusToRecTaskStatus = (
  status: TaskStatus
): RecTaskStatus => {
  switch (status) {
    case TaskStatus.Failed:
      return 'failed';
    case TaskStatus.Finished:
      return 'finished';
    case TaskStatus.Started:
      return 'started';
    default:
      return 'pending';
  }
};

export const deserializeRecommendationTaskItem = (
  rec: RecommendationTaskItem
): RecTask => ({
  dateCreated: rec.date_created,
  dateUpdated: rec.date_updated,
  error: rec.error,
  isStale: rec.is_stale,
  status: taskStatusToRecTaskStatus(rec.status),
  uuid: rec.uuid,
});
