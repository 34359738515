import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';
import { groupBy } from 'lodash';

import { TagDetails } from 'api/tagsApi/tagsApi.types';

import { getTagsByIds } from '../tags.utils';
import { DEFAULT_SHARED_TAGS_SORTING } from '../TagsSort/tagsSort.const';
import { TagsSort } from '../TagsSort/TagsSort.interface';

import {
  getSharedTagsThunk,
  updateSharedTagsSortSettingsThunk,
} from './sharedTagsSlice.utils';

const SLICE_NAME = 'sharedTags';

export interface SharedTagsState {
  data: SharedTagsStateData;
  error: string | null;
  fetchState: FiniteStatesType;
}

export interface SharedTagsStateData {
  items: TagDetails[];
  newTagsCount: number;
  sortSettings: TagsSort;
  updatesLastSeen: string;
}

export const initialState: SharedTagsState = {
  data: {
    items: [],
    newTagsCount: 0,
    sortSettings: DEFAULT_SHARED_TAGS_SORTING,
    updatesLastSeen: '',
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getSharedTags = createAsyncThunk(
  `${SLICE_NAME}/get`,
  getSharedTagsThunk
);

export const updateSharedTagsSortSettings = createAsyncThunk(
  `${SLICE_NAME}/updateSortSettings`,
  updateSharedTagsSortSettingsThunk
);

const sharedTags = createSlice({
  extraReducers: (builder) => {
    // Get tags
    builder
      .addCase(getSharedTags.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getSharedTags.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error = action.error.message || 'Unexpected error';
      })
      .addCase(getSharedTags.fulfilled, (state, action) => {
        state.fetchState = FiniteStates.Success;
        state.data.sortSettings = action.payload.sortSettings;
        state.data.items = action.payload.items;
        state.error = null;
      });
    // Update tag sort settings
    builder.addCase(
      updateSharedTagsSortSettings.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.sortSettings = payload.sortSettings;
        state.data.items = payload.items;
        state.error = null;
      }
    );
  },
  initialState,
  name: SLICE_NAME,
  reducers: {
    resetSharedTagsState: (state) => {
      state.fetchState = initialState.fetchState;
      state.data = initialState.data;
      state.error = initialState.error;
    },
  },
});

const sharedTagsSelector = (state: RootState) => state.tags.shared;

export const selectNewSharedTagsCount = createSelector(
  sharedTagsSelector,
  ({ data }) => data.newTagsCount
);

export const selectSharedTags = createSelector(
  sharedTagsSelector,
  ({ data }) => data.items
);

export const selectEditorSharedTags = createSelector(
  sharedTagsSelector,
  ({ data }) => data.items.filter((t) => t.permission === 'write')
);

export const selectSharedTagsGroupedByOwner = createSelector(
  sharedTagsSelector,
  ({ data }) => groupBy(data.items, (item) => item.owner?.id)
);

export const selectSharedTagsByIds = (ids: number[]) =>
  createSelector(sharedTagsSelector, ({ data }) =>
    getTagsByIds(ids, data.items)
  );

export const selectSharedTagsSortSettings = createSelector(
  sharedTagsSelector,
  ({ data }) => data.sortSettings
);

export const selectSharedTagsLoading = createSelector(
  sharedTagsSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const { resetSharedTagsState } = sharedTags.actions;

export default sharedTags.reducer;
