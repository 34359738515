import React, { FC } from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

interface OpenChatNoteButtonProps {
  noteId: number;
  onClick: () => void;
}

export const OpenChatNoteButton: FC<OpenChatNoteButtonProps> = ({
  noteId,
  onClick,
}) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={t('notes.actions.openChatNote')}>
      <TrackedIconButton
        aria-label="Open Chat note content"
        data-testid="open-chat-note"
        eventName={TrackEventName.ClickOnOpenChatNote}
        eventProps={{ noteId }}
        size="small"
        onClick={onClick}
      >
        <OrangeGradientOpenAiIcon />
      </TrackedIconButton>
    </Tooltip>
  );
};
