import { useCallback } from 'react';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';

export type UseTagsAutocompleteOptionSelectedReturn = (
  option: TagsAutocompleteOptionValue,
  value: TagsAutocompleteOptionValue
) => boolean;

export const useTagsAutocompleteOptionSelected =
  (): UseTagsAutocompleteOptionSelectedReturn => {
    const getOptionSelect = useCallback(
      (
        option: TagsAutocompleteOptionValue,
        value: TagsAutocompleteOptionValue
      ) => option.id === value.id,
      []
    );

    return getOptionSelect;
  };
