import React, { KeyboardEvent, useMemo } from 'react';

import { Button, CardActions, CardContent } from '@mui/material';
import { AppDispatch } from 'app/state/store';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TextField } from 'common/components/TextField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { addTag } from '../ownTagsSlice/ownTags.slice';

import { useStyles } from './styles';

interface TagFormValues {
  tagName: string;
}

const MIN_TAG_LENGTH = 1;
const MAX_TAG_LENGTH = 128;
const initialValues: TagFormValues = {
  tagName: '',
};

export type TagFormProps = {
  onCancel: () => void;
  onSubmit: (tagItem: TagDetails) => void;
};

export const TagForm = ({ onCancel, onSubmit }: TagFormProps) => {
  const { t } = useTranslation(['tags', 'common']);
  const dispatch = useDispatch<AppDispatch>();
  const { actionsRoot, formActions, wrapper } = useStyles();
  const { tenant } = useParsedHostname();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tagName: Yup.string()
          .min(MIN_TAG_LENGTH, t('tags:errors.minLength'))
          .max(MAX_TAG_LENGTH, t('tags:errors.maxLength'))
          .required(t('common:errors.required')),
      }),
    [t]
  );

  const handleSubmitForm = async (
    { tagName }: TagFormValues,
    { setErrors, setSubmitting }: FormikHelpers<TagFormValues>
  ) => {
    setSubmitting(true);

    const { payload, type } = await dispatch(
      addTag({ payload: { name: tagName }, tenant })
    );

    const error = type === addTag.rejected?.type && String(payload);

    setSubmitting(false);
    if (!error) {
      onSubmit(payload as TagDetails);
    } else {
      setErrors({ tagName: error });
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className={wrapper} data-testid="TagForm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onReset={onCancel}
        onSubmit={handleSubmitForm}
      >
        {({ dirty, handleSubmit, isSubmitting, isValid }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
          >
            <CardContent>
              <Field name="tagName">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    error={!!(meta.error && meta.touched)}
                    helperText={meta.touched && meta.error}
                    inputProps={{ 'aria-label': 'Tag name input' }}
                    label={t('tags:settings.tagName.label')}
                    autoFocus
                    fullWidth
                    multiline
                    onKeyPress={handleKeyPress}
                  />
                )}
              </Field>
            </CardContent>

            <CardActions classes={{ root: actionsRoot }}>
              <div className={formActions}>
                <Button type="reset">{t('common:buttons.cancel')}</Button>

                <SubmitButton
                  aria-label="submit"
                  disabled={!isValid || !dirty}
                  eventName={TrackEventName.TagCreated}
                  isSubmitting={isSubmitting}
                  text={t('common:buttons.submit')}
                />
              </div>
            </CardActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};
