import React from 'react';
import AreaSelection from '../AreaSelection';
import { useAreaSelection } from './useAreaHighlighting';

const AreaHighlighting = () => {
  const {
    parentEl,
    highlightColor,
    handleSelectionChangeDebounced,
    handleSelectionEnd,
    handleSelectionStart,
    handleSelectionClear,
  } = useAreaSelection();
  return (
    <AreaSelection
      parentEl={parentEl}
      selectionColor={highlightColor}
      onSelectionChange={handleSelectionChangeDebounced}
      onSelectionEnd={handleSelectionEnd}
      onSelectionStart={handleSelectionStart}
      onSelectionCancel={handleSelectionClear}
    />
  );
};

export default AreaHighlighting;
