import { createContext } from 'react';
import { initialPDFPageState, PDFPageAction } from '../../hooks/usePDFPage';
import { PDFPageState } from '../../types';

export interface PDFPageContextValue {
  state: PDFPageState;
  dispatch: (actionType: PDFPageAction) => void;
}

export const initContext: PDFPageContextValue = {
  state: initialPDFPageState,
  dispatch: () => {},
};

export const PDFPageContext = createContext<PDFPageContextValue>(initContext);
