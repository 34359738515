import { useCallback } from 'react';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

interface Props {
  noteId: number;
  onOpenChatClick?: () => void;
}

export const useOpenMyDocumentsChat = ({ noteId, onOpenChatClick }: Props) => {
  const navigation = useNavigationWithState();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    navigation.push(`/my-documents/?tab=chat&noteId=${noteId}`);
  });

  const openMyDocumentsChat = useCallback(async () => {
    onOpenChatClick?.();

    openChatNote(true);
  }, [onOpenChatClick, openChatNote]);

  return { openMyDocumentsChat };
};
