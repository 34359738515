import { useEffect, useState } from 'react';

import { usePages } from '../usePages';

export const usePagesToRender = (limit: number = 5): number[] => {
  const { activePage, pages } = usePages();
  const [pagesToRender, setPagesToRender] = useState<number[]>([]);

  useEffect(() => {
    const from = Math.max(activePage - limit - 1, 0);
    const to = Math.min(from + limit * 2 + 1, pages.length);

    const pagesToRender = pages
      .slice(from, to)
      .map(({ pageNumber }) => pageNumber);

    setPagesToRender(pagesToRender);
  }, [setPagesToRender, activePage, pages, limit]);

  return pagesToRender;
};
