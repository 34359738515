import { ZOOM_LEVELS } from '../const/defaults.const';

export const getNextZoomValue = (
  currentZoom: number,
  dir: 'asc' | 'desc'
): number => {
  const curLevelIndex = ZOOM_LEVELS.findIndex((v) => currentZoom <= v);

  return {
    desc: ZOOM_LEVELS[curLevelIndex - 1] ?? ZOOM_LEVELS[curLevelIndex],
    asc: ZOOM_LEVELS[curLevelIndex + 1] ?? ZOOM_LEVELS[curLevelIndex],
  }[dir];
};

export const calcZoomDelta = (zoom: number, deltaY: number): number => {
  return deltaY * zoom * 0.01;
};
