import { PaginatedResults } from 'api/models/PaginatedResults';
import {
  AnnotationHighlight,
  ListNotesPayload,
  NoteDetails,
  UpdateNotePayload,
} from 'api/notesApi/notesApi.types';

import { SavedNote, SavedNotes } from './SavedNotes.interface';

export const getDateModified = () => new Date().toString();

export const getSavedNoteById = (
  savedNotes: SavedNotes,
  id: string
): SavedNote | null =>
  Object.hasOwnProperty.call(savedNotes, id) ? savedNotes[id] : null;

export const notesQueryKeys = {
  all: ['notes'] as const,
  list: (payload: ListNotesPayload) =>
    [...notesQueryKeys.lists(), payload] as const,
  lists: () => [...notesQueryKeys.all, 'list'] as const,
};

export const paginatedNotesOptimisticUpdate = (
  payload: UpdateNotePayload,
  oldResults?: PaginatedResults<NoteDetails>
): PaginatedResults<NoteDetails> | undefined => {
  if (!oldResults) return;

  return {
    ...oldResults,
    items: oldResults.items.map((note) =>
      note.id === payload.noteId
        ? {
            ...note,
            content: payload.content,
            sharing: payload.sharing,
          }
        : note
    ),
  };
};

export const paginatedNotesOptimisticDelete = (
  noteId: number,
  oldResults?: PaginatedResults<NoteDetails>
): PaginatedResults<NoteDetails> | undefined => {
  if (!oldResults) return;

  return {
    ...oldResults,
    items: oldResults.items.filter((note) => note.id !== noteId),
  };
};

export const addNoteHighlightTokens = (
  text: string,
  highlightTokens: string[]
): string => {
  if (!highlightTokens.length) return text;

  const regex = new RegExp(`(${highlightTokens.join('|')})`, 'gi');

  return text.replace(regex, '**$&**');
};

export const addHighlightToAnnotationHighlight = (
  annotation: AnnotationHighlight | null,
  highlightTokens: string[]
): AnnotationHighlight | null => {
  if (annotation?.content.type !== 'text') return null;

  return {
    ...annotation,
    content: {
      body: addNoteHighlightTokens(annotation.content.body, highlightTokens),
      type: annotation.content.type,
    },
  };
};
