import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dialog, DialogContent, InputAdornment, Theme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { TagType } from '@zarn/vendor/dist/saved-results';

import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { FieldCopyButton } from 'common/components/Fields/FieldCopyButton';
import { TextField } from 'common/components/TextField';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { useOntologyDocIds } from 'containers/Docs/hooks/useOntologyDocIds';

import { useAllTaggedDocs } from '../hooks/useAllTaggedDocs';
import { useBibtexList } from '../hooks/useBibtexList';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    paddingBottom: theme.spacing(3),
  },
  inputAdornment: {
    height: 'auto',
  },
  inputRoot: {
    alignItems: 'flex-start',
  },
  textarea: {
    fontFamily: 'monospace',
    fontSize: theme.typography.body2.fontSize,
  },
}));

export type BibtexDialogProps = {
  ids?: string[];
  open: boolean;
  setOpen: (value: boolean) => void;
  tagId?: number;
  tagType?: TagType;
};

const BibtexDialog = ({
  ids,
  open,
  setOpen,
  tagId,
  tagType,
}: BibtexDialogProps) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('common');
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>();

  const { data: taggedDocs, isAllTaggedDocsLoading } = useAllTaggedDocs({
    options: {
      ...QUERY_OPTIONS,
      enabled: open && !!tagType && !!tagId,
    },
    tagId: tagId ?? 0,
    tagType: tagType ?? 'OWN',
  });
  const ontologyIds = useOntologyDocIds(taggedDocs);

  const { data, error, isError, isLoading } = useBibtexList(
    ids ?? ontologyIds,
    {
      ...QUERY_OPTIONS,
      enabled: open,
    }
  );

  useEffect(() => {
    if (open) {
      if (isLoading || isAllTaggedDocsLoading) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  }, [isAllTaggedDocsLoading, isLoading, open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog
      aria-label="Bibtex dialog"
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <DialogActionTitle id="shareDialog" onClose={handleClose}>
        {t('bibTeXDialog.title')}
      </DialogActionTitle>

      <DialogContent className={classes.content}>
        {loading && (
          <div aria-label="bibtex loading">
            <Skeleton height={15} width="30%" />
            <Skeleton height={15} width="70%" />
            <Skeleton height={15} width="50%" />
            <Skeleton height={15} width="60%" />
            <Skeleton height={15} width="30%" />
            <Skeleton height={15} width="10%" />
          </div>
        )}

        {isError && <Alert severity="error">{error?.message}</Alert>}

        {data && (
          <TextField
            InputProps={{
              classes: { multiline: classes.textarea, root: classes.inputRoot },
              endAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  position="end"
                >
                  <FieldCopyButton inputRef={inputRef} />
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            label={t('bibTeXDialog.formattedCitation')}
            value={data}
            fullWidth
            multiline
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

BibtexDialog.displayName = 'BibtexDialog';

export default BibtexDialog;
