export const throttle = (func: () => void, wait: number) => {
  let isThrottled = false;
  return () => {
    if (!isThrottled) {
      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, wait);
      func();
    }
  };
};
