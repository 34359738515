import React, { createContext } from 'react';

import { TagsListType } from './TagsListType.enum';

export interface TagsListContextValue {
  anchorsRefs: TagsListAnchorsRefs;
  expandedListState: TagsListExpandedState;
  searchVisibility: boolean;
  toggleExpandedListState: (listType: TagsListType) => Promise<void>;
}

export interface TagsListAnchorsRefs {
  following: React.MutableRefObject<HTMLDivElement | null>;
  own: React.MutableRefObject<HTMLDivElement | null>;
  shared: React.MutableRefObject<HTMLDivElement | null>;
  wrapper: React.MutableRefObject<HTMLDivElement | null>;
}

export interface TagsListExpandedState {
  [TagsListType.Own]: boolean;
  [TagsListType.Following]: boolean;
  [TagsListType.Shared]: boolean;
}

export const tagsListContextInitialValue: TagsListContextValue = {
  anchorsRefs: {
    following: { current: null },
    own: { current: null },
    shared: { current: null },
    wrapper: { current: null },
  },
  expandedListState: {
    following: true,
    own: true,
    shared: false,
  },
  searchVisibility: false,
  toggleExpandedListState: () => Promise.resolve(),
};

export const TagsListContext = createContext<TagsListContextValue>(
  tagsListContextInitialValue
);
