import React from 'react';
import { Box, ClickAwayListener, Tooltip, TooltipProps } from '@mui/material';

export type HighlightTooltipProps = {
  anchorTop: number;
  anchorLeft: number;
  children?: React.ReactNode;
  onClose: () => void;
} & Omit<TooltipProps, 'title' | 'children' | 'onClose'>;

const HighlightTooltip = React.memo(
  ({
    open,
    anchorTop,
    anchorLeft,
    children,
    onClose,
    ...rest
  }: HighlightTooltipProps) => {
    return (
      <Tooltip
        arrow
        open={open}
        disableFocusListener
        title={
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={onClose}
          >
            <Box display="flex" gap={0.5}>
              {children}
            </Box>
          </ClickAwayListener>
        }
        {...rest}
      >
        <div
          style={{
            width: 30,
            height: 5,
            position: 'fixed',
            top: anchorTop,
            left: anchorLeft - 15,
            visibility: open ? 'visible' : 'hidden',
          }}
        />
      </Tooltip>
    );
  }
);

HighlightTooltip.displayName = 'HighlightTooltip';

export default HighlightTooltip;
