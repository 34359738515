import { Theme, alpha, ComponentsOverrides } from '@mui/material/styles';

export const stepIconOverrides: ComponentsOverrides<Theme>['MuiStepIcon'] = {
  root: ({ theme }) => ({
    '&.Mui-active': {
      '& > .MuiStepIcon-text': {
        fill: theme.palette.common.white,
      },
      boxShadow: theme.shadows[5],
    },
    '&.Mui-active, &.Mui-completed': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-completed': {
      boxShadow: theme.shadows[3],
    },
    border: `4px solid ${alpha(theme.palette.secondary.main, 0.4)}`,
    borderRadius: 20,
    color: 'transparent',
    fontSize: 28,
  }),
  text: ({ theme }) => ({
    fill: theme.palette.primary.main,
  }),
};

export const stepConnectorOverrides: ComponentsOverrides<Theme>['MuiStepConnector'] =
  {
    lineVertical: ({ theme }) => ({
      borderColor: alpha(theme.palette.secondary.main, 0.4),
      borderLeftWidth: 2,
      minHeight: 14,
    }),
    vertical: {
      marginLeft: 13,
      padding: 0,
    },
  };

export const stepContentOverrides: ComponentsOverrides<Theme>['MuiStepContent'] =
  {
    root: ({ theme }) => ({
      borderLeft: `2px solid ${alpha(theme.palette.secondary.main, 0.4)}`,
      marginLeft: 13,
      marginTop: 0,
      paddingLeft: 22,
    }),
  };
