import { useCallback } from 'react';

import { ConversationPayload } from 'containers/Chat/Chat.types';
import { useParser } from 'containers/Chat/hooks/useConversation/parsers/useParser';

export const useSavedPropertyFromNote = (noteContent: string) => {
  const { parser } = useParser<ConversationPayload>(noteContent);

  const getSavedPropFromNote = useCallback(
    async (prop: keyof ConversationPayload) => {
      const disassembleNote = await parser?.disassemble();
      return disassembleNote?.payload?.[prop];
    },
    [parser]
  );

  return { getSavedPropFromNote };
};
