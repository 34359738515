import React, { useEffect, useRef, useState } from 'react';

import { Collapse, CollapseClassKey, Popover, Theme } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { PageDrawerSectionItem } from './PageDrawerSectionItem';

const useStyles = makeStyles((theme: Theme) => ({
  collapse: {
    marginLeft: theme.spacing(2),
  },
  popoverPaper: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100vh - 200px)`,
    padding: theme.spacing(1, 2),
    width: 300,
  },
}));

export type PageDrawerMenuSectionProps = {
  children: React.ReactNode;
  collapseClasses?: Partial<ClassNameMap<CollapseClassKey>>;
  icon?: React.ReactNode;
  menuOpen?: boolean;
  minified?: boolean;
  sectionPath?: string;
  sectionTitle: string;
  subheaderActions?: React.ReactElement;
};

export const PageDrawerMenuSection = ({
  children,
  collapseClasses,
  icon,
  menuOpen,
  minified,
  sectionPath,
  sectionTitle,
  subheaderActions,
}: PageDrawerMenuSectionProps) => {
  const classes = useStyles();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(!!menuOpen);

  useEffect(() => {
    if (minified === true) {
      setOpen(!minified);
    }
  }, [minified]);

  useEffect(() => {
    if (typeof menuOpen !== 'undefined') {
      setOpen(menuOpen);
    }
  }, [menuOpen]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PageDrawerSectionItem
        actions={subheaderActions}
        expanded={open}
        icon={icon}
        minified={minified}
        ref={itemRef}
        sectionPath={sectionPath}
        text={sectionTitle}
        onToggle={handleToggle}
      />

      {minified ? (
        <Popover
          anchorEl={itemRef.current}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          classes={{ paper: classes.popoverPaper }}
          open={open}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          disableScrollLock
          onClose={handleClose}
        >
          {children}
        </Popover>
      ) : (
        <Collapse
          classes={collapseClasses}
          className={classes.collapse}
          in={open}
          mountOnEnter
        >
          {children}
        </Collapse>
      )}
    </>
  );
};
