import { useQuery, UseQueryOptions } from 'react-query';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { listNotes } from 'api/notesApi/notesApi';
import {
  ListNotesPayloadParams,
  NoteDetails,
} from 'api/notesApi/notesApi.types';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { docNotesQueryKeys } from '../docNotes.utils';

export type UseDocNotesProps = {
  docId: string;
  options?: Omit<
    UseQueryOptions<PaginatedResults<NoteDetails>, BaseError>,
    'queryKey' | 'queryFn'
  >;
} & ListNotesPayloadParams;

export const useDocNotes = ({
  createdByMe,
  docId,
  options,
  q,
  sortBy,
  withAnnotations,
}: UseDocNotesProps) => {
  const parsedHostname = useParsedHostname();

  return useQuery<PaginatedResults<NoteDetails>, BaseError>(
    docNotesQueryKeys.listWithParams(docId, {
      createdByMe,
      q,
      sortBy,
      withAnnotations,
    }),
    async () => {
      const { data } = await listNotes({
        createdByMe,
        noteObjectId: docId,
        noteObjectType: 'document',
        // TODO: FIX iT!
        pageSize: 1000,

        q,

        sortBy,

        tenant: parsedHostname.tenant,
        withAnnotations,
      });

      return data;
    },
    {
      ...QUERY_OPTIONS,
      ...options,
      retry: 1,
    }
  );
};
