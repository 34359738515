import React from 'react';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material';

import { theme as defaultTheme } from '../../theme/theme';
import PDFViewer, { PDFViewerProps } from '../PDFViewer';

export type PDFReaderProps = {
  theme?: Theme;
} & PDFViewerProps<string>;

export const PDFReader = ({
  theme = defaultTheme,
  ...rest
}: PDFReaderProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <PDFViewer {...rest} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

PDFReader.displayName = 'PDFReader';

export default PDFReader;
