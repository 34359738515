import { AreaSelectionRect } from '../components/AreaSelection';
import { RectBase } from '../types';

export const isMinSelectionSize = (
  { x1, x2, y1, y2 }: AreaSelectionRect,
  minSelectionSize: number
): boolean => {
  const selectionWidth = Math.abs(x2 - x1);
  const selectionHeight = Math.abs(y2 - y1);

  return (
    selectionWidth < minSelectionSize && selectionHeight < minSelectionSize
  );
};

export const areaSelectionToRect = ({
  x1,
  x2,
  y1,
  y2,
}: AreaSelectionRect): RectBase => {
  return {
    x1: Math.min(x1, x2),
    y1: Math.min(y1, y2),
    x2: Math.max(x1, x2),
    y2: Math.max(y1, y2),
    width: Math.abs(x2 - x1),
    height: Math.abs(y2 - y1),
  };
};
