import React from 'react';

import { Card, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useInView } from 'react-hook-inview';

import { useDocDetails } from 'containers/Docs/hooks/useDocDetails';
import { useTagDetails } from 'containers/Tags/useTagDetails';

import { NoteItem, NoteItemProps } from '../NoteItem';

import { useNoteOriginalUrl } from './hooks/useNoteOriginalUrl';
import { NoteFooter } from './NoteFooter';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  noteWrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
}));

export type NoteCardProps = NoteItemProps;

export const NoteCard = ({ note, ...noteItemProps }: NoteCardProps) => {
  const classes = useStyles();
  const { objectId, objectType } = note;
  const [ref, isVisible] = useInView({ threshold: 0 });
  const { data: docData, isLoading: isDocDataLoading } = useDocDetails({
    docId: String(objectId),
    options: {
      enabled: !!(objectType === 'document' && objectId && isVisible),
    },
    propertyName: 'id',
  });
  const { data: tagData } = useTagDetails(
    objectType === 'tag' ? Number(objectId) : -1
  );

  const { originalUrl } = useNoteOriginalUrl({
    noteContent: note.content,
  });

  return (
    <Card data-testid="NoteCard" ref={ref}>
      <div className={classes.noteWrapper}>
        <NoteItem
          docId={docData?.document.id}
          note={note}
          tagId={tagData?.id}
          {...noteItemProps}
        />
      </div>

      <NoteFooter
        docData={docData}
        isDocDataLoading={isDocDataLoading}
        originalUrl={originalUrl}
        tagData={tagData}
      />
    </Card>
  );
};
