import { DisplayConfiguration } from 'api/tenantSettingsApi/tenantSettingsApi.types';

const LOCAL_STORAGE_DISPLAY_CONFIGURATION_KEY = 'ZA_DC';

export const saveDisplayConfigurationToLocalStorage = (
  displayConfiguration: DisplayConfiguration | null
) => {
  localStorage.setItem(
    LOCAL_STORAGE_DISPLAY_CONFIGURATION_KEY,
    JSON.stringify(displayConfiguration)
  );
};

export const loadDisplayConfigurationFromLocalStorage =
  (): DisplayConfiguration | null => {
    const displayConfiguration = localStorage.getItem(
      LOCAL_STORAGE_DISPLAY_CONFIGURATION_KEY
    );

    if (!displayConfiguration) {
      return null;
    }

    try {
      return JSON.parse(displayConfiguration) as DisplayConfiguration;
    } catch (e) {
      return null;
    }
  };
