export enum SearchUrlParamsEnum {
  Countries = 'countries',
  Date = 'd',
  DateRange = 'dr',
  DocIds = 'doc_ids',
  DocSources = 'doc_sources',
  DocTypes = 'doc_types',
  FederatedSearch = 'fs',
  IndexCluster = 'ic',
  Organizations = 'orgs',
  Page = 'page',
  Query = 'q',
  QueryEncoderService = 'qes',
  Rerank = 'rerank',
  RerankTopN = 'rerank_top_n',
  Reranker = 'reranker',
  RerankerService = 'rs',
  RetrievalMethod = 'retrieval_method',
  RetrievalUnit = 'retrieval_unit',
  SimilarTo = 'similar_to',
  SortBy = 'sort_by',
  Tag = 't',
  Tenant = 'tenant',
  Term = 'term',
  Visibility = 'visibility',
  WithCode = 'with_code',
}
