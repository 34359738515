import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { FiniteStates } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { TagDetails, TagOrderUpdatePayload } from 'api/tagsApi/tagsApi.types';

import { TagsState, TagsStateData } from '../tags.types';
import { TagsSortByEnum } from '../TagsSort/TagsSortBy.enum';
import { TagsSortOrderEnum } from '../TagsSort/TagsSortOrder.enum';

import {
  addFollowingTagsThunk,
  getFollowingTagsThunk,
  removeFollowingTagsThunk,
  updateFollowingTagOrderThunk,
  updateFollowingTagSettingsThunk,
  updateFollowingTagsLastSeenThunk,
} from './followingTagsSlice.utils';

const SLICE_NAME = 'followingTags';

export const initialState: TagsState = {
  data: {
    items: [],
    sortSettings: {
      sortBy: TagsSortByEnum.PriorityOrder,
      sortOrder: TagsSortOrderEnum.Desc,
    },
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getFollowingTags = createAsyncThunk(
  `${SLICE_NAME}/get`,
  getFollowingTagsThunk
);

export const addFollowingTag = createAsyncThunk(
  `${SLICE_NAME}/add`,
  addFollowingTagsThunk
);

export const removeFollowingTag = createAsyncThunk(
  `${SLICE_NAME}/remove`,
  removeFollowingTagsThunk
);

export const updateFollowingTagLastSeen = createAsyncThunk<
  TagDetails,
  { tagId: number; tenant: string },
  { state: RootState }
>('followingTags/updateLastSeen', updateFollowingTagsLastSeenThunk);

export const updateFollowingTagOrder = createAsyncThunk<
  TagDetails[],
  TagOrderUpdatePayload,
  { state: RootState }
>(`${SLICE_NAME}/updateTagOrder`, updateFollowingTagOrderThunk, {
  condition: ({ newIndex, oldIndex }: TagOrderUpdatePayload) => {
    if (oldIndex === newIndex) {
      return false;
    }
    return undefined;
  },
});

export const updateFollowingTagsSortSettings = createAsyncThunk<
  TagsStateData,
  TagsSortByEnum,
  { state: RootState }
>(`${SLICE_NAME}/updateSortSettings`, updateFollowingTagSettingsThunk);

const followingTags = createSlice({
  extraReducers: (builder) => {
    // Get tags
    builder
      .addCase(getFollowingTags.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getFollowingTags.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error = action.error.message || 'Unexpected error';
      })
      .addCase(getFollowingTags.fulfilled, (state, action) => {
        state.fetchState = FiniteStates.Success;
        state.data = action.payload;
        state.error = null;
      });
    // Add following tag
    builder.addCase(addFollowingTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = [...state.data.items, payload];
      state.error = null;
    });
    // Remove following tag
    builder.addCase(removeFollowingTag.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = state.data.items.filter(({ id }) => id !== payload);
      state.error = null;
    });
    // Update following tag order
    builder.addCase(updateFollowingTagOrder.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.items = payload;
      state.error = null;
    });
    // Update tag sort settings
    builder.addCase(
      updateFollowingTagsSortSettings.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data = payload;
        state.error = null;
      }
    );
    // Update last seen
    builder.addCase(updateFollowingTagLastSeen.fulfilled, (state, action) => {
      const index = state.data.items.findIndex(
        ({ id }) => id === action.payload.id
      );
      state.fetchState = FiniteStates.Success;
      if (index !== -1) {
        state.data.items[index] = action.payload;
      }
      state.error = null;
    });
  },
  initialState,
  name: SLICE_NAME,
  reducers: {},
});

const followingTagsSelector = (state: RootState) => state.tags.following;

export const selectFollowingTags = createSelector(
  followingTagsSelector,
  ({ data }) => data.items
);

export const selectHasFollowingTags = createSelector(
  followingTagsSelector,
  ({ data }) => data.items.length > 0
);

export const selectFollowingTagsWithUpdates = createSelector(
  followingTagsSelector,
  ({ data }) => data.items.filter((tag) => !!tag.newDocuments)
);

export const selectFollowingTagsSortSettings = createSelector(
  followingTagsSelector,
  ({ data }) => data.sortSettings
);

export const selectFollowingTagsLoading = createSelector(
  followingTagsSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export default followingTags.reducer;
