import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { IconButton, ListItemSecondaryAction, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import {
  withPrivateDocsMenu,
  WithPrivateDocsMenuProps,
} from 'containers/PrivateDocs/hocs/withPrivateDocsMenu';

type MyDocumentsNavItemProps = WithPrivateDocsMenuProps<{
  minified?: boolean;
}>;

const MyDocumentsNavItem = ({
  minified,
  onMenuOpen,
}: MyDocumentsNavItemProps) => {
  const onClick = useLoggedInFeature(onMenuOpen);
  const { t } = useTranslation(['pages', 'privateDocs']);

  const { isDocumentUploadsDisabled, isDocumentUploadsHidden } =
    useTenantFeatures();

  if (isDocumentUploadsHidden) {
    return null;
  }

  return (
    <PageDrawerMenuItem
      icon={<LibraryBooksIcon />}
      isDisabled={isDocumentUploadsDisabled}
      minified={minified}
      title={t('pages:myDocuments.navTitle')}
      to="/my-documents"
      tooltip={t('pages:myDocuments.navTitle')}
      exact
    >
      {!minified && (
        <ListItemSecondaryAction>
          <Tooltip title={t<string>('privateDocs:addButton.text')}>
            <span>
              <IconButton
                aria-label="Add private docs"
                data-testid="myDocumentsAddPrivateDocsMenu"
                disabled={isDocumentUploadsDisabled}
                edge="end"
                size="small"
                onClick={onClick}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </PageDrawerMenuItem>
  );
};

MyDocumentsNavItem.displayName = 'MyDocumentsNavItem';

export default withPrivateDocsMenu<MyDocumentsNavItemProps>(MyDocumentsNavItem);
