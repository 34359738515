import React, { useReducer, useEffect } from 'react';
import { debounce } from '@mui/material';

import { useZoomLevel } from '../useZoomLevel';
import { isMobileTouchDevice } from '../../utils';

type ScrollPositionState = {
  clientY: number;
  clientX: number;
  scrollHeight: number;
};

export type ScrollPositionAction =
  | { type: 'setMousePos'; payload: Omit<ScrollPositionState, 'scrollHeight'> }
  | { type: 'setScrollHeight'; payload: number };

const scrollPositionReducer = (
  state: ScrollPositionState,
  action: ScrollPositionAction
): ScrollPositionState => {
  switch (action.type) {
    case 'setMousePos':
      return {
        ...state,
        ...action.payload,
      };
    case 'setScrollHeight':
      return {
        ...state,
        scrollHeight: action.payload,
      };
    default:
      return state;
  }
};

export const useKeepScrollPosition = (
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const { zoomLevel } = useZoomLevel();
  const [{ clientX, clientY, scrollHeight }, dispatch] = useReducer(
    scrollPositionReducer,
    {
      clientX: 0,
      clientY: 0,
      scrollHeight: ref.current?.scrollHeight ?? 1,
    }
  );

  useEffect(() => {
    if (!ref.current || isMobileTouchDevice()) return;
    const el = ref.current;

    const debouncedDispatch = debounce(dispatch, 300);

    function mouseEnterListener() {
      el.addEventListener('mousemove', mouseMoveListener);
    }

    function mouseMoveListener({ clientY, clientX }: MouseEvent) {
      debouncedDispatch({
        type: 'setMousePos',
        payload: { clientY, clientX },
      });
    }

    function mouseLeaveListener() {
      dispatch({
        type: 'setMousePos',
        payload: { clientY: 0, clientX: 0 },
      });
    }

    el.addEventListener('mouseenter', mouseEnterListener);
    el.addEventListener('mouseleave', mouseLeaveListener);

    return () => {
      el.removeEventListener('mouseenter', mouseEnterListener);
      el.removeEventListener('mousemove', mouseMoveListener);
      el.removeEventListener('mouseleave', mouseLeaveListener);
    };
  }, [ref]);

  useEffect(() => {
    if (!ref.current || !isMobileTouchDevice()) return;
    const el = ref.current;

    const debouncedDispatch = debounce(dispatch, 300);

    const getTouchCenter = (touches: TouchList) => {
      const [touch1, touch2] = [touches.item(0), touches.item(1)];

      if (!touch1) return;

      const { clientX: x1, clientY: y1 } = touch1;
      const { clientX: x2, clientY: y2 } = touch2 ?? {
        clientX: x1,
        clientY: y1,
      };

      return {
        clientX: Math.min(x1, x2) + Math.abs(x1 - x2),
        clientY: Math.min(y1, y2) + Math.abs(y1 - y2),
      };
    };

    function touchStartListener({ touches }: TouchEvent) {
      const center = getTouchCenter(touches);

      if (!center) return;

      debouncedDispatch({
        type: 'setMousePos',
        payload: center,
      });

      el.addEventListener('touchmove', touchMoveListener);
    }

    function touchMoveListener({ touches }: TouchEvent) {
      const center = getTouchCenter(touches);

      if (!center) return;

      debouncedDispatch({
        type: 'setMousePos',
        payload: center,
      });
    }

    function touchEndListener(e: TouchEvent) {
      if (e.touches.length === 2) return;

      dispatch({
        type: 'setMousePos',
        payload: { clientY: 0, clientX: 0 },
      });
    }

    el.addEventListener('touchstart', touchStartListener);
    el.addEventListener('touchend', touchEndListener);

    return () => {
      el.removeEventListener('touchstart', touchStartListener);
      el.removeEventListener('touchmove', touchMoveListener);
      el.removeEventListener('touchend', touchEndListener);
    };
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;

    dispatch({
      type: 'setScrollHeight',
      payload: el.scrollHeight,
    });
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const newScrollHeight = el.scrollHeight;
    const scaleFactor = newScrollHeight / scrollHeight;

    dispatch({
      type: 'setScrollHeight',
      payload: newScrollHeight,
    });

    /**
     * Do nothing, when scaleFactor:
     * - is NaN
     * - is not Finite
     * - precision is not enough, e.g. 1.0001 is false, 1.00001 is true
     */
    if (
      isNaN(scaleFactor) ||
      !isFinite(scaleFactor) ||
      parseFloat(scaleFactor.toPrecision(5)) === 1
    ) {
      return;
    }

    const { top, left } = el.getBoundingClientRect();
    const y = clientY ? clientY - top : clientY;
    const x = clientX ? clientX - left : clientX;
    const newTop = (el.scrollTop + y) * scaleFactor - y;
    const newLeft = (el.scrollLeft + x) * scaleFactor - x;

    el.scrollTo({
      top: newTop,
      left: newLeft,
    });
  }, [ref, zoomLevel, clientX, clientY, scrollHeight]);
};
