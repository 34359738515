import React, { FC } from 'react';

import NoteIcon from '@mui/icons-material/Note';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useTranslation } from 'react-i18next';

import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import NotificationLinkItem from 'containers/Notifications/NotificationLinkItem';
import { NotificationLinkItemExpandable } from 'containers/Notifications/NotificationLinkItemExpandable';

import { useReportsNotifications } from './useReportsNotifications';

interface Props {
  onClose: () => void;
}

export const ReportsNotifications: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { onClick, reportsNotifications } = useReportsNotifications(onClose);

  return (
    <NotificationLinkItemExpandable
      chipLabel={reportsNotifications.length}
      icon={SummarizeIcon}
      renderItem={(item, index) => (
        <NotificationLinkItem
          chipLabel={item.notifications}
          eventName={TrackEventName.ReportNotificationClicked}
          eventProps={{ tagId: item.id }}
          icon={NoteIcon}
          key={item.id}
          text={item.label}
          to={item.to}
          nested
          onClick={onClick(index)}
        />
      )}
      subItems={reportsNotifications}
      text={t('navBar.reports')}
    />
  );
};
