import { useMemo } from 'react';

import { useTheme } from '@mui/material';
import {
  cyan,
  lightGreen,
  indigo,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@mui/material/colors';

import { PaletteColorOption } from 'common/components/Fields/ColorPaletteField/ColorPalette.interface';

export const useTagsPalette = (): PaletteColorOption[] => {
  const theme = useTheme();
  const palette: PaletteColorOption[] = useMemo(
    () => [
      { color: theme.palette.tagChip, value: '' },
      { color: red[500], value: red[500] },
      { color: purple[300], value: purple[300] },
      { color: teal[400], value: teal[400] },
      { color: cyan[700], value: cyan[700] },
      { color: lightGreen[700], value: lightGreen[700] },
      { color: orange[800], value: orange[800] },
      { color: indigo[300], value: indigo[300] },
      { color: pink[400], value: pink[400] },
    ],
    [theme.palette.tagChip]
  );

  return palette;
};
