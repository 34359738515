import React from 'react';

import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TagSharingPermission } from 'api/tagsApi';

import TagPermissionTooltip from '../TagPermissionTooltip';

export type TagPermissionChipProps = {
  permission: TagSharingPermission;
};

const TagPermissionChip = ({ permission }: TagPermissionChipProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagPermissionTooltip permission={permission}>
      <Chip
        color="secondary"
        label={t<string>(`permissions.${permission}.chipLabel`)}
        size="small"
        variant="outlined"
      />
    </TagPermissionTooltip>
  );
};

export default TagPermissionChip;
