import React from 'react';

import { Alert, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    padding: 0,
  },
  root: {
    fontSize: theme.typography.body2.fontSize,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.25, 1),
  },
}));

type Props = {
  explanation: string;
};

export const RetrievalUnitExplanation = ({ explanation }: Props) => {
  const classes = useStyles();

  return (
    <Alert
      classes={{ message: classes.message }}
      className={classes.root}
      data-testid="retrievalUnitExplanation"
      icon={false}
      severity="info"
    >
      {explanation}
    </Alert>
  );
};
