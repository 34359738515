import { alpha, PaletteColor, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const createIconButtonColorStyles = (color: PaletteColor, theme: Theme) => ({
  '&:hover': {
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
    backgroundColor: alpha(color.main, theme.palette.action.hoverOpacity),
  },
  color: color.main,
});

export const useIconButtonColors = makeStyles((theme: Theme) => ({
  colorInfo: createIconButtonColorStyles(theme.palette.info, theme),
}));
