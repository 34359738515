import { HighlightActionItem, HighlightContentType } from '../../types';

export const useAvailableCustomHighlightActions = <T>(
  highlightType: HighlightContentType,
  actions: HighlightActionItem<T>[] = []
): HighlightActionItem<T>[] => {
  return actions.filter(({ highlightTypes }) =>
    highlightTypes.includes(highlightType)
  );
};
