import { useCallback } from 'react';

const THRESHOLD_GAP = 20;

export const useSelectionVerticalScroll = (el: HTMLElement | null) => {
  return useCallback(
    (topY: number, bottomY: number) => {
      if (!el) return;

      const { top } = el.getBoundingClientRect();
      const bottomThreshold = el.clientHeight + top - THRESHOLD_GAP;
      const topThreshold = top + THRESHOLD_GAP;

      if (bottomY >= bottomThreshold) {
        el.scrollTo({
          top: el.scrollTop + (bottomY - bottomThreshold),
        });
      }

      if (topY <= topThreshold) {
        el.scrollTo({
          top: el.scrollTop - (topThreshold - topY),
        });
      }
    },
    [el]
  );
};
