import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Popover } from '@mui/material';
import { AppDispatch } from 'app/state/store';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { PageDrawerMenuAction } from 'common/components/PageDrawer/PageDrawerMenuAction';
import {
  selectOwnTagsSortSettings,
  updateOwnTagsSortSettings,
} from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { TagForm } from 'containers/Tags/TagForm/TagForm';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';
import { TagsSortSelect } from 'containers/Tags/TagsSort/TagsSortSelect';
import { useTagsSortOptions } from 'containers/Tags/TagsSort/useTagsSortOptions';

export const OwnTagsListActions = () => {
  const { t } = useTranslation('tags');
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const ownSortSettings = useSelector(selectOwnTagsSortSettings);
  const sortOptions = useTagsSortOptions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (tagItem: TagDetails) => {
    handlePopoverClose();
    history.push(`/tags/${tagItem.id}`);
  };

  const handleSortChange = async (sortBy: TagsSortByEnum) => {
    await dispatch(updateOwnTagsSortSettings(sortBy));
  };

  return (
    <>
      <TagsSortSelect
        initialValue={ownSortSettings.sortBy}
        options={sortOptions}
        onChange={handleSortChange}
      />

      <PageDrawerMenuAction
        aria-label={t('buttons.addNew')}
        tooltipTitle={t('buttons.addNew')}
        onClick={handlePopoverOpen}
      >
        <AddIcon />
      </PageDrawerMenuAction>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={popoverOpen}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handlePopoverClose}
      >
        <TagForm onCancel={handlePopoverClose} onSubmit={handleSubmit} />
      </Popover>
    </>
  );
};
