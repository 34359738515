import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles<Theme, { numberOfPages: number }>(
  (_: Theme) =>
    createStyles({
      select: {
        paddingTop: 6,
        textAlign: 'right',
        paddingRight: ({ numberOfPages }) => {
          if (numberOfPages < 100) return '34px !important';
          if (numberOfPages < 1000) return '44px !important';

          return '54px !important';
        },
      },
      numberOfPages: {
        position: 'absolute',
        right: 8,
        zIndex: -1,
      },
    })
);
