import { useReducer } from 'react';
import {
  DEFAULT_HIGHLIGHT_COLOR,
  MAX_ZOOM,
  MIN_ZOOM,
} from '../../const/defaults.const';
import { HighlightMode, PDFPageDetails, PDFViewerState } from '../../types';

export type PDFViewerAction =
  | { type: 'setMetadata'; payload: Object }
  | { type: 'setActivePage'; payload: number }
  | { type: 'setDownloadUri'; payload: string }
  | { type: 'setPages'; payload: PDFPageDetails[] }
  | { type: 'setNumberOfPages'; payload: number }
  | { type: 'setHighlightMode'; payload: HighlightMode }
  | { type: 'setHighlightColor'; payload: string }
  | { type: 'setHighlightVisibility'; payload: boolean }
  | { type: 'setMaxZoomLevel'; payload: number }
  | { type: 'setMinZoomLevel'; payload: number }
  | { type: 'setZoomLevel'; payload: number }
  | { type: 'setFullscreen'; payload: boolean };

const PDFViewerReducer = (
  state: PDFViewerState,
  action: PDFViewerAction
): PDFViewerState => {
  switch (action.type) {
    case 'setActivePage':
      let activePage = action.payload || 1;

      if (activePage > state.numberOfPages) {
        activePage = state.numberOfPages;
      }

      return {
        ...state,
        activePage,
      };
    case 'setNumberOfPages':
      return {
        ...state,
        numberOfPages: action.payload,
      };
    case 'setPages':
      const pages = action.payload;

      return {
        ...state,
        pages,
        numberOfPages: pages.length,
      };
    case 'setDownloadUri':
      return {
        ...state,
        downloadUri: action.payload,
      };
    case 'setMetadata':
      const metadata = action.payload;
      const title = metadata['Title'];

      return {
        ...state,
        metadata: {
          title,
          author: metadata['Author'],
          creator: metadata['Creator'],
          subject: metadata['Subject'],
          producer: metadata['Producer'],
          fileName: title ? `${title.split(' ').join('-')}.pdf` : '',
        },
      };
    case 'setZoomLevel':
      const { maxZoomLevel, minZoomLevel } = state;
      let newZoom =
        Number.parseFloat(action.payload.toFixed(2)) || state.zoomLevel;

      if (newZoom > maxZoomLevel) {
        newZoom = maxZoomLevel;
      } else if (newZoom < minZoomLevel) {
        newZoom = minZoomLevel;
      }

      return {
        ...state,
        zoomLevel: newZoom,
      };
    case 'setMaxZoomLevel':
      return {
        ...state,
        maxZoomLevel: action.payload,
      };
    case 'setMinZoomLevel':
      return {
        ...state,
        minZoomLevel: action.payload,
      };
    case 'setHighlightMode':
      return {
        ...state,
        highlightMode: action.payload,
      };
    case 'setHighlightVisibility':
      return {
        ...state,
        highlightsVisible: action.payload,
      };
    case 'setHighlightColor':
      return {
        ...state,
        highlightColor: action.payload,
      };
    case 'setFullscreen':
      return {
        ...state,
        fullscreen: action.payload,
      };
    default:
      return state;
  }
};

export const initialPDFViewerState: PDFViewerState = {
  numberOfPages: 1,
  activePage: 1,
  maxZoomLevel: MAX_ZOOM,
  minZoomLevel: MIN_ZOOM,
  zoomLevel: 1,
  pages: [],
  downloadUri: '',
  highlightMode: 'text',
  highlightsVisible: true,
  highlightColor: DEFAULT_HIGHLIGHT_COLOR,
  metadata: {
    title: '',
    fileName: '',
  },
  fullscreen: undefined,
};

export const usePDFViewer = (initState: Partial<PDFViewerState> = {}) => {
  const [state, dispatch] = useReducer(PDFViewerReducer, {
    ...initialPDFViewerState,
    ...initState,
  });

  return { state, dispatch };
};
