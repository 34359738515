import React, { FC, useEffect, useState } from 'react';

import ExploreIcon from '@mui/icons-material/Explore';
import { useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';

import { useIsActivePage } from '../../../../common/hooks/useIsActivePage';

interface DiscoverNavItemProps {
  minified?: boolean;
}

export const DiscoverNavItem: FC<DiscoverNavItemProps> = ({ minified }) => {
  const { t } = useTranslation('pages');
  const [path, setPath] = useState<string>('/');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setPath(`/${location.search}`);
    }
  }, [location.pathname, location.search]);

  const theme = useTheme<AppTheme>();

  const { isActive } = useIsActivePage(path as string);

  return (
    <PageDrawerMenuItem
      icon={
        <ExploreIcon
          sx={{
            color: isActive
              ? theme.palette.sideDrawer.icons.highlight
              : theme.palette.sideDrawer.icons.defaultColor,
          }}
        />
      }
      minified={minified}
      title={t('home.navTitle')}
      to={path}
      tooltip={t('home.navTitle')}
      exact
    />
  );
};
