import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

import {
  AuthenticationApiFactory,
  Configuration,
} from '@zarn/vendor/dist/auth';

import { isJWTValid } from '../common/utils/jwt-tokens';

import {
  API_CONFIG,
  AXIOS_RETRY_CONFIG,
  BASE_API_URL,
  BASE_HEADERS,
} from './apiConfig';

export const axiosInstance = axios.create(API_CONFIG);

const AUTH_URL = `${BASE_API_URL}/auth`;
const authenticationApi = AuthenticationApiFactory(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: AUTH_URL }),
  AUTH_URL,
  axios
);

export const tryToGetRefreshedToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh-token');
    const accessToken = localStorage.getItem('token');

    if (isJWTValid(accessToken)) {
      return accessToken;
    }

    if (refreshToken && isJWTValid(refreshToken)) {
      const response = await authenticationApi.refresh({ jwt: refreshToken });
      const { jwt } = response.data;
      return jwt;
    }
  } catch (error) {
    console.error('Error refreshing token', error);
  }
};

export const setAuthHeaders = async (config: AxiosRequestConfig) => {
  const resultConfig = { ...config, headers: { ...config.headers } };

  const jwt = await tryToGetRefreshedToken();

  if (jwt) {
    resultConfig.headers.Authorization = `Bearer ${jwt}`;
  }

  return resultConfig;
};

axiosInstance.interceptors.request.use(setAuthHeaders);

axiosRetry(axiosInstance, AXIOS_RETRY_CONFIG);

export default axiosInstance;
