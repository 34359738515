import { useMemo, useState } from 'react';

import { captureException } from '@sentry/react';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { createUser } from 'api/usersApi';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { SignUpFormValues } from '../SignUpForm.interface';

export const initialValues: SignUpFormValues = {
  affiliation: '',
  captchaToken: '',
  email: '',
  firstName: '',
  lastName: '',
  promoCode: '',
};

export type UseSignUpFormProps = {
  onReset?: () => void;
  onSubmit?: () => void;
};

export const useSignUpForm = ({ onReset }: UseSignUpFormProps) => {
  const { t } = useTranslation('common');
  const { tenant } = useParsedHostname();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        affiliation: Yup.string(),
        captchaToken: Yup.string().required(t('common:errors.required')),
        email: Yup.string().email().required(t('common:errors.required')),
        firstName: Yup.string().required(t('common:errors.required')),
        lastName: Yup.string().required(t('common:errors.required')),
        promoCode: Yup.string(),
      }),
    [t]
  );

  const handleReset = () => {
    onReset?.();
  };

  const handleSubmit = async (
    values: SignUpFormValues,
    { setSubmitting }: FormikHelpers<SignUpFormValues>
  ): Promise<void> => {
    try {
      setSubmitting(true);
      await createUser(values, tenant);
      setSuccess(t('user.signUp.successMessage'));
    } catch (err: any) {
      captureException(err);
      setError(deserializeAxiosError(err).message);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    error,
    initialValues,
    onReset: handleReset,
    onSubmit: handleSubmit,
    success,
    validationSchema,
  };
};
