import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SummaryBotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import { useChatMessages } from '../hooks/useChatMessages';

interface Props extends Omit<ButtonProps, 'onClick'> {
  onClick: (botMessage: SummaryBotTypeMessage) => void;
}

export const SummaryModeBotButton: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation(['common', 'chat']);
  const { buildSummaryMessage } = useChatMessages();

  const handleClick = () => onClick(buildSummaryMessage());

  return (
    <TrackedButton
      color="secondary"
      data-testid="summary-modeButton"
      eventName={TrackEventName.SummarybotStartClicked}
      size="small"
      sx={{ margin: '0.5rem' }}
      variant="contained"
      onClick={handleClick}
      {...props}
    >
      {t(`common:chat.modeButtons.summary`)}
    </TrackedButton>
  );
};
