import { useContext, useEffect, useMemo, useState } from 'react';

import { AxiosResponse } from 'axios';
import { HomePageContext } from 'pages/HomePage/HomePageContextProvider';
import { useSelector } from 'react-redux';

import { SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';
import { isExist } from 'common/utils/assert';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectSearchResultsHits } from 'containers/SearchResults/searchResults.slice';

import { isChatContextWithDocs } from './helpers';

interface UseAllHitsFromUrlProps {
  docsUrl: string[];
}

export const useAllHitsFromUrl = ({ docsUrl }: UseAllHitsFromUrlProps) => {
  const [docsData, setDocsData] = useState<RetrievalUnitData[]>([]);
  const homeContext = useContext(HomePageContext);
  const hits = useSelector(selectSearchResultsHits);

  const ids = useMemo(
    () =>
      docsUrl.map((docUrl) => {
        const id = docUrl.replace('custom://', '');
        return {
          docUrl,
          documentFromContext:
            homeContext?.chatResponse?.context &&
            isChatContextWithDocs(homeContext.chatResponse.context)
              ? homeContext.chatResponse.context.fromDocs?.find(
                  ({ document }) => document.id === id
                )
              : null,
          documentFromPage: hits.find(({ document }) => document.id === id),
          isCustom: docUrl.startsWith('custom://'),
        };
      }),
    [docsUrl, hits, homeContext?.chatResponse?.context]
  );

  const getDocumentData = async (
    documentHitUrl: string
  ): Promise<AxiosResponse<RetrievalUnitData | null>> => {
    // TODO: Chang it to use API from vendors
    const response = await axiosInstance.get(`${SERVICE_URL}${documentHitUrl}`);
    const { data, ...rest } = response;
    const hit = data.hits[0];

    return {
      ...rest,
      data: hit ? deserializeSearchHit(hit) : null,
    };
  };

  useEffect(() => {
    const fetchHits = async () => {
      const loadedHits = await Promise.all(
        ids.map(
          ({ documentFromContext, documentFromPage, docUrl, isCustom }) => {
            if (documentFromPage) {
              return { data: documentFromPage };
            }
            if (documentFromContext) {
              return { data: documentFromContext };
            }
            if (isCustom) {
              return null;
            }
            return getDocumentData(docUrl);
          }
        )
      );

      const data = loadedHits
        .filter((hit) => isExist(hit) && isExist(hit.data))
        .map((hit) => hit!.data)
        .filter(isExist);

      setDocsData(data);
    };

    void fetchHits();
  }, [docsUrl, ids]);

  return { docsData };
};
