import { useCallback, useContext, useEffect } from 'react';
import { debounce } from '@mui/material';

import { HighlightingContext } from '../Highlighting/Highlighting.context';
import { useHighlightColor, useHighlightMode, useZoomLevel } from '../../hooks';
import { Highlight } from '../../types';
import {
  getClientRects,
  getHighlightAbsolutePosition,
  getPagesFromRange,
  getTouchEventItem,
} from '../../utils';

const TextHighlighting = () => {
  const {
    state: { status },
    onUpdateHighlight,
    onEndHighlight,
    onClearHighlight,
  } = useContext(HighlightingContext);
  const { zoomLevel } = useZoomLevel();
  const { highlightColor } = useHighlightColor();
  const { highlightMode } = useHighlightMode();

  const handleHighlight = useCallback(() => {
    const selection = document.getSelection();

    if (!selection || highlightMode !== 'text') {
      return;
    }

    if (selection.rangeCount === 0 || selection.isCollapsed) {
      onClearHighlight();
      return;
    }

    const range = selection.getRangeAt(0);

    const selectedPages = getPagesFromRange(range);

    const highlightContent = selection.toString();

    const rects = getClientRects(range, selectedPages, zoomLevel);

    if (rects.length === 0) {
      onClearHighlight();

      return;
    }

    const position = getHighlightAbsolutePosition({
      pageHeight: selectedPages[0].node.clientHeight / zoomLevel,
      pageNumber: selectedPages[0].pageNumber,
      y1: rects[0].y1,
    });

    const payload: Highlight = {
      id: -1,
      position,
      color: highlightColor,
      actions: ['addNote', 'delete', 'update'],
      content: {
        type: 'text',
        body: highlightContent,
      },
      rects,
    };

    onUpdateHighlight(payload);
  }, [
    zoomLevel,
    highlightColor,
    highlightMode,
    onClearHighlight,
    onUpdateHighlight,
  ]);

  useEffect(() => {
    if (highlightMode !== 'text') return;

    const debouncedTextSelection = debounce(handleHighlight, 100);

    const debouncedEndHighlight = debounce(onEndHighlight, 200);

    function touchStartListener() {
      debouncedEndHighlight.clear();

      document.addEventListener('touchend', touchEndListener);
      document.addEventListener('selectionchange', debouncedTextSelection);
    }

    function touchEndListener(e: TouchEvent) {
      const touchItem = getTouchEventItem(e);

      if (!touchItem) return;

      debouncedEndHighlight({ x: touchItem.clientX, y: touchItem.clientY });
    }

    function mouseDownListener() {
      debouncedEndHighlight.clear();

      document.addEventListener('mouseup', mouseUpListener);

      document.addEventListener('dblclick', dblClickListener);

      document.addEventListener('selectionchange', debouncedTextSelection);
    }

    function mouseUpListener({ clientX, clientY }: MouseEvent) {
      debouncedEndHighlight({ x: clientX, y: clientY });
    }

    function dblClickListener({ clientX, clientY }: MouseEvent) {
      debouncedTextSelection.clear();

      handleHighlight();

      debouncedEndHighlight({ x: clientX, y: clientY });
    }

    document.addEventListener('touchstart', touchStartListener);
    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      debouncedTextSelection.clear();
      debouncedEndHighlight.clear();
      document.removeEventListener('selectionchange', debouncedTextSelection);
      document.removeEventListener('touchstart', touchStartListener);
      document.removeEventListener('touchend', touchEndListener);
      document.removeEventListener('selectionchange', debouncedTextSelection);
      document.removeEventListener('mousedown', mouseDownListener);
      document.removeEventListener('mouseup', mouseUpListener);
      document.removeEventListener('dblclick', dblClickListener);
    };
  }, [handleHighlight, onEndHighlight, highlightMode]);

  useEffect(() => {
    if (status === 'idle' || highlightMode !== 'text') return;

    function keyDownListener(e: KeyboardEvent) {
      if (e.code !== 'Escape') return;

      const selection = document.getSelection();

      if (selection?.rangeCount && selection?.removeAllRanges) {
        selection.removeAllRanges();
      }

      onClearHighlight();
    }

    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('keydown', keyDownListener);
    };
  }, [highlightMode, onClearHighlight, status]);

  return null;
};

export default TextHighlighting;
