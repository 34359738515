import { useCallback, useEffect, useState } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useLocationParams } from 'common/hooks/useLocationParams';

import { Conversation } from '../../Chat.types';

import { useParser } from './parsers/useParser';

export const useChatConversation = <T extends object = {}>(
  predefinedConversation: Conversation<T> | undefined | null = null
) => {
  const [note, setNote] = useState<NoteDetails | { content: string } | null>(
    null
  );
  const [conversation, setConversation] = useState<Conversation<T> | null>(
    predefinedConversation
  );

  const { parser } = useParser<T>(note?.content ?? null);
  const { noteId } = useLocationParams('noteId');

  useEffect(() => {
    (async () => {
      if (note && 'id' in note && noteId !== `${note.id}`) {
        setConversation(predefinedConversation);
        return;
      }

      if (!conversation) {
        const disassemble = await parser?.disassemble();

        if (disassemble) {
          setConversation(disassemble);
          return;
        }

        setConversation(predefinedConversation);
      }
    })();
  }, [
    parser,
    note?.content,
    conversation,
    noteId,
    note,
    predefinedConversation,
  ]);

  const onConversationChange = useCallback(
    async (newConversation: Conversation<T> | null) => {
      setConversation(newConversation);

      if (!newConversation) {
        setNote(null);
        return null;
      }

      const content = (await parser?.assemble(newConversation)) ?? '';
      const newNote: NoteDetails | { content: string } = {
        ...note,
        content,
      };
      setNote(newNote);
      return newNote;
    },
    [note, parser]
  );

  const onReset = useCallback(() => {
    setNote(null);
    setConversation(null);
  }, []);

  return {
    conversation,
    note,
    onConversationChange,
    onNoteChange: setNote,
    onReset,
  };
};
