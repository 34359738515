import React from 'react';

import { usePDFViewer } from '../../hooks';
import { PDFViewerState } from '../../types';
import { PDFViewerContext } from './PDFViewer.context';

export type PDFViewerProviderProps = {
  children: React.ReactNode;
  initState?: Partial<PDFViewerState>;
};

export const PDFViewerProvider = ({
  children,
  initState = {},
}: PDFViewerProviderProps) => {
  const { state, dispatch } = usePDFViewer(initState);

  return (
    <PDFViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </PDFViewerContext.Provider>
  );
};
