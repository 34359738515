import { useMemo } from 'react';

import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';
import {
  ShareOrgOptionValue,
  ShareUserOptionValue,
} from 'common/components/Share/ShareSettings/ShareSelection/ShareSelection.interface';
import { OrgRole } from 'containers/Auth/Auth.interface';
import {
  SharingOrgPolicy,
  SharingPolicies,
  SharingUserPolicy,
} from 'containers/Sharing/Sharing.types';

import { TagShareFormValues } from '../TagShareSettings.interface';

export type UseTagShareSettingsInitialValuesProps = {
  organization: OrgRole | null;
  settings?: SharingPolicies;
  teamMembers: OrganizationMemberData[];
};

export const useTagShareSettingsInitialValues = ({
  organization,
  settings,
  teamMembers,
}: UseTagShareSettingsInitialValuesProps): TagShareFormValues => {
  const values = useMemo<TagShareFormValues>(() => {
    let orgSelection: ShareOrgOptionValue[] = [];
    let userSelection: ShareUserOptionValue[] = [];

    orgSelection = (settings?.orgs ?? []).reduce(
      (acc: ShareOrgOptionValue[], { id, permission }: SharingOrgPolicy) => {
        return organization
          ? [
              ...acc,
              {
                id,
                name: organization.accountName,
                notify: false,
                permission,
                type: 'organization',
              } as ShareOrgOptionValue,
            ]
          : acc;
      },
      [] as ShareOrgOptionValue[]
    );

    userSelection = (settings?.users ?? []).reduce(
      (acc: ShareUserOptionValue[], { id, permission }: SharingUserPolicy) => {
        const userOption = teamMembers.find(({ uuid }) => uuid === id);

        return userOption
          ? [
              ...acc,
              {
                email: userOption.email,
                id: userOption.uuid,
                initials: userOption.initials,
                name: `${userOption.firstName} ${userOption.lastName}`,
                notify: false,
                permission,
                type: 'user',
              } as ShareUserOptionValue,
            ]
          : acc;
      },
      [] as ShareUserOptionValue[]
    );

    return {
      isPublicTag: settings?.public ? true : false,
      selection: [...orgSelection, ...userSelection],
    };
  }, [settings, teamMembers, organization]);

  return values;
};
