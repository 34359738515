import React, { FC } from 'react';

import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ErrorPageContentProps {
  description: React.ReactNode;
  title: string;
}

export const ErrorPageContent: FC<ErrorPageContentProps> = ({
  description,
  title,
}) => {
  const { t } = useTranslation(['pages', 'common']);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={3}
      my={5}
    >
      <Typography variant="h1">{title}</Typography>

      <Typography>{description}</Typography>

      <Box mt={3}>
        <Button color="primary" component="a" href="/" startIcon={<HomeIcon />}>
          {t('common:navBar.backToHome')}
        </Button>
      </Box>
    </Box>
  );
};
