import React from 'react';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    },
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    margin: theme.spacing(0.5, 0, 0.5),
  },
}));

export type DocMetadataProps = {
  cardStyle?: React.CSSProperties;
  children: React.ReactNode;
};

const DocMetadata = ({ cardStyle, children }: DocMetadataProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={cardStyle}>
      {children}
    </div>
  );
};

export default DocMetadata;
