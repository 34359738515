/* eslint-disable camelcase */
import { ParsedEntity, Entity } from '@zarn/vendor/dist/search';

import {
  QueryAnalysis,
  EntityData,
} from 'containers/QueryEntities/QueryEntities.interface';

import { QueryEntity } from './QueryEntities.interface';

export const deserializeEntity = (entity: Entity): EntityData => ({
  entityClass: entity.entity_class,
  id: entity.uid,
  name: entity.name,
  nodeType: entity.node_type,
  type: entity.entity_type,
});

export const mapParsedEntity = ({
  end_offset,
  entity,
  start_offset,
}: ParsedEntity): QueryEntity | null => {
  return {
    data: deserializeEntity(entity),
    offset: { end: end_offset ?? -1, start: start_offset ?? -1 },
  };
};

export const mapParsedQueryResponse = (
  entities: ParsedEntity[]
): QueryEntity[] => {
  return entities
    .map(mapParsedEntity)
    .filter((value) => value) as QueryEntity[];
};

export const getQueryAnalysis = (
  query: string = '',
  entities: QueryEntity[]
): QueryAnalysis => {
  if (query.trim().length === 0) return [];
  if (entities.length === 0) return [query];

  const queryLength = query.length;
  const textArray = entities.reduce((acc, cur, index, src) => {
    const {
      data: { entityClass, id, nodeType },
      offset: { end, start },
    } = cur;
    const first = index === 0;
    const last = index === src.length - 1;

    if (start !== 0 && first) {
      acc.push(query.slice(0, start));
    } else if (index !== 0) {
      acc.push(query.slice(src[index - 1].offset.end, start));
    }

    acc.push({
      definition: entityClass,
      nodeType,
      ontologyId: id,
      text: query.slice(start, end),
    });

    if (last && end < queryLength) {
      acc.push(query.slice(end));
    }

    return acc;
  }, [] as QueryAnalysis);

  return textArray;
};
