import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const autocompleteOverrides: ComponentsOverrides<Theme>['MuiAutocomplete'] =
  {
    endAdornment: ({ theme }) => ({
      '& svg': {
        fontSize: theme.typography.pxToRem(20),
      },
    }),
  };
