import type { HighlightRect, SelectedPage } from '../types';

const samePage = (pNumber1: number, pNumber2: number): boolean => {
  return pNumber1 === pNumber2;
};

const sortRects = (a: HighlightRect, b: HighlightRect): number => {
  const top = (a.pageNumber || 0) * a.y1 - (b.pageNumber || 0) * b.y1;

  if (top === 0) {
    return a.x1 - b.x1;
  }

  return top;
};

const mergeSameLine = (a: HighlightRect, b: HighlightRect): HighlightRect => {
  const [x1, x2, y1, y2] = [
    Math.min(a.x1, b.x1),
    Math.max(a.x2, b.x2),
    Math.min(a.y1, b.y1),
    Math.max(a.y2, b.y2),
  ];

  return {
    ...a,
    x1,
    y1,
    x2,
    y2,
    height: y2 - y1,
    width: x2 - x1,
  };
};

const isOverlap = (a: HighlightRect, b: HighlightRect): boolean => {
  if (!samePage(a.pageNumber, b.pageNumber)) return false;

  // the line cannot have positive overlap
  if (a.x1 === a.x2 || a.y1 === a.y2 || b.x1 === b.x2 || b.y1 === b.y2)
    return false;

  // If one rectangle is on left side of other
  if (a.x1 >= b.x2 || b.x1 >= a.x2) return false;

  // If one rectangle is above other
  if (a.y1 >= b.y2 || b.y1 >= a.y2) return false;

  return true;
};

const isNextTo = (a: HighlightRect, b: HighlightRect): boolean => {
  const symbolWidth = (a.height + b.height) / 4;
  return Math.max(a.x1, b.x1) - Math.min(a.x2, b.x2) < symbolWidth;
};

const isSameLine = (a: HighlightRect, b: HighlightRect): boolean => {
  if (!samePage(a.pageNumber, b.pageNumber)) return false;
  const center = b.y1 + b.height / 2;
  return center < a.y2 && center > a.y1 && (isOverlap(a, b) || isNextTo(a, b));
};

const isInside = (a: HighlightRect, b: HighlightRect) => {
  if (!samePage(a.pageNumber, b.pageNumber)) return false;

  return (
    a.y1 > b.y1 &&
    a.x1 > b.x1 &&
    a.y1 + a.height < b.y1 + b.height &&
    a.x1 + a.width < b.x1 + b.width
  );
};

function optimizeClientRects(rects: HighlightRect[]): HighlightRect[] {
  let optimized: HighlightRect[] = [];
  let toDelete: Set<number> = new Set();
  let i = 0;

  while (i < rects.length) {
    let rect = { ...rects[i] };

    i += 1;

    for (let j = 0; j < optimized.length; j++) {
      const optRect = { ...optimized[j] };

      if (isInside(rect, optRect)) {
        toDelete.add(j);
        continue;
      }

      if (isSameLine(rect, optRect)) {
        rect = { ...mergeSameLine(rect, optRect) };
        toDelete.add(j);
        continue;
      }
    }

    optimized.push(rect);
  }

  const filtered = optimized.filter((_, i) => !toDelete.has(i));

  return filtered.sort(sortRects);
}

const isClientRectInsidePageRect = (clientRect: DOMRect, pageRect: DOMRect) => {
  if (clientRect.top < pageRect.top) {
    return false;
  }
  if (clientRect.bottom > pageRect.bottom) {
    return false;
  }
  if (clientRect.right > pageRect.right) {
    return false;
  }
  if (clientRect.left < pageRect.left) {
    return false;
  }

  return true;
};

export const getClientRects = (
  range: Range,
  pages: SelectedPage[],
  zoom: number
): HighlightRect[] => {
  const clientRects = Array.from(range.getClientRects());

  const rects: HighlightRect[] = [];

  for (const clientRect of clientRects) {
    for (const page of pages) {
      const pageRect = page.node.getBoundingClientRect();

      if (
        isClientRectInsidePageRect(clientRect, pageRect) &&
        clientRect.top >= 0 &&
        clientRect.bottom >= 0 &&
        clientRect.width > 0 &&
        clientRect.height > 0 &&
        clientRect.width < pageRect.width &&
        clientRect.height < pageRect.height
      ) {
        const width = clientRect.width / zoom;
        const height = clientRect.height / zoom;
        const y1 = (clientRect.top + page.node.scrollTop - pageRect.top) / zoom;
        const x1 =
          (clientRect.left + page.node.scrollLeft - pageRect.left) / zoom;

        const highlightedRect = {
          y1,
          x1,
          y2: y1 + height,
          x2: x1 + width,
          width: width,
          height: height,
          pageNumber: page.pageNumber,
        };

        rects.push(highlightedRect);
      }
    }
  }

  return optimizeClientRects(rects);
};
