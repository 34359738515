import { createContext } from 'react';

export interface PageContextValue {
  pageDrawerMinified: boolean;
  pageDrawerMobileOpen: boolean;
  setPageDrawerMinified: (state: boolean) => void;
  setPageDrawerMobileOpen: (state: boolean) => void;
}

export const pageContextInitialValue: PageContextValue = {
  pageDrawerMinified: false,
  pageDrawerMobileOpen: false,
  setPageDrawerMinified: () => {},
  setPageDrawerMobileOpen: () => {},
};

export const PageContext = createContext<PageContextValue>(
  pageContextInitialValue
);
