import React, { FC } from 'react';

import { Button, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OnboardingWizardImg from 'common/components/OnboardingWizard/OnboardingWizardImg/OnboardingWizardImg';
import welcomeImg from 'containers/OnboardingWizard/images/welcome_img.jpg';

export interface FirstStepProps {
  onNext: () => void;
}

export const FirstStep: FC<FirstStepProps> = ({ onNext }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);

  return (
    <Grid
      aria-label={t('onboardingWizard:firstStep.label')}
      spacing={3}
      container
    >
      <Grid lg={6} md={5} sm={12} item>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom>
            {t('onboardingWizard:firstStep.title')}
          </Typography>
        </Box>
        <Typography>{t('onboardingWizard:firstStep.subTitle')}</Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button color="secondary" variant="contained" onClick={onNext}>
            {t('common:buttons.start')}
          </Button>
        </Box>
      </Grid>
      <Grid lg={6} md={7} sm={12} item>
        <OnboardingWizardImg
          alt={t('onboardingWizard:firstStep.imageAlt')}
          src={welcomeImg}
        />
      </Grid>
    </Grid>
  );
};
