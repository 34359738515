import React from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import FilterPopover from 'common/components/FilterPopover';
import { useNotesFilterVisibilityOptions } from 'containers/NotesFilter/hooks/useNotesFilterVisibilityOptions';
import { NotesFilterVisibility } from 'containers/NotesFilter/NotesFilter.types';

export type NotesVisibilityFilterProps = {
  options?: NotesFilterVisibility[];
};

const NotesVisibilityFilter = ({ options }: NotesVisibilityFilterProps) => {
  const { t } = useTranslation('common');
  const visibilityOptions = useNotesFilterVisibilityOptions(options);
  const popoverTitle = t('pdfViewer.notesFilter.popoverTitle');
  const [field, , fieldHelpers] = useField<NotesFilterVisibility>({
    name: 'visibility',
  });
  const isDefaultValue = field.value === NotesFilterVisibility.All;
  const title: string =
    (!isDefaultValue &&
      visibilityOptions.find(({ value }) => value === field.value)?.label) ||
    t('pdfViewer.notesFilter.title');

  const handleReset = () => {
    fieldHelpers.setValue(NotesFilterVisibility.All);
  };

  const handleVisiblityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    field.onChange(event);
  };

  return (
    <FilterPopover
      handleReset={handleReset}
      isValueFilled={isDefaultValue}
      popoverTitle={popoverTitle}
      title={title}
    >
      <Box pb={2} px={2}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={popoverTitle}
            name={field.name}
            value={field.value}
            onChange={handleVisiblityChange}
          >
            {visibilityOptions.map(({ label, value }) => (
              <FormControlLabel
                control={<Radio />}
                key={value}
                label={label}
                value={value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </FilterPopover>
  );
};

export default NotesVisibilityFilter;
