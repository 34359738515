import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const VD_BAR_WIDTH = 64;
const VD_BAR_WIDTH_M = 48;

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    background: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: `calc(100vw - 100px)`,
    overflow: 'hidden auto',
    width: 320,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      left: VD_BAR_WIDTH_M,
      overflowY: 'auto',
      position: 'absolute',
      top: 0,
    },
  },
  contentMinified: {
    width: 0,
  },
  contentRight: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: 'none',
    [theme.breakpoints.down('md')]: {
      left: 'auto',
      right: VD_BAR_WIDTH_M,
    },
  },
  drawerPaper: {
    background: theme.palette.background.default,
    borderLeft: 'none',
    flexDirection: 'row',
    position: 'relative',
  },
  drawerPaperMobile: {
    overflow: 'visible',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 'auto',
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    height: VD_BAR_WIDTH,
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: VD_BAR_WIDTH_M,
    },
  },
  sidebar: {
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flex: 'none',
    flexDirection: 'column',
    width: VD_BAR_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: VD_BAR_WIDTH_M,
    },
  },
  sidebarRight: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: 'none',
    order: 1,
  },
}));
