import React from 'react';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTranslation } from 'react-i18next';

import TrackedButton from 'common/components/Buttons/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import withInviteUserDialog, {
  WithInviteUserDialogWrappedProps,
} from 'containers/Users/InviteUser/hocs/withInviteUserDialog';

const InviteUserButton = ({
  setDialogOpen,
}: WithInviteUserDialogWrappedProps) => {
  const { t } = useTranslation('common');

  const handleClick = () => setDialogOpen(true);

  return (
    <TrackedButton
      color="secondary"
      eventName={TrackEventName.ShowInviteUserPopoverClicked}
      startIcon={<PersonAddIcon />}
      variant="contained"
      fullWidth
      onClick={handleClick}
    >
      {t('inviteUser.button.text')}
    </TrackedButton>
  );
};

export default withInviteUserDialog(InviteUserButton);
