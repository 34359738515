export enum TokenStoreKey {
  RefreshToken = 'refresh-token',
  Token = 'token',
}

/**
 * Access Token
 */
export const storeAccessToken = (token: string) => {
  localStorage.setItem(TokenStoreKey.Token, token);
};

export const getAccessTokenFromStore = (): string | null => {
  return localStorage.getItem(TokenStoreKey.Token);
};

export const removeAccessTokenFromStore = () => {
  localStorage.removeItem(TokenStoreKey.Token);
};

export const storeOrRemoveAccessToken = (token: string | null) => {
  if (token) {
    storeAccessToken(token);
  } else {
    removeAccessTokenFromStore();
  }
};

/**
 * Refresh Token
 */
export const getRefreshTokenFromStore = (): string | null => {
  return localStorage.getItem(TokenStoreKey.RefreshToken);
};

export const storeRefreshToken = (refreshToken: string) => {
  localStorage.setItem(TokenStoreKey.RefreshToken, refreshToken);
};

export const removeRefreshTokenFromStore = () => {
  localStorage.removeItem(TokenStoreKey.RefreshToken);
};

export const storeOrRemoveRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    storeRefreshToken(refreshToken);
  } else {
    removeRefreshTokenFromStore();
  }
};
