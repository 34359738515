import {
  PDFPageProxy,
  PDFDocumentProxy,
} from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';

import { MAX_CANVAS_SIZE, MAX_ZOOM } from '../const/defaults.const';
import { PDFPageDetails } from '../types';
import { isMobile } from './dom.utils';

const PT_TO_PX_RATIO = 1.333;

export const pointsToPixels = (pt: number): number => {
  return Math.ceil(pt * PT_TO_PX_RATIO);
};

export const getAdjustedInitZoomLevel = ({
  viewportWidth,
  pageWidth,
  zoomLevel,
  minZoomLevel,
}: {
  viewportWidth: number;
  pageWidth: number;
  zoomLevel: number;
  minZoomLevel: number;
}): number => {
  const ratio = (viewportWidth - 40) / pageWidth;

  if (ratio < minZoomLevel) return minZoomLevel;

  if (ratio >= zoomLevel) return zoomLevel;

  return parseFloat(ratio.toFixed(2));
};

export const calcMaxZoomLevel = (
  pageWidth: number,
  pageHeight: number
): number => {
  if (!isMobile()) return MAX_ZOOM;

  const [lo, hi] = [
    Math.min(pageWidth, pageHeight),
    Math.max(pageWidth, pageHeight),
  ];
  const pageRatio = parseFloat((hi / lo).toFixed(2));
  const dpr = window.devicePixelRatio;
  const maxCanvasSide = Math.round(Math.sqrt(MAX_CANVAS_SIZE / pageRatio));
  const maxZoomLevel = Math.floor((maxCanvasSide / (dpr * lo)) * 10) / 10;

  return maxZoomLevel;
};

const deserializePDFPageDetails = ({
  pageNumber,
  _pageIndex: pageIndex,
  view,
  rotate,
}: PDFPageProxy): PDFPageDetails => {
  const [, , pWidth, pHeight] = view;

  return {
    rotate,
    pageNumber,
    pageIndex,
    originalHeight: pointsToPixels(pHeight),
    originalWidth: pointsToPixels(pWidth),
  };
};

export const getPagesFromDocument = async (
  pdfDocProxy: PDFDocumentProxy
): Promise<PDFPageDetails[]> => {
  const pagePromises = [];

  for (let i = 1; i <= pdfDocProxy.numPages; i++) {
    pagePromises.push(pdfDocProxy.getPage(i));
  }

  const pages = await Promise.all(pagePromises);

  return pages.map(deserializePDFPageDetails);
};
