import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { LoginDialogState } from 'containers/Auth/Auth.context';

export type UseLoginDialogReturn = {
  ariaLabel: string;
  dialogId: string;
  dialogTitle: string;
};

export const useLoginDialog = (
  loginDialog: LoginDialogState
): UseLoginDialogReturn => {
  const { t } = useTranslation('common');

  return useMemo<UseLoginDialogReturn>(() => {
    switch (loginDialog) {
      case LoginDialogState.ResetPassword:
        return {
          ariaLabel: 'reset password dialog',
          dialogId: 'resetPasswordDialog',
          dialogTitle: t('user.forgotPassword.dialogTitle'),
        };
      case LoginDialogState.SignUp:
        return {
          ariaLabel: 'sign up dialog',
          dialogId: 'signUpDialog',
          dialogTitle: t('user.signUp.dialogTitle'),
        };
      default:
        return {
          ariaLabel: 'login dialog',
          dialogId: 'loginDialog',
          dialogTitle: t('user.login.dialogTitle'),
        };
    }
  }, [t, loginDialog]);
};
