import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { RootState } from 'app/state/store';
import { omit } from 'lodash';

import {
  createFavoriteDocument,
  listDocumentsUserData,
  tagDocument,
  unFavoriteDocument,
  untagDocument,
} from 'api/documentsApi/documentsApi';
import {
  TagDocumentPayload,
  UntagDocumentPayload,
} from 'api/documentsApi/DocumentsApi.types';
import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { HitType } from 'common/enums';
import { deserializeAxiosError } from 'common/utils/error';
import { addNotesCountToDocuments } from 'containers/SavedNotes/DocumentsNotes/documentsNotes.slice';

import {
  FavoriteDocument,
  GroupedSavedDocuments,
  SavedDocumentsData,
  SavedFavouriteDocuments,
} from './SavedDocuments.interface';
import {
  groupSavedDocuments,
  mapFavouriteDocuments,
  mapFavouriteDocumentsData,
} from './savedDocuments.utils';

export const getSavedDocumentsThunk: AsyncThunkPayloadCreator<
  SavedDocumentsData,
  { documentList: string[]; tenant: string },
  { state: RootState }
> = async ({ documentList, tenant }, { dispatch }) => {
  try {
    if (documentList.length === 0) {
      return {
        favourites: {},
        own: {},
      };
    }

    const { data } = await listDocumentsUserData({
      docIds: documentList,
      pageSize: documentList.length || 1,
      tenant,
    });

    if (data.items.length === 0) {
      return {
        favourites: {},
        own: {},
      };
    }

    dispatch(
      addNotesCountToDocuments(
        data.items.map((item) => ({
          docId: item.docId,
          notesCount: item.notesCount,
        }))
      )
    );

    return {
      favourites: mapFavouriteDocuments(data.items),
      own: groupSavedDocuments(data.items),
    };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const updateDocumentTagsThunk: AsyncThunkPayloadCreator<
  GroupedSavedDocuments,
  {
    docType: HitType;
    organizeDocId: string;
    selectedTags: TagDetailsBase[];
    tenant: string;
  },
  { state: RootState }
> = async ({ docType, organizeDocId, selectedTags, tenant }, { getState }) => {
  try {
    const docTags = getState().savedDocuments.data.own[organizeDocId] ?? [];

    const tagsToAdd = selectedTags.filter((st) =>
      docTags.every(({ tag }) => tag.id !== st.id)
    );

    const tagsToDelete = docTags.filter(
      ({ tag }) => !selectedTags.find((st) => st.id === tag.id)
    );

    if (tagsToAdd.length) {
      const promises = tagsToAdd.map((tag) =>
        tagDocument({
          docId: organizeDocId,
          docType,
          tagId: tag.id,
          tagType: tag.type,
          tenant,
          userOrder: 'first',
        })
      );

      await Promise.all(promises);
    }

    if (tagsToDelete.length) {
      const promises = tagsToDelete.map(({ tag: { id: tagId, type } }) =>
        untagDocument({
          docId: organizeDocId,
          tagId,
          tagType: type,
          tenant,
        })
      );

      await Promise.all(promises);
    }

    return {
      [organizeDocId]: selectedTags.map((tag) => ({
        documentId: organizeDocId,
        documentType: docType,
        tag: {
          id: tag.id,
          name: tag.name,
          permission: tag.permission,
          type: tag.type,
        },
      })),
    };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const addTagToDocumentThunk: AsyncThunkPayloadCreator<
  TagDocumentPayload,
  TagDocumentPayload,
  { state: RootState }
> = async ({ docId, docType, tagId, tagType, tenant, userOrder }) => {
  try {
    await tagDocument({
      docId,
      docType,
      tagId,
      tagType,
      tenant,
      userOrder,
    });

    return {
      docId,
      docType,
      tagId,
      tagType,
      tenant,
      userOrder,
    };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const removeTagFromDocumentThunk: AsyncThunkPayloadCreator<
  { deletedTagId: number; docId: string },
  UntagDocumentPayload,
  { state: RootState }
> = async (payload) => {
  try {
    await untagDocument(payload);

    return { deletedTagId: payload.tagId, docId: payload.docId };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const saveDocumentToFavouritesThunk: AsyncThunkPayloadCreator<
  SavedFavouriteDocuments,
  { docType: HitType; organizeDocId: string; tenant: string },
  { state: RootState }
> = async ({ docType, organizeDocId, tenant }) => {
  try {
    const { data } = await createFavoriteDocument({
      docId: organizeDocId,
      docType,
      tenant,
      userOrder: 'first',
    });

    return mapFavouriteDocumentsData(data);
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const removeDocumentFromFavouritesThunk: AsyncThunkPayloadCreator<
  SavedFavouriteDocuments,
  { documentId: string; tenant: string },
  { state: RootState }
> = async ({ documentId, tenant }, { getState }) => {
  try {
    const { favourites } = getState().savedDocuments.data;
    const savedDoc: FavoriteDocument | undefined = favourites[documentId];

    if (!savedDoc) return favourites;

    await unFavoriteDocument(savedDoc.documentId, tenant);

    return omit(favourites, documentId);
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};
