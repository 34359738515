import { FiltersConfiguration } from '@zarn/vendor/dist/search';

import {
  DynamicSettings,
  SearchFilterDynamicsType,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { isExist } from 'common/utils/assert';

import {
  DynamicCheckboxFilterValue,
  DynamicFilterValue,
} from './DynamicSearchPayload.interface';

const serializeCheckboxFilter = (
  filter: DynamicCheckboxFilterValue
): FiltersConfiguration => ({
  is_in: {
    field_path: filter.fieldName,
    field_values: filter.value,
  },
});

const serializeFilter = (
  filter: DynamicFilterValue
): FiltersConfiguration | null => {
  switch (filter.filterType) {
    case SearchFilterDynamicsType.Checkbox:
      return serializeCheckboxFilter(filter);
    case SearchFilterDynamicsType.DatesRange:
    default:
      return null;
  }
};

export const serializeFilters = (
  payload: DynamicFilterValue[] | undefined
): FiltersConfiguration | undefined => {
  const filters: FiltersConfiguration[] = payload
    ? payload.map(serializeFilter).filter(isExist)
    : [];
  return payload && payload.length ? { and_operator: filters } : undefined;
};

export const deserializeFiltersConfigToUrl = (
  filters: FiltersConfiguration,
  searchFiltersDynamic: DynamicSettings[]
): Array<{ key: string; value: string }> => {
  if (!filters.and_operator) {
    return [];
  }
  const result: Record<string, string[]> = {};

  filters.and_operator
    .filter(({ is_in }) => !!is_in)
    .forEach(({ is_in }) => {
      const { field_path, field_values } = is_in!;
      if (!result[field_path]) {
        result[field_path] = [];
      }
      if (Array.isArray(field_values)) {
        result[field_path].push(
          ...field_values.map((value: any) => `${value}`)
        );
      }
    });

  return Object.keys(result)
    .map((key) => {
      const filter = searchFiltersDynamic.find(
        ({ fieldName }) => fieldName === key
      );
      return filter ? filter : null;
    })
    .filter(isExist)
    .map((filter) => ({
      key: filter.urlParam,
      value: result[filter.fieldName].join(','),
    }));
};
