import { orderBy } from 'lodash';

import { TagsSortingValue } from '@zarn/vendor/dist/saved-results';

import { TagDetails } from 'api/tagsApi/tagsApi.types';

import { TagsSort } from './TagsSort.interface';
import { TagsSortByEnum } from './TagsSortBy.enum';
import { TagsSortOrderEnum } from './TagsSortOrder.enum';

export const deserializeTagsSortSettings = (
  sortSettings: TagsSortingValue | null | undefined,
  defaultSettings: TagsSort
): TagsSort => {
  if (!sortSettings) {
    return defaultSettings;
  }

  return {
    sortBy: sortSettings.sort_by as TagsSortByEnum,
    sortOrder: sortSettings.sort_order as TagsSortOrderEnum,
  };
};

export const serializeTagsSortSettings = (
  sortSettings: TagsSort
): TagsSortingValue => ({
  sort_by: sortSettings.sortBy,
  sort_order: sortSettings.sortOrder,
});

export const sortTagItems = (
  data: TagDetails[],
  { sortBy, sortOrder }: TagsSort
): TagDetails[] => {
  return orderBy(
    data,
    [
      (tagItem) => {
        switch (sortBy) {
          case TagsSortByEnum.Alphabetically:
            return tagItem.name.toLocaleLowerCase();
          case TagsSortByEnum.CreationDate:
            return new Date(tagItem.dateCreated);
          case TagsSortByEnum.ModifiedDate:
            return new Date(tagItem.dateModified ?? tagItem.dateCreated);
          default:
            return tagItem.userOrder;
        }
      },
    ],
    [sortOrder]
  );
};
