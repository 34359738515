import React from 'react';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import {
  Container,
  Divider,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useHiddenForPages } from 'common/hooks/useHiddenForPages';

import IconButtonLink from '../Buttons/IconButtonLink/IconButtonLink';
import TrackedNavLinkButton from '../Buttons/TrackedNavLinkButton/TrackedNavLinkButton';
import { TrackedActionContext } from '../TrackedActions/trackedAction.context';
import { TrackEventName } from '../TrackedActions/withTrackedAction.interface';

import { useStyles } from './styles';

export const Footer = () => {
  const { t } = useTranslation('common');
  const { palette } = useTheme<Theme>();
  const isHidden = useHiddenForPages(['/reset-password', '/create-password']);
  const classes = useStyles();
  const copyright = t('footer.copyright', { year: new Date().getFullYear() });

  if (isHidden) return null;

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Footer' }}>
      <footer className={classes.wrapper}>
        <Divider />

        <Container className={classes.container} maxWidth="xl">
          <Grid alignItems="center" spacing={2} container>
            <Grid className={classes.navigation} item xs>
              <TrackedNavLinkButton
                activeClassName={classes.navLinkActive}
                eventName={TrackEventName.AboutProductClicked}
                size="small"
                to="/product/about"
              >
                {t('footer.nav.about')}
              </TrackedNavLinkButton>

              <TrackedNavLinkButton
                activeClassName={classes.navLinkActive}
                eventName={TrackEventName.ProductFeaturesClicked}
                size="small"
                to="/product/features"
              >
                {t('footer.nav.features')}
              </TrackedNavLinkButton>

              <TrackedNavLinkButton
                activeClassName={classes.navLinkActive}
                eventName={TrackEventName.ProductReleaseNotesClicked}
                size="small"
                to="/product/release-notes"
              >
                {t('footer.nav.releaseNotes')}
              </TrackedNavLinkButton>
            </Grid>

            <Grid justifyContent="flex-end" container item xs>
              <Grid item>
                <IconButtonLink
                  eventName={TrackEventName.TwitterSocialClicked}
                  href={t('socialNetworks.twitter.url')}
                  style={{ color: palette.x }}
                >
                  <XIcon />
                </IconButtonLink>

                <IconButtonLink
                  eventName={TrackEventName.LinkedInSocialClicked}
                  href={t('socialNetworks.linkedin.url')}
                  style={{ color: palette.linkedin }}
                >
                  <LinkedInIcon />
                </IconButtonLink>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <div className={classes.disclaimer}>
          <Container>
            <Tooltip title={window.REACT_APP_VERSION}>
              <Typography color="textSecondary" variant="body2">
                {copyright}
              </Typography>
            </Tooltip>
          </Container>
        </div>
      </footer>
    </TrackedActionContext.Provider>
  );
};
