import React from 'react';

import {
  OutlinedInputProps,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';

import { style } from './style';

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(style);
