import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  moreLess: {
    bottom: 0,
    left: 0,
    marginBottom: -16,
    position: 'absolute',
    textAlign: 'center',
    visibility: 'hidden',
    width: '100%',
  },
  root: {
    '&:hover': {
      opacity: 0.9,
    },
    '&:hover $moreLess': {
      visibility: 'visible',
    },
    position: 'relative',
  },
}));
