import { useMemo } from 'react';

import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useTagsUpdates } from 'containers/Notifications/useTagsUpdates';

export const useTagsNotifications = () => {
  const { isAuthenticated } = useAuth();
  const { followingTags, hasTagsUpdates, tagsUpdatesCount } = useTagsUpdates();

  const tagSubItems = useMemo(
    () =>
      followingTags.map((tag) => ({
        id: tag.id,
        label: tag.name,
        notifications: tag.newDocuments,
        to: `/tags/${tag.id}`,
      })),
    [followingTags]
  );

  return {
    hasTagsUpdates,
    isAuthenticated,
    tagSubItems,
    tagsUpdatesCount,
  };
};
