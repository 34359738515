import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    image: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  })
);

const PDFPageShadowImage = ({ dataUrl }: { dataUrl: string }) => {
  const classes = useStyles();

  if (!dataUrl) return null;

  return <img src={dataUrl} alt="" className={classes.image} />;
};

export default PDFPageShadowImage;
