import { createContext } from 'react';

import { TrackedActionEventProps } from './withTrackedAction.interface';

export type TrackedActionContextValue = {
  actionContext?: string;
} & TrackedActionEventProps;

export const trackedActionContextInitialValue: TrackedActionContextValue = {};

export const TrackedActionContext = createContext<TrackedActionContextValue>(
  trackedActionContextInitialValue
);
