import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import hexToHsl from 'hex-to-hsl';

export const useStyles = makeStyles<
  Theme,
  { color: string; width: string; height: string }
>((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 'auto', 1),
      position: 'relative',
      backgroundColor: 'white',
      width: ({ width }) => width,
      height: ({ height }) => height,
      '& canvas': {
        userSelect: 'none',
      },
      '& .react-pdf__Page__annotations': {
        '& section': {
          zIndex: 2,
        },
      },
      '& .react-pdf__Page__textContent': {
        '& span': {
          lineHeight: '1em',
        },
      },
      '& ::selection': {
        /**
         * Workaround: as CSS prop 'mix-blend-mode' breaks the entire selection
         * need to convert the selection color to HSLA and make it more colorful
         * and little bit darker
         */
        background: ({ color }) => {
          const [h, s] = hexToHsl(color);

          return `hsla(${h}deg ${s}% 45% / 30%)`;
        },
      },
    },
    rootRendered: {
      backgroundColor: 'initial',
    },
    rootHighlighting: {
      '& > .react-pdf__Page__textContent': {
        zIndex: 2,
      },
      '& .react-pdf__Page__annotations': {
        '& section': {
          zIndex: 'initial',
        },
      },
    },
    pageVisibilityAnchor: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    },
    spinner: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
    },
  })
);
