import { useCallback, useState } from 'react';

export type HighlightTooltipState = {
  show: boolean;
  anchorPos: {
    left: number;
    top: number;
  };
};

const initState: HighlightTooltipState = {
  show: false,
  anchorPos: { top: 0, left: 0 },
};

export const useHighlightTooltip = () => {
  const [state, setState] = useState<HighlightTooltipState>(initState);

  const handleShowTooltip = useCallback((x: number, y: number) => {
    setState({
      show: true,
      anchorPos: {
        left: x,
        top: y,
      },
    });
  }, []);

  const handleHideTooltip = useCallback(() => {
    setState({
      ...state,
      show: false,
    });
  }, [state]);

  return {
    showTooltip: state.show,
    anchorPos: state.anchorPos,
    onShowTooltip: handleShowTooltip,
    onHideTooltip: handleHideTooltip,
  };
};
