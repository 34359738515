import React from 'react';
import NotesIcon from '@mui/icons-material/NotesRounded';

import HighlightTooltipAction from '../HighlightTooltipAction';

export type HighlightTooltipAddNoteActionProps = {
  onAddNote?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'addNote'
  ) => void;
};

const HighlightTooltipAddNoteAction = ({
  onAddNote,
}: HighlightTooltipAddNoteActionProps) => {
  return (
    <HighlightTooltipAction
      action="addNote"
      title="Add note"
      onClick={onAddNote}
      icon={NotesIcon}
    />
  );
};

export default HighlightTooltipAddNoteAction;
