import { useContext, useCallback } from 'react';

import { PDFViewerContext } from '../components/PDFViewer/PDFViewer.context';

export const useHighlightColor = () => {
  const {
    state: { highlightColor },
    dispatch,
  } = useContext(PDFViewerContext);

  const setHighlightColor = useCallback(
    (newColor: string) => {
      dispatch({ type: 'setHighlightColor', payload: newColor });
    },
    [dispatch]
  );

  return {
    highlightColor,
    setHighlightColor,
  };
};
