import React from 'react';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useIconButtonColors } from 'app/theme/styles/iconButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import DocsCollection from 'containers/Docs/DocsCollection';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectUser } from 'containers/User/user.slice';

type ExplainHighlightResultProps = {
  evidences: RetrievalUnitData[] | undefined;
  explanation: string;
};

export const ExplainHighlightResult = ({
  evidences,
  explanation,
}: ExplainHighlightResultProps) => {
  const { t } = useTranslation('search');
  const { colorInfo } = useIconButtonColors();
  const [open, setOpen] = React.useState(false);
  const organizeDocIds = useOrganizeDocIds(evidences);
  const userSettings = useSelector(selectUser);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      aria-label={`explain-highlight-result`}
      pt={2}
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography pb={1} variant="body2">
        {explanation}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <TrackedIconButton
          aria-label="show evidence"
          className={colorInfo}
          eventName={TrackEventName.PDFExplainHighlightEvidenceClicked}
          size="small"
          onClick={handleOpen}
        >
          <LibraryBooksIcon />
        </TrackedIconButton>

        <Dialog
          maxWidth="md"
          open={open}
          scroll="body"
          fullWidth
          onClose={handleClose}
        >
          <DialogContent>
            {evidences !== undefined && evidences.length > 0 ? (
              <DocsCollection organizeDocIds={organizeDocIds}>
                {evidences.map((evidence) => (
                  <RetrievalUnit
                    cardComposition={userSettings?.cardStyle}
                    data={evidence}
                    key={evidence.document.id}
                  />
                ))}
              </DocsCollection>
            ) : (
              <Typography>{t('explainHighlight.evidence.fallback')}</Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};
