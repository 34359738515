import { useCallback, useContext } from 'react';

import { PDFViewerContext } from '../components/PDFViewer/PDFViewer.context';

export const usePDFMetadata = () => {
  const {
    state: { metadata },
    dispatch,
  } = useContext(PDFViewerContext);

  const setMetadata = useCallback(
    (payload: any) => {
      dispatch({ type: 'setMetadata', payload });
    },
    [dispatch]
  );

  return {
    metadata,
    setMetadata,
  };
};
