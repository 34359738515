import React, { FC } from 'react';

import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  AnnotationHighlight,
  ListNotesPayload,
} from 'api/notesApi/notesApi.types';
import FormAutoSave from 'common/components/Form/FormAutosave/FormAutoSave';
import { SearchEngineEnum } from 'common/enums';

import DocNoteCreate from '../../DocNotes/DocNoteCreate';
import { useDocDetails } from '../../Docs/hooks/useDocDetails';
import { NotesFilterVisibility } from '../../NotesFilter/NotesFilter.types';
import NotesVisibilityFilter from '../../NotesFilter/NotesVisibilityFilter';
import { DocPDFAnnotations } from '../DocPDFAnnotations';
import DocPDFViewerInfo from '../DocPDFViewerInfo';
import { DocPDFNotesFormValues } from '../hooks/useDocPDFNotesForm';

interface Props {
  activeHighlight: number | null;
  docId: string;
  handleHighlightClick: (highlight: AnnotationHighlight) => void;
  initialValues: DocPDFNotesFormValues;
  notesPayload: Pick<ListNotesPayload, 'sortBy' | 'createdByMe'>;
  onSubmit: ({ sortBy, visibility }: DocPDFNotesFormValues) => Promise<void>;
  searchEngine?: SearchEngineEnum;
}

export const InfoNotes: FC<Props> = ({
  activeHighlight,
  docId,
  handleHighlightClick,
  initialValues,
  notesPayload,
  onSubmit,
  searchEngine,
}) => {
  const { t } = useTranslation('common');
  const { data, isLoading } = useDocDetails({ docId });

  return (
    <>
      {isLoading && (
        <Box p={2}>
          <Skeleton height={240} variant="rectangular" />
        </Box>
      )}
      {data && <DocPDFViewerInfo docData={data} />}
      <Divider />
      <Box p={2}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h5">{t('pdfViewer.notes.title')}</Typography>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
              <Form name="docPdfNotesFilter">
                <FormAutoSave />
                <NotesVisibilityFilter
                  options={[
                    NotesFilterVisibility.All,
                    NotesFilterVisibility.OnlyMyNotes,
                  ]}
                />
              </Form>
            )}
          </Formik>
        </Box>
        {data && (
          <DocNoteCreate
            docData={data}
            docId={docId}
            searchEngine={searchEngine}
          />
        )}
        <DocPDFAnnotations
          activeNoteId={activeHighlight}
          docId={docId}
          notesPayload={notesPayload}
          onHighlightClick={handleHighlightClick}
        />
      </Box>
    </>
  );
};
