import React from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';
import { useField } from 'formik';

type Props = {
  name: string;
  onValueChange?: (e: React.ChangeEvent<any>, checked: boolean) => void;
} & CheckboxProps;

export const FormCheckboxField = ({ name, onValueChange, ...rest }: Props) => {
  const [field] = useField({ name, type: 'checkbox' });

  const handleChange = (e: React.ChangeEvent<any>, checked: boolean) => {
    field.onChange(e);

    if (onValueChange) {
      onValueChange(e, checked);
    }
  };

  return (
    <Checkbox {...field} {...rest} color="primary" onChange={handleChange} />
  );
};
