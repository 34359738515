import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  actionsRoot: {
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
  formActions: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  wrapper: {
    width: 300,
  },
}));
