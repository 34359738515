import { InvitationForm } from '@zarn/vendor/dist/auth';

import { UserInvitationPayload } from './invitationsApi.types';

export const serializeUserInvitationForm = (
  data: UserInvitationPayload
): InvitationForm => {
  return {
    email: data.email,
    first_name: data.firstName ?? null,
  };
};
