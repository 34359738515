import React from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import NoteIcon from '@mui/icons-material/Notes';
import PeopleIcon from '@mui/icons-material/People';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Box, List, Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AccessRole from 'common/components/Access/AccessRole';
import DrawerSection from 'common/components/PageDrawer/DrawerSection/DrawerSection';
import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { TagsList } from 'containers/Tags/TagsList/TagsList';

import { useIsActivePage } from '../../../common/hooks/useIsActivePage';
import { RoleEnum } from '../../Auth/enums';

import { DiscoverNavItem } from './DiscovertNavItem';
import MyDocumentsNavItem from './MyDocumentsNavItem';
import RecommendationsNavItem from './RecommendationsNavItem';
import SidebarManagementSection from './SidebarManagementSection';

const useStyles = makeStyles((theme: Theme) => ({
  tagsPopover: {
    paddingRight: theme.spacing(1),
  },
}));

type SidebarContentProps = {
  minified?: boolean;
};

export const SidebarContent = ({ minified }: SidebarContentProps) => {
  const { isAuthenticated } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation('pages');
  const { pathname } = useLocation();

  const theme = useTheme<AppTheme>();

  const { isActive: isTagsActive } = useIsActivePage('/tags');

  return (
    <>
      <Box data-testid="sidebarNav" px={1}>
        <List disablePadding>
          <DiscoverNavItem minified={minified} />

          <RecommendationsNavItem minified={minified} />

          <PageDrawerMenuItem
            icon={<PeopleIcon />}
            minified={minified}
            title={t('people.navTitle')}
            to="/people"
            tooltip={t('people.navTitle')}
          />

          <PageDrawerMenuItem
            icon={<StarRoundedIcon />}
            minified={minified}
            title={t('favourites.navTitle')}
            to="/favorites"
            tooltip={t('favourites.navTitle')}
            exact
          />

          <MyDocumentsNavItem minified={minified} />

          <PageDrawerMenuItem
            icon={<NoteIcon />}
            minified={minified}
            title={t('notes.navTitle')}
            to="/notes"
            tooltip={t('notes.navTitle')}
            exact
          />

          {!isAuthenticated && (
            <PageDrawerMenuItem
              icon={<BookmarkIcon />}
              minified={minified}
              title={t('tags.navTitle')}
              to={{ pathname, state: { signUp: true } }}
              tooltip={t('tags.navTitle')}
              exact
            />
          )}
        </List>
      </Box>

      <AccessRole accessRoles={[RoleEnum.Admin, RoleEnum.Manager]}>
        <SidebarManagementSection minified={minified} />
      </AccessRole>

      {isAuthenticated && (
        <DrawerSection
          icon={
            <BookmarkIcon
              sx={{
                color: isTagsActive
                  ? theme.palette.sideDrawer.icons.highlight
                  : theme.palette.sideDrawer.icons.defaultColor,
              }}
            />
          }
          minified={minified}
          popoverClassName={classes.tagsPopover}
          sectionPath="/tags"
          text={t('tags.navTitle')}
        >
          <TagsList />
        </DrawerSection>
      )}
    </>
  );
};
