import React, { FC, ReactNode, ReactText, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import makeStyles from '@mui/styles/makeStyles';
import { errorChipStyles } from 'app/theme/styles/chip';

export const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    ...errorChipStyles(theme),
    marginLeft: theme.spacing(1),
  },
}));

export interface SubItem {
  id: string | number;
  label: ReactNode;
  notifications?: ReactNode;
  to: string;
}

type WithExpandableItemsProps = {
  chipLabel?: ReactText;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  renderItem: (item: SubItem, index: number) => ReactNode;
  subItems: SubItem[];
  text: ReactNode;
};

export const NotificationLinkItemExpandable: FC<WithExpandableItemsProps> = ({
  chipLabel,
  icon: Icon,
  renderItem,
  subItems,
  text,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem selected={open} button onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
        <Chip className={classes.chip} label={chipLabel} size="small" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map(renderItem)}
        </List>
      </Collapse>
    </>
  );
};
