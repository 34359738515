import React, { useCallback } from 'react';

import { Button, ClickAwayListener, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';

import SubmitButton from '../Buttons/SubmitButton';
import { WithTrackedActionProps } from '../TrackedActions/withTrackedAction';

import NoteMarkdownField from './NoteMarkdownField/NoteMarkdownField';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {
  onClickAway?: () => void;
  submitButtonText: string;
} & FormikProps<NoteFormValue> &
  WithTrackedActionProps;

export const NoteForm = ({
  dirty,
  eventName,
  eventProps,
  handleSubmit,
  isSubmitting,
  isValid,
  onClickAway,
  submitButtonText,
}: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const handleClickAway = useCallback((): void => {
    if (!dirty && onClickAway) {
      onClickAway();
      return;
    }

    handleSubmit();
  }, [dirty, onClickAway, handleSubmit]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Form
        className={classes.root}
        role="form"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            handleSubmit(e);
          }
        }}
      >
        <NoteMarkdownField
          helperText={t('notes.form.contentField.helperText')}
          name="content"
          placeholder={t('notes.form.contentField.label')}
        />

        <Grid justifyContent="flex-end" spacing={2} container item xs>
          <Grid item>
            <Button disabled={isSubmitting} type="reset">
              {t('common:buttons.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <SubmitButton
              disabled={!isValid || !dirty}
              eventName={eventName}
              eventProps={eventProps}
              isSubmitting={isSubmitting}
              text={submitButtonText}
            />
          </Grid>
        </Grid>
      </Form>
    </ClickAwayListener>
  );
};
