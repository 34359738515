import { LoginDialogState } from 'containers/Auth/Auth.context';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export const useCustomSignIn = () => {
  const { setLoginDialog } = useAuth();

  const handleSignIn = () => {
    setLoginDialog(LoginDialogState.Login);
  };

  const handleSignUp = () => {
    setLoginDialog(LoginDialogState.SignUp);
  };

  return { handleSignIn, handleSignUp };
};
