import React, { FC } from 'react';

import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HtmlContent } from 'common/components/HtmlContent/HtmlContent';

import { EvidenceTitle } from './EvidenceTitle';

interface Props {
  disabled: boolean;
  evidence: string;
  isInsideDocument: boolean;
  isLoading: boolean;
  label: string;
  onClick: () => void;
  title: string;
}

export const EvidenceContent: FC<Props> = ({
  disabled,
  evidence,
  isInsideDocument,
  isLoading,
  label,
  onClick,
  title,
}) => {
  const { t } = useTranslation('common');

  return (
    <Card
      data-testid="EvidenceContent"
      sx={{ boxShadow: 'none', padding: '0.5rem' }}
    >
      <CardActionArea component="div" disabled={disabled} onClick={onClick}>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          {!isInsideDocument ? (
            <>
              <Typography variant="body2">{label}</Typography>
              <EvidenceTitle isLoading={isLoading} title={title} />
            </>
          ) : null}
        </Box>
        <HtmlContent
          content={`${isInsideDocument ? label : ''} ${t(
            'chat.evidence.passage.prefix'
          )} ${evidence}`}
          variant="body2"
        />
      </CardActionArea>
    </Card>
  );
};
