import React, { FC } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { NoteCard } from 'common/components/Notes/NoteCard';

interface NoteInChatProps {
  note: NoteDetails;
}

export const NoteInChat: FC<NoteInChatProps> = ({ note }) => {
  //TODO: Implement handleDelete and handleUpdate
  const handleDelete = async () => {};
  const handleUpdate = async () => {};

  return (
    <NoteCard
      note={note}
      readMode={true}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
    />
  );
};
