import React, { useEffect, useState } from 'react';

export type UsePDFPageDataUrlProps = {
  rendered: boolean;
  pageWidth: number;
  pageHeight: number;
  canvasRef?: React.MutableRefObject<HTMLCanvasElement | null>;
};

export const usePDFPageDataUrl = ({
  rendered,
  canvasRef,
  pageWidth,
  pageHeight,
}: UsePDFPageDataUrlProps) => {
  const [dataUrl, setDataUrl] = useState<string>('');

  useEffect(() => {
    try {
      const canvas = canvasRef?.current;

      if (!rendered || !canvas) return;

      const newCanvas = canvas.ownerDocument.createElement('canvas');
      const width = pageWidth;
      const height = pageHeight;

      newCanvas.width = width;
      newCanvas.height = height;

      const newCanvasContext = newCanvas.getContext('2d', { alpha: false });

      if (!newCanvasContext) return;

      newCanvasContext.drawImage(canvas, 0, 0, width, height);
      setDataUrl(newCanvas.toDataURL());
    } catch (error) {
      console.error('usePDFPageDataUrl.error', error);
    }
  }, [rendered, canvasRef, pageWidth, pageHeight]);

  return dataUrl;
};
