import React, { FC } from 'react';
import { AreaSelectionProps } from './types';
import { useAreaSelection } from './hooks/useAreaSelection';

const AreaSelection: FC<AreaSelectionProps> = (props) => {
  const { selection, selectionColor } = useAreaSelection(props);

  if (!selection) return null;

  return (
    <div
      aria-label="Area selection"
      style={{
        position: 'absolute',
        boxSizing: 'border-box',
        top: Math.min(selection.y1, selection.y2),
        left: Math.min(selection.x1, selection.x2),
        width: Math.abs(selection.x2 - selection.x1),
        height: Math.abs(selection.y2 - selection.y1),
        border: '2px dashed rgba(0,0,0,0.6)',
        backgroundColor: selectionColor,
      }}
    />
  );
};

export default AreaSelection;
