import React from 'react';
import DoneIcon from '@mui/icons-material/DoneRounded';

import HighlightTooltipAction from '../HighlightTooltipAction';

export type HighlightTooltipActionCreateProps = {
  onCreate?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'create'
  ) => void;
};

const HighlightTooltipCreateAction = ({
  onCreate,
}: HighlightTooltipActionCreateProps) => {
  return (
    <HighlightTooltipAction
      title="Create"
      action="create"
      onClick={onCreate}
      icon={DoneIcon}
    />
  );
};

export default HighlightTooltipCreateAction;
