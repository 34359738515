import React, { FC, useContext } from 'react';

import { Button, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import { useCustomSignIn } from 'common/hooks/useSignIn/useCustomSignIn';
import { adjustColor } from 'common/utils/colors';

import { AuthContext } from '../../Auth/Auth.context';

export const MainToolbarSignUpButton: FC = () => {
  const { t } = useTranslation('common');
  const { oidcConfig } = useContext(AuthContext);
  const { handleSignUp } = useCustomSignIn();
  const theme = useTheme<AppTheme>();

  return oidcConfig?.enabled ? null : (
    <Button
      sx={{
        '&:hover': {
          borderColor: adjustColor(theme.palette.header.iconColor, 40),
        },
        borderColor: theme.palette.header.iconColor,
        color: theme.palette.header.iconColor,
      }}
      variant="outlined"
      onClick={handleSignUp}
    >
      {t('buttons.signUp')}
    </Button>
  );
};
