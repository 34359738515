export class OrderedSet<T> {
  private readonly orderedSet: Map<number, T>;
  private readonly unorderedSet: T[];

  constructor() {
    this.orderedSet = new Map();
    this.unorderedSet = [];
  }

  setWidgetToRow = (row: number, el: T) => {
    this.orderedSet.set(row, el);
  };

  addUnorderedElement = (el: T) => {
    this.unorderedSet.push(el);
  };

  add(row: number | undefined, el: T) {
    if (row) {
      this.setWidgetToRow(row, el);
    } else {
      this.addUnorderedElement(el);
    }
  }

  hasOrderedElement = (row: number) => this.orderedSet.has(row);

  getOrderedElement = (row: number) => this.orderedSet.get(row);

  setOrderedElement = (row: number, el: T) => this.orderedSet.set(row, el);

  getOrderedSet = () =>
    Array.from(this.orderedSet)
      .sort((a, b) => a[0] - b[0])
      .map((el) => el[1])
      .concat(this.unorderedSet);
}
