import React, { FC, useEffect, useState } from 'react';

import posthog from 'posthog-js';

import { PageLoader } from 'common/components/Loaders/PageLoader/PageLoader';

import { setupAxiosInterceptorFor401, updateAccessToken } from './Auth.service';
import { parseAccessTokenPayload } from './Auth.utils';
import { Me } from './models/Me.interface';

interface Props {
  children: React.ReactNode;
  setMe: (me: Me | null) => void;
  showLoginDialog: () => void;
}

export const AuthProviderCustom: FC<Props> = ({
  children,
  setMe,
  showLoginDialog,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setupAxiosInterceptorFor401(showLoginDialog);

    (async () => {
      const [err, accessToken] = await updateAccessToken();

      if (err) {
        setMe(null);
        posthog.reset(true);
        showLoginDialog();
        setIsLoading(false);
        return;
      }

      if (!accessToken) {
        setIsLoading(false);
        return;
      }

      const parsedAccessTokenPayload = parseAccessTokenPayload(accessToken);
      if (parsedAccessTokenPayload) {
        posthog.identify(parsedAccessTokenPayload.sub, {
          roles: parsedAccessTokenPayload.roles,
        });
      }
      setMe(parsedAccessTokenPayload);
      setIsLoading(false);
    })();
  }, [setMe, showLoginDialog]);

  if (isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};
