import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import { listDocuments, ListDocumentsPropertyName } from 'api/searchApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { selectUser } from 'containers/User/user.slice';

import { multipleDocsQueryKeys } from '../docs.utils';

export type UseDocDetailsReturn = UseQueryResult<
  RetrievalUnitData[] | null,
  BaseError
>;

export type UseDocsDetailsProps = {
  docsIds: string[];
  options?: Omit<
    UseQueryOptions<RetrievalUnitData[] | null, BaseError>,
    'queryKey' | 'queryFn'
  >;
  propertyName?: ListDocumentsPropertyName;
  salt?: string;
};

export const useDocsDetails = ({
  docsIds,
  options = {},
  propertyName = 'organize_doc_id',
  salt,
}: UseDocsDetailsProps): UseDocDetailsReturn => {
  const parsedHostname = useParsedHostname();
  const userSettings = useSelector(selectUser);
  const query = useQuery<RetrievalUnitData[] | null, BaseError>(
    multipleDocsQueryKeys.detailsItem(
      docsIds,
      userSettings?.indexCluster,
      salt
    ),
    async () => {
      const { data } = await listDocuments({
        docIds: docsIds,
        indexCluster: userSettings?.indexCluster,
        propertyName: propertyName,
        tenant: parsedHostname.tenant,
      });

      return data.items ?? null;
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      staleTime: Infinity,
      ...options,
    }
  );

  return query;
};
