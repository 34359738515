import React from 'react';

import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export const ExplainHighlightResultSkeleton = () => {
  return (
    <Box p={2} pb={1}>
      <Skeleton height={20} />
      <Skeleton height={20} />
      <Skeleton height={20} width="40%" />
    </Box>
  );
};
