export const getInitialsFromName = (name: string) => {
  if (!name) {
    return '';
  }

  const names = `${name}`.trim().split(' ');
  let initials = names[0].substring(0, 1);

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1);
  }
  return initials.toUpperCase();
};
