import { AxiosResponse } from 'axios';

import { Configuration, DocumentAssetsApi } from '@zarn/vendor/dist/search';

import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';

import {
  DocumentAssetContentData,
  GetDocumentAssetContentPayload,
} from './documentAssetApi.types';

export const documentAssetsApi = new DocumentAssetsApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const getDocumentAssetContent = async ({
  assetType,
  docId,
  indexCluster,
  tenant,
}: GetDocumentAssetContentPayload): Promise<
  AxiosResponse<DocumentAssetContentData>
> => {
  const { data, headers, ...rest } = await documentAssetsApi.retrieveContent(
    {
      assetType,
      documentId: docId,
      indexCluster,
      requesterUuid: '',
      tenant,
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const contentType = headers['content-type'];
  const arrayBuffer = await new Response(data).arrayBuffer();

  return {
    ...rest,
    data: {
      content: new Uint8Array(arrayBuffer),
      contentType: contentType,
    },
    headers,
  };
};
