import React, { ReactNode } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import ExploreIcon from '@mui/icons-material/Explore';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

interface UsePageInfoProps {
  originalUrl: string;
}

interface IPageInfo {
  linkIcon: ReactNode;
  linkText: string;
  linkUrl: string;
}

const DEFAULT_PAGE_INFO: IPageInfo = {
  linkIcon: <BookmarkIcon fontSize="small" />,
  linkText: 'Original Page',
  linkUrl: '/',
};

const URL_TO_PAGE_INFO: Record<string, IPageInfo> = {
  '/': {
    linkIcon: <ExploreIcon fontSize="small" />,
    linkText: 'Discover Page',
    linkUrl: '/',
  },
  '/my-documents': {
    linkIcon: <LibraryBooksIcon fontSize="small" />,
    linkText: 'My Documents Page',
    linkUrl: '/my-documents',
  },
};

export const usePageInfo = ({ originalUrl }: UsePageInfoProps): IPageInfo => {
  const url = new URL(originalUrl);
  const { pathname } = url;

  return URL_TO_PAGE_INFO[pathname]
    ? URL_TO_PAGE_INFO[pathname]
    : { ...DEFAULT_PAGE_INFO, linkText: pathname, linkUrl: pathname };
};
