import React, { useCallback } from 'react';

import { Button, ClickAwayListener, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TextField } from 'common/components/TextField';
import { WithTrackedActionProps } from 'common/components/TrackedActions/withTrackedAction';
import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: `0px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(2),
  },
}));

type ExplainHighlightInputFormProps = {
  inputValue: string;
  name: string;
  onClickAway?: () => void;
  setInputValue: (value: string) => void;
  submitButtonText: string;
} & FormikProps<NoteFormValue> &
  WithTrackedActionProps;

export const ExplainHighlightInputForm = ({
  dirty,
  eventName,
  eventProps,
  handleSubmit,
  inputValue,
  isSubmitting,
  isValid,
  name,
  onClickAway,
  setInputValue,
  submitButtonText,
}: ExplainHighlightInputFormProps) => {
  const { t } = useTranslation(['common', 'search']);

  const classes = useStyles();
  const [field] = useField({ name });

  const handleClickAway = useCallback((): void => {
    if (!dirty && onClickAway) {
      onClickAway();
      return;
    }

    handleSubmit();
  }, [dirty, onClickAway, handleSubmit]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Form
        className={classes.root}
        role="form"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            handleSubmit(e);
          }
        }}
      >
        <TextField
          {...field}
          helperText={t('search:explainHighlight.input.helperText')}
          rows={4}
          sx={{ paddingTop: 1 }}
          value={inputValue}
          autoFocus
          fullWidth
          multiline
          onInput={handleChange}
        />

        <Grid justifyContent="flex-end" spacing={2} container item xs>
          <Grid item>
            <Button disabled={isSubmitting} type="reset">
              {t('common:buttons.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <SubmitButton
              disabled={!isValid || !dirty}
              eventName={eventName}
              eventProps={eventProps}
              isSubmitting={isSubmitting}
              text={submitButtonText}
            />
          </Grid>
        </Grid>
      </Form>
    </ClickAwayListener>
  );
};
