import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      '&::before': {
        content: '""',
        ...theme.mixins.toolbar,
      },
    },
    documentContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      width: '100%',
    },
  })
);
