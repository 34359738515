import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = ButtonProps & NavLinkProps;

const TrackedNavLinkButton = ({
  children,
  onAction,
  onClick,
  to,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button component={NavLink} to={to} {...rest} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedNavLinkButton
);
