import React, { useCallback, useEffect, useState } from 'react';

import { PopperProps, Theme, Tooltip, TooltipProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { throttle } from 'lodash';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import TagPreview from 'common/components/Tags/TagPreview/TagPreview';

import { useTagPreviewDocs } from './hooks/useTagPreviewDocs';

const popperProps: Partial<PopperProps> = {
  modifiers: [
    {
      enabled: true,
      name: 'preventOverflow',
      options: {
        rootBoundary: 'viewport',
      },
    },
  ],
};

export const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    color: theme.palette.text.primary,
    maxWidth: '90vw',
    padding: theme.spacing(0),
    width: 300,
  },
  tooltipArrow: {
    '&::before': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export type TagPreviewTooltipProps = {
  children: React.ReactElement<any, any>;
  enterDelay?: TooltipProps['enterDelay'];
  enterNextDelay?: TooltipProps['enterNextDelay'];
  placement?: TooltipProps['placement'];
  tag: TagDetails;
};

const TagPreviewTooltip = React.memo(
  ({
    children,
    enterDelay = 500,
    enterNextDelay = 500,
    placement,
    tag,
  }: TagPreviewTooltipProps) => {
    const classes = useStyles();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {
      data: docsPreviewData,
      isLoading: docsPreviewLoading,
      refetch: fetchPreviewDocs,
    } = useTagPreviewDocs({
      tagId: tag.id,
      tagName: tag.name,
      tagType: tag.type,
    });

    const handleFetchData = useCallback(async () => {
      setTooltipOpen(true);

      if (!tooltipOpen) {
        fetchPreviewDocs();
      }
    }, [fetchPreviewDocs, setTooltipOpen, tooltipOpen]);

    const handleTooltipOpen = useCallback(throttle(handleFetchData, 2000), [
      handleFetchData,
    ]);

    const handleTooltipClose = useCallback(() => {
      setTooltipOpen(false);
    }, [setTooltipOpen]);

    useEffect(() => {
      return handleTooltipOpen.cancel;
    }, [handleTooltipOpen]);

    return (
      <Tooltip
        aria-describedby="tag preview tooltip"
        classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
        enterDelay={enterDelay}
        enterNextDelay={enterNextDelay}
        leaveDelay={300}
        open={tooltipOpen}
        placement={placement}
        PopperProps={popperProps}
        title={
          <TagPreview
            docsPreviewData={docsPreviewData}
            docsPreviewLoading={docsPreviewLoading}
            tag={tag}
          />
        }
        arrow
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
      >
        {children}
      </Tooltip>
    );
  }
);

TagPreviewTooltip.displayName = 'TagPreviewTooltip';

export default TagPreviewTooltip;
