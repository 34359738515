import React, { useMemo, useRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Chip, ListItemIcon, Menu } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import TrackedMenuItem from 'common/components/Menu/TrackedMenuItem';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { normalizePaperSource } from 'common/utils/documents';
import { FeedbackType } from 'containers/Feedback/enums';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { DuplicateDocument } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

type DocSourceProps = {
  duplicates: DuplicateDocument[];
  id: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  resultType?: ResultType;
  searchId?: string;
  source: string;
  uri?: string | null;
};

const DocSource = ({
  duplicates,
  id,
  onAction,
  onClick,
  resultType,
  searchId,
  source,
  uri,
}: WithTrackedActionWrappedProps<DocSourceProps>) => {
  const chipRef = useRef<HTMLDivElement | null>(null);
  const { sendFeedback } = useSendFeedback();
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const normalizedSource = useMemo(
    () => normalizePaperSource(source),
    [source]
  );
  const hasDuplicates = duplicates.length > 0;

  const handleMenuOpen = () => {
    if (chipRef?.current) {
      setMenu(chipRef.current);
    }
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleClick = (resultId: string) => {
    handleMenuClose();

    if (searchId && resultType) {
      sendFeedback({
        feedbackType: FeedbackType.Click,
        resultId,
        resultType,
        score: 0,
        searchId,
      });
    }
  };

  const handleSourceChipClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };

  if (!hasDuplicates) {
    return (
      <Chip
        component="a"
        data-testid="documentSource"
        href={uri ?? undefined}
        icon={<LocalLibraryIcon />}
        label={normalizedSource}
        rel="noopener noreferrer"
        size="small"
        sx={{ display: 'inline-flex', mr: 0.5 }}
        target="_blank"
        clickable
        onClick={handleSourceChipClick}
      />
    );
  }

  return (
    <>
      <Chip
        data-testid="documentSource"
        deleteIcon={<ExpandMoreIcon />}
        icon={<LocalLibraryIcon />}
        label={
          <>
            {normalizedSource}

            {hasDuplicates ? <b color="">{` + ${duplicates.length}`}</b> : ''}
          </>
        }
        ref={chipRef}
        size="small"
        sx={{ display: 'inline-flex', mr: 0.5 }}
        onClick={handleMenuOpen}
        onDelete={handleMenuOpen}
      />

      <Menu
        anchorEl={menu}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={!!menu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleMenuClose}
      >
        <TrackedMenuItem
          component="a"
          eventName={TrackEventName.DocumentSourceClicked}
          href={uri ?? ''}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => handleClick(id)}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>

          {source}
        </TrackedMenuItem>

        {duplicates.map((duplicate) => (
          <TrackedMenuItem
            component="a"
            eventName={TrackEventName.DocumentSourceClicked}
            href={duplicate.uri ?? ''}
            key={duplicate.document.id}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => handleClick(duplicate.document.id)}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>

            {duplicate.source}
          </TrackedMenuItem>
        ))}
      </Menu>
    </>
  );
};

DocSource.deisplayName = 'DocSource';

export default withTrackedAction<DocSourceProps & WithTrackedActionProps>(
  DocSource,
  TrackEventName.DocumentSourceClicked
);
