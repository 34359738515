/* eslint-disable camelcase */
import { RecFeedbackItem, SeenRec } from '@zarn/vendor/dist/feedback';

export const getSeenRecIDs = (results: RecFeedbackItem[]): string[] => {
  return results.map(({ recommendation_uuid }) => recommendation_uuid);
};

export const getUniqueRecIDs = (
  seenIDs: string[],
  newSeenIDs: string[]
): string[] => {
  return [...new Set([...seenIDs, ...newSeenIDs])];
};

export const getUnseenRecIDs = (
  recIds: SeenRec[],
  seenRecs: string[]
): SeenRec[] => {
  return recIds.filter(({ uuid }) => !seenRecs.includes(uuid));
};
