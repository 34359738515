import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles<Theme, { numberOfPages: number }>(
  (theme: Theme) =>
    createStyles({
      toolbarInput: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        textAlign: 'center',
        width: ({ numberOfPages }) => {
          if (numberOfPages < 100) return 20;
          if (numberOfPages < 1000) return 30;

          return 40;
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    })
);
