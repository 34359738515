import React, { useContext } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  IconButton,
  Theme,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { AppTheme } from 'app/theme';

import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { useHiddenForPages } from 'common/hooks/useHiddenForPages';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { NavbarProfile } from 'containers/NavbarProfile';
import { Notifications } from 'containers/Notifications/Notifications';
import { PageContext } from 'containers/Page/Page.context';
import { SearchField } from 'containers/Search/SearchField/SearchField';
import SearchLoader from 'containers/SearchResults/SearchLoader';

import { MainToolbarButtons } from './MainToolbarAuthButtons/MainToolbarButtons';
import { MainToolbarLogo } from './MainToolbarLogo';
import MainToolbarPrivateDocs from './MainToolbarPrivateDocs/MainToolbarPrivateDocs';
import { useStyles } from './styles';

export const MainToolbar = () => {
  const isHidden = useHiddenForPages([
    '/reset-password',
    '/create-password',
    '/files',
  ]);
  const { isAuthenticated } = useAuth();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const { isDocumentUploadsActive } = useTenantFeatures();

  const {
    pageDrawerMinified,
    pageDrawerMobileOpen,
    setPageDrawerMinified,
    setPageDrawerMobileOpen,
  } = useContext(PageContext);

  const handleDrawerToggle = () => {
    if (isMobile) {
      setPageDrawerMobileOpen(!pageDrawerMobileOpen);
    } else {
      setPageDrawerMinified(!pageDrawerMinified);
    }
  };

  const theme = useTheme<AppTheme>();

  if (isHidden) return null;

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Main toolbar' }}>
      <AppBar
        className={classes.appBar}
        data-testid="MainToolbar"
        elevation={scrollTrigger ? 5 : 0}
        position="fixed"
        sx={{ background: theme.palette.header.background }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            className={classes.menuButton}
            color="contrast"
            edge={isMobile && 'start'}
            size="large"
            onClick={handleDrawerToggle}
          >
            <MenuIcon sx={{ color: theme.palette.header.iconColor }} />
          </IconButton>

          <MainToolbarLogo />

          <SearchField />

          <div className={classes.spacing} />

          <Box alignItems="center" display="flex" gap={1}>
            <Notifications />

            <MainToolbarButtons />

            {isAuthenticated && isDocumentUploadsActive && (
              <MainToolbarPrivateDocs />
            )}
          </Box>

          <NavbarProfile />
        </Toolbar>

        <SearchLoader />
      </AppBar>

      <div className={classes.toolbar} />
    </TrackedActionContext.Provider>
  );
};
