import { useReducer } from 'react';
import { PDFPageState } from '../types';

export type PDFPageAction =
  | { type: 'setPageState'; payload: Partial<PDFPageState> }
  | { type: 'setVisibility'; payload: boolean }
  | { type: 'setRendered'; payload: boolean }
  | {
      type: 'setPageSize';
      payload: { width: number; height: number };
    };

const PDFPageReducer = (
  state: PDFPageState,
  action: PDFPageAction
): PDFPageState => {
  switch (action.type) {
    case 'setPageState':
      return {
        ...state,
        ...action.payload,
      };
    case 'setPageSize':
      const { width, height } = action.payload;

      return {
        ...state,
        originalPageWidth: width,
        originalPageHeight: height,
      };
    case 'setVisibility':
      return {
        ...state,
        isVisible: action.payload,
      };
    case 'setRendered':
      return {
        ...state,
        rendered: action.payload,
      };
    default:
      return state;
  }
};

export const initialPDFPageState: PDFPageState = {
  pageNumber: 1,
  isVisible: false,
  rendered: false,
  originalPageHeight: 1,
  originalPageWidth: 1,
};

export const usePDFPage = (initState: Partial<PDFPageState> = {}) => {
  const [state, dispatch] = useReducer(PDFPageReducer, {
    ...initialPDFPageState,
    ...initState,
  });

  return { state, dispatch };
};
