import {
  NewTaggedResourceItem,
  ResourcePermission,
  TaggableResourceType as TaggableResourceTypeApi,
  TaggedResourcesApiCreateFavoriteTaggedResourceRequest,
  TaggedResourcesApiCreateTaggedResourceRequest,
  TaggedResourcesApiDeleteFavoriteTaggedResourceRequest,
  TaggedResourcesApiDeleteTaggedResourceRequest,
  TaggedResourcesApiFavoriteTaggedResourcesFilterRequest,
  TaggedResourcesApiTaggedResourcesFilterRequest,
} from '@zarn/vendor/dist/saved-results';

import { tagTypeToTagTypeParam } from 'api/tagsApi';
import { SharingPermission } from 'containers/Sharing/Sharing.types';

import {
  TaggedResourceDetails,
  TaggedResourcesFilterPayload,
  TaggableResourceType,
  FavoriteResourcesFilterPayload,
  CreateTaggedResourcePayload,
  CreateFavoriteResourcePayload,
  DeleteFavoriteResourcePayload,
} from './taggedResourcesApi.types';

export const normalizeResourcePermission = (
  permission: ResourcePermission
): SharingPermission => {
  switch (permission) {
    case 'MODIFY':
      return 'modify';
    case 'WRITE':
      return 'write';
    default:
      return 'read';
  }
};

export const normalizeTaggableResourceType = (
  resType: TaggableResourceTypeApi
): TaggableResourceType => {
  switch (resType) {
    case 'person':
      return TaggableResourceType.Person;
    default:
      return TaggableResourceType.Query;
  }
};

export const serializeFavoriteResourcesFilterPayload = ({
  externalResourceIds,
  page,
  pageSize,
  resourceType,
  tenant,
}: FavoriteResourcesFilterPayload): TaggedResourcesApiFavoriteTaggedResourcesFilterRequest => ({
  page,
  pageSize,
  requesterUuid: '',
  taggableResourceCollection: resourceType,
  taggableResourceIds: externalResourceIds,
  tenant,
  userRoles: '',
});

export const serializeTaggedResourcesFilterPayload = ({
  tagId,
  tagType,
  ...rest
}: TaggedResourcesFilterPayload): TaggedResourcesApiTaggedResourcesFilterRequest => {
  const tagTypeParam = tagTypeToTagTypeParam(tagType);

  return {
    ...serializeFavoriteResourcesFilterPayload(rest),
    id: tagId,
    tagType: tagTypeParam !== 'favorites' ? tagTypeParam : 'own',
  };
};

export const serializeCreateFavoriteResourcePayload = ({
  resourceId,
  resourceType,
  tenant,
}: CreateFavoriteResourcePayload): TaggedResourcesApiCreateFavoriteTaggedResourceRequest => ({
  requesterUuid: '',
  taggableResourceCollection: resourceType,
  taggedResourceForm: {
    taggable_resource_id: resourceId,
  },
  tenant,
  userRoles: '',
});

export const serializeCreateTaggedResourcePayload = ({
  tagId,
  tagType,
  ...rest
}: CreateTaggedResourcePayload): TaggedResourcesApiCreateTaggedResourceRequest => {
  const tagTypeParam = tagTypeToTagTypeParam(tagType);

  return {
    ...serializeCreateFavoriteResourcePayload(rest),
    id: tagId,
    tagType: tagTypeParam !== 'favorites' ? tagTypeParam : 'own',
  };
};

export const serializeDeleteFavoriteResourcePayload = ({
  resourceId,
  resourceType,
  tenant,
}: DeleteFavoriteResourcePayload): TaggedResourcesApiDeleteFavoriteTaggedResourceRequest => ({
  requesterUuid: '',
  taggableResourceCollection: resourceType,
  taggableResourceId: resourceId,
  tenant,
  userRoles: '',
});

export const serializeDeleteTaggedResourcePayload = ({
  tagId,
  tagType,
  ...rest
}: CreateTaggedResourcePayload): TaggedResourcesApiDeleteTaggedResourceRequest => {
  const tagTypeParam = tagTypeToTagTypeParam(tagType);

  return {
    ...serializeDeleteFavoriteResourcePayload(rest),
    id: tagId,
    tagType: tagTypeParam !== 'favorites' ? tagTypeParam : 'own',
  };
};

export const deserializeTaggedResourceDetails = ({
  date_tagged,
  permission,
  taggable_resource_id,
  taggable_resource_type,
}: NewTaggedResourceItem): TaggedResourceDetails => ({
  dateTagged: date_tagged,
  permission: normalizeResourcePermission(permission),
  resourceId: taggable_resource_id,
  resourceType: normalizeTaggableResourceType(taggable_resource_type),
});
