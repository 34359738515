import React, { FC } from 'react';

import { CheckCircle } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const useStyles = makeStyles(() => ({
  root: {
    '& > *:first-child': {
      marginTop: 0,
      paddingLeft: 0,
    },
    '& > *:first-child li': {
      marginBottom: 8,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    '& img': {
      maxWidth: '100%',
    },
    '& pre': {
      '& > code': {
        padding: 0,
      },
    },
  },
}));

interface Props {
  children: string;
  className?: string;
}

// Define custom components to handle markdown elements
const components: Components = {
  h1: (props) => (
    <h1 style={{ fontSize: '2em', fontWeight: 'bold' }} {...props} />
  ),
  h2: (props) => (
    <h2 style={{ fontSize: '1.5em', fontWeight: 'bold' }} {...props} />
  ),
  h3: (props) => (
    <h3 style={{ fontSize: '1.17em', fontWeight: 'bold' }} {...props} />
  ),
  h4: (props) => (
    <h4 style={{ fontSize: '1em', fontWeight: 'bold' }} {...props} />
  ),
  li: ({ checked, children, className, ...props }) => {
    if (className === 'task-list-item') {
      return (
        <li {...props} style={{ listStyle: 'none' }}>
          <div style={{ display: 'flex' }}>
            <span style={{ display: 'none' }}>{children[0]}</span>
            <div style={{ alignSelf: 'flex-start' }}>
              {checked ? (
                <CheckCircle color="success" sx={{ fontSize: 20 }} />
              ) : (
                <CircularProgress size={20} />
              )}
            </div>
            <span style={{ marginLeft: 8 }}>{children.slice(1)}</span>
          </div>
        </li>
      );
    }

    return <li {...props}>{children}</li>;
  },
};

export const ProgressMarkdownText: FC<Props> = ({
  children,
  className,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={clsx(classes.root, className)}
      components={components}
      remarkPlugins={[remarkGfm]}
      {...rest}
    >
      {children}
    </ReactMarkdown>
  );
};
