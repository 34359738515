import React, { FC, useState } from 'react';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { SearchEngineEnum } from 'common/enums';
import { useSavedDocument } from 'containers/SavedDocuments/useSavedDocument';
import { TagsAutocompleteOptionValue } from 'containers/Tagging/TagsAutocomplete/TagsAutocomplete.types';

import { useDocTagging } from '../hooks/useDocTagging';
import { useDocTagsInitialValues } from '../hooks/useDocTagsInitialValues';
import { RetrievalUnitData } from '../RetrievalUnitData.interface';

import DocInlineTagsEdit from './DocInlineTagsEdit/DocInlineTagsEdit';
import { DocInlineTagsView } from './DocInlineTagsView';

interface Props {
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
  suggestedTags?: TagDetailsBase[];
}

export const DocInlineTags: FC<Props> = ({
  docData,
  searchEngine,
  suggestedTags = [],
}) => {
  const {
    document: { id: docId },
    organizeDocId,
  } = docData;
  const [isEditable, setIsEditable] = useState(false);
  const { docTags, suggestedTagItems } = useSavedDocument(
    organizeDocId,
    suggestedTags
  );
  const initialValues = useDocTagsInitialValues(docTags);
  const { onUpdateDocTags } = useDocTagging({ docData, searchEngine });

  const handleButtonClick = () => {
    setIsEditable(true);
  };

  const handleUpdateDocumentTags = (
    selectedTags: TagsAutocompleteOptionValue[]
  ) => onUpdateDocTags(selectedTags, initialValues.selectedTags);

  const onBlur = () => {
    setIsEditable(false);
  };

  if (isEditable) {
    return (
      <DocInlineTagsEdit
        eventName={TrackEventName.InlineDocumentTagEdited}
        eventProps={{ docId }}
        initialValues={initialValues}
        onBlur={onBlur}
        onUpdateDocumentTags={handleUpdateDocumentTags}
      />
    );
  }

  if (docTags.length > 0 || suggestedTagItems.length > 0) {
    return (
      <DocInlineTagsView
        suggestedTags={suggestedTagItems}
        tags={docTags}
        onButtonClick={handleButtonClick}
        onUpdateDocumentTags={handleUpdateDocumentTags}
      />
    );
  }

  return null;
};
