import { useQuery, UseQueryOptions } from 'react-query';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { getTag, TagDetails } from 'api/tagsApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { tagsQueryKeys } from 'containers/Tags/tags.utils';

/**
 * @workaround
 * Our Tag API doesn't retrieve the tag details when FE doesn't
 * provide TagType.
 *
 * This is a small hack where FE calls our API with all possible
 * tag types, other than Favrourites, as Favorites is handled separately
 */
export const useTagPageDetails = (
  tagId: number,
  options?: UseQueryOptions<TagDetails | null, BaseError>
) => {
  const { me } = useAuth();
  const { tenant } = useParsedHostname();
  return useQuery<TagDetails | null, BaseError>(
    tagsQueryKeys.detail(tagId),
    async () => {
      const tagTypes = me
        ? [TagType.Own, TagType.Following, TagType.Shared]
        : [TagType.Shared];
      let tagData: TagDetails | null = null;
      let error: BaseError | null = null;

      for (let i = 0; i < tagTypes.length; i++) {
        const tagType = tagTypes[i];
        try {
          tagData = (await getTag(tagId, tenant, tagType)).data;
          break;
        } catch (err) {
          error = deserializeAxiosError(err);
        }
      }

      if (!tagData) {
        throw error;
      }

      return tagData;
    },
    {
      ...QUERY_OPTIONS,
      ...options,
    }
  );
};
