import { AxiosResponse } from 'axios';

import {
  SourcesApi,
  SourceListItem,
  SourcesApiSourceListRequest,
  Configuration,
} from '@zarn/vendor/dist/search';

import { BASE_HEADERS, SERVICE_URL } from './apiConfig';
import AxiosInstance from './axiosInstance';

export const sourcesApi = new SourcesApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  AxiosInstance
);

export const getSourceList = (
  tenant: string
): Promise<AxiosResponse<SourceListItem[]>> => {
  const request: SourcesApiSourceListRequest = {
    tenant,
  };
  return sourcesApi.sourceList(request);
};
