import React, { FC, useCallback, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useParsedHostname } from 'common/utils/useParsedHostname';
import { addTag } from 'containers/Tags/ownTagsSlice/ownTags.slice';

import { useTagsAutocompleteFilterOptions } from '../hooks/useTagsAutocompleteFilterOptions';
import { useTagsAutocompleteOptions } from '../hooks/useTagsAutocompleteOptions';
import { useTagsAutocompleteOptionSelected } from '../hooks/useTagsAutocompleteOptionSelected';
import { useTagsAutocompleteRenderInput } from '../hooks/useTagsAutocompleteRenderInput';
import { useTagsAutocompleteRenderOption } from '../hooks/useTagsAutocompleteRenderOption';
import { NewTagsAutocompleteOptionValue } from '../models/NewTagsAutocompleteOption';
import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';

interface TagsAutocompleteSingleProps {
  autoFocus?: boolean;
  helperText?: string;
  label?: string;
  loading?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  name: string;
  onBlur?: () => void;
  onChange?: (value: TagsAutocompleteOptionValue[]) => void;
  tags: string[];
}

const TagsAutocompleteSingle: FC<TagsAutocompleteSingleProps> = ({
  autoFocus,
  helperText,
  label,
  loading,
  margin,
  name,
  onBlur = () => null,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('tags');
  const [field, meta, fieldHelpers] = useField<
    TagsAutocompleteOptionValue | string
  >(name);
  const hasError = !!(meta.touched && meta.error);
  const [creating, setCreating] = useState<boolean>(false);
  const { tenant } = useParsedHostname();
  const { options, ownTags } = useTagsAutocompleteOptions();

  const renderOption = useTagsAutocompleteRenderOption(ownTags);

  const filterOptions = useTagsAutocompleteFilterOptions();

  const getOptionSelected = useTagsAutocompleteOptionSelected();

  const renderInput = useTagsAutocompleteRenderInput({
    autoFocus,
    error: hasError,
    helperText: hasError ? meta.error : helperText,
    label,
    loading,
    margin,
  });

  const handleChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      newValue: string | TagsAutocompleteOptionValue | null
    ): Promise<void> => {
      if (newValue instanceof NewTagsAutocompleteOptionValue) {
        setCreating(true);

        onChange?.([newValue]);

        const resultAction = await dispatch(
          addTag({ payload: newValue, tenant })
        );

        setCreating(false);

        if (addTag.rejected.match(resultAction)) {
          enqueueSnackbar(resultAction.error.message, {
            variant: 'error',
          });

          return;
        }

        enqueueSnackbar(
          t('common:tagDocument.addSingle.successMessage', {
            tagName: newValue.name,
          })
        );

        await fieldHelpers.setValue(resultAction.payload);

        return;
      }

      await fieldHelpers.setValue(newValue ?? '');
    },
    [dispatch, enqueueSnackbar, fieldHelpers, onChange, t, tenant]
  );

  return (
    <Autocomplete
      data-testid="TagsAutocompleteSingle"
      filterOptions={filterOptions}
      getOptionLabel={(option: TagsAutocompleteOptionValue | string) =>
        typeof option === 'string' ? option : option.name ?? ''
      }
      isOptionEqualToValue={getOptionSelected}
      ListboxProps={{ 'aria-label': 'tagsAutocompleteList' }}
      loading={loading || creating}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      size="small"
      value={field.value}
      autoHighlight
      clearOnBlur
      filterSelectedOptions
      freeSolo
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};

TagsAutocompleteSingle.displayName = 'TagsAutocompleteSingle';

export default TagsAutocompleteSingle;
