import React, { FC, useState } from 'react';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Box, Dialog, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Evidence } from 'api/chatApi/chatApi.types';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import DocsCollection from 'containers/Docs/DocsCollection';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { EvidenceDoc } from './EvidenceItem/EvidenceDoc';

interface Props {
  answerContent: React.ReactNode;
  evidences: Evidence[];
  hits?: RetrievalUnitData[];
  organizeDocIds: string[];
}

export const EvidenceButtonWithDialogLayout: FC<Props> = ({
  answerContent,
  evidences,
  hits,
  organizeDocIds,
}) => {
  const { t } = useTranslation('common');

  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleEvidence = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  if (!hits || !hits.length) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '0.25rem',
        }}
      >
        <Tooltip title={t('chat.evidence.tooltip')}>
          <TrackedIconButton
            aria-label="show evidence"
            eventName={TrackEventName.ChatEvidenceClicked}
            size="small"
            onClick={toggleEvidence}
          >
            <LibraryBooksIcon />
          </TrackedIconButton>
        </Tooltip>
      </Box>
      <Dialog
        aria-label="Evidences for the current chat answer"
        maxWidth="md"
        open={dialogOpen}
        fullWidth
        onClose={handleClose}
      >
        <DialogActionTitle onClose={handleClose}>
          <Typography variant="body2">{answerContent}</Typography>
        </DialogActionTitle>

        <CollectionDialogContent dividers>
          <DocsCollection organizeDocIds={organizeDocIds}>
            {hits.map((doc, i) => (
              <EvidenceDoc doc={doc} evidences={evidences} i={i} key={i} />
            ))}
          </DocsCollection>
        </CollectionDialogContent>
      </Dialog>
    </>
  );
};
