import React from 'react';

import { Container, ContainerProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}));

type PageContainerPros = {} & ContainerProps;

export const PageContainer = ({ children, ...rest }: PageContainerPros) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="xl" {...rest}>
      {children}
    </Container>
  );
};
