import React, { createContext, FC, ReactNode, useState } from 'react';

import { ChatResponse } from 'api/chatApi/chatApi.types';
import { RetrievalUnitEnum } from 'common/enums';

export interface HomePageContextValue {
  chatResponse: ChatResponse | null;
  retrievalUnit: RetrievalUnitEnum | null;
  setChatResponse: (chatResponse: ChatResponse | null) => void;
  setRetrievalUnit: (retrievalUnit: RetrievalUnitEnum | null) => void;
}

export const HomePageContext = createContext<HomePageContextValue | null>(null);

export const HomePageContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [chatResponse, setChatResponse] = useState<ChatResponse | null>(null);
  const [retrievalUnit, setRetrievalUnit] = useState<RetrievalUnitEnum | null>(
    null
  );

  return (
    <HomePageContext.Provider
      value={{
        chatResponse,
        retrievalUnit,
        setChatResponse,
        setRetrievalUnit,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};
