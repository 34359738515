import React, { FC } from 'react';

import { Button, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import { useCustomSignIn } from 'common/hooks/useSignIn/useCustomSignIn';

export const MainToolbarCustomSignInButton: FC = () => {
  const { t } = useTranslation('common');
  const { handleSignIn } = useCustomSignIn();
  const theme = useTheme<AppTheme>();

  return (
    <Button
      sx={{ color: theme.palette.header.iconColor }}
      onClick={handleSignIn}
    >
      {t('buttons.signIn')}
    </Button>
  );
};
