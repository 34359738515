import { useEffect, useState } from 'react';

export const useSpotlightHighlighted = (
  shouldHighlight: boolean = false,
  highlightStopDelay: number = 1500
) => {
  const [highlighted, setHighlighted] = useState<boolean>(shouldHighlight);

  useEffect(() => {
    if (highlighted && shouldHighlight) {
      const timeout = setTimeout(() => {
        setHighlighted(false);
      }, highlightStopDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [highlightStopDelay, highlighted, shouldHighlight]);

  useEffect(() => {
    setHighlighted(shouldHighlight);
  }, [shouldHighlight]);

  return highlighted;
};
