import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { getUserSettings, updateUserSettings } from 'api/authApi';
import { deserializeAxiosError } from 'common/utils/error';
import { posthogPeopleSet } from 'common/utils/posthog.utils';

import { DocCardComposition } from './User.enum';
import { User } from './User.interface';
import {
  deserializeUserSettings,
  serializeUserSettingsPayload,
} from './user.utils';

export type UserState = {
  data: User | null;
  error: string | null;
  fetchState: FiniteStatesType;
  indexCluster: string | null | undefined;
};

export const initialState: UserState = {
  data: null,
  error: null,
  fetchState: FiniteStates.Idle,
  indexCluster:
    localStorage.getItem('indexCluster') === '' ||
    localStorage.getItem('indexCluster') === null
      ? undefined
      : localStorage.getItem('indexCluster'),
};

export const getUser = createAsyncThunk<User, string>(
  'user/get',
  async (tenant: string) => {
    try {
      const { data } = await getUserSettings(tenant);
      const deserializedUserSettings = deserializeUserSettings(data);

      posthogPeopleSet({
        affiliation: deserializedUserSettings.organization,
        cardStyle: deserializedUserSettings.cardStyle,
        email: deserializedUserSettings.email,
        feedbackButtons: deserializedUserSettings.feedbackButtons,
        firstName: deserializedUserSettings.firstName,
        indexCluster: deserializedUserSettings.indexCluster,
        lastName: deserializedUserSettings.lastName,
        onboarded: deserializedUserSettings.onboarded,
        recommendations: deserializedUserSettings.recommendations,
        useOpenAI: deserializedUserSettings.useOpenAI,
      });

      localStorage.setItem(
        'indexCluster',
        deserializedUserSettings.indexCluster ?? ''
      );

      return deserializedUserSettings;
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

type UpdateUserArgs = {
  cardStyle?: DocCardComposition;
  email?: string;
  feedbackButtons?: boolean;
  firstName?: string;
  lastName?: string;
  onboarded?: boolean;
  recommendations?: boolean;
  useOpenAI?: boolean;
};

export const updateUser = createAsyncThunk<
  User,
  UpdateUserArgs,
  { state: RootState }
>('user/update', async (payload: UpdateUserArgs, { getState }) => {
  try {
    const user = getState().user.data as User;
    const updatedUser = { ...user, ...payload };

    await updateUserSettings(serializeUserSettingsPayload(updatedUser));

    localStorage.setItem('indexCluster', updatedUser.indexCluster ?? '');

    return updatedUser;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
});

const user = createSlice({
  extraReducers: (builder) => {
    // Get user
    builder.addCase(getUser.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Unexpected error';
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.fetchState = FiniteStates.Idle;
      state.data = action.payload;
      state.indexCluster = action.payload.indexCluster;
      state.error = null;
    });
    // Update user
    builder.addCase(updateUser.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Unexpected error';
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.fetchState = FiniteStates.Success;
      state.data = action.payload;
      state.indexCluster = action.payload.indexCluster;
      state.error = null;
    });
  },
  initialState,
  name: 'user',
  reducers: {
    resetUserState: (state) => {
      state.fetchState = initialState.fetchState;
      state.data = initialState.data;
      state.error = initialState.error;
    },
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.data = action.payload;
      state.indexCluster = action.payload.indexCluster;
    },
  },
});

const userSelector = (state: RootState): UserState => state.user;

export const selectUser = createSelector(userSelector, ({ data }) => data);
export const selectIndexCluster = createSelector(
  userSelector,
  ({ indexCluster }) => indexCluster
);
export const selectUserSuccess = createSelector(
  userSelector,
  ({ fetchState }) => fetchState === FiniteStates.Success
);
export const selectUserLoading = createSelector(
  userSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);
export const selectUserError = createSelector(
  userSelector,
  ({ error, fetchState }) =>
    fetchState === FiniteStates.Failure ? error : null
);

export const { resetUserState, setState, setUser } = user.actions;

export default user.reducer;
