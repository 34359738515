import { ListNotesPayloadParams } from 'api/notesApi/notesApi.types';
import { RetrievalMethodEnum, SearchUrlParamsEnum } from 'common/enums';

export const docNotesQueryKeys = {
  all: ['docNotes'] as const,
  list: (docId: string) => [...docNotesQueryKeys.lists(), docId] as const,
  listWithParams: (docId: string, params: ListNotesPayloadParams) =>
    [...docNotesQueryKeys.list(docId), params] as const,
  lists: () => [...docNotesQueryKeys.all, 'list'] as const,
};

export const getAnnotationSearchQuery = (
  annotationContent: string,
  noteContent: string
): string => {
  if (!noteContent && !annotationContent) return '';

  const searchParams = new URLSearchParams();
  searchParams.set(
    SearchUrlParamsEnum.RetrievalMethod,
    RetrievalMethodEnum.Knn
  );
  searchParams.set(SearchUrlParamsEnum.DocTypes, 'document');
  searchParams.set(
    SearchUrlParamsEnum.Query,
    `${annotationContent}${noteContent}`.replace(/[()]/g, ' ')
  );

  return `?${searchParams}`;
};

export const getTextAnnotationContent = (
  annotationContentType?: 'text' | 'image',
  annotationContentBody?: string
): string => {
  return annotationContentType === 'text' && annotationContentBody
    ? `${annotationContentBody} `
    : '';
};
