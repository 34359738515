import { RetrievalUnitEnum } from 'common/enums';
import { useParser } from 'containers/Chat/hooks/useConversation/parsers/useParser';

export const useRetrievalUnitFromNote = (noteContent: string) => {
  const { parser } = useParser<{
    retrievalUnit: RetrievalUnitEnum;
  }>(noteContent);

  const getRetrievalUnitFromNote = async () => {
    const disassembleNote = await parser?.disassemble();
    return disassembleNote?.payload?.retrievalUnit;
  };

  return { getRetrievalUnitFromNote };
};
