import React, { FC } from 'react';

import {
  BotType,
  BotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';

import { OnboardingModeBotButton } from './OnboardingModeBotButton';
import { QuizbotModeBotButton } from './QuizbotModeBotButton';
import { SummaryModeBotButton } from './SummaryModeBotButton';

interface Props {
  botType: BotType;
  disabled?: boolean;
  onClick: (botMessage: BotTypeMessage) => void;
}

export const ModeBotButton: FC<Props> = ({ botType, ...props }) => {
  switch (botType) {
    case 'summary':
      return <SummaryModeBotButton {...props} />;
    case 'quizbot':
      return <QuizbotModeBotButton {...props} />;
    case 'chat_with_tag':
      return <OnboardingModeBotButton {...props} />;
    case 'chat_with_pdf':
    default:
      return null;
  }
};
