import { captureException } from '@sentry/react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';

import { createExplanation } from 'api/qaApi/qaApi';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { RetrievalUnitEnum, SearchEngineEnum } from 'common/enums';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { selectIndexCluster } from '../../User/user.slice';
import { explanationQueryKeys } from '../explainHighlight.utils';

export interface Explanation {
  evidences?: RetrievalUnitData[];
  explanation?: string;
  passage: string;
}

interface UseExplanationProps {
  docIdHitsMap: Record<string, RetrievalUnitData>;
  docsIds: string[];
  passage: string;
  retrievalUnit: RetrievalUnitEnum;
  searchEngine?: SearchEngineEnum;
}

const createAnswerContext = (
  searchEngine: SearchEngineEnum,
  docIds: string[],
  docIdHitsMap: Record<string, RetrievalUnitData>,
  retrievalUnit: RetrievalUnitEnum
) => {
  // TODO: I added this only to use the new payload, but the logic is probably wrong.
  // We want to send the federated document by content, but the similar documents by id
  if (searchEngine === SearchEngineEnum.ZetaAlpha) {
    return { ids: { docIds, retrievalUnit } };
  } else {
    const titleAbstractArray = Object.values(docIdHitsMap).map(
      ({ abstractContent, document, title }) => {
        return {
          content: `${title}. ${abstractContent}`,
          documentId: document.id,
        };
      }
    );
    return {
      content: titleAbstractArray,
    };
  }
};

export const useExplanation = ({
  docIdHitsMap,
  docsIds,
  passage,
  retrievalUnit,
  searchEngine = SearchEngineEnum.ZetaAlpha,
}: UseExplanationProps) => {
  const indexCluster = useSelector(selectIndexCluster);
  const parsedHostname = useParsedHostname();
  const queryClient = useQueryClient();

  const cacheKey = explanationQueryKeys.detail({
    docIds: docsIds,
    indexCluster,
    passage,
    retrievalUnit,
    searchEngine,
  });
  const existingData = queryClient.getQueryData(cacheKey);

  const answerContext = createAnswerContext(
    searchEngine,
    docsIds,
    docIdHitsMap,
    retrievalUnit
  );

  const options: UseQueryOptions<Explanation, BaseError> = {
    enabled: !!passage && docsIds.length > 0 && indexCluster !== null,
  };

  return useQuery<Explanation, BaseError>(
    cacheKey,
    async () => {
      try {
        const { data } = await createExplanation(
          {
            answerContext,
            passage,
          },
          parsedHostname.tenant,
          indexCluster as string | undefined
        );

        return {
          evidences:
            data.evidences &&
            data.evidences.reduce((acc, el) => {
              if (el.docId === undefined) {
                return acc;
              }
              const hit = docIdHitsMap[el.docId];
              if (hit === undefined) {
                return acc;
              }
              return [...acc, { ...hit, highlight: el.highlight }];
            }, [] as RetrievalUnitData[]),
          explanation: data.explanation,
          passage: data.passage,
        };
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      ...QUERY_OPTIONS,
      refetchOnMount: false,
      retry: 1,
      ...options,
      enabled:
        options?.enabled &&
        (existingData !== undefined || indexCluster === null
          ? false
          : options?.enabled),
    }
  );
};
