import { useEffect, useRef } from 'react';

export const useMounted = (initValue: boolean = true): boolean => {
  const isMounted = useRef<boolean>(initValue);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return isMounted.current;
};
