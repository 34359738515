import { UserPayload } from '@zarn/vendor/dist/auth';

import { AccessToken } from '../interfaces/AccessToken';

import { Me } from './Me.interface';
import { MeCustomModel } from './MeCustom.model';
import { MeOidcModel } from './MeOidc.model';

const isCustomAccessToken = (token: AccessToken): token is UserPayload =>
  'user_roles' in token && 'role_data' in token.user_roles;

export const createMe = (token: AccessToken): Me =>
  isCustomAccessToken(token)
    ? new MeCustomModel(token)
    : new MeOidcModel(token);
