import React from 'react';

import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';
import { useTranslation, Trans } from 'react-i18next';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataModifiedProps = {
  dateModified: string | null;
};

const TagMetadataModified = ({ dateModified }: TagMetadataModifiedProps) => {
  const { t } = useTranslation('tags');

  if (!dateModified) return null;

  return (
    <TagMetadataItem
      icon={EventIcon}
      translation={
        <Trans
          i18nKey="tagMetadata.dateModified.text"
          t={t}
          values={{
            dateModified: format(new Date(dateModified), 'dd MMM yyy'),
          }}
        >
          <strong />
        </Trans>
      }
    />
  );
};

TagMetadataModified.displayName = 'TagMetadataModified';

export default TagMetadataModified;
