import React, { FC } from 'react';

import { Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ChunkCard } from 'containers/Chat/DocsContext/ChunkCard';

export interface SingleEvidenceDialogProps {
  chunkId: string | null;
  closeDialog: () => void;
  dialogOpen: boolean;
}

export const SingleEvidenceDialog: FC<SingleEvidenceDialogProps> = ({
  chunkId,
  closeDialog,
  dialogOpen,
}) => {
  const { t } = useTranslation('chat');

  return (
    <Dialog open={dialogOpen} onClose={closeDialog}>
      <DialogContent>
        {chunkId ? (
          <ChunkCard chunkId={chunkId} />
        ) : (
          <Typography>{t('evidences.notFound')}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
