import { grey } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

export const WarningTooltip = withStyles({
  tooltip: {
    backgroundColor: alpha(grey[700], 0.9),
    color: 'yellow',
  },
})(Tooltip) as typeof Tooltip;
