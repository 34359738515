import { combineReducers } from 'redux';

import following from 'containers/Tags/followingTagsSlice/followingTags.slice';
import own from 'containers/Tags/ownTagsSlice/ownTags.slice';
import shared from 'containers/Tags/sharedTagsSlice/sharedTags.slice';

export default combineReducers({
  following,
  own,
  shared,
});
