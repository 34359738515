import React, { FC, useEffect, useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { selectUser } from 'containers/User/user.slice';

import { WizardSteps } from './WizardSteps/WizardSteps';

export const OnboardingWizard: FC = () => {
  const { t } = useTranslation('onboardingWizard');
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseWizard = () => {
    setOpen(false);
  };

  const handleDialogClose = (
    e: Event,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason !== 'backdropClick') {
      handleCloseWizard();
    }
  };

  useEffect(() => {
    if (user && !user.onboarded) {
      handleOpen();
    }
  }, [user]);

  return (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Onboarding wizard' }}
    >
      <Dialog
        aria-label={t('dialogTitle')}
        data-testid="OnboardingWizard"
        maxWidth="md"
        open={open}
        disableEscapeKeyDown
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogContent>
          <WizardSteps onClose={handleCloseWizard} />
        </DialogContent>
      </Dialog>
    </TrackedActionContext.Provider>
  );
};
