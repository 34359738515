import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

import { useNavigationWithState } from '../../../../hooks/useNavigationWithState';

interface Props {
  docId?: string | undefined;
  noteId: number;
  noteImage?: string;
  onOpenChatClick?: () => void;
}

export const useOpenPDFChat = ({
  docId,
  noteId,
  noteImage,
  onOpenChatClick,
}: Props) => {
  const navigation = useNavigationWithState();
  const { tenant } = useParsedHostname();
  const { setParams } = useQuerySearchParams();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    const pathname = `/pdf/${docId}`;
    navigation.push(`${pathname}?tab=chat&noteId=${noteId}`);
    setParams({
      ...(noteImage ? { explainText: noteImage } : {}),
    });
  });

  const openPDFChat = () => {
    onOpenChatClick?.();

    openChatNote(true);

    posthogPDFReader.chat(tenant, !!noteImage, !!noteId);
  };

  return { openPDFChat };
};
