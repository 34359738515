import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { TagDetails, tagDetailsToTagUpdatePayload } from 'api/tagsApi';
import { useTrackedAction } from 'common/components/TrackedActions/hooks/useTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useUpdateTagDetails } from 'containers/Tags/hooks/useUpdateTagDetails';
import { serializeTagShareSettingsFormValues } from 'containers/TagShare/TagsShareSettings/tagShareSettings.utils';

import { TagSettingsFormValues } from '../TagSettingsForm.interface';

export type UseTagSettingsFormProps = {
  onSubmitForm?: (values: TagSettingsFormValues) => void;
  tag: TagDetails;
};

export const useTagSettingsForm = ({
  onSubmitForm,
  tag,
}: UseTagSettingsFormProps) => {
  const { t } = useTranslation('tags');
  const { enqueueSnackbar } = useSnackbar();
  const { onAction } = useTrackedAction();
  const { mutateAsync: updateTagDetails } = useUpdateTagDetails();
  const { tenant } = useParsedHostname();
  const handleSubmit = async (
    values: TagSettingsFormValues,
    { setSubmitting }: FormikHelpers<TagSettingsFormValues>
  ) => {
    setSubmitting(true);

    await updateTagDetails(
      tagDetailsToTagUpdatePayload(
        {
          ...tag,
          color: values.color,
          disableRecommendations: !values.enableRecommendations,
          name: values.tagName,
          shareSettings: serializeTagShareSettingsFormValues(
            values.shareSettings
          ),
        },
        tenant
      ),
      {
        onError(error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });

          setSubmitting(false);
        },
        onSuccess() {
          enqueueSnackbar(t('tags:settings.successMessage'));

          if (values.shareSettings.isPublicTag) {
            onAction(TrackEventName.SetTagPublicAccess);
          }

          setSubmitting(false);

          onSubmitForm?.(values);
        },
      }
    );
  };

  return {
    onSubmit: handleSubmit,
  };
};
