import { chunk } from 'lodash';

import {
  BibTex,
  Configuration,
  ContentApiFactory,
  PersonApi,
  PersonApiAuthorGetRequest,
  QueryApiFactory,
} from '@zarn/vendor/dist/search';

import { deserializeSearchHit } from 'common/utils/documents';
import { ListExpertsPayload } from 'containers/ExpertSearch/Expert.interface';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';

import { serializeSearchPostPayload } from '../containers/Search/search.utils';

import { BASE_HEADERS, SERVICE_URL } from './apiConfig';
import axiosInstance from './axiosInstance';

export const parseQueryApi = QueryApiFactory(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const contentApi = ContentApiFactory(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const personEntityApi = new PersonApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const parseQuery = (queryString: string, tenant: string) => {
  return parseQueryApi.parseGet('', tenant, queryString);
};

export const getPersonEntity = (uid: string, tenant: string) => {
  const payload: PersonApiAuthorGetRequest = {
    authorId: uid,
    tenant,
  };

  return personEntityApi.authorGet(payload);
};

export const makeExpertSearchPost = (
  searchPayload: SearchPayload,
  page: number = 1
) => {
  const request = serializeSearchPostPayload({ ...searchPayload, page: page });
  return personEntityApi.personSearchPost(request);
};

export const listExperts = (payload: ListExpertsPayload) => {
  // MOCK
  return personEntityApi.authorsGet({
    authorIds: payload.ids ?? [],
    page: payload.page,
    pageSize: payload.pageSize,
    tenant: payload.tenant,
  });
};

export const getContentEntities = async (
  ids: string[],
  tenant: string,
  indexCluster?: string
): Promise<RetrievalUnitData[]> => {
  const responses = await Promise.allSettled(
    ids.map((id) => contentApi.paperGet(id, '', tenant, indexCluster))
  );

  return responses.reduce((acc, cur) => {
    if (cur.status === 'rejected') return acc;

    return [...acc, deserializeSearchHit(cur.value.data)];
  }, [] as RetrievalUnitData[]);
};

export const getBibtexList = async (docIds: string[], tenant: string) => {
  // Workaround
  const filteredIds = docIds.filter((id) => !!id);
  const chunkedDocIds = filteredIds ? chunk(filteredIds, 100) : [[]];
  const result: BibTex[] = [];
  for (const ids of chunkedDocIds) {
    const { data } = await contentApi.bibtexsGet(
      [ids.join(',')],
      '',
      '',
      tenant
    );
    result.push(...data);
  }
  return { data: result };
};
