import React, { useState } from 'react';

import { useInViewEffect } from 'react-hook-inview';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import DocTitleLink from 'common/components/Docs/DocTitleLink';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { SearchEngineEnum } from 'common/enums';
import { useIsMounted } from 'common/hooks/useIsMounted/useIsMounted';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { DocPDFViewerDialog } from 'containers/DocPDFViewerDialog';
import { FeedbackType } from 'containers/Feedback/enums';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';

import { RetrievalUnitData } from '../RetrievalUnitData.interface';

export type DocTitleWithExternalPDFProps = {
  docData: RetrievalUnitData;
  id: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  title: string;
};

const DocTitleWithExternalPDF = React.memo(
  ({
    docData,
    id,
    onAction,
    onClick,
    resultType,
    searchEngine,
    searchId,
    title,
  }: WithTrackedActionWrappedProps<DocTitleWithExternalPDFProps>) => {
    const { tenant } = useParsedHostname();
    const { sendFeedback } = useSendFeedback();

    const docId = docData.document.id;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [hasAccessToPDF, setHasAccessToPDF] = useState<boolean>(false);
    const pdfUri = docData.externalPublicAsset?.assetValue;
    const isMounted = useIsMounted();

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      setDialogOpen(true);
      posthogPDFReader.open(tenant, docId);
      if (searchId && resultType) {
        sendFeedback({
          feedbackType: FeedbackType.Click,
          resultId: id,
          resultType,
          searchId,
        });
      }
      onAction();
      if (!onClick) return;

      onClick(event);
    };

    const handleCloseDialog = () => {
      setDialogOpen(false);
    };

    const ref = useInViewEffect(
      ([entry], observer) => {
        if (!pdfUri) return;

        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
        }

        const controller = new AbortController();

        // Check whether we have an access to the PDF due to CORS issues
        // If not, then hide the PDFViewer button
        fetch(pdfUri, { method: 'GET', signal: controller.signal })
          .then(() => {
            controller.abort();
            isMounted.current && setHasAccessToPDF(true);
          })
          .catch(() => isMounted.current && setHasAccessToPDF(false));
      },
      { threshold: 0 },
      [pdfUri, isMounted]
    );

    if (!hasAccessToPDF)
      return (
        <span ref={ref}>
          <DocTitle
            id={docData.document.id}
            resultType={resultType}
            searchId={searchId}
            title={docData.title}
            uri={docData.uri}
          />
        </span>
      );

    return (
      <>
        <DocTitleLink
          data-testid="retrievalUnitTitleWithExternalPDFLink"
          docId={id}
          docTitle={title}
          linkType="pdf"
          role="button"
          onClick={handleClick}
        />
        <DocPDFViewerDialog
          docData={docData}
          docId={docId}
          open={dialogOpen}
          searchEngine={searchEngine}
          onClose={handleCloseDialog}
        />
      </>
    );
  }
);

DocTitleWithExternalPDF.displayName = 'DocTitleWithExternalPDF';

export default withTrackedAction<
  DocTitleWithExternalPDFProps & WithTrackedActionProps
>(DocTitleWithExternalPDF, TrackEventName.DocumentTitleWithPDFClicked);
