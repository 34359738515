import React, { FC, ReactNode } from 'react';

import { Theme, useTheme } from '@mui/material';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import OpenAiIcon from 'common/components/CustomIcons/OpenAiIcon';

export interface AvatarProps {
  content?: ReactNode;
  isContent?: boolean;
}

export const Avatar: FC<AvatarProps> = ({ content, isContent }) => {
  const { palette } = useTheme<Theme>();

  return (
    <StyledAvatar size="small" sx={{ backgroundColor: palette.secondary.main }}>
      {isContent ? (
        content
      ) : (
        <OpenAiIcon sx={{ fill: palette.neutral.light }} />
      )}
    </StyledAvatar>
  );
};
