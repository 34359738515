import { HitItem } from 'common/interfaces/HitItem';

import { ListDocumentsPropertyName } from './searchApi.types';

export const normalizeListDocPropertyName = (
  propertyName: ListDocumentsPropertyName
) => {
  switch (propertyName) {
    case 'uid':
    case 'guid':
    case 'organize_doc_id':
      return propertyName;
    case 'id':
    default:
      return '_id';
  }
};

export const sortListDocHitsByPropertyName = <T extends HitItem>(
  hits: T[],
  docIds: string[],
  propertyName: ListDocumentsPropertyName
): T[] => {
  const idKey = normalizeListDocPropertyName(propertyName);
  const sortedHits = docIds.reduce((acc, cur) => {
    const hit = hits.find(
      // @ts-ignore
      (h) => (h[idKey] ?? h.custom_metadata?.[idKey]) === cur
    );

    if (hit) {
      acc.push(hit);
    }
    return acc;
  }, [] as T[]);

  return sortedHits;
};
