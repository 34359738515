import React, { useMemo } from 'react';

import { RadioGroup } from '@mui/material';

import ColorPickerRadio from 'common/components/Fields/ColorPickerRadio/ColorPickerRadio';
import ColorRadio from 'common/components/Fields/ColorRadio/ColorRadio';

import { PaletteColorOption } from './ColorPalette.interface';

export type ColorPaletteFieldProps = {
  colorPalette: PaletteColorOption[];
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export const ColorPaletteField = ({
  colorPalette,
  ...field
}: ColorPaletteFieldProps) => {
  const initialCustomColor = useMemo(() => {
    const isPredefinedColor = colorPalette.some(
      ({ value }) => value === field.value
    );
    return !isPredefinedColor ? field.value : undefined;
  }, []);

  return (
    <RadioGroup aria-label="tag color" row {...field}>
      {colorPalette.map(({ color, value }) => (
        <ColorRadio
          key={color}
          radioColor={color}
          title={color}
          value={value}
        />
      ))}

      <ColorPickerRadio initialColor={initialCustomColor} {...field} />
    </RadioGroup>
  );
};
