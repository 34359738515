import posthog from 'posthog-js';

import {
  getAccessTokenFromStore,
  getRefreshTokenFromStore,
} from 'common/utils/store';
import { removeTokensFromStore } from 'containers/Auth/Auth.utils';

export const useLogout = () => {
  return (): void => {
    if (!getRefreshTokenFromStore() && !getAccessTokenFromStore()) {
      return;
    }
    removeTokensFromStore();
    posthog.reset(true);
  };
};
