import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  children: React.ReactNode;
  title: string;
  to: string;
};

const NavButtonLink = ({
  children,
  onAction,
  title,
  to,
}: WithTrackedActionWrappedProps<Props>) => {
  return (
    <Tooltip aria-label={title} title={title}>
      <IconButton component={NavLink} size="large" to={to} onClick={onAction}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(NavButtonLink);
