import {
  GroupedHighlightsByPage,
  IntermediateHighlightByPage,
  Highlight,
  HighlightRect,
  SelectedPage,
} from '../types';

export const getDocument = (elm: any): Document =>
  (elm || {}).ownerDocument || document;

export const getWindow = (elm: any): typeof window =>
  (getDocument(elm) || {}).defaultView || window;

export const isHTMLElement = (elm: any) =>
  elm instanceof HTMLElement || elm instanceof getWindow(elm).HTMLElement;

export const isHTMLCanvasElement = (elm: any) =>
  elm instanceof HTMLCanvasElement ||
  elm instanceof getWindow(elm).HTMLCanvasElement;

export const asElement = (x: any): HTMLElement => x;

export const getPageFromElement = (
  target: HTMLElement
): SelectedPage | null => {
  const node = asElement(target.closest('.react-pdf__Page'));

  if (!node || !isHTMLElement(node)) {
    return null;
  }

  const pageNumber = Number(asElement(node).dataset.pageNumber);

  return { node, pageNumber } as SelectedPage;
};

export const getPagesFromRange = (range: Range): SelectedPage[] => {
  const startParentElement = range.startContainer.parentElement;
  const endParentElement = range.endContainer.parentElement;

  if (!isHTMLElement(startParentElement) || !isHTMLElement(endParentElement)) {
    return [] as SelectedPage[];
  }

  const startPage = getPageFromElement(asElement(startParentElement));
  const endPage = getPageFromElement(asElement(endParentElement));

  if (!startPage?.pageNumber || !endPage?.pageNumber) {
    return [] as SelectedPage[];
  }

  if (startPage.pageNumber === endPage.pageNumber) {
    return [startPage] as SelectedPage[];
  }

  if (startPage.pageNumber === endPage.pageNumber - 1) {
    return [startPage, endPage] as SelectedPage[];
  }

  const pages: SelectedPage[] = [];

  let currentPageNumber = startPage.pageNumber;

  const document = startPage.node.ownerDocument;

  while (currentPageNumber <= endPage.pageNumber) {
    const currentPage = getPageFromElement(
      document.querySelector(
        `[data-page-number='${currentPageNumber}'`
      ) as HTMLElement
    );
    if (currentPage) {
      pages.push(currentPage);
    }
  }

  return pages as SelectedPage[];
};

export const findOrCreateContainerLayer = (
  container: HTMLElement,
  className: string
) => {
  const doc = getDocument(container);
  let layer = container.querySelector(`.${className}`);

  if (!layer) {
    layer = doc.createElement('div');
    layer.className = className;
    container.appendChild(layer);
  }

  return layer;
};

export const getHighlightAbsolutePosition = ({
  pageNumber,
  pageHeight,
  y1,
}: {
  pageNumber: number;
  pageHeight: number;
  y1: number;
}): number => {
  return parseInt(((pageNumber - 1) * pageHeight + y1).toFixed());
};

export const groupRectsByPage = (
  rects: HighlightRect[]
): { [page: number]: HighlightRect[] } => {
  return rects.reduce((acc, cur) => {
    if (acc[cur.pageNumber]) {
      acc[cur.pageNumber].push(cur);

      return acc;
    }

    return {
      ...acc,
      [cur.pageNumber]: [cur],
    };
  }, {} as { [page: number]: HighlightRect[] });
};

export const groupIntermediateHighlightByPage = (
  highlight: Highlight
): IntermediateHighlightByPage => {
  const groupedRects = groupRectsByPage(highlight.rects);

  const group: IntermediateHighlightByPage = {};

  for (const key in groupedRects) {
    group[key] = {
      ...highlight,
      rects: groupedRects[key],
    };
  }

  return group;
};

export const groupHighlightsByPage = (
  highlights: Array<Highlight>
): GroupedHighlightsByPage => {
  return highlights.reduce((acc, cur) => {
    const groupedRects = groupRectsByPage(cur.rects);

    for (const key in groupedRects) {
      if (key in acc) {
        acc[key].push({
          ...cur,
          rects: groupedRects[key],
        });
      } else {
        acc[key] = [
          {
            ...cur,
            rects: groupedRects[key],
          },
        ];
      }
    }

    return acc;
  }, {} as GroupedHighlightsByPage);
};
