import React from 'react';
import { Theme, darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { DEFAULT_HIGHLIGHT_COLOR } from '../../../const/defaults.const';

export const useStyles = makeStyles<Theme, { color: string }>(() =>
  createStyles({
    root: {
      mixBlendMode: 'multiply',
      '&:hover div': {
        backgroundColor: ({ color }) => darken(color, 0.05),
      },
    },
  })
);

export type HighlightRectsWrapperProps = {
  children: React.ReactNode;
  highlightColor?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const HighlightRectsWrapper = ({
  children,
  highlightColor = DEFAULT_HIGHLIGHT_COLOR,
  ...rest
}: HighlightRectsWrapperProps) => {
  const classes = useStyles({ color: highlightColor });

  return (
    <div className={classes.root} {...rest}>
      {children}
    </div>
  );
};

export default HighlightRectsWrapper;
