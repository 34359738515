import React from 'react';

import { IconButton, IconButtonProps } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { WithTrackedActionProps } from 'common/components/TrackedActions/withTrackedAction';

import TrackedIconButton from '../TrackedIconButton/TrackedIconButton';

type Props = {
  children: React.ReactNode;
  href?: string;
  to?: string;
} & IconButtonProps &
  WithTrackedActionProps;

export default function IconButtonLink({ children, href, to, ...rest }: Props) {
  if (href) {
    return (
      <Link href={href} rel="noreferrer" target="_blank" title={href}>
        <TrackedIconButton color="secondary" {...rest}>
          {children}
        </TrackedIconButton>
      </Link>
    );
  }

  if (to) {
    return (
      <RouterLink title={to} to={to}>
        <TrackedIconButton {...rest}>{children}</TrackedIconButton>
      </RouterLink>
    );
  }

  return (
    <IconButton size="large" disabled>
      {children}
    </IconButton>
  );
}
