import { useEffect, useState } from 'react';

export const LS_SIDEBAR_MINIFIED = 'mainSidebarMinified';

export const setPageDrawerMinifiedToLS = (state: boolean): void => {
  if (state) {
    localStorage.setItem(LS_SIDEBAR_MINIFIED, String(state));
  } else {
    localStorage.removeItem(LS_SIDEBAR_MINIFIED);
  }
};

const getPageDrawerMinifiedFromLS = (): boolean => {
  return !!localStorage.getItem(LS_SIDEBAR_MINIFIED);
};

export interface UsePageDrawerMinifiedReturn {
  pageDrawerMinified: boolean;
  setPageDrawerMinified: (state: boolean) => void;
}

export const usePageDrawerMinified = (): UsePageDrawerMinifiedReturn => {
  const [pageDrawerMinified, setPageDrawerMinified] = useState(
    getPageDrawerMinifiedFromLS
  );

  useEffect(() => {
    setPageDrawerMinifiedToLS(pageDrawerMinified);
  }, [pageDrawerMinified]);

  return {
    pageDrawerMinified,
    setPageDrawerMinified,
  };
};
