import React, { FC, useMemo } from 'react';

import { CircularProgress } from '@mui/material';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { ChatMessageElement } from 'api/chatApi/chatApi.types';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { Optional } from 'common/utils/assert';

import { DynamicContextButtonWithDialog } from '../Conversation/DynamicContext/DynamicContextButtonWithDialog';
import { Parser } from '../hooks/useConversation/parsers/Parser';
import { Message } from '../Message';

import { LoadingInfo } from './LoadingInfo';

interface Props {
  message: Optional<ChatMessageElement>;
}

export const LoadingMessage: FC<Props> = ({ message }) => {
  const content = useMemo(
    () => (message ? Parser.prepareMessage(message) : ''),
    [message]
  );

  if (message?.context?.loading) {
    return (
      <Message
        isContent={false}
        sender={ChatMessageSenderEnum.Bot}
        topExtra={<LoadingInfo />}
      >
        {''}
      </Message>
    );
  }

  return (
    <Message
      isContent={true}
      sender={ChatMessageSenderEnum.Bot}
      topExtra={
        message?.context?.docsIds ? (
          <DynamicContextButtonWithDialog docsIds={message.context.docsIds} />
        ) : null
      }
    >
      <MarkdownText>{content}</MarkdownText>
      <div>
        <CircularProgress
          color="secondary"
          data-testid="loading-icon"
          size={18}
        />
      </div>
    </Message>
  );
};
