import React, { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import parse from 'html-react-parser';

export interface HtmlContentProps extends TypographyProps {
  content: string;
}

export const HtmlContent: FC<HtmlContentProps> = ({ content, ...props }) => {
  const html = useMemo(() => parse(content), [content]);

  return (
    <Typography data-testid="html" {...props}>
      {html}
    </Typography>
  );
};
