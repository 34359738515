import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    fontSize: theme.typography.body1.fontSize,
    height: 34,
    width: 34,
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  profileListItem: {
    backgroundColor: theme.palette.grey[200],
  },
  profilePaper: {
    minWidth: 240,
  },
}));
