import {
  Configuration,
  UserDocumentBatchesApi,
  UserDocumentsBatchForm,
} from '@zarn/vendor/dist/user-documents';

import axiosInstance from 'api/axiosInstance';
import { PrivateDocAccessRoleEnum } from 'common/enums';
import { blobToBase64 } from 'common/utils/fileHelpers';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';

import { PrivateDocBatchCreatePayload } from './privateDocsApi.types';

export const userDocumentBatchesApi = new UserDocumentBatchesApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const userDocumentBatchCreate = async ({
  batchName,
  files,
  indexId,
  tenant,
}: PrivateDocBatchCreatePayload) => {
  const binary: string = await blobToBase64(files[0]);

  const userDocumentsBatchForm: UserDocumentsBatchForm = {
    access_roles: [PrivateDocAccessRoleEnum.Own],
    base64_content: binary,
    file_name: files[0].name,
    name: batchName,
  };

  return userDocumentBatchesApi.createUserDocumentBatch({
    indexId,
    requesterUuid: '',
    tenant,
    userDocumentsBatchForm,
    userRoles: '',
    userTenants: '',
  });
};

export const userDocumentBatchListDocs = (
  tenant: string,
  documentBatchId: string,
  page?: number,
  pageSize?: number,
  indexId?: string
) => {
  return userDocumentBatchesApi.filterBatchUserDocuments({
    documentBatchId,
    indexId,
    page,
    pageSize,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userTenants: '',
  });
};
