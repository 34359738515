import { useCallback, useReducer } from 'react';

import { captureException } from '@sentry/react';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';

import { postFeedback } from 'api/feedbackApi/feedbackApi';
import { deserializeAxiosError } from 'common/utils/error';
import { parseHostname } from 'common/utils/useParsedHostname';
import { SendFeedbackPayload } from 'containers/Feedback/Feedback.interface';
import { serializeFeedbackPayload } from 'containers/Feedback/feedback.utils';

type SendFeedbackState = {
  error: string | null;
  fetchState: FiniteStatesType;
};

type SendFeedbackAction =
  | { type: 'success' }
  | { payload: string; type: 'failure' };

export const sendFeedbackInitialState: SendFeedbackState = {
  error: null,
  fetchState: FiniteStates.Idle,
};

const sendFeedbackReducer = (
  state: SendFeedbackState,
  action: SendFeedbackAction
): SendFeedbackState => {
  switch (action.type) {
    case 'success':
      return {
        error: null,
        fetchState: FiniteStates.Success,
      };
    case 'failure':
      return {
        ...state,
        error: action.payload,
        fetchState: FiniteStates.Failure,
      };
    default:
      throw new Error();
  }
};

export type UseSendFeedbackReturn = {
  sendFeedback: (payload: SendFeedbackPayload) => Promise<void>;
  state: SendFeedbackState;
};

export const useSendFeedback = (): UseSendFeedbackReturn => {
  const [state, dispatch] = useReducer(
    sendFeedbackReducer,
    sendFeedbackInitialState
  );
  const { tenant } = parseHostname();

  const sendFeedback = useCallback(
    async (payload: SendFeedbackPayload) => {
      try {
        await postFeedback(tenant, serializeFeedbackPayload(payload));
        dispatch({ type: 'success' });
      } catch (error) {
        captureException(error);
        dispatch({
          payload: deserializeAxiosError(error).message,
          type: 'failure',
        });
      }
    },
    [tenant]
  );

  return {
    sendFeedback,
    state,
  };
};
