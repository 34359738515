import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      marginTop: theme.spacing(2),
      color: 'white',
    },
    document: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    mobileDevice: {
      touchAction: 'pan-x pan-y',
    },
    loadProgress: {
      position: 'absolute',
      top: 48,
      left: 0,
      width: '100%',
      height: 8,
    },
  })
);
