import { useRef } from 'react';

import { captureException } from '@sentry/react';

import { TENANT } from 'common/constants';

export type ParsedHostname = {
  tenant: string;
};

const parseHostnameHelper: () => ParsedHostname = () => {
  const hostname = window?.location?.host;
  if (hostname !== undefined) {
    const parts = hostname.split('.')[0].split('-search');
    if (parts.length >= 2) {
      return {
        tenant: parts[0],
      };
    }
  }
  return { tenant: TENANT };
};

export const parseHostname = (): ParsedHostname => {
  try {
    const storedParsedHostname = localStorage.getItem('parsedHostname');

    if (storedParsedHostname === null) {
      const parsedHostname = parseHostnameHelper();
      localStorage.setItem('parsedHostname', JSON.stringify(parsedHostname));
      return parsedHostname;
    }
    return JSON.parse(storedParsedHostname);
  } catch (error) {
    captureException(error);
    return {
      tenant: TENANT,
    };
  }
};

export const useParsedHostname = (): ParsedHostname => {
  const parsedHostname = useRef(parseHostname());

  return parsedHostname.current;
};
