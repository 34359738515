import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { HIGHLIGHT, SEARCH_INPUT_BORDER_RADIUS } from '../../../app/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  resetButton: {
    '&:hover': {
      borderColor: theme.palette.common.white,
      color: HIGHLIGHT,
    },
    borderRadius: 0,
    color: theme.palette.primary.main,
    height: 40,
    paddingRight: theme.spacing(0.5),
    width: 40,
  },
  saveQuery: {
    marginLeft: 0,
  },
  search: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      marginLeft: theme.spacing(6),
    },
  },
  searchIcon: {
    '&:hover': {
      borderColor: theme.palette.common.white,
      color: HIGHLIGHT,
    },
    borderRadius: `0 ${SEARCH_INPUT_BORDER_RADIUS}px ${SEARCH_INPUT_BORDER_RADIUS}px 0`,
    color: theme.palette.primary.main,
    height: 40,
    width: 40,
  },
  searchInput: {
    '&::placeholder': {
      opacity: 0.8,
    },
    borderRadius: `${SEARCH_INPUT_BORDER_RADIUS}px 0 0 ${SEARCH_INPUT_BORDER_RADIUS}px`,
    color: theme.palette.primary.main,
    height: theme.typography.fontSize * 1.7,
    padding: theme.spacing(1, 1, 1, 1.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '&:focus,&:active': {
        width: 'calc(100vw - 240px)',
      },
    },
  },
  searchInputRoot: {
    borderRadius: SEARCH_INPUT_BORDER_RADIUS,
    width: '100%',
  },
}));
