import React, { FC } from 'react';

import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EmptyState from 'common/components/EmptyState';
import { PageDrawerMenuItem } from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { useFilterTagsByName } from 'common/hooks/useFilterTagsByName';
import { selectFollowingTags } from 'containers/Tags/followingTagsSlice/followingTags.slice';
import {
  selectSharedTags,
  selectSharedTagsLoading,
} from 'containers/Tags/sharedTagsSlice/sharedTags.slice';
import { tagIsFollowed } from 'containers/Tags/tags.utils';
import TagItemTitle from 'containers/Tags/TagsList/TagItemTitle';

import { TagsListSkeleton } from '../TagsListSkeleton';

export const useStyles = makeStyles(() => ({
  container: {
    '& a': {
      paddingBottom: 0,
      paddingTop: 0,
    },
    padding: 0,
  },
}));

interface Props {
  filteredTagName: string;
}

export const SharedTagsList: FC<Props> = ({ filteredTagName }) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const followingTags = useSelector(selectFollowingTags);
  const sharedTags = useSelector(selectSharedTags);
  const sharedTagsLoading = useSelector(selectSharedTagsLoading);
  const filteredTags = useFilterTagsByName(filteredTagName, sharedTags);

  if (sharedTagsLoading && sharedTags.length === 0) {
    return <TagsListSkeleton />;
  }

  if (sharedTags.length > 0) {
    return (
      <List
        aria-label={t('sharedTags.list.ariaLabel')}
        role="menubar"
        disablePadding
      >
        {filteredTags.map((tag) => (
          <PageDrawerMenuItem
            className={classes.container}
            key={tag.id}
            title={
              <TagItemTitle
                following={tagIsFollowed(followingTags, tag.id)}
                tag={tag}
              />
            }
            to={`/tags/${tag.id}`}
          />
        ))}
      </List>
    );
  }

  return (
    <EmptyState
      data-testid="sharedTagsEmptyState"
      subtitle={t('sharedTags.emptyState.subTitle')}
      title={t('sharedTags.emptyState.title')}
    />
  );
};
