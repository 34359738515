import {
  BestAnswerForm,
  BestAnswerItem,
  ExplainForm,
  ExplainItem,
  KAnswersForm,
  KAnswersItem,
} from '@zarn/vendor/dist/search';

import {
  BestAnswerRequest,
  BestAnswerResponse,
  ExplanationRequest,
  ExplanationResponse,
  KAnswerRequest,
  KAnswerResponse,
} from './qaApi.types';

export const serializeBestAnswerForm = ({
  answerContext,
  question,
  reforumlateQuestion,
}: BestAnswerRequest): BestAnswerForm => ({
  context: {
    ...(answerContext.content
      ? {
          from_document_content: answerContext.content.map((item) => {
            return {
              content: item.content,
              document_id: item.documentId,
            };
          }),
        }
      : {}),
    ...(answerContext.ids
      ? {
          from_document_ids: {
            document_ids: answerContext.ids.docIds,
            retrieval_unit: answerContext.ids.retrievalUnit,
          },
        }
      : {}),
  },
  question,
  reformulate_question: reforumlateQuestion,
});

export const deserializeBestAnswerItem = (
  response: BestAnswerItem
): BestAnswerResponse => {
  return {
    answer: response.answer,
    evidences:
      response.evidences === undefined
        ? []
        : response.evidences?.map((evidence) => {
            return {
              docId: evidence.document_id,
              highlight: evidence.text_extract,
            };
          }),
    explanation: response.explanation,
    originalQuestion: response.original_question,
    question: response.question,
  };
};

export const serializeKAnswersForm = ({
  answerContext,
  question,
  reforumlateQuestion,
}: KAnswerRequest): KAnswersForm => {
  return {
    context: {
      ...(answerContext.content
        ? {
            from_document_content: {
              content: answerContext.content?.content,
              document_id: answerContext.content?.documentId,
            },
          }
        : {}),
      ...(answerContext.id
        ? {
            from_document_id: {
              document_id: answerContext.id.docId,
              retrieval_unit: answerContext.id.retrievalUnit,
            },
          }
        : {}),
    },
    question,
    reformulate_question: reforumlateQuestion,
  };
};

export const deserializeKAnswerItem = (
  response: KAnswersItem
): KAnswerResponse => {
  return {
    answer: response.answer,
    evidences:
      response.evidences === undefined
        ? []
        : response.evidences?.map((evidence) => {
            return {
              docId: evidence.document_id,
              highlight: evidence.text_extract,
            };
          }),
    originalQuestion: response.original_question,
    question: response.question,
  };
};

export const serializeExplanationForm = ({
  answerContext,
  passage,
}: ExplanationRequest): ExplainForm => ({
  context: {
    ...(answerContext.content
      ? {
          from_document_content: answerContext.content.map((item) => {
            return {
              content: item.content,
              document_id: item.documentId,
            };
          }),
        }
      : {}),
    ...(answerContext.ids
      ? {
          from_document_ids: {
            document_ids: answerContext.ids.docIds,
            retrieval_unit: answerContext.ids.retrievalUnit,
          },
        }
      : {}),
  },
  passage,
});

export const deserializeExplanationItem = (
  response: ExplainItem
): ExplanationResponse => {
  return {
    evidences:
      response.evidences === undefined
        ? []
        : response.evidences?.map((evidence) => {
            return {
              docId: evidence.document_id,
              highlight: evidence.text_extract,
            };
          }),
    explanation: response.explanation,
    passage: response.passage,
  };
};
