import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const svgIconOverrides: ComponentsOverrides<Theme>['MuiSvgIcon'] = {
  fontSizeSmall: ({ theme }) => ({
    fontSize: theme.typography.fontSize,
  }),
  root: ({ theme }) => ({
    fontSize: theme.typography.fontSize * 1.4,
  }),
};
