import {
  indigo,
  green,
  yellow,
  red,
  pink,
  purple,
  teal,
  cyan,
  lightBlue,
  lime,
  lightGreen,
  amber,
  brown,
  blueGrey,
} from '@mui/material/colors';
import { HIGHLIGHT, SECONDARY } from 'app/theme';

export const GRAPH_COLORS = [
  SECONDARY,
  HIGHLIGHT,
  indigo[600],
  green[600],
  yellow[600],
  red[600],
  pink[600],
  purple[600],
  teal[600],
  cyan[600],
  lightBlue[600],
  lime[600],
  lightGreen[600],
  amber[600],
  brown[600],
  blueGrey[600],
];
