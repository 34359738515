import { useCallback } from 'react';

const THRESHOLD_GAP = 20;

export const useSelectionHorizonalScroll = (el: HTMLElement | null) => {
  return useCallback(
    (leftY: number, rightY: number) => {
      if (!el) return;

      const { left } = el.getBoundingClientRect();
      const rightThreshold = el.clientWidth + left - THRESHOLD_GAP;
      const leftThreshold = left + THRESHOLD_GAP;

      if (rightY >= rightThreshold) {
        el.scrollTo({
          left: el.scrollLeft + (rightY - rightThreshold),
        });
      }

      if (leftY <= leftThreshold) {
        el.scrollTo({
          left: el.scrollLeft - (leftThreshold - leftY),
        });
      }
    },
    [el]
  );
};
