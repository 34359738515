import React from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { selectUser } from 'containers/User/user.slice';

type DocOwnerProps = {};

const DocOwner = (_: DocOwnerProps) => {
  const { t } = useTranslation('common');
  const user = useSelector(selectUser);

  if (!user) {
    return null;
  }

  return (
    <Tooltip
      title={t<string>('retrievalUnit.owner.tooltip', {
        owner: `${user.firstName} ${user.lastName}`,
      })}
    >
      <StyledAvatar color="secondary" size="small">
        {user.initials}
      </StyledAvatar>
    </Tooltip>
  );
};

DocOwner.displayName = 'DocOwner';

export default DocOwner;
