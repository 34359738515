import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const cardOverrides: ComponentsOverrides<Theme>['MuiCard'] = {
  root: {
    width: 'inherit',
  },
};

export const cardActionsOverrides: ComponentsOverrides<Theme>['MuiCardActions'] =
  {
    root: ({ theme }) => ({
      padding: theme.spacing(1, 2),
    }),
  };

export const cardContentOverrides: ComponentsOverrides<Theme>['MuiCardContent'] =
  {
    root: ({ theme }) => ({
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    }),
  };
