import React from 'react';

import { MenuItem } from '@mui/material';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  children: React.ReactNode;
  component?: React.ElementType;
  href?: string | undefined;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  rel?: string;
  target?: string;
};

const TrackedMenuItem = ({
  children,
  component = 'a',
  href,
  onAction,
  onClick,
  rel,
  target,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <MenuItem
      component={component}
      href={href}
      rel={rel}
      target={target}
      {...rest}
      onClick={handleClick}
    >
      {children}
    </MenuItem>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedMenuItem
);
