import React, { useEffect, useState } from 'react';
import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import ArrowUpIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardRounded';

import { useStyles } from './style';

export type PageControlProps = {
  activePage: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
};

const PageControl = React.memo(
  ({ onPageChange, numberOfPages, activePage }: PageControlProps) => {
    const classes = useStyles({ numberOfPages });
    const [value, setValue] = useState<number | ''>(activePage);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value ? Number(e.currentTarget.value) : '');
    };

    useEffect(() => {
      setValue(activePage);
    }, [activePage]);

    const handlePageChange = () => {
      const val = Number(value || 1);

      setValue(val);
      onPageChange(val);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code !== 'Enter') return;

      handlePageChange();
    };

    const handlePrevPage = () => {
      onPageChange(activePage - 1);
    };

    const handleNextPage = () => {
      onPageChange(activePage + 1);
    };

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Hidden mdDown>
          <IconButton
            onClick={handlePrevPage}
            color="inherit"
            aria-label="Prev page"
            size="large"
          >
            <ArrowUpIcon fontSize="small" />
          </IconButton>
        </Hidden>

        <TextField
          id="activePageInput"
          size="small"
          type="number"
          value={value}
          variant="filled"
          onChange={handleChange}
          onBlur={handlePageChange}
          onKeyPress={handleKeyPress}
          inputProps={{ min: 1, max: numberOfPages }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{`/ ${numberOfPages}`}</InputAdornment>
            ),
            classes: {
              input: classes.toolbarInput,
            },
          }}
        />

        <Hidden mdDown>
          <IconButton
            onClick={handleNextPage}
            color="inherit"
            aria-label="Next page"
            size="large"
          >
            <ArrowDownIcon fontSize="small" />
          </IconButton>
        </Hidden>
      </Box>
    );
  }
);

PageControl.displayName = 'PageControl';

export default PageControl;
