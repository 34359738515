import React from 'react';

import {
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Grid,
  useMediaQuery,
  Theme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Formik, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import AlertAutoDismiss from 'common/components/AlertAutoDismiss';
import ButtonLink from 'common/components/Buttons/ButtonLink';
import ButtonWithLoader from 'common/components/Buttons/ButtonWithLoader';
import FormCaptchaField from 'common/components/Fields/FormCaptchaField';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { LoginDialogState } from 'containers/Auth/Auth.context';

import { useSignUpForm } from './hooks/useSignUpForm';

export type SignUpFormProps = {
  onClose: () => void;
  onFormSubmit: () => void;
  setOpenState: React.Dispatch<React.SetStateAction<LoginDialogState>>;
};

const SignUpForm = ({
  onClose,
  onFormSubmit,
  setOpenState,
}: SignUpFormProps): JSX.Element => {
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true }
  );

  const { error, initialValues, onReset, onSubmit, success, validationSchema } =
    useSignUpForm({
      onReset: onClose,
    });

  const handleLogin = () => {
    setOpenState(LoginDialogState.Login);
  };

  const handleDismiss = () => {
    onFormSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onReset={onReset}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, submitCount }) => (
        <Form aria-label="Sign Up form" id="signUpForm">
          <DialogContent sx={{ pt: 0 }}>
            <Typography sx={{ mb: 2 }}>{t('user.signUp.subtitle')}</Typography>

            <Grid spacing={2} container>
              <Grid xs={12} item>
                <FormTextField
                  id="signUpEmail"
                  label={t('user.signUp.email.label')}
                  name="email"
                  type="email"
                  autoFocus
                />
              </Grid>

              <Grid sm={6} xs={12} item>
                <FormTextField
                  id="signUpFirstName"
                  label={t('user.signUp.firstName.label')}
                  name="firstName"
                />
              </Grid>

              <Grid sm={6} xs={12} item>
                <FormTextField
                  id="signUpLastName"
                  label={t('user.signUp.lastName.label')}
                  name="lastName"
                />
              </Grid>

              <Grid sm={6} xs={12} item>
                <FormTextField
                  id="signUpAffiliation"
                  label={t('user.signUp.affiliation.label')}
                  name="affiliation"
                />
              </Grid>

              <Grid sm={6} xs={12} item>
                <FormTextField
                  id="signUpPromoCode"
                  label={t('user.signUp.promoCode.label')}
                  name="promoCode"
                />
              </Grid>
            </Grid>

            {error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            {success && (
              <Box mt={2}>
                <AlertAutoDismiss
                  dismissIn={10000}
                  severity="success"
                  text={success}
                  onDismiss={handleDismiss}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Box display="flex" flexDirection="column" gap={1}>
              <FormCaptchaField
                id="signUpCaptcha"
                name="captchaToken"
                size={isMobile ? 'compact' : 'normal'}
              />

              <ButtonWithLoader
                color="secondary"
                disabled={isSubmitting || (!!submitCount && !isValid)}
                eventName={TrackEventName.SignUpButtonClicked}
                isFetching={isSubmitting}
                type="submit"
                variant="contained"
                fullWidth
              >
                {t('user.signUp.submitButton')}
              </ButtonWithLoader>

              <Typography color="textSecondary" variant="body2">
                <Trans i18nKey="user.signUp.terms.text" t={t}>
                  <a
                    href={t('user.signUp.terms.website')}
                    rel="noreferrer"
                    target="_blank"
                  />
                  <a
                    href={t('user.signUp.terms.privacyLink')}
                    rel="noreferrer"
                    target="_blank"
                  />
                </Trans>
              </Typography>
            </Box>
          </DialogActions>

          <Divider />

          <Box
            alignItems="center"
            display="flex"
            gap={1}
            justifyContent="center"
            p={2}
          >
            <Typography>{t('user.signUp.haveAccount')}</Typography>

            <ButtonLink underline="hover" variant="body1" onClick={handleLogin}>
              {t('user.signUp.loginButton')}
            </ButtonLink>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
