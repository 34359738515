import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  moreBtn: {
    boxShadow: theme.shadows[5],
  },
  moreBtnWrapper: {
    display: 'inline-block',
  },
}));
