import React from 'react';

import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  isSubmitting?: boolean;
  startIcon?: React.ReactNode;
  text?: string;
} & ButtonProps<ButtonTypeMap['defaultComponent'], {}>;

const SubmitButton = ({
  isSubmitting,
  onAction,
  onClick,
  startIcon,
  text,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const { t } = useTranslation('common');
  const btnText = text ?? t('buttons.submit');
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button
      color="secondary"
      startIcon={
        !isSubmitting ? (
          startIcon
        ) : (
          <CircularProgress color="inherit" size={18} />
        )
      }
      type="submit"
      variant="contained"
      {...rest}
      onClick={handleClick}
    >
      {btnText}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(SubmitButton);
