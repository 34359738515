import React, { useCallback } from 'react';

import { HighlightingContext } from './Highlighting.context';
import { useHighlighting } from '../../hooks';
import {
  Highlight,
  HighlightActionItem,
  HighlightingMousePos,
  OnHighlightAction,
} from '../../types';

export type HighlightingProps<T extends string> = {
  children: React.ReactNode;
  onHighlightAction: OnHighlightAction<T>;
  customHighlightActions?: HighlightActionItem<T>[];
};

const Highlighting = <T extends string>({
  children,
  onHighlightAction,
  customHighlightActions,
}: HighlightingProps<T>) => {
  const { state, dispatch } = useHighlighting();

  const handleUpdateHighlight = useCallback(
    (payload: Highlight) => {
      dispatch({
        type: 'updateHighlightSelection',
        payload,
      });
    },
    [dispatch]
  );

  const handleClearHighlight = useCallback(() => {
    dispatch({ type: 'clearHighlightSelection' });
  }, [dispatch]);

  const handleStartHighlight = useCallback(() => {
    dispatch({ type: 'startHighlightSelection' });
  }, [dispatch]);

  const handleEndHighlight = useCallback(
    (payload?: HighlightingMousePos) => {
      dispatch({ type: 'endHighlightSelection', payload });
    },
    [dispatch]
  );

  return (
    <HighlightingContext.Provider
      value={{
        state,
        onHighlightAction,
        customHighlightActions,
        onStartHighlight: handleStartHighlight,
        onEndHighlight: handleEndHighlight,
        onClearHighlight: handleClearHighlight,
        onUpdateHighlight: handleUpdateHighlight,
      }}
    >
      {children}
    </HighlightingContext.Provider>
  );
};

export default Highlighting;
