import React from 'react';

import { List, ListProps } from '@mui/material';

export type ScrollListStyleProps = {
  maxHeight?: number | string;
};

export type ScrollListProps = {} & ScrollListStyleProps & ListProps;

const ScrollList = React.memo(
  ({ children, maxHeight = 200 }: ScrollListProps) => {
    return (
      <List
        sx={{
          borderBottom: '1px solid',
          borderColor: 'palette.divider',
          borderTop: '1px solid',
          maxHeight,
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {children}
      </List>
    );
  }
);

ScrollList.displayName = 'ScrollList';

export default ScrollList;
