import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { SearchSortMethodEnum, SearchUrlParamsEnum } from 'common/enums';

type SearchSimilarToReturn = {
  searchSimilarTo: (ids: string[]) => void;
};

export function useSearchSimilarTo(): SearchSimilarToReturn {
  const history = useHistory();

  const searchSimilarTo = useCallback(
    (ids: string[]): void => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete(SearchUrlParamsEnum.Query);
      searchParams.delete(SearchUrlParamsEnum.Page);
      searchParams.delete(SearchUrlParamsEnum.DocIds);
      searchParams.delete(SearchUrlParamsEnum.Term);
      searchParams.set(SearchUrlParamsEnum.SimilarTo, ids.toString());
      searchParams.set(
        SearchUrlParamsEnum.SortBy,
        SearchSortMethodEnum.Relevance
      );
      history.push(`/?${searchParams}`);
    },
    [history.location.search]
  );

  return { searchSimilarTo };
}
