import { useMemo } from 'react';

export type UseIndeterminateCheckboxReturn = {
  checked: boolean;
  indeterminate: boolean;
};

export const useIndeterminateCheckbox = (
  checkedCond: boolean,
  unCheckedCond: boolean
): UseIndeterminateCheckboxReturn => {
  const value = useMemo<UseIndeterminateCheckboxReturn>(() => {
    return {
      checked: checkedCond,
      indeterminate: !checkedCond && !unCheckedCond,
    };
  }, [checkedCond, unCheckedCond]);

  return value;
};
