import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded';

export type HighlightsVisibilityControlProps = {
  highlightsVisible: boolean;
  onHighlightsVisibilityChange: (value: boolean) => void;
};

const HighlightsVisibilityControl = React.memo(
  ({
    onHighlightsVisibilityChange,
    highlightsVisible,
  }: HighlightsVisibilityControlProps) => {
    const Icon = highlightsVisible ? VisibilityIcon : VisibilityOffIcon;

    const handleClick = (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onHighlightsVisibilityChange(!highlightsVisible);
    };

    return (
      <Tooltip title={`${highlightsVisible ? 'Hide' : 'Show'} highlights`}>
        <IconButton
          aria-label="toggle highlight visibility"
          onClick={handleClick}
          size="large"
        >
          <Icon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }
);

HighlightsVisibilityControl.displayName = 'HighlightsVisibilityControl';

export default HighlightsVisibilityControl;
