import React from 'react';

import { Tooltip, Theme, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';

import { TweetDocument } from '../types';

import { RetrievalUnitTweetsListItem } from './RetrievalUnitTweetsListItem';

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      zIndex: 2,
    },
    border: `2px solid ${theme.palette.background.paper}`,
    marginLeft: theme.spacing(-1),
  },
  avatarGroup: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
  },
  moreButton: {
    border: `2px solid ${theme.palette.background.paper}`,
    boxSizing: 'content-box',
    height: 20,
    marginLeft: theme.spacing(-1),
    minWidth: 24,
    width: 'auto',
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.primary,
    margin: '4px 0',
    maxWidth: 300,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 450,
    },
  },
}));

type Props = {
  numberOfTweets?: number;
  onClickMore: (event: React.MouseEvent<any>) => void;
  onTweetClick: (e: React.MouseEvent<HTMLAnchorElement>, link: string) => void;
  tweets: TweetDocument[];
};

export const RetrievalUnitTweetsAvatarGroup = ({
  numberOfTweets,
  onClickMore,
  onTweetClick,
  tweets,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.avatarGroup}>
        {tweets.slice(0, 3).map((tweet, index) => (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            key={tweet.url}
            placement="bottom-start"
            title={
              <RetrievalUnitTweetsListItem
                tweet={tweets[index]}
                onClick={onTweetClick}
              />
            }
          >
            <StyledAvatar
              alt={tweet.name}
              className={classes.avatar}
              size="small"
              src={tweet.userProfilePictureUrl}
            />
          </Tooltip>
        ))}
      </div>

      {numberOfTweets && numberOfTweets > 3 && (
        <Chip
          className={classes.moreButton}
          label={numberOfTweets}
          size="small"
          clickable
          onClick={onClickMore}
        />
      )}
    </>
  );
};
