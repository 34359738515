import React, { FC } from 'react';

import { Formik } from 'formik';

import { AddPrivateDocForm } from './AddPrivateDocForm';
import { useAddPrivateDocsForm } from './useAddPrivateDocsForm';
import { useAddPrivateDocsValidation } from './useAddPrivateDocsValidation';

interface AddSinglePrivateDocProps {
  onFormSubmit: () => void;
}

export const AddPrivateDocs: FC<AddSinglePrivateDocProps> = ({
  onFormSubmit,
}) => {
  const validationSchema = useAddPrivateDocsValidation();
  const { initialValues, loadingsStates, onSubmit } =
    useAddPrivateDocsForm(onFormSubmit);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onReset={onFormSubmit}
      onSubmit={onSubmit}
    >
      {(helpers) => (
        <AddPrivateDocForm loadingsStates={loadingsStates} {...helpers} />
      )}
    </Formik>
  );
};

AddPrivateDocs.displayName = 'AddSinglePrivateDoc';

// need for test
export default AddPrivateDocs;
