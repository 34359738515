import React, { useState } from 'react';

import { useAuth } from 'containers/Auth/hooks/useAuth';

import { useReportsNotifications } from './NotificationsContent/ReportsNotifications/useReportsNotifications';
import { useChangelogUpdates } from './useChangelogUpdates';
import { useRecommendationsUpdates } from './useRecommendationsUpdates';
import { useTagsUpdates } from './useTagsUpdates';

export const useNotifications = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isAuthenticated } = useAuth();
  const {
    changelogUpdates,
    setLatestChangelogDate,
    updateLatestChangelogDate,
  } = useChangelogUpdates();
  const { hasNewRecs, unseenRecsCount } = useRecommendationsUpdates();
  const { hasTagsUpdates, tagsUpdatesCount } = useTagsUpdates();
  const { reportsNotifications } = useReportsNotifications();

  const updatesCount =
    (changelogUpdates ? 1 : 0) +
    (isAuthenticated
      ? tagsUpdatesCount + (hasNewRecs ? unseenRecsCount : 0)
      : 0) +
    reportsNotifications.length;

  const hasUpdates =
    changelogUpdates ||
    (isAuthenticated && (hasTagsUpdates || hasNewRecs)) ||
    reportsNotifications.length;

  const onPopoverOpen = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    await updateLatestChangelogDate();
  };

  const onPopoverClose = async () => {
    setAnchorEl(null);
    setTimeout(async () => {
      await setLatestChangelogDate();
    }, 300);
  };

  return {
    anchorEl,
    changelogUpdates,
    hasTagsUpdates,
    hasUpdates,
    onPopoverClose,
    onPopoverOpen,
    unseenRecsCount,
    updatesCount,
  };
};
