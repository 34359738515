import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const dialogActionsOverrides: ComponentsOverrides<Theme>['MuiDialogActions'] =
  {
    root: ({ theme }) => ({
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
    }),
  };

export const dialogTitleOverrides: ComponentsOverrides<Theme>['MuiDialogTitle'] =
  {
    root: ({ theme }) => ({
      '& + .MuiDialogContent-root': {
        paddingTop: theme.spacing(2),
      },
      padding: theme.spacing(2, 3),
    }),
  };
