import React, { useEffect } from 'react';

import { calcZoomDelta, isMobile } from '../utils';
import { useZoomLevel } from './useZoomLevel';

export const usePinchToZoomListener = (
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const { zoomLevel, setZoomLevel } = useZoomLevel();

  useEffect(() => {
    if (!ref.current || isMobile()) return;
    const el = ref.current;

    const wheelListener = (e: WheelEvent) => {
      if (!e.ctrlKey) return;

      e.preventDefault();

      const newZoom = parseFloat(
        (zoomLevel - calcZoomDelta(zoomLevel, e.deltaY)).toFixed(2)
      );

      setZoomLevel(newZoom);
    };

    el.addEventListener('wheel', wheelListener);

    return () => {
      el.removeEventListener('wheel', wheelListener);
    };
  }, [ref, zoomLevel, setZoomLevel]);
};
