import { useMemo } from 'react';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { normalizeRegexSpecialChar } from 'common/utils/regex.utils';

export const useFilterTagsByName = (
  tagName: string,
  tags: TagDetails[]
): TagDetails[] => {
  const filteredTags = useMemo(() => {
    const normalizedTagName = normalizeRegexSpecialChar(tagName.trim());

    if (normalizedTagName.length === 0) return tags;

    return tags.filter((tag) => RegExp(normalizedTagName, 'gi').test(tag.name));
  }, [tags, tagName]);

  return filteredTags;
};
