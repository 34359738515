import React, { useRef, useState } from 'react';

import { Box, CardContent, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TextField } from 'common/components/TextField';

import { SendFeedbackScoreToggle } from '../SendFeedbackScoreToggle/SendFeedbackScoreToggle';

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'inline-flex',
  },
  popoverContent: {
    maxWidth: 340,
  },
}));

const DELAY = 500;

type Props = {
  iconStyle?: React.CSSProperties;
  withFeedbackMessage?: boolean;
};

export const SendFeedbackForm = ({ iconStyle, withFeedbackMessage }: Props) => {
  const { t } = useTranslation(['common', 'feedback']);
  const classes = useStyles();
  const scoreButtonsRef = useRef(null);
  const { isSubmitting, setFieldValue, submitForm } = useFormikContext();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    await submitForm();
    setPopoverOpen(false);
  };

  const handleOpenPopover = () => setPopoverOpen(true);

  const handleClosePopover = async (
    e: Event,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') {
      await handleSubmit();
    } else {
      setPopoverOpen(false);
    }
  };

  const handleMouseDown = async ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const scoreValue = Number(currentTarget.value);
    const clickStart = new Date().valueOf();
    const openPopoverTimeout = setTimeout(() => {
      handleOpenPopover();
      if (Number.isInteger(scoreValue)) {
        setFieldValue('feedbackScore', scoreValue);
      }
    }, DELAY);

    const listener = async () => {
      const clickEnd = new Date().valueOf();
      if (clickEnd - clickStart < DELAY || !withFeedbackMessage) {
        clearTimeout(openPopoverTimeout);
        setFieldValue('feedbackScore', scoreValue);
        await handleSubmit();
      }

      currentTarget.removeEventListener('mouseup', listener);
      setPopoverOpen(false);
    };

    currentTarget.addEventListener('mouseup', listener);
  };

  return (
    <>
      <div className={classes.buttonsWrapper} ref={scoreButtonsRef}>
        <SendFeedbackScoreToggle
          iconStyle={iconStyle}
          onMouseDown={handleMouseDown}
        />
      </div>

      {withFeedbackMessage && (
        <Popover
          anchorEl={scoreButtonsRef?.current}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={popoverOpen}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          disablePortal
          onClose={handleClosePopover}
        >
          <CardContent className={classes.popoverContent}>
            <Field name="feedbackText">
              {({ field }: FieldProps) => (
                <TextField
                  label={t('feedback:form.yourFeedback')}
                  autoFocus
                  fullWidth
                  multiline
                  {...field}
                />
              )}
            </Field>
          </CardContent>

          <Box display="flex" justifyContent="flex-end" p={2} pt={0}>
            <SubmitButton
              aria-label="submit"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              text={t('feedback:form.sendFeedback')}
              type="button"
              onClick={handleSubmit}
            />
          </Box>
        </Popover>
      )}
    </>
  );
};
