import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      mixBlendMode: 'multiply',
      cursor: 'pointer',
    },
  })
);
