import React from 'react';
import { Box, MenuItem, TextField } from '@mui/material';

import { useStyles } from './style';
import { PDFPageDetails } from '../../../types';

export type PageControlMobileProps = {
  activePage: number;
  pages: PDFPageDetails[];
  numberOfPages: number;
  onPageChange: (page: number) => void;
};

const PageControlMobile = React.memo(
  ({
    onPageChange,
    pages,
    numberOfPages,
    activePage,
  }: PageControlMobileProps) => {
    const classes = useStyles({ numberOfPages });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onPageChange(Number(e.target.value));
    };

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <TextField
          select
          size="small"
          variant="filled"
          value={activePage}
          name="activePage"
          onChange={handleChange}
          SelectProps={{
            classes: {
              select: classes.select,
            },
            IconComponent: () => (
              <span className={classes.numberOfPages}>/{numberOfPages}</span>
            ),
          }}
        >
          {pages.map(({ pageNumber }) => (
            <MenuItem
              dense
              key={`page_${pageNumber}`}
              aria-label={`Page ${pageNumber}`}
              value={pageNumber}
            >
              {pageNumber}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }
);

PageControlMobile.displayName = 'PageControlMobile';

export default PageControlMobile;
