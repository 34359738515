import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type MoreActionItem = {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  onClick: Function;
};

type Props = {
  actions: MoreActionItem[];
  disabled?: boolean;
  size?: 'small' | 'medium';
  testId?: string;
  title: string;
};

export const MoreActions = React.memo(
  ({ actions, disabled, size, testId, title }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleShow = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      callback: Function
    ) => {
      handleClose();
      callback();
    };

    return (
      <>
        <Tooltip title={title}>
          <span>
            <IconButton
              data-testid={testId}
              disabled={disabled}
              size={size}
              onClick={handleShow}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={open}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={handleClose}
        >
          {actions.map(({ Icon, label, onClick }) => (
            <MenuItem
              aria-label={label}
              key={label}
              onClick={(e) => handleItemClick(e, onClick)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);

MoreActions.displayName = 'MoreActions';
