import React from 'react';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  drawerContent: {
    padding: theme.spacing(0, 1),
  },
}));

type Props = {
  children?: React.ReactNode;
};

export const PageDrawerContent = React.forwardRef<HTMLDivElement, Props>(
  ({ children }: Props, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    return (
      <div className={classes.drawerContent} ref={ref}>
        {children}
      </div>
    );
  }
);

PageDrawerContent.displayName = 'PageDrawerContent';
