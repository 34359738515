import { useCallback, useEffect, useState } from 'react';

export type UseActivePageListenerProps = {
  ref: React.RefObject<HTMLDivElement | null>;
  numberOfPages: number;
};

export const useActivePageListener = ({
  ref,
  numberOfPages,
}: UseActivePageListenerProps) => {
  const [visibleActivePage, setVisibleActivePage] = useState<number>(1);

  const handleVisibleActivePage = useCallback(() => {
    const el = ref.current;
    const height = el?.getBoundingClientRect().height ?? 0;

    if (el && numberOfPages !== 0 && height !== 0) {
      const pageHeight = el.scrollHeight / numberOfPages;
      let newActivePage = (el.scrollTop + pageHeight) / pageHeight;

      if (height < pageHeight * 2) {
        newActivePage = (el.scrollTop + height / 2) / pageHeight;
      }

      setVisibleActivePage(Math.ceil(newActivePage));
    }
  }, [numberOfPages, ref, setVisibleActivePage]);

  useEffect(() => {
    const container = ref.current;

    if (container) {
      container.addEventListener('scroll', handleVisibleActivePage);

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleVisibleActivePage);
        }
      };
    }

    return;
  }, [ref, handleVisibleActivePage]);

  useEffect(() => {
    const container = ref.current;

    if (container) {
      const resizeObserver = new ResizeObserver(handleVisibleActivePage);

      resizeObserver.observe(container);

      handleVisibleActivePage();

      return () => {
        resizeObserver.unobserve(container);
      };
    }

    return;
  }, [ref, handleVisibleActivePage]);

  return visibleActivePage;
};
