import React, { useCallback } from 'react';

import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { useSearchSimilarTo } from 'common/hooks/useSearchSimilarTo';
import { DocCardComposition } from 'containers/User/User.enum';

type DocSimilarToButtonProps = {
  actionComposition?: DocCardComposition;
  getSimilarDocsId: string;
};

const DocSimilarToButton = ({
  actionComposition,
  getSimilarDocsId,
  onAction,
}: WithTrackedActionWrappedProps<DocSimilarToButtonProps>) => {
  const { t } = useTranslation('common');
  const { searchSimilarTo } = useSearchSimilarTo();

  const handleClick = useCallback(() => {
    if (getSimilarDocsId) {
      searchSimilarTo([getSimilarDocsId]);
      onAction();
    }
  }, [getSimilarDocsId, searchSimilarTo]);

  return (
    <Button
      color="primary"
      data-testid="findSimilarPapers"
      size="small"
      startIcon={<FindReplaceIcon />}
      style={
        actionComposition === DocCardComposition.Compressed
          ? {
              alignItems: 'start',
              alignSelf: 'start',
              justifyContent: 'start',
              width: '100%',
            }
          : undefined
      }
      onClick={handleClick}
    >
      {t('retrievalUnit.actions.findSimilar')}
    </Button>
  );
};

export default withTrackedAction<
  DocSimilarToButtonProps & WithTrackedActionProps
>(DocSimilarToButton);
