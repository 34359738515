import React, { useState } from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import FormatColorTextIcon from '@mui/icons-material/FormatColorTextRounded';
import NearMeIcon from '@mui/icons-material/NearMeRounded';
import CropOriginalIcon from '@mui/icons-material/CropOriginalRounded';

import { HighlightMode } from '../../../types';

export type HighlightModeControlMobileProps = {
  highlightMode: HighlightMode;
  children: React.ReactNode;
};

const HighlightModePopover = ({
  children,
  highlightMode,
}: HighlightModeControlMobileProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconButton
        onClick={handleOpen}
        color="inherit"
        aria-label="Toggle highlight mode popover"
        size="large"
      >
        {highlightMode === 'text' && <FormatColorTextIcon fontSize="small" />}
        {highlightMode === 'none' && <NearMeIcon fontSize="small" />}
        {highlightMode === 'area' && <CropOriginalIcon fontSize="small" />}
      </IconButton>

      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={0.5}>{children}</Box>
      </Popover>
    </Box>
  );
};

HighlightModePopover.displayName = 'HighlightModePopover';

export default HighlightModePopover;
