import {
  ApiDisplayConfiguration,
  DisplayConfiguration,
} from './tenantSettingsApi.types';

export const deserializeDisplayConfiguration = (
  fromApi: ApiDisplayConfiguration | null
): DisplayConfiguration | null => {
  if (!fromApi) {
    return null;
  }
  return {
    createdByField: fromApi.created_by_field,
    dateField: fromApi.date_field,
    descriptionField: fromApi.description_field,
    documentMetadataFields: fromApi.document_metadata_fields?.map((field) => ({
      fieldName: field.field_name,
      icon: field.icon,
      label: field.label,
      listFieldName: field.list_field_name,
      type: field.type,
      urlFieldName: field.url_field_name,
    })),
    imageUrlField: fromApi.image_url_field,
    sourceField: fromApi.source_field,
    titleField: fromApi.title_field,
    urlField: fromApi.url_field,
  };
};
