import React, { FC, useEffect, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, List, Popover, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import { TagDetailsBase } from 'api/tagsApi';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { HitType, SearchEngineEnum } from 'common/enums';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import BibtexDialog from 'containers/Bibtex/BibtexDialog/BibtexDialog';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import SendFeedback from 'containers/Feedback/SendFeedback/SendFeedback';
import DeletePrivateDocDialog from 'containers/PrivateDocs/DeletePrivateDoc/DeletePrivateDocDialog/DeletePrivateDocDialog';
import { EditPrivateDocDialog } from 'containers/PrivateDocs/EditPrivateDoc/EditPrivateDocDialog/EditPrivateDocDialog';
import DocTags from 'containers/SavedDocuments/DocTags';
import { selectFavouriteDocByOrganizeDocId } from 'containers/SavedDocuments/savedDocuments.slice';
import { DocCardComposition } from 'containers/User/User.enum';

import DocFavoriteButton from '../DocFavoriteButton';
import DocNotesToggle from '../DocNotesToggle';
import DocShare from '../DocShare';
import DocSimilarToButton from '../DocSimilarToButton';
import { RetrievalUnitData } from '../RetrievalUnitData.interface';

import DocActionItem from './DocActionItem/DocActionItem';
import { DocActionsContext } from './docActions.context';
import { DocAction } from './DocActions.interface';
import { useDocActions } from './hooks/useDocActions';
import { usePrivateDocActions } from './hooks/usePrivateDocActions';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& + $list': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export interface DocActionsProps {
  data: RetrievalUnitData;
  feedback?: FeedbackData;
  moreActions?: DocAction[];
  notesExpanded: boolean;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  renderFeedback: boolean;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  setNotesExpanded: (el: boolean) => void;
  suggestedTags?: TagDetailsBase[];
  withSharedNotes?: boolean;
  withTagging?: boolean;
}

const DocActionsCompressed: FC<DocActionsProps> = ({
  data,
  feedback,
  moreActions,
  notesExpanded,
  onPrivateDocDelete,
  onPrivateDocEdit,
  renderFeedback,
  resultType,
  searchEngine,
  searchId,
  setNotesExpanded,
  suggestedTags,
  withSharedNotes,
  withTagging,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { bibtexDialog, docActions, setBibtexDialog } = useDocActions(data);

  const {
    handlePrivateDocDelete,
    handlePrivateDocEdit,
    privateDocActions,
    privateDocDialog,
    setPrivateDocDialog,
  } = usePrivateDocActions({ onPrivateDocDelete, onPrivateDocEdit });
  const savedDoc = useSelector(
    selectFavouriteDocByOrganizeDocId(data.organizeDocId)
  );
  const isPrivateDoc = data.document.type === HitType.PrivateDocument;
  const renderPrivateDocActions = isPrivateDoc && !!privateDocActions.length;
  const renderMoreActions = !!moreActions?.length;
  const renderDocActions = !!docActions.length;
  const { me } = useAuth();
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsOwner(data.ownerUuid === me?.sub);
  }, [data.ownerUuid, me?.sub]);

  const isEditDialog = privateDocDialog === 'edit';
  const isDeleteDialog = privateDocDialog === 'delete';

  return (
    <DocActionsContext.Provider value={{ anchorEl, open, setAnchorEl }}>
      <IconButton
        aria-controls="actions"
        aria-haspopup="true"
        aria-label="doc actions button"
        size="small"
        sx={{ padding: '0px' }}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        aria-label="doc actions popover"
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClose}
      >
        <List
          className={classes.list}
          data-testid="docActions"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {data.getSimilarDocsId && (
            <DocSimilarToButton
              actionComposition={DocCardComposition.Compressed}
              eventName={TrackEventName.FindSimilarClicked}
              eventProps={{ ontologyId: data.ontologyId }}
              getSimilarDocsId={data.getSimilarDocsId}
            />
          )}

          {withTagging && (
            <DocTags
              actionComposition={DocCardComposition.Compressed}
              docData={data}
              searchEngine={searchEngine}
              suggestedTags={suggestedTags}
            />
          )}

          <DocFavoriteButton
            actionComposition={DocCardComposition.Compressed}
            docData={data}
            eventName={
              savedDoc
                ? TrackEventName.DocumentUnfavorited
                : TrackEventName.DocumentFavorited
            }
            eventProps={{ docId: data.document.id }}
            resultType={resultType}
            searchEngine={searchEngine}
            searchId={searchId}
          />

          {data.shareUri && (
            <DocShare
              actionComposition={DocCardComposition.Compressed}
              docAuthors={data.authors}
              docId={data.document.id}
              docTitle={data.title}
              eventName={TrackEventName.ShareDocumentClicked}
              resultType={resultType}
              searchId={searchId}
              shareUri={data.shareUri}
            />
          )}
          <DocNotesToggle
            actionComposition={DocCardComposition.Compressed}
            docId={data.organizeDocId}
            handleCloseDocActionsMenu={handleClose}
            notesExpanded={notesExpanded}
            setNotesExpanded={setNotesExpanded}
            withSharedNotes={withSharedNotes}
          />
          {renderMoreActions && (
            <List
              className={classes.list}
              data-testid="docMoreActions"
              style={{ paddingBottom: '0px', paddingTop: '0px' }}
            >
              {moreActions.map((action: DocAction) => (
                <DocActionItem
                  actionComposition={DocCardComposition.Compressed}
                  key={action.title}
                  {...action}
                  eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
                />
              ))}
            </List>
          )}

          {renderDocActions && (
            <List
              className={classes.list}
              data-testid="docActions"
              style={{ paddingBottom: '0px', paddingTop: '0px' }}
            >
              {docActions.map((action: DocAction) => (
                <DocActionItem
                  actionComposition={DocCardComposition.Compressed}
                  key={action.title}
                  {...action}
                  eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
                />
              ))}
            </List>
          )}

          {renderPrivateDocActions && isOwner && (
            <List
              className={classes.list}
              data-testid="privateDocActions"
              style={{ paddingBottom: '0px', paddingTop: '0px' }}
            >
              {privateDocActions.map((action: DocAction) => (
                <DocActionItem
                  actionComposition={DocCardComposition.Compressed}
                  key={action.title}
                  {...action}
                  eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
                />
              ))}
            </List>
          )}

          {renderFeedback && searchId && resultType && (
            <Box ml={1}>
              <SendFeedback
                iconStyle={{ fontSize: 'small' }}
                initialValues={
                  feedback && {
                    feedbackScore: feedback.score,
                    feedbackText: feedback.text ?? '',
                  }
                }
                resultId={data.document.id}
                resultType={resultType}
                searchId={searchId}
                withFeedbackMessage
              />
            </Box>
          )}
        </List>
      </Popover>
      {data.getBibtexId && (
        <BibtexDialog
          ids={[data.getBibtexId]}
          open={bibtexDialog}
          setOpen={setBibtexDialog}
        />
      )}
      {isEditDialog && (
        <EditPrivateDocDialog
          data={data}
          open={isEditDialog}
          onClose={() => setPrivateDocDialog(false)}
          onFormSubmit={handlePrivateDocEdit}
        />
      )}

      {isDeleteDialog && (
        <DeletePrivateDocDialog
          id={data.privateDocId || ''}
          open={isDeleteDialog}
          setOpen={setPrivateDocDialog}
          onPrivateDocDelete={handlePrivateDocDelete}
        />
      )}
    </DocActionsContext.Provider>
  );
};

DocActionsCompressed.displayName = 'DocActionsCompressed';

export default DocActionsCompressed;
