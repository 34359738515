import { createTheme } from '@mui/material/styles';
import { blue, pink, deepOrange, green, lightBlue } from '@mui/material/colors';

export const THEME_SPACING = 8;
export const FONT_SIZE = 16;
export const PRIMARY = '#005d83';
export const PAPER_COLOR = '#343434';
export const BODY_COLOR = '#444444';

const theme = createTheme({
  spacing: THEME_SPACING,
  typography: {
    htmlFontSize: FONT_SIZE,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: PRIMARY,
    },
    secondary: {
      light: blue[200],
      main: blue[500],
      dark: blue[700],
    },
    error: {
      main: pink[500],
    },
    success: {
      main: green[600],
    },
    warning: {
      light: deepOrange[200],
      main: deepOrange[500],
    },
    info: {
      main: lightBlue[600],
    },
    background: {
      default: BODY_COLOR,
      paper: PAPER_COLOR,
    },
  },
});

theme.components = {
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
      }),
      underline: {
        '&::before, &::after': {
          border: 'none',
        },
        '&:hover::before': {
          border: 'none',
        },
      },
      adornedEnd: ({ theme }) => ({
        paddingRight: theme.spacing(1),
      }),
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        gap: theme.spacing(0.5),
        '& .MuiToggleButtonGroup-grouped': {
          border: 0,
          '&.Mui-disabled': {
            border: 0,
          },
          '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
          },
          '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
          },
        },
      }),
    },
  },
};

export { theme };
