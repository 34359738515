import React, { useCallback } from 'react';

import HighlightTooltip from '../../HighlightTooltip';
import HighlightRectsWrapper from '../HighlightRectsWrapper';
import HighlightRectItem from '../HighlightRectItem';
import {
  Highlight,
  HighlightActionItem,
  HighlightActionPayloadType,
  OnHighlightAction,
} from '../../../types';
import {
  useAvailableCustomHighlightActions,
  useHighlightTooltip,
} from '../../../hooks';
import HighlightTooltipAction from '../../HighlightTooltip/HighlightTooltipAction';
import HighlightTooltipDeleteAction from '../../HighlightTooltip/HighlightTooltipDeleteAction';
import HighlightTooltipAddNoteAction from '../../HighlightTooltip/HighlightTooltipAddNoteAction';
import HighlightTooltipCopyAction from '../../HighlightTooltip/HighlightTooltipCopyAction';

export type HighlightItemProps<T extends string> = {
  highlight: Highlight;
  zoom: number;
  onHighlightAction: OnHighlightAction<T>;
  customHighlightActions?: HighlightActionItem<T>[];
};

const HighlightItem = <T extends string>({
  highlight,
  zoom,
  onHighlightAction,
  customHighlightActions,
}: HighlightItemProps<T>) => {
  const { showTooltip, onShowTooltip, onHideTooltip, anchorPos } =
    useHighlightTooltip();
  const hasAnnotate = highlight.actions.includes('addNote');
  const hasDelete = highlight.actions.includes('delete');
  const hasCopy = highlight.content.type === 'text';
  const customActions = useAvailableCustomHighlightActions(
    highlight.content.type,
    customHighlightActions
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onHighlightAction({ action: 'click', data: highlight });

    onShowTooltip(e.clientX, e.clientY);
  };

  const handleActionClick = useCallback(
    async (
      _: React.MouseEvent<HTMLButtonElement>,
      action: HighlightActionPayloadType<T>
    ) => {
      onHideTooltip();

      onHighlightAction({ action, data: highlight });
    },
    [onHighlightAction, highlight, onHideTooltip]
  );

  return (
    <div>
      <HighlightRectsWrapper
        onClick={handleClick}
        highlightColor={highlight.color}
      >
        {highlight.rects.map((rect, i) => (
          <HighlightRectItem
            {...rect}
            key={`${highlight.id}_${i}`}
            color={highlight.color}
            zoom={zoom}
          />
        ))}
      </HighlightRectsWrapper>

      <HighlightTooltip
        open={showTooltip}
        onClose={onHideTooltip}
        anchorTop={anchorPos.top}
        anchorLeft={anchorPos.left}
      >
        {hasAnnotate && (
          <HighlightTooltipAddNoteAction onAddNote={handleActionClick} />
        )}

        {hasCopy && (
          <HighlightTooltipCopyAction
            onCopy={handleActionClick}
            contentBody={highlight.content.body}
          />
        )}

        {customActions.map(({ action, icon, title }) => (
          <HighlightTooltipAction
            key={action}
            title={title}
            icon={icon}
            action={action}
            onClick={handleActionClick}
          />
        ))}

        {hasDelete && (
          <HighlightTooltipDeleteAction onDelete={handleActionClick} />
        )}
      </HighlightTooltip>
    </div>
  );
};

HighlightItem.displayName = 'HighlightItem';

export default HighlightItem;
