export enum SearchTypeEnum {
  Default = 'default',
  Query = 'query',
  Term = 'term',
}

export type SearchType =
  | SearchTypeEnum.Default
  | SearchTypeEnum.Query
  | SearchTypeEnum.Term;
