import React from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { CardHeader, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import TagChip from 'common/components/TagChip/TagChip';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  root: {
    padding: theme.spacing(1, 2),
  },
}));

type NoteCardTagInfoProps = {
  onTagClick: () => void;
  tag: TagDetails;
};

const NoteCardTagInfo = ({ onTagClick, tag }: NoteCardTagInfoProps) => {
  const classes = useStyles();

  return (
    <CardHeader
      avatar={
        <StyledAvatar size="small">
          {tag.type !== TagType.Favourites ? (
            <BookmarkIcon fontSize="small" />
          ) : (
            <StarRoundedIcon />
          )}
        </StyledAvatar>
      }
      classes={classes}
      title={
        <TagChip
          color={tag.color}
          label={tag.type !== TagType.Favourites ? tag.name : 'Favorites'}
          onClick={onTagClick}
        />
      }
    />
  );
};

export default NoteCardTagInfo;
