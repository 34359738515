import posthog from 'posthog-js';

export const posthogInit = () => {
  if (!window.env.POSTHOG_API_HOST) {
    return;
  }
  posthog.init(window.env.POSTHOG_API_KEY, {
    api_host: window.env.POSTHOG_API_HOST,
  });
};
