import React from 'react';

import { Skeleton } from '@mui/material';

const ShareSelectionSkeleton = () => {
  return (
    <div>
      <Skeleton height={40} variant="rectangular" />

      <Skeleton height={20} width="20%" />

      <Skeleton height={100} />
    </div>
  );
};

ShareSelectionSkeleton.displayName = 'ShareSelectionSkeleton';

export default ShareSelectionSkeleton;
