import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DocResultsContainer from 'common/components/Docs/DocResultsContainer';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import DocsCollection from 'containers/Docs/DocsCollection';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { useSearchResults } from 'containers/SearchResults/hooks/useSearchResults';
import { selectIndexCluster, selectUser } from 'containers/User/user.slice';

export type SearchPopperContentProps = {
  searchQuery: string;
};

const SearchPopperContent = React.memo(
  ({ searchQuery }: SearchPopperContentProps) => {
    const parsedHostname = useParsedHostname();
    const indexCluster = useSelector(selectIndexCluster);
    const userSettings = useSelector(selectUser);

    const searchPayload = useMemo<SearchPayload>(
      () =>
        new SearchPayloadFromURL(
          searchQuery,
          parsedHostname,
          undefined,
          indexCluster
        ).getSearchPayload(),
      [searchQuery]
    );
    const { data, error, isError, isLoading } = useSearchResults({
      ...searchPayload,
      indexCluster: indexCluster,
      options: {
        retry: 1,
      },
    });
    const organizeDocIds = useOrganizeDocIds(data?.data.items);

    return (
      <CollectionDialogContent dividers>
        <DocResultsContainer
          error={error?.message}
          isError={isError}
          isLoading={isLoading}
          noResults={!data?.data.items.length}
        >
          <DocsCollection organizeDocIds={organizeDocIds}>
            {(data?.data.items ?? []).map((doc) => (
              <RetrievalUnit
                cardComposition={userSettings?.cardStyle}
                cardProps={{ square: true }}
                data={doc}
                key={doc.document.id}
              />
            ))}
          </DocsCollection>
        </DocResultsContainer>
      </CollectionDialogContent>
    );
  }
);

SearchPopperContent.displayName = 'SearchPopperContent';

export default SearchPopperContent;
