import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
  DefaultBotTypeMessage,
  DefaultBotTypes,
  QuizbotBotTypeMessage,
  SummaryBotTypeMessage,
  TagOnboardingBotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';

export const useChatMessages = () => {
  const { t } = useTranslation(['chat']);

  const buildDefaultMessage = useCallback(
    (
      message: string,
      defaultBotType: DefaultBotTypes = 'chat_with_pdf',
      image?: string | null
    ): DefaultBotTypeMessage => {
      return {
        botType: defaultBotType,
        message,
        ...(image ? { image } : {}),
      };
    },
    []
  );

  const buildExplainMessage = useCallback(
    (text: string) =>
      buildDefaultMessage(t('chat:explain', { highlight: text })),
    [buildDefaultMessage, t]
  );

  const buildExplainImageMessage = useCallback(
    (image: string | null) =>
      buildDefaultMessage(t('chat:image'), undefined, image),
    [buildDefaultMessage, t]
  );

  const buildSummaryMessage = useCallback((): SummaryBotTypeMessage => {
    return {
      botType: 'summary',
      message: t('chat:summary'),
    };
  }, [t]);

  const buildQuizMessage = useCallback((): QuizbotBotTypeMessage => {
    return {
      botType: 'quizbot',
    };
  }, []);

  const buildTagOnboardingMessage = useCallback(
    (message: string = ''): TagOnboardingBotTypeMessage => {
      return message
        ? {
            botType: 'chat_with_tag',
            message,
          }
        : { botType: 'chat_with_tag' };
    },
    []
  );

  return {
    buildDefaultMessage,
    buildExplainImageMessage,
    buildExplainMessage,
    buildQuizMessage,
    buildSummaryMessage,
    buildTagOnboardingMessage,
  };
};
