import React, { FC, ReactNode } from 'react';

import { CircularProgressProps } from '@mui/material';

import ContentLoader from '../ContentLoader/ContentLoader';

interface Props extends CircularProgressProps {
  children: ReactNode;
  loading: boolean;
}

export const LoaderContainer: FC<Props> = ({ children, loading, ...props }) =>
  loading ? (
    <ContentLoader size={18} disableShrink {...props} />
  ) : (
    <>{children}</>
  );
