import { UserPayload } from '@zarn/vendor/dist/auth';

/**
 * Parse token and validate expiration time.
 */
export const isJWTValid = (token: string | null) => {
  if (!token) return false;

  const tokenContent: UserPayload = JSON.parse(atob(token.split('.')[1]));

  if (!tokenContent) return false;

  return tokenContent.exp * 1000 > Date.now() - 60 * 1000; // 60 * 1000 = 1 minute, just for preventing request session timeout
};
