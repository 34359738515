import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { NotesFilterVisibility } from '../NotesFilter.types';

const ALL_OPTIONS = Object.values(NotesFilterVisibility);

export const useNotesFilterVisibilityOptions = (
  options: NotesFilterVisibility[] = ALL_OPTIONS
) => {
  const { t } = useTranslation('common');

  return useMemo<{ label: string; value: NotesFilterVisibility }[]>(
    () => [
      ...(options.includes(NotesFilterVisibility.All)
        ? [
            {
              label: t('notes.filters.visibility.options.all'),
              value: NotesFilterVisibility.All,
            },
          ]
        : []),
      ...(options.includes(NotesFilterVisibility.OnlyMyNotes)
        ? [
            {
              label: t('notes.filters.visibility.options.onlyMyNotes'),
              value: NotesFilterVisibility.OnlyMyNotes,
            },
          ]
        : []),
      ...(options.includes(NotesFilterVisibility.OnlySharedNotes)
        ? [
            {
              label: t('notes.filters.visibility.options.onlySharedNotes'),
              value: NotesFilterVisibility.OnlySharedNotes,
            },
          ]
        : []),
    ],
    [t, options]
  );
};
