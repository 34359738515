import { createContext } from 'react';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';

import { NoteDetails } from 'api/notesApi/notesApi.types';

export interface NoteItemContextValue {
  editMode: boolean;
  editModeToggle: () => void;
  note: NoteDetails;
}

export const noteItemContextInitialValue: NoteItemContextValue = {
  editMode: false,
  editModeToggle: () => {},
  note: {
    annotationHighlight: null,
    content: '',
    dateCreated: '',
    dateModified: '',
    dateShared: null,
    id: 0,
    objectId: '',
    objectType: NoteObjectType.Free,
    ownerId: '',
    permission: 'read',
    shareable: false,
    sharing: {
      orgs: [],
      users: [],
    },
  },
};

export const NoteItemContext = createContext<NoteItemContextValue>(
  noteItemContextInitialValue
);
