import React, { useCallback, useState } from 'react';

import ShareIcon from '@mui/icons-material/Share';
import { Alert, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

import NoteItemShareableTooltipTitle from './NoteItemShareableTooltipTitle';

interface NoteItemShareableActionProps {
  noteId: number;
  onToggleShareable: () => Promise<void>;
  shareable: boolean;
}

export const NoteItemShareableAction = ({
  noteId,
  onToggleShareable,
  shareable,
}: NoteItemShareableActionProps) => {
  const { t } = useTranslation('common');

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleToggleSharable = useCallback(async () => {
    await onToggleShareable();
    handleClose();
  }, [onToggleShareable]);

  return (
    <>
      <Tooltip title={<NoteItemShareableTooltipTitle shareable={shareable} />}>
        {shareable ? (
          <TrackedIconButton
            aria-label="share note toggle"
            data-testid="mark note as private"
            eventName={TrackEventName.PrivateNoteMarked}
            eventProps={{ noteId: noteId }}
            size="small"
            onClick={onToggleShareable}
          >
            <ShareIcon color="secondary" fontSize="small" />
          </TrackedIconButton>
        ) : (
          <TrackedIconButton
            aria-label="share note toggle"
            data-testid="mark note as shareable"
            size="small"
            onClick={handleOpen}
          >
            <ShareIcon fontSize="small" />
          </TrackedIconButton>
        )}
      </Tooltip>

      <ExtendedConfirmationDialog
        aria-label={t('notes.shareable.dialogTitle')}
        eventName={TrackEventName.SharedNoteMarked}
        eventProps={{ noteId: noteId }}
        open={open}
        setOpen={setOpen}
        onSubmit={handleToggleSharable}
      >
        <Typography gutterBottom>
          {t('notes.shareable.confirmationTitle')}
        </Typography>

        <Alert severity="info">
          {t('notes.shareable.confirmationDescription')}
        </Alert>
      </ExtendedConfirmationDialog>
    </>
  );
};
