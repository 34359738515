import React from 'react';

import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

import { useNavigationWithState } from '../../../hooks/useNavigationWithState';

export type ExplainNoteActionProps = {
  annotationContentBody?: string;
  docId?: string | undefined;
  noteContent: string;
  noteId: number;
};

export const ExplainNoteAction = ({
  annotationContentBody,
  docId,
  noteContent,
  noteId,
}: ExplainNoteActionProps) => {
  const { t } = useTranslation(['common', 'chat']);
  const { tenant } = useParsedHostname();
  const navigation = useNavigationWithState();
  const { setParams } = useQuerySearchParams();

  const isImageNote = !!annotationContentBody?.includes(
    'data:image/png;base64'
  );

  const action = useLoggedInFeature<boolean>(() => {
    const highlight = [noteContent, annotationContentBody]
      .filter(Boolean)
      .join(', ');

    navigation.replace(`/pdf/${docId}?tab=chat&explainText=${highlight}`);
    setParams({
      explainText: highlight,
    });
  });

  const onClick = () => {
    if (docId) {
      action(true);

      posthogPDFReader.open(tenant, docId);
    }
  };

  return (
    <Tooltip title={t<string>('notes.actions.startNewChat')}>
      <TrackedIconButton
        aria-label="Explain note content"
        data-testid="explain"
        eventName={
          isImageNote
            ? TrackEventName.ClickOnExplainInNoteWithImage
            : TrackEventName.ClickOnExplainInNote
        }
        eventProps={{ isImageNote, noteId }}
        size="small"
        onClick={onClick}
      >
        <OrangeGradientOpenAiIcon />
      </TrackedIconButton>
    </Tooltip>
  );
};
