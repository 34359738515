import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { useTagsPageParams } from 'pages/TagsPage/hooks/useTagsPageParams';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TagDetails } from 'api/tagsApi';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { deleteTag } from '../ownTagsSlice/ownTags.slice';

export type TagDeleteButtonProps = {
  tag: TagDetails;
};

const TagDeleteButton = ({ tag }: TagDeleteButtonProps) => {
  const { t } = useTranslation(['tags', 'common']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const routeMatch = useTagsPageParams();
  const { tenant } = useParsedHostname();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    handleClose();

    await dispatch(deleteTag({ tagId: tag.id, tenant }));

    if (routeMatch?.params?.tagId === String(tag.id)) {
      history.push('/tags');
    }
  };

  return (
    <>
      <IconButton
        aria-label="delete tag"
        size="small"
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </IconButton>

      <ExtendedConfirmationDialog
        aria-label="delete tag dialog"
        eventName={TrackEventName.TagDeleted}
        eventProps={{ tagId: tag.id }}
        open={open}
        setOpen={setOpen}
        onSubmit={handleDelete}
      >
        <Trans
          i18nKey="tags:confirmationDelete.message"
          t={t}
          values={{ tagName: tag.name }}
        >
          <Box component="b" sx={{ mx: 0.25 }}>
            {tag.name}
          </Box>
        </Trans>
      </ExtendedConfirmationDialog>
    </>
  );
};

TagDeleteButton.displayName = 'TagDeleteButton';

export default TagDeleteButton;
