import { useContext } from 'react';

import { ConfirmDialogProps } from './ConfirmDialog.types';
import { ConfirmDialogContext } from './ConfirmDialogContext';

export const useConfirmDialog = () => {
  const { dialog, setDialog } = useContext(ConfirmDialogContext);

  const close = () => {
    setDialog(null);
  };

  const open = (props: Omit<ConfirmDialogProps, 'onConfirm' | 'onCancel'>) => {
    return new Promise<'confirm' | 'cancel'>((resolve) => {
      setDialog({
        ...props,
        onCancel: () => {
          resolve('cancel');
          close();
        },
        onConfirm: () => {
          resolve('confirm');
          close();
        },
      });
    });
  };

  return {
    close,
    dialog,
    open,
  };
};
