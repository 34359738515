import React from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopyRounded';

import HighlightTooltipAction from '../HighlightTooltipAction';

export type HighlightTooltipCopyActionProps = {
  contentBody: string;
  onCopy?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'copy'
  ) => void;
};

const HighlightTooltipCopyAction = ({
  onCopy,
  contentBody,
}: HighlightTooltipCopyActionProps) => {
  const handleCopy = async (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    await navigator.clipboard.writeText(contentBody);

    if (!onCopy) return;

    onCopy(e, 'copy');
  };

  return (
    <HighlightTooltipAction
      title="Copy"
      action="copy"
      onClick={handleCopy}
      icon={FileCopyIcon}
    />
  );
};

export default HighlightTooltipCopyAction;
