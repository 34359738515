import { useAuth as useAuthOIDC } from 'react-oidc-context';

export interface useOidcSignInReturn {
  handleSignIn: () => void;
}

export const useOidcSignIn = () => {
  const auth = useAuthOIDC();

  const handleSignIn = () => {
    void auth.signinRedirect();
  };

  return { handleSignIn };
};
