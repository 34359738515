const regEx = /(<\/?(?:em)[^>]*>)|<[^>]+>/gi;

/**
 * Remove all HTML tags except `em`
 * @param text {string}
 * @returns {string}
 */
export function removeHtmlTags(text: string = ''): string {
  return text.replace(regEx, '$1');
}
