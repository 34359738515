import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { useTagsPageParams } from 'pages/TagsPage/hooks/useTagsPageParams';

import { HitType, SearchEngineEnum } from 'common/enums';
import { useDocTagsUpdateSnackbar } from 'common/hooks/useDocTagsUpdateSnackbar/useDocTagsUpdateSnackbar';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useExternalDocCreate } from 'containers/ExternalDocs/hooks/useExternalDocCreate';
import { updateDocumentTags } from 'containers/SavedDocuments/savedDocuments.slice';
import { useOnAddTaggedDoc } from 'containers/TaggedDocs/hooks/useOnAddTaggedDoc';
import { useOnDeleteTaggedDoc } from 'containers/TaggedDocs/hooks/useOnDeleteTaggedDoc';
import { TagsAutocompleteOptionValue } from 'containers/Tagging/TagsAutocomplete/TagsAutocomplete.types';

import { RetrievalUnitData } from '../RetrievalUnitData.interface';

export type UseDocTaggingProps = {
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
};

export const useDocTagging = ({
  docData,
  searchEngine,
}: UseDocTaggingProps) => {
  const {
    document: { type: docType },
    organizeDocId,
  } = docData;
  const { onAddSnackbar, onDeleteSnackbar, onErrorSnackbar } =
    useDocTagsUpdateSnackbar();
  const dispatch = useAppDispatch();
  const { mutateAsync: externalDocCreate } = useExternalDocCreate();
  const isExternalDoc = searchEngine && docType === HitType.ExternalDocument;
  const onAddTaggedDoc = useOnAddTaggedDoc();
  const onDeleteTaggedDoc = useOnDeleteTaggedDoc();
  const routeMatch = useTagsPageParams();
  const currentTagPageId = routeMatch?.params?.tagId;
  const { tenant } = useParsedHostname();

  const handleUpdateDocTags = async (
    selectedTags: TagsAutocompleteOptionValue[],
    initialTags: TagsAutocompleteOptionValue[]
  ) => {
    if (isExternalDoc) {
      await externalDocCreate({ ...docData, searchEngine });
    }

    const action = await dispatch(
      updateDocumentTags({
        docType: docType as HitType,
        organizeDocId,
        selectedTags,
        tenant,
      })
    );

    if (updateDocumentTags.rejected.match(action)) {
      onErrorSnackbar(action.error.message);

      return;
    }

    const tagsToAdd = selectedTags.filter((st) =>
      initialTags.every((tag) => tag.id !== st.id)
    );

    const tagsToDelete = initialTags.filter(
      (tag) => !selectedTags.find((st) => st.id === tag.id)
    );

    tagsToAdd.forEach((tag) => {
      if (tag.id.toString() !== currentTagPageId) return;
      onAddTaggedDoc(tag.id, docData);
    });

    tagsToDelete.forEach((tag) => {
      onDeleteTaggedDoc(tag.id, organizeDocId);
    });

    onAddSnackbar(selectedTags, initialTags);

    onDeleteSnackbar(selectedTags, initialTags);
  };

  return {
    onUpdateDocTags: handleUpdateDocTags,
  };
};
