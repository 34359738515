import { useContext, useCallback } from 'react';

import { PDFViewerContext } from '../../components/PDFViewer/PDFViewer.context';

export const useZoomLevel = () => {
  const {
    state: { zoomLevel, maxZoomLevel, minZoomLevel },
    dispatch,
  } = useContext(PDFViewerContext);

  const setZoomLevel = useCallback(
    (newZoom: number) => {
      dispatch({ type: 'setZoomLevel', payload: newZoom });
    },
    [dispatch]
  );

  const setMinZoomLevel = useCallback(
    (value: number) => {
      dispatch({ type: 'setMinZoomLevel', payload: value });
    },
    [dispatch]
  );

  const setMaxZoomLevel = useCallback(
    (value: number) => {
      dispatch({ type: 'setMaxZoomLevel', payload: value });
    },
    [dispatch]
  );

  return {
    zoomLevel,
    maxZoomLevel,
    minZoomLevel,
    setZoomLevel,
    setMinZoomLevel,
    setMaxZoomLevel,
  };
};
