import React from 'react';

import {
  ListItem,
  ListItemText,
  ListItemIcon,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type TagMetadataItemProps = {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  translation: React.ReactElement;
};

const TagMetadataItem = ({ icon: Icon, translation }: TagMetadataItemProps) => {
  return (
    <ListItem dense>
      <ListItemIcon>
        <Icon color="action" fontSize="small" />
      </ListItemIcon>

      <ListItemText primaryTypographyProps={{ color: 'textSecondary' }}>
        {translation}
      </ListItemText>
    </ListItem>
  );
};

TagMetadataItem.displayName = 'TagMetadataItem';

export default TagMetadataItem;
