import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { updateNote } from 'api/notesApi/notesApi';
import {
  NoteDetails,
  NoteListSortBy,
  UpdateDocNotePayload,
} from 'api/notesApi/notesApi.types';
import { BaseError } from 'common/models/Error.interface';
import { paginatedNotesOptimisticUpdate } from 'containers/SavedNotes/savedNotes.utils';

import { docNotesQueryKeys } from '../docNotes.utils';

export interface UseDocNoteUpdateProps {
  docId: string;
  options?: UseMutationOptions<
    UpdateDocNotePayload,
    BaseError,
    UpdateDocNotePayload,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >;
  q?: string;
  sortBy?: NoteListSortBy;
  withAnnotations?: boolean;
}

export const useDocNoteUpdate = ({
  docId,
  options = {},
}: UseDocNoteUpdateProps) => {
  const queryClient = useQueryClient();

  return useMutation<
    UpdateDocNotePayload,
    BaseError,
    UpdateDocNotePayload,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >(
    async (payload) => {
      await updateNote({ ...payload, objectType: 'document' });

      return payload;
    },
    {
      onError: (_, __, context) => {
        queryClient.setQueryData(
          docNotesQueryKeys.list(docId),
          context?.previousNotes
        );
      },
      onMutate(payload) {
        const previousNotes = queryClient.getQueryData<
          PaginatedResults<NoteDetails>
        >(docNotesQueryKeys.list(docId));

        queryClient.setQueryData<PaginatedResults<NoteDetails> | undefined>(
          docNotesQueryKeys.list(docId),
          (oldResults) => paginatedNotesOptimisticUpdate(payload, oldResults)
        );

        return { previousNotes };
      },
      onSettled() {
        return queryClient.invalidateQueries<PaginatedResults<NoteDetails>>(
          docNotesQueryKeys.list(docId)
        );
      },
      ...options,
    }
  );
};
