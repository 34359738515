import { AxiosResponse } from 'axios';

import {
  RecommendationsApiFactory,
  RecFeedbackPaginated,
  SeenRecs,
  Configuration,
} from '@zarn/vendor/dist/feedback';

import { serializeIds } from 'common/utils/apiHelpers';

import { SERVICE_URL, BASE_HEADERS } from './apiConfig';
import axiosInstance from './axiosInstance';

export const recommendationsFeedbackApi = RecommendationsApiFactory(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const getSeenRecommendations = (
  ids: string[]
): Promise<AxiosResponse<RecFeedbackPaginated>> => {
  return recommendationsFeedbackApi.feedbackList('', serializeIds(ids));
};

export const markRecommendationsAsSeen = (
  payload: SeenRecs
): Promise<AxiosResponse<void>> => {
  return recommendationsFeedbackApi.feedbackMarkSeen('', payload);
};
