import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import { Chip } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataPapersProps = {
  numberOfPapers: number;
};

const TagMetadataPapers = ({ numberOfPapers }: TagMetadataPapersProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagMetadataItem
      icon={DescriptionIcon}
      translation={
        <Trans i18nKey="tagMetadata.numberOfPapers.text" t={t}>
          <Chip label={numberOfPapers} size="small" />
        </Trans>
      }
    />
  );
};

TagMetadataPapers.displayName = 'TagMetadataPapers';

export default TagMetadataPapers;
