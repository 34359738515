import React, { useContext, useEffect, useMemo, useState } from 'react';

import SortIcon from '@mui/icons-material/Sort';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { PageDrawerMenuAction } from 'common/components/PageDrawer/PageDrawerMenuAction';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { posthogTag } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { TagsSortOption } from './TagsSort.interface';
import { TagsSortByEnum } from './TagsSortBy.enum';

const useStyles = makeStyles(() => ({
  select: {
    display: 'none',
  },
}));

type Props = {
  initialValue: TagsSortByEnum;
  onChange: (sortByValue: TagsSortByEnum) => Promise<void>;
  options: TagsSortOption[];
};

export const TagsSortSelect = ({ initialValue, onChange, options }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const [actionAnchor, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState<TagsSortByEnum>(initialValue);
  const selectedValueText = useMemo(() => {
    return options.find((option) => option.value === value)?.label ?? '';
  }, [value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const { actionContext } = useContext(TrackedActionContext);
  const { tenant } = useParsedHostname();
  const handleChange = async (event: SelectChangeEvent<TagsSortByEnum>) => {
    const newValue = event.target.value as TagsSortByEnum;
    await onChange(newValue);
    if (actionContext && tenant) {
      posthogTag.sortSelect(tenant, actionContext, newValue.toString());
    }
    setValue(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <PageDrawerMenuAction
        data-testid="tagsSortByTrigger"
        tooltipTitle={t('sort.sortedByTooltip', { value: selectedValueText })}
        onClick={handleOpen}
      >
        <SortIcon />
      </PageDrawerMenuAction>

      <Select
        className={classes.select}
        MenuProps={{
          anchorEl: actionAnchor,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          transformOrigin: { horizontal: 'right', vertical: 'top' },
        }}
        open={!!actionAnchor}
        value={value}
        onChange={handleChange}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
