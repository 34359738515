import { OrgRole, UserRole } from '../Auth.interface';
import { PermissionEnum, RoleEnum } from '../enums';
import { AccessToken } from '../interfaces/AccessToken';

import { Me } from './Me.interface';

export class MeCommonModel implements Me {
  readonly email: string;
  readonly exp: number;
  readonly iat: number;
  readonly sub: string;
  readonly userTenants: string[];
  readonly userRoles: string[];
  organization: OrgRole | null = null;
  userRolesData: UserRole[] = [];

  constructor(token: AccessToken) {
    this.email = token.email;
    this.exp = token.exp;
    this.iat = token.iat;

    this.sub = token.sub;
    this.userTenants = token.user_tenants?.tenants ?? [];
    this.userRoles = token.user_roles?.roles ?? [];
  }

  get roles() {
    return this.userRolesData.map(({ permission }) => permission);
  }

  get defaultOrganization(): UserRole | null {
    return this.getMemberPermissions()[0] ?? null;
  }

  hasAccess(roles: RoleEnum[]): boolean {
    return roles.some((role) => this.hasRole(role));
  }

  hasPermission(permission: PermissionEnum) {
    return this.userRoles.includes(permission);
  }

  protected isUserMemberOfOrganisation(role: UserRole) {
    return (
      !!role.accountId &&
      !!role.userRoleId &&
      role.permission === RoleEnum.Member
    );
  }

  protected getOrganization(): OrgRole | null {
    const orgRole = this.userRolesData.find((role) =>
      this.isUserMemberOfOrganisation(role)
    ) as OrgRole | undefined;

    if (!orgRole) {
      return null;
    }

    return {
      ...orgRole,
      accountId: orgRole.accountId,
      accountName: orgRole.accountName,
      name: orgRole.name,
    };
  }

  private hasRole(role: RoleEnum) {
    return !!this.userRolesData.find((ur) => ur.permission === role);
  }

  private getMemberPermissions(): UserRole[] {
    return this.userRolesData.filter(
      ({ permission }) => permission === RoleEnum.Member
    );
  }
}
