import React, { useState } from 'react';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import BibtexDialog from 'containers/Bibtex/BibtexDialog/BibtexDialog';

export type DocBibtexButtonProps = {
  bibtexId: string;
};

const DocBibtexButton = ({ bibtexId }: DocBibtexButtonProps) => {
  const { t } = useTranslation(['common']);
  const [bibtexDialog, setBibtexDialog] = useState<boolean>(false);

  return (
    <>
      <Tooltip title={t<string>('common:retrievalUnit.actions.bibtex')}>
        <TrackedIconButton
          aria-controls="actions"
          aria-haspopup="true"
          aria-label="document bibtex"
          eventName={TrackEventName.DocumentBibtexClicked}
          size="small"
          onClick={() => setBibtexDialog(!bibtexDialog)}
        >
          <FormatQuoteIcon color="primary" />
        </TrackedIconButton>
      </Tooltip>

      <BibtexDialog
        ids={[bibtexId]}
        open={bibtexDialog}
        setOpen={setBibtexDialog}
      />
    </>
  );
};

export default DocBibtexButton;
