import React from 'react';

import { HighlightRect } from '../../../types';
import { DEFAULT_HIGHLIGHT_COLOR } from '../../../const/defaults.const';
import { useStyles } from './style';

export type HighlightRectItemProps = {
  zoom: number;
  color?: string;
} & HighlightRect;

const HighlightRectItem = ({
  zoom,
  x1,
  y1,
  height,
  width,
  color = DEFAULT_HIGHLIGHT_COLOR,
}: HighlightRectItemProps) => {
  const classes = useStyles({ color });

  return (
    <div
      className={classes.root}
      style={{
        left: x1 * zoom,
        top: y1 * zoom,
        height: height * zoom,
        width: width * zoom,
      }}
    />
  );
};

HighlightRectItem.displayName = 'HighlightRectItem';

export default HighlightRectItem;
