import React, { useCallback } from 'react';

import { AutocompleteRenderGetTagProps } from '@mui/material';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import TagChip from 'common/components/TagChip/TagChip';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';

export const useTagsAutocompleteRenderTags = (
  suggestedTags: TagDetailsBase[]
) => {
  const renderTags = useCallback(
    (
      selectedTagsValue: TagsAutocompleteOptionValue[],
      getTagProps: AutocompleteRenderGetTagProps
    ) =>
      selectedTagsValue.map((option, index) => {
        const tagProps = { ...getTagProps({ index }) };

        if (option.permission === 'read') {
          // @ts-ignore
          delete tagProps['onDelete'];
        }

        return (
          <TagChip
            label={option.name}
            variant={
              suggestedTags.some((tag) => tag.id === option.id)
                ? 'suggested'
                : 'default'
            }
            {...tagProps}
            key={option.id}
          />
        );
      }),
    [suggestedTags]
  );

  return renderTags;
};
