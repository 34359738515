import React from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/StarRounded';
import { Link, MenuItem, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { RetrievalUnitGithubRepo } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    alignItems: 'center',
    display: 'inline-flex',
    overflow: 'hidden',
  },
  repoInfo: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    alignItems: 'unset',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90vw',
    padding: theme.spacing(1, 2),
    width: 400,
  },
  stars: {
    '& > svg': {
      color: theme.palette.warning.main,
      marginRight: theme.spacing(0.5),
    },
    alignItems: 'center',
    display: 'inline-flex',
    marginLeft: theme.spacing(2),
  },
  title: {
    /**
     * @workaround
     * truncate the word from the beginning
     */
    direction: 'rtl',

    overflow: 'hidden',

    paddingRight: theme.spacing(0.5),

    textAlign: 'left',
    textOverflow: 'ellipsis',
  },
}));

type RetrievalUnitGithubRepoProps = {
  data: RetrievalUnitGithubRepo;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>, repoLink: string) => void;
};

export const RetrievalUnitGithubRepoItem = React.forwardRef(
  (
    { data, onClick }: RetrievalUnitGithubRepoProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      onClick(e, data.url);
    };

    return (
      <MenuItem
        className={classes.root}
        component={Link}
        href={data.url}
        ref={ref}
        rel="noreferrer"
        target="_blank"
        title={data.url}
        onClick={handleClick}
      >
        <div className={classes.repoInfo}>
          <div className={classes.link}>
            <span className={classes.title}>
              {`${data.repoOwner}/${data.repoName}`}
            </span>

            {data.isOfficial && (
              <Tooltip
                title={t<string>('retrievalUnit.github.officialTooltip')}
              >
                <CheckIcon color="secondary" fontSize="small" />
              </Tooltip>
            )}
          </div>

          <div className={classes.stars}>
            <StarIcon />
            {data.stars}
          </div>
        </div>

        {data.description && (
          <Typography
            color="textSecondary"
            title={data.description}
            variant="body2"
            noWrap
          >
            {data.description}
          </Typography>
        )}
      </MenuItem>
    );
  }
);

RetrievalUnitGithubRepoItem.displayName = 'RetrievalUnitGithubRepoItem';
