import { useEffect, useMemo, useState } from 'react';

import { Nullable } from 'common/utils/assert';
import { Conversation, ConversationPayload } from 'containers/Chat/Chat.types';

import { Parser } from './Parser';
import { ParserV1 } from './ParserV1';

export const useParser = <T extends ConversationPayload = {}>(
  content: Nullable<string>
) => {
  const parser = useMemo(() => {
    if (ParserV1.isConversation(content)) {
      return new ParserV1<T>(content);
    }

    return new Parser<T>(content);
  }, [content]);

  const [noteConversation, setNoteConversation] =
    useState<Nullable<Conversation<T>>>(null);

  useEffect(() => {
    (async () => {
      if (parser) {
        setNoteConversation(await parser.disassemble());
      }
    })();
  }, [parser]);

  return { noteConversation, parser };
};
