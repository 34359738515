import { useMutation, useQueryClient } from 'react-query';

import { createNote } from 'api/notesApi/notesApi';
import { CreateDocNotePayload, NoteDetails } from 'api/notesApi/notesApi.types';
import { BaseError } from 'common/models/Error.interface';

import { docNotesQueryKeys } from '../docNotes.utils';

export const useDocNoteCreate = (docId: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    NoteDetails,
    BaseError,
    Omit<CreateDocNotePayload, 'objectId' | 'objectType'>
  >(
    async (payload) => {
      const { data } = await createNote({
        ...payload,
        objectId: docId,
        objectType: 'document',
      });

      return data;
    },
    {
      onSuccess() {
        return queryClient.invalidateQueries<NoteDetails>(
          docNotesQueryKeys.list(docId)
        );
      },
    }
  );
};
