import React, { FC } from 'react';

import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import tagsPage from 'containers/OnboardingWizard/images/add_new_tag.gif';
import saveDocument from 'containers/OnboardingWizard/images/save_document.gif';

export interface LastStepProps {
  onNext: () => void;
}

export const LastStep: FC<LastStepProps> = ({ onNext }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);

  return (
    <div aria-label={t('onboardingWizard:lastStep.label')}>
      <Box mb={3} mt={2}>
        <Typography variant="h5" gutterBottom>
          {t('onboardingWizard:lastStep.title')}
        </Typography>
        <Typography>{t('onboardingWizard:lastStep.text1')}</Typography>
        <Typography>{t('onboardingWizard:lastStep.text2')}</Typography>
      </Box>
      <Grid spacing={3} container>
        <Grid md={6} sm={12} item>
          <Card variant="outlined">
            <CardHeader
              subheader={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography color="textSecondary" variant="body2">
                  {t('onboardingWizard:lastStep.documentCard.subheader')}
                </Typography>
              }
              title={
                <Typography variant="body1">
                  {t('onboardingWizard:lastStep.documentCard.title')}
                </Typography>
              }
            />
            <Divider />
            <CardMedia
              image={saveDocument}
              style={{ paddingTop: '50%' }}
              title={t('onboardingWizard:lastStep.documentCard.title')}
            />
          </Card>
        </Grid>
        <Grid md={6} sm={12} item>
          <Card variant="outlined">
            <CardHeader
              subheader={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography color="textSecondary" variant="body2">
                  {t('onboardingWizard:lastStep.tagsCard.subheader')}
                </Typography>
              }
              title={
                <Typography variant="body1">
                  {t('onboardingWizard:lastStep.tagsCard.title')}
                </Typography>
              }
            />
            <Divider />
            <CardMedia
              image={tagsPage}
              style={{ paddingTop: '50%' }}
              title={t('onboardingWizard:lastStep.tagsCard.title')}
            />
          </Card>
        </Grid>
      </Grid>
      <Box display="flex" mt={2}>
        <SubmitButton
          aria-label="submit"
          eventName={TrackEventName.WizardLastStepCompleted}
          text={t('common:buttons.finish')}
          onClick={onNext}
        />
      </Box>
    </div>
  );
};
