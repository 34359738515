import { useEffect, useRef, useState } from 'react';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { Nullable } from 'common/utils/assert';

import { Conversation } from '../../Chat.types';

import { useParser } from './parsers/useParser';

export const useNoteConversation = (note: NoteDetails | null) => {
  const [isChatConversation, setIsChatConversation] = useState(false);
  const conversation = useRef<Nullable<Conversation>>(null);
  const { parser } = useParser(note?.content ?? null);

  useEffect(() => {
    (async () => {
      conversation.current = (await parser?.disassemble()) ?? null;
      setIsChatConversation(!!conversation.current);
    })();
  }, [parser, note]);

  return { conversation: conversation.current, isChatConversation };
};
