import React, { FC, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const ZetaIcon: FC<Props> = ({ size = 240, ...props }) => {
  return (
    <svg
      fill="none"
      height={size}
      style={{
        // @ts-ignore
        enableBackground: `new 0 0 ${size} ${size}`,
      }}
      viewBox="0 0 240 240"
      width={size}
      x={0}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y={0}
      {...props}
    >
      <path
        d="M111.9 135.3c0-11.3 4.3-21.4 12.8-29.9s18-12.8 28.2-12.8c7 0 12.6 2 16.7 6 4.2 4 7.1 8.9 8.5 14.6.6 2.9 1 6.2 1 10v4.4c4.9-7.4 8.6-15.6 11.2-24.8.4-1.4.7-2.2.9-2.4.2-.2.5-.3 1.2-.3.8-.1 1.6-.2 2.4-.1 1.3.1 2 .5 2 1.1 0 .9-.4 2.9-1.3 5.8-.9 3-2.7 7.2-5.4 12.7s-5.8 10.5-9.2 14.9l-1.7 2.1.2 3.6c.2 5.6.6 9.3 1.2 11.3s1.6 3.1 2.9 3.1c1.4-.2 2.7-.8 3.9-1.7s2-2 2.5-3.4c.2-.7.4-1.1.8-1.2.3-.1.7-.2 1.4-.3.6-.1 1.2-.1 1.9 0 1.3.1 1.9.5 1.9 1.1s-.3 1.6-.9 2.9c-1.2 2.4-3 4.3-5.2 5.7s-4.4 2.2-6.5 2.3h-1c-6.7 0-11.2-3.6-13.6-10.7l-.4-1-1.7 1.2c-1 .8-2.5 1.8-4.4 3-2 1.2-4.3 2.4-6.7 3.4s-5.4 2.1-8.6 3c-3.3.9-6.4 1.3-9.6 1.3-7.8 0-14-2.5-18.6-7.5-4.5-4.9-6.8-10.7-6.8-17.4zm26 19.4c4.9 0 9.8-1 14.6-3.1s8.5-4.3 10.9-6.4l3.6-3.3c-.2-14.2-.5-23.1-1-26.6-1.4-11.4-5.6-17.3-13.8-17.2-6.9.2-12.9 3.7-18 10.9-2.7 4-4.9 9.3-6.5 16.1-1.7 6.8-2.6 11.9-2.7 15.5 0 4.9 1.2 8.5 3.7 10.6 2.5 2.2 5.5 3.4 9.2 3.5z"
        fill="url(#gradientB)"
      />
      <path
        d="m82.9 75.2 1.3-.9C86 76.1 88.6 77 92 77h2.1c8.1-.2 12.3-2.2 12.7-6 0-3.3-3.8-5-11.5-5h-1.5l-1.5.2c-.9 0-1.9.1-3 .3-1.1.2-1.7.4-1.9.6-.2 0-.3.1-.4.2 0-.2-.1-.5-.2-1s-.2-1.2-.2-2.3c0-1.3.2-2.9.4-4.6.3-1.8.4-3 .4-3.4 0-1.7-.9-2.5-2.7-2.5-2.6 0-4 3.1-4.2 9.2 0 3 .3 5.4.9 7.1l-1.3.8c-9.5 5.7-18 14.6-25.7 26.9S43 122 43 134.7c.2 5.8 1.2 10.7 3.1 14.6 1.9 3.9 4.4 6.8 7.6 8.6 3.2 1.9 6.5 3.6 10 5s6.8 2.5 10 3.3 5.8 2 7.8 3.6 3 3.7 3 6.3c0 2.1-.6 3.8-1.8 5.2s-2.5 2.2-4 2.4c-3.6-.1-6.7-.9-9.2-2.3-2.6-1.4-4.1-2.1-4.4-2.1-1.1 0-2 .4-2.7 1.3-.6 1.1-.6 2-.2 2.9.4.5 1.5 1.2 3.1 2.3 1.7 1 3.3 1.7 4.8 2.1 2.2.8 4.6 1.2 7.3 1.2 1.8 0 3.1-.1 3.9-.3 3.9-1.3 7.1-3.7 9.6-7.1 2.5-3.5 3.7-7.1 3.7-11.1 0-6.3-2.7-10.7-8-13.4-2.5-1.7-5.8-3.3-10.3-4.4-15.6-3.5-21-11.5-22.6-18.4-.1-1.3-.2-2.7-.2-4.2 0-2.7.3-5.4.7-8.2 1.2-7.4 4-15 8.4-22.8 6-10.8 12.7-18.7 20.3-24z"
        fill="url(#gradientB)"
      />
      <path
        d="M129.9 62.7h49.3c-2.4-3.7-3.6-6.7-3.6-8.8 0-.8.3-1.5.8-2.1s1.2-.9 2.3-.9c1 0 1.7.4 2.3 1.2.5.8.9 1.7 1.2 2.9.3 1.1 1 2.4 1.9 4 1 1.6 2.4 3 4 4 1.1.7 1.7 1.6 1.7 2.7 0 .9-.3 1.7-1 2.3s-1.6 1.1-2.5 1.5c-1 .3-2.5 1.3-4.4 2.9-2 1.5-3.9 3.5-5.7 6-1.4 1.5-2.5 2.3-3.3 2.3s-1.5-.3-2.1-.9-.9-1.3-.9-2.1.5-1.9 1.7-3.4c1.1-1.5 2.3-2.7 3.4-3.7 1.2-1 1.8-1.6 1.9-1.7h-25.5l-21.3-.2c-1.6 0-2.9-1.3-3-2.9-.3-1.7 1.1-3.1 2.8-3.1z"
        fill="url(#gradientB)"
      />
      <path d="M0 0v240h240V0H0zm232 232H8V8h224v224z" fill="url(#gradientB)" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="gradientB"
          x1="39.38"
          x2="151.97"
          y1="-38.83"
          y2="73.76"
        >
          <stop stopColor="#58BCB4" />
          <stop offset=".45" stopColor="#4BA5AF" />
          <stop offset=".80" stopColor="#3680A7" />
          <stop offset=".86" stopColor="#2765A1" />
          <stop offset=".88" stopColor="#1E549E" />
          <stop offset=".90" stopColor="#1B4F9D" />
          <stop offset=".95" stopColor="#005D83" />
        </linearGradient>
      </defs>
    </svg>
  );
};
