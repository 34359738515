import { useTenantSettings } from './useTenantSettings';

export const useAssertTenantSettings = () => {
  const data = useTenantSettings();

  const tenantSettings = data.assertTenantSettings();

  const qaDefaultBot = tenantSettings.chat?.qaDefaultBot
    ? tenantSettings.chat.botsConfiguration[tenantSettings.chat?.qaDefaultBot]
        ?.tenantBotType
    : null;

  return {
    ...data,
    qaDefaultBot,
    tenantSettings,
  };
};
