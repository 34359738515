import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const linkOverrides: ComponentsOverrides<Theme>['MuiLink'] = {
  root: ({ theme }) => ({
    '&:visited': {
      color: theme.palette.primary.light,
    },
    color: theme.palette.primary.main,
  }),
};
