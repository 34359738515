import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  anchorsWrapper: {
    '& > div': {
      marginRight: theme.spacing(0.5),
    },
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 0),
    position: 'relative',
  },
  anchorsWrapperHidden: {
    opacity: 0,
  },
  searchToolbar: {
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(0.5, 0),
    position: 'relative',
  },
  searchToolbarActive: {
    borderColor: theme.palette.primary.main,
  },
  tagListsWrapper: {
    marginRight: theme.spacing(-1),
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tagSearchBox: {
    left: 40,
    position: 'absolute',
    width: 'calc(100% - 40px)',
    zIndex: 2,
  },
}));
