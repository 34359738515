import React, { FC } from 'react';

import { ConfirmDialog } from './ConfirmDialog';
import { useConfirmDialog } from './useConfirmDialog';

export const AppConfirmDialog: FC = () => {
  const { dialog } = useConfirmDialog();

  if (!dialog) {
    return null;
  }

  return <ConfirmDialog {...dialog} />;
};
