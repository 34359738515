import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  DialogTitle,
  IconButton,
  TypographyProps,
} from '@mui/material';

export type DialogActionTitleProps = {
  children: React.ReactNode;
  id?: string;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  titleTypographyProps?: TypographyProps<'div'>;
};

export const DialogActionTitle = ({
  children,
  onClose,
  titleTypographyProps = {},
  ...rest
}: DialogActionTitleProps) => {
  const handleClose = (e: React.MouseEvent) => {
    if (onClose) {
      onClose(e, 'backdropClick');
    }
  };

  return (
    <DialogTitle sx={{ m: 0, pr: '50px' }} {...rest}>
      <Typography {...titleTypographyProps} component="div" variant="h6">
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          size="large"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

DialogActionTitle.displayName = 'DialogActionTitleProps';

export default DialogActionTitle;
