import { useMemo } from 'react';

import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export const useOntologyDocIds = (items?: RetrievalUnitData[]) => {
  return useMemo<string[]>(
    () =>
      (items ?? [])
        .map(({ ontologyId }) => ontologyId)
        .filter((el): el is string => el !== null),
    [items]
  );
};
