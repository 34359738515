import React, { FC, ReactNode, useState } from 'react';

import { ConfirmDialogProps } from './ConfirmDialog.types';
import { ConfirmDialogContext } from './ConfirmDialogContext';

interface Props {
  children: ReactNode;
}

export const ConfirmDialogProvider: FC<Props> = ({ children }) => {
  const [dialog, setDialog] = useState<ConfirmDialogProps | null>(null);

  return (
    <ConfirmDialogContext.Provider
      value={{
        dialog,
        setDialog,
      }}
    >
      {children}
    </ConfirmDialogContext.Provider>
  );
};
