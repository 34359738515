import React, { useContext, useEffect } from 'react';

import { Route, Switch, useLocation } from 'react-router-dom';

import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { posthogCapture } from 'common/utils/posthog.utils';
import LoginDialog from 'containers/Auth/LoginDialog';
import { FeedbackDialogButton } from 'containers/Feedback/FeedbackDialogButton/FeedbackDialogButton';

import { AuthContext } from '../containers/Auth/Auth.context';

import { Page400, Page403, Page404 } from './ErrorPages';
import { InfoPage } from './InfoPage';
import { InnerPages } from './InnerPages';

const SignUpPage = React.lazy(() => import('pages/SignUpPage'));
const ResetPasswordPage = React.lazy(() => import('pages/ResetPasswordPage'));
const CreatePasswordPage = React.lazy(() => import('pages/CreatePasswordPage'));

export const Routes = () => {
  const { pathname } = useLocation();
  const { oidcConfig } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    posthogCapture('$pageview');
  }, [pathname]);

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Main page' }}>
      <Switch>
        <Route component={ResetPasswordPage} path="/reset-password" />
        <Route component={CreatePasswordPage} path="/create-password" />
        <Route component={SignUpPage} path="/signup" />
        <Route component={Page400} path="/400" />
        <Route component={Page403} path="/403" />
        <Route component={Page404} path="/404" />
        <Route component={InfoPage} path="/info/:id" />
        <InnerPages />
      </Switch>

      <FeedbackDialogButton />

      {!oidcConfig?.enabled && <LoginDialog />}
    </TrackedActionContext.Provider>
  );
};
