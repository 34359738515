import React, { FC } from 'react';

import { Box } from '@mui/material';

import { ZetaAlphaLogo } from 'common/components/CustomIcons/ZetaAlphaLogo';
import { ZetaIcon } from 'common/components/CustomIcons/ZetaIcon';
import TrackedRouterLink from 'common/components/Links/TrackedRouterLink/TrackedRouterLink';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { useImage } from 'common/hooks/useImage';
import { useInitialQuery } from 'containers/InitialQuery/hooks/useInitialQuery';

export const MainToolbarLogo: FC = () => {
  const {
    query: { data: initialQueryData },
  } = useInitialQuery();

  const { tenantSettings } = useAssertTenantSettings();
  const MobileLogo = useImage(
    tenantSettings.whitelabel?.logo?.mobile,
    <ZetaIcon size={32} />
  );
  const WebLogo = useImage(
    tenantSettings.whitelabel?.logo?.web,
    <ZetaAlphaLogo size={36} />
  );

  return (
    <Box sx={{ width: 180 }}>
      <TrackedRouterLink
        color="contrast"
        eventName={TrackEventName.ZetaAlphaLogoClicked}
        to={`/${initialQueryData?.searchParams ?? ''}`}
      >
        <Box
          sx={{
            display: {
              md: 'none',
              xs: 'flex',
            },
          }}
        >
          {MobileLogo}
        </Box>

        <Box
          sx={{
            display: {
              md: 'flex',
              xs: 'none',
            },
          }}
        >
          {WebLogo}
        </Box>
      </TrackedRouterLink>
    </Box>
  );
};
