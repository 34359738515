import { useMemo, useState } from 'react';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useTranslation } from 'react-i18next';

import { useTenantFeatures } from 'common/hooks/useTenantFeatures';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { DocAction } from '../DocActions.interface';

export const useDocActions = ({ getBibtexId }: RetrievalUnitData) => {
  const { t } = useTranslation(['common']);
  const [bibtexDialog, setBibtexDialog] = useState<boolean>(false);
  const { isExportBibtexInDoc } = useTenantFeatures();

  const docActions = useMemo<DocAction[]>(
    () => [
      ...(getBibtexId && isExportBibtexInDoc
        ? [
            {
              Icon: FormatQuoteIcon,
              action: () => setBibtexDialog(true),
              title: t('common:retrievalUnit.actions.bibtex'),
            },
          ]
        : []),
    ],
    [t, getBibtexId, setBibtexDialog, isExportBibtexInDoc]
  );

  return {
    bibtexDialog,
    docActions,
    setBibtexDialog,
  };
};
