import React, { FC } from 'react';

import { Button, Typography, Box, Grid } from '@mui/material';
import { AppDispatch } from 'app/state/store';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { FormAutocomplete } from 'common/components/Fields/FormAutocomplete/FormAutocomplete';
import OnboardingWizardImg from 'common/components/OnboardingWizard/OnboardingWizardImg/OnboardingWizardImg';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import secondStepImg from 'containers/OnboardingWizard/images/second_step.jpg';
import {
  addMultipleTags,
  addTag,
} from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { updateUser } from 'containers/User/user.slice';

const validationSchema = Yup.object().shape({
  tags: Yup.array().min(
    1,
    'Type any topic name and press [ENTER] to add. Please add at least one topic.'
  ),
});

interface TagFormValues {
  tags: string[];
}

const initialValues = {
  tags: [] as string[],
};

export interface SecondStepProps {
  onBack: () => void;
  onNext: () => void;
}

export const SecondStep: FC<SecondStepProps> = ({ onBack, onNext }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);
  const dispatch = useDispatch<AppDispatch>();
  const { tenant } = useParsedHostname();

  const handleSubmitForm = async (
    { tags }: TagFormValues,
    { setErrors, setSubmitting }: FormikHelpers<TagFormValues>
  ) => {
    if (tags.length === 0) {
      onNext();
    } else {
      let error: string | null = null;
      setSubmitting(true);
      const { payload, type } = await dispatch(
        addMultipleTags({ payload: tags, tenant })
      );
      await dispatch(updateUser({ onboarded: true }));
      error = type === addTag.rejected?.type ? String(payload) : null;

      setSubmitting(false);

      if (!error) {
        onNext();
      } else {
        setErrors({ tags: error });
      }

      setSubmitting(false);
    }
  };

  return (
    <Grid
      aria-label={t('onboardingWizard:secondStep.label')}
      spacing={3}
      container
    >
      <Grid lg={6} md={5} sm={12} item>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom>
            {t('onboardingWizard:secondStep.title')}
          </Typography>
        </Box>
        <Typography gutterBottom>
          {t('onboardingWizard:secondStep.subTitle')}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onReset={onBack}
          onSubmit={handleSubmitForm}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <FormAutocomplete
                helperText={t(
                  'onboardingWizard:secondStep.addTopics.helperText'
                )}
                label={t('onboardingWizard:secondStep.addTopics.label')}
                name="tags"
                options={[]}
                placeholder={t(
                  'onboardingWizard:secondStep.addTopics.placeholder'
                )}
                freeSolo
              />
              <Box display="flex" mt={2}>
                <Box mr={1}>
                  <Button type="reset">{t('common:buttons.back')}</Button>
                </Box>
                <SubmitButton
                  aria-label="submit"
                  disabled={!isValid || isSubmitting}
                  eventName={TrackEventName.WizardSecondStepCompleted}
                  isSubmitting={isSubmitting}
                  text={t('common:buttons.next')}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid lg={6} md={7} sm={12} item>
        <OnboardingWizardImg
          alt={t('onboardingWizard:secondStep.imageAlt')}
          src={secondStepImg}
        />
      </Grid>
    </Grid>
  );
};
