import React from 'react';

import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  anchor: {
    position: 'relative',
    top: -90,
    visibility: 'hidden',
  },
  section: {
    margin: theme.spacing(2, 0, 4),
  },
  sectionTitle: {
    '&:after': {
      backgroundColor: theme.palette.divider,
      content: '""',
      display: 'block',
      height: 2,
      marginTop: theme.spacing(0.5),
      width: 60,
    },
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  anchor?: string;
  children: React.ReactNode;
  sectionTitle: string;
};

export const PageSection = ({ anchor = '', children, sectionTitle }: Props) => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      {anchor && <div className={classes.anchor} id={anchor} />}

      <Typography className={classes.sectionTitle} variant="h5">
        {sectionTitle}
      </Typography>

      {children}
    </section>
  );
};
