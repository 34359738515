import React from 'react';

import { LinkProps, Typography } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';

import DocTitleLink from 'common/components/Docs/DocTitleLink';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { FeedbackType } from 'containers/Feedback/enums';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';

const MAX_LENGTH = 120;

type DocTitleProps = {
  id: string;
  resultType?: ResultType;
  searchId?: string;
  title: string;
  uri?: string | null;
} & LinkProps;

const DocTitle = React.memo(
  ({
    id,
    onAction,
    onClick,
    resultType,
    searchId,
    title,
    uri,
    ...typographyProps
  }: WithTrackedActionWrappedProps<DocTitleProps>) => {
    const { sendFeedback } = useSendFeedback();
    const truncatedTitle =
      title.length > MAX_LENGTH ? `${title.slice(0, MAX_LENGTH)}...` : title;

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (searchId && resultType) {
        sendFeedback({
          feedbackType: FeedbackType.Click,
          resultId: id,
          resultType,
          searchId,
        });
      }

      onAction();
      if (!onClick) return;

      onClick(event);
    };

    if (!uri) {
      return (
        <Typography component="span" {...typographyProps}>
          {truncatedTitle}
        </Typography>
      );
    }

    return (
      <DocTitleLink
        data-testid="retrievalUnitTitleLink"
        docId={id}
        docTitle={title}
        href={uri}
        target="_blank"
        underline="hover"
        onClick={handleClick}
        {...typographyProps}
      />
    );
  }
);

DocTitle.displayName = 'DocTitle';

export default withTrackedAction<DocTitleProps & WithTrackedActionProps>(
  DocTitle,
  TrackEventName.DocumentURLClicked
);
