import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Fade, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AutoSaveProps {
  isSaving: boolean;
}

export const SavingStatus: FC<AutoSaveProps> = ({ isSaving }) => {
  const [showingStatus, setShowingSaveStatus] = useState(isSaving);
  const { t } = useTranslation(['common']);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const show = useCallback(() => {
    setShowingSaveStatus(isSaving);
  }, [isSaving]);

  const hide = useCallback((force?: boolean) => {
    if (timer.current && !force) {
      return;
    }
    setShowingSaveStatus(false);
  }, []);

  const showAndHide = useCallback(() => {
    show();
    timer.current = setTimeout(() => {
      hide(true);
    }, 1500);
  }, [hide, show]);

  useEffect(() => {
    if (isSaving) {
      return showAndHide();
    }

    hide();
  }, [hide, show, showAndHide, isSaving, t]);

  return (
    <Fade in={showingStatus}>
      <Typography
        color="primary"
        data-testid="saving-status"
        fontSize="small"
        sx={{
          height: 30,
          padding: '0.5rem 3rem 0rem 1rem ',
          textAlign: 'right',
        }}
      >
        {showingStatus ? t('common:chat.saveStatus.saving') : null}
      </Typography>
    </Fade>
  );
};
