import React, { FC } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import { Hidden, useTheme } from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';

import NavButtonLink from 'common/components/Buttons/NavButtonLink';
import { useAuth } from 'containers/Auth/hooks/useAuth';

import { MainToolbarSignInButton } from './MainToolbarSignInButton';
import { MainToolbarSignUpButton } from './MainToolbarSignUpButton';

export const MainToolbarButtons: FC = () => {
  const { t } = useTranslation('common');
  const { isAuthenticated } = useAuth();
  const theme = useTheme<AppTheme>();

  if (isAuthenticated) {
    return null;
  }

  return (
    <>
      <Hidden mdDown>
        <NavButtonLink title={t('navBar.about')} to="/product/about">
          <HelpIcon sx={{ color: theme.palette.header.iconColor }} />
        </NavButtonLink>
        <MainToolbarSignInButton />
      </Hidden>
      <MainToolbarSignUpButton />
    </>
  );
};
