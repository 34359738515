import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles<Theme, { color: string }>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({ color }) => color,
      position: 'absolute',
      userSelect: 'none',
      transition: theme.transitions.create('background-color'),
    },
  })
);
