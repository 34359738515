import React from 'react';
import { IconButton, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { HighlightAction } from '../../../types';

export type HighlightTooltipActionProps<T = void> = {
  title: string;
  action: T;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    action: T | HighlightAction
  ) => void;
};

const HighlightTooltipAction = <T,>({
  title,
  action,
  onClick,
  icon: Icon,
}: HighlightTooltipActionProps<T>) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;

    onClick(e, action);
  };

  return (
    <Tooltip title={title} placement="bottom" enterDelay={300}>
      <IconButton size="small" onClick={handleClick}>
        <Icon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default HighlightTooltipAction;
