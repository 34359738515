import React from 'react';
import { useTheme } from '@mui/material';

export type PDFPageShadowProps = {
  originalWidth: number;
  originalHeight: number;
  zoomLevel: number;
  children?: React.ReactNode;
};

const PDFPageShadow = ({
  originalWidth,
  zoomLevel,
  originalHeight,
  children,
}: PDFPageShadowProps) => {
  const { spacing } = useTheme();

  return (
    <div
      style={{
        width: Math.round(originalWidth * zoomLevel),
        height: Math.round(originalHeight * zoomLevel),
        margin: spacing(0, 'auto', 1),
        backgroundColor: 'white',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
};

export default PDFPageShadow;
