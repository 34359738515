import React, { FC } from 'react';

import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useTranslation } from 'react-i18next';

import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import NotificationLinkItem from 'containers/Notifications/NotificationLinkItem';
import { useRecommendationsUpdates } from 'containers/Notifications/useRecommendationsUpdates';

interface Props {
  onClose: () => void;
}

export const NewRecsNotifications: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { isAuthenticated } = useAuth();
  const { hasNewRecs, unseenRecsCount } = useRecommendationsUpdates();

  if (!isAuthenticated || !hasNewRecs) {
    return null;
  }

  return (
    <NotificationLinkItem
      chipLabel={unseenRecsCount}
      eventName={TrackEventName.RecommendationsNotificationClicked}
      icon={NewReleasesIcon}
      text={t<string>('navBar.recommendations')}
      to="/recommendations"
      onClick={onClose}
    />
  );
};
