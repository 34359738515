import React from 'react';

import { Badge, BadgeProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  colorWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
}));

type StyledBadgeProps = Omit<BadgeProps, 'color'> & {
  color?: 'primary' | 'secondary' | 'default' | 'error' | 'warning';
};

export const StyledBadge = ({
  children,
  color,
  overlap = 'rectangular',
  ...rest
}: StyledBadgeProps) => {
  const classes = useStyles();

  return (
    <Badge
      {...rest}
      classes={{ badge: clsx(color === 'warning' && classes.colorWarning) }}
      color={color === 'warning' ? undefined : color}
      overlap={overlap}
    >
      {children}
    </Badge>
  );
};
