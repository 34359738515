import React from 'react';
import DeleteIcon from '@mui/icons-material/DeleteRounded';

import HighlightTooltipAction from '../HighlightTooltipAction';

export type HighlightTooltipDeleteActionProps = {
  onDelete?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: 'delete'
  ) => void;
};

const HighlightTooltipDeleteAction = ({
  onDelete,
}: HighlightTooltipDeleteActionProps) => {
  return (
    <HighlightTooltipAction
      title="Delete"
      action="delete"
      onClick={onDelete}
      icon={DeleteIcon}
    />
  );
};

export default HighlightTooltipDeleteAction;
