import { blue, deepOrange, green, grey, pink } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';

import { TenantSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';

import { autocompleteOverrides } from './overrides/autocomplete';
import {
  buttonGroupOverrides,
  buttonOverrides,
  iconButtonOverrides,
  toggleButtonOverrides,
} from './overrides/button';
import {
  cardActionsOverrides,
  cardContentOverrides,
  cardOverrides,
} from './overrides/card';
import { chipOverrides } from './overrides/chip';
import {
  dialogActionsOverrides,
  dialogTitleOverrides,
} from './overrides/dialog';
import { svgIconOverrides } from './overrides/icon';
import {
  formControlLabelOverrides,
  outlinedInputOverrides,
} from './overrides/input';
import { linkOverrides } from './overrides/link';
import { listItemIconOverrides, listItemTextOverrides } from './overrides/list';
import {
  stepConnectorOverrides,
  stepContentOverrides,
  stepIconOverrides,
} from './overrides/stepper';
import { toolbarOverrides } from './overrides/surfaces';
import { tabOverrides } from './overrides/tabs';
import { tooltipOverrides } from './overrides/tooltip';
import { typographyOverrides } from './overrides/typography';
import { SHADOWS } from './shadows';
import { globalStyles } from './styles/global';

export const DRAWER_WIDTH = 280;
const THEME_SPACING = 8;
const FONT_SIZE = 16;

export const SEARCH_INPUT_BORDER_RADIUS = 13;
export const BORDER_RADIUS = 13;
export const SQUARE_BORDER_RADIUS = 4;

export const PRIMARY = '#005d83';
export const PRIMARY_RGB = '0, 93, 131';
export const PRIMARY_01 = `rgba(${PRIMARY_RGB}, 0.1)`;
export const PRIMARY_015 = `rgba(${PRIMARY_RGB}, 0.15)`;

export const SECONDARY = blue[600];

// const MIDDLE = '#1A4EA0';
const MIDDLE_RGB = '26, 78, 160';
const MIDDLE_01 = `rgba(${MIDDLE_RGB}, 0.1)`;
const MIDDLE_015 = `rgba(${MIDDLE_RGB}, 0.15)`;

// const END = '#29D9C3';
const END_RGB = '41, 217, 195';
const END_01 = `rgba(${END_RGB}, 0.1)`;
const END_015 = `rgba(${END_RGB}, 0.15)`;

export const HIGHLIGHT = '#F17225';

export const BODY_COLOR = '#fafcff';

const X_COLOR = '#000';
const LINKEDIN_COLOR = '#0077B5';
const EMAIL_COLOR = '#d65346';
const GITHUB_COLOR = '#181515';
const DEFAULT_TAG_COLOR = '#ebf5f9';

export const MAIN_CONTENT_GRADIENT = `linear-gradient(58deg, ${PRIMARY_01} 25%, ${MIDDLE_01} 65%, ${END_01} 100%)`;
export const TOP_BAR_GRADIENT = `linear-gradient(92deg, ${PRIMARY_015} 25%, ${PRIMARY_015} 25%, ${MIDDLE_015} 65%, ${END_015} 100%)`;
export const SIDE_BAR_GRADIENT = `linear-gradient(162deg, ${PRIMARY_015} 25%, ${MIDDLE_015} 65%, ${END_015} 100%)`;

interface TenantTheme {
  palette: {
    header: {
      background: string;
      fontColor: string;
      iconColor: string;
    };
    icons: {
      defaultColor: string;
      highlight: string;
    };
    mainContent: {
      background: string;
    };
    searchBar: {
      background: string;
    };
    sideDrawer: {
      background: string;
      backgroundColor: string;
      icons: {
        defaultColor: string;
        highlight: string;
      };
    };
  };
}

export type AppTheme = Theme & TenantTheme;
export type AppThemeOption = ThemeOptions & TenantTheme;

export const buildTheme = (settings: TenantSettings | null) => {
  const theme: AppThemeOption = {
    palette: {
      background: {
        default: BODY_COLOR,
      },
      body: BODY_COLOR,
      contrast: {
        main: '#fff',
      },
      contrastThreshold: 2.5,
      divider: 'rgba(0, 0, 0, 0.08)',
      email: EMAIL_COLOR,
      error: {
        light: pink[100],
        main: pink[500],
      },
      github: GITHUB_COLOR,
      header: {
        background:
          settings?.whitelabel?.header?.background ?? TOP_BAR_GRADIENT,
        fontColor: settings?.whitelabel?.header?.fontColor ?? BODY_COLOR,
        iconColor: settings?.whitelabel?.header?.iconColor ?? PRIMARY,
      },
      icons: {
        defaultColor: settings?.whitelabel?.icons?.defaultColor ?? SECONDARY,
        highlight: settings?.whitelabel?.icons?.highlight ?? HIGHLIGHT,
      },
      info: {
        light: SECONDARY,
        main: SECONDARY,
      },
      linkedin: LINKEDIN_COLOR,
      mainContent: {
        background:
          settings?.whitelabel?.mainContent?.background ??
          MAIN_CONTENT_GRADIENT,
      },
      mode: 'light',
      neutral: {
        contrastText: grey[900],
        dark: grey[500],
        light: grey[100],
        main: grey[300],
      },
      primary: {
        main: PRIMARY,
      },
      searchBar: {
        background: settings?.whitelabel?.searchBar?.background ?? SECONDARY,
      },
      secondary: {
        dark: SECONDARY,
        light: SECONDARY,
        main: SECONDARY,
      },
      sideDrawer: {
        background:
          settings?.whitelabel?.mainContent?.background ?? SIDE_BAR_GRADIENT,
        backgroundColor: settings?.whitelabel?.mainContent?.background
          ? ''
          : BODY_COLOR,
        icons: {
          defaultColor: settings?.whitelabel?.icons?.defaultColor ?? PRIMARY,
          highlight: settings?.whitelabel?.icons?.highlight ?? HIGHLIGHT,
        },
      },
      success: {
        main: green[600],
      },
      tagChip: DEFAULT_TAG_COLOR,
      warning: {
        light: deepOrange[200],
        main: deepOrange[500],
      },
      x: X_COLOR,
    },
    shadows: SHADOWS,
    shape: { borderRadius: BORDER_RADIUS },
    spacing: THEME_SPACING,
    typography: {
      fontFamily: 'Inter',
      htmlFontSize: FONT_SIZE,
    },
  };

  theme.components = {
    MuiAutocomplete: { styleOverrides: autocompleteOverrides },
    MuiButton: { styleOverrides: buttonOverrides },
    MuiButtonGroup: { styleOverrides: buttonGroupOverrides },
    MuiCard: { styleOverrides: cardOverrides },
    MuiCardActions: { styleOverrides: cardActionsOverrides },
    MuiCardContent: { styleOverrides: cardContentOverrides },
    MuiChip: { styleOverrides: chipOverrides },
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
    MuiDialogActions: { styleOverrides: dialogActionsOverrides },
    MuiDialogTitle: { styleOverrides: dialogTitleOverrides },
    MuiFormControlLabel: {
      styleOverrides: formControlLabelOverrides,
    },
    MuiIconButton: { styleOverrides: iconButtonOverrides },
    MuiLink: { styleOverrides: linkOverrides },
    MuiListItemIcon: { styleOverrides: listItemIconOverrides },
    MuiListItemText: { styleOverrides: listItemTextOverrides },
    MuiOutlinedInput: { styleOverrides: outlinedInputOverrides },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: ({ theme: { shape } }) => ({
          borderRadius: shape.borderRadius,
        }),
      },
    },
    MuiStepConnector: { styleOverrides: stepConnectorOverrides },
    MuiStepContent: { styleOverrides: stepContentOverrides },
    MuiStepIcon: { styleOverrides: stepIconOverrides },
    MuiSvgIcon: { styleOverrides: svgIconOverrides },
    MuiTab: { styleOverrides: tabOverrides },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiToggleButton: { styleOverrides: toggleButtonOverrides },
    MuiToolbar: { styleOverrides: toolbarOverrides },
    MuiTooltip: { styleOverrides: tooltipOverrides },
    MuiTypography: { styleOverrides: typographyOverrides },
  } as Theme['components'];

  return createTheme(theme);
};
