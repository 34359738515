import { SourceListItem } from '@zarn/vendor/dist/search';

import { TreeViewItem } from 'common/components/TreeViewSelect/TreeViewSelect.interface';

export const deserializeDocSource = ({
  identifiers,
  subsources,
  title,
}: SourceListItem): TreeViewItem => {
  const id = (identifiers && identifiers[0]) || title;

  if (subsources && subsources.length > 0) {
    return {
      /**
       * WORKAROUND
       * Parent source may have a child source with the same ID, which
       * can cause duplicate source ID.
       */
      id: `${id}_parent`,
      items: subsources.map(deserializeDocSource),
      title,
    };
  }

  return {
    id,
    title,
  };
};
