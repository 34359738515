import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { getSourceList } from 'api/sourcesApi';
import { TreeViewItem } from 'common/components/TreeViewSelect/TreeViewSelect.interface';

import { deserializeDocSource } from './documentSources.utils';

export type DocumentSourcesState = {
  data: TreeViewItem[];
  error: string | null;
  fetchState: FiniteStatesType;
};

export const initialState: DocumentSourcesState = {
  data: [],
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getDocumentSources = createAsyncThunk<
  TreeViewItem[],
  { tenant: string; userHasLoggedIn?: boolean },
  { state: RootState }
>(
  'documentSources/get',
  async ({ tenant }) => {
    const { data } = await getSourceList(tenant);

    return data.map(deserializeDocSource);
  },
  {
    condition: (
      { userHasLoggedIn } = { tenant: '', userHasLoggedIn: false },
      { getState }
    ) => {
      const {
        documentSources: { fetchState },
      } = getState();
      if (
        !userHasLoggedIn &&
        (fetchState === FiniteStates.Loading ||
          fetchState === FiniteStates.Success)
      ) {
        return false;
      }
      return undefined;
    },
  }
);

const documentSourcesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentSources.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getDocumentSources.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error =
          action.error.message || 'Sorry, unexpected error happened';
      })
      .addCase(getDocumentSources.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data = payload;
        state.error = null;
      });
  },
  initialState,
  name: 'documentSources',
  reducers: {},
});

export const documentSourcesDataSelector = (state: RootState): TreeViewItem[] =>
  state.documentSources.data;

export const documentSourcesFetchStateSelector = (
  state: RootState
): FiniteStates => state.documentSources.fetchState;

export const selectDocumentSources = createSelector(
  documentSourcesDataSelector,
  (data) => data
);

export const selectDocumentSourcesLoading = createSelector(
  documentSourcesFetchStateSelector,
  (fetchState) => fetchState === FiniteStates.Loading
);

export default documentSourcesSlice.reducer;
