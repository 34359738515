import { captureException } from '@sentry/react';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { BotType } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { defined, Nullable } from 'common/utils/assert';
import { Conversation, ConversationPayload } from 'containers/Chat/Chat.types';

import { ParserInterface } from './Parser.interface';

export class ParserV1<T extends ConversationPayload = {}>
  implements ParserInterface
{
  private static CONVERSATION_START = '## Chat';

  constructor(public content: Nullable<string>) {}

  static prepareSenderContent = ({
    content,
    sender,
  }: {
    content: string[];
    sender: string;
  }) => ({
    content: content
      .map((line) => ParserV1.prepareMessageContent(line))
      .join('\n\n')
      .trim(),
    sender: ParserV1.prepareMessageSender(sender),
  });

  static getSenderContent = (message: string) => {
    const [sender, ...content] = message.split('\n\n');
    if (sender) {
      return { content, sender };
    }
    if (content[0].startsWith('###')) {
      return { content: content.splice(1), sender: content[0] };
    }
    throw new Error(`Unknown chat sender in the message: ${message}`);
  };

  static prepareMessage = (message: string | undefined): string =>
    message ? message.replaceAll('\n', '\n>') : '';

  static getBotType = (conversation: string): BotType => {
    if (
      conversation.startsWith(`${ParserV1.CONVERSATION_START} <!-- summary -->`)
    ) {
      return 'summary';
    }
    if (
      conversation.startsWith(`${ParserV1.CONVERSATION_START} <!-- quizbot -->`)
    ) {
      return 'quizbot';
    }
    return 'default';
  };

  static isConversation(content: Nullable<string>) {
    return !!content && content.startsWith(ParserV1.CONVERSATION_START);
  }

  private static deserializeChatSender = (
    sender: string
  ): ChatMessageSenderEnum => {
    switch (sender) {
      case 'bot':
        return ChatMessageSenderEnum.Bot;
      case 'user':
        return ChatMessageSenderEnum.User;
      default:
        throw new Error(`Unknown chat sender: ${sender}`);
    }
  };

  private static prepareMessageSender = (sender: string) =>
    ParserV1.deserializeChatSender(sender.trim().slice(4, -1));

  private static prepareMessageContent = (line: string): string => {
    const trimmedLine = line.trim();
    if (trimmedLine.startsWith('>')) {
      return this.prepareMessageContent(trimmedLine.slice(1));
    }
    return trimmedLine;
  };

  public async disassemble(): Promise<Conversation<T> | null> {
    try {
      if (!this.content) {
        return null;
      }
      const botType = ParserV1.getBotType(this.content);
      return Promise.resolve({
        botType,
        messages: defined(this.content)
          .split('\n\n---\n\n')
          .slice(1) // Skip header with bot type
          .map((message) => ParserV1.getSenderContent(message))
          .map(({ content, sender }) =>
            ParserV1.prepareSenderContent({ content, sender })
          ),
      });
    } catch (error) {
      captureException(error);
      return null;
    }
  }

  public async assemble(conversation: Conversation): Promise<string> {
    this.content = conversation.messages.reduce((acc, message) => {
      return `${acc}\n\n---\n\n### ${
        message.sender
      }: \n\n${ParserV1.prepareMessage(message.content)}`;
    }, `${ParserV1.CONVERSATION_START} <!-- ${conversation.botType} -->`);

    return Promise.resolve(this.content);
  }
}
