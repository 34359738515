import { MutableRefObject, useCallback, useEffect } from 'react';

interface UseScrollIntoViewReturn {
  triggerScroll: () => void;
}

export const useScrollIntoView = (
  ref: MutableRefObject<HTMLElement | null>,
  condition: boolean = true,
  scrollIntoViewArgs: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'center',
  }
): UseScrollIntoViewReturn => {
  const triggerScroll = useCallback(() => {
    if (!ref.current?.scrollIntoView) return;

    ref.current.scrollIntoView(scrollIntoViewArgs);
  }, [ref]);

  useEffect(() => {
    if (!condition) return;

    triggerScroll();
  }, [triggerScroll, condition]);

  return {
    triggerScroll,
  };
};
