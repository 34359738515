import React, { useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import SuggestedIcon from '@mui/icons-material/ThumbUpOutlined';
import { alpha, darken, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SQUARE_BORDER_RADIUS } from 'app/theme';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { validateHTMLColor } from 'validate-color';

export type TagChipVariant = 'default' | 'suggested';

const useStyles = makeStyles<Theme, { color?: string }>((theme: Theme) => ({
  appendButton: {
    '&:hover': {
      color: ({ color = theme.palette.tagChip }) =>
        alpha(theme.palette.getContrastText(color), 0.7),
    },
    color: ({ color = theme.palette.tagChip }) =>
      alpha(theme.palette.getContrastText(color), 0.2),
    cursor: 'pointer',
    display: 'inline-flex',
    marginLeft: theme.spacing(0.5),
    marginRight: 0,
    transition: 'color .25s ease-out',
  },
  docNumber: {
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.divider}`,
    display: 'inline-flex',
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  icon: {
    color: 'inherit',
    height: '16px',
    width: '16px',
  },
  label: {
    maxWidth: '18ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  prependIcon: {
    extend: 'icon',
    marginRight: theme.spacing(0.5),
  },
  root: {
    '&$rootSuggested': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.secondary.main,
    },
    alignItems: 'center',
    backgroundColor: ({ color = theme.palette.tagChip }) => color,
    borderRadius: SQUARE_BORDER_RADIUS,
    color: ({ color = theme.palette.tagChip }) =>
      theme.palette.getContrastText(color),
    display: 'inline-flex',
    fontSize: theme.typography.fontSize * 0.875,
    height: 20,
    lineHeight: '20px',
    padding: theme.spacing(0, 0.5),
    whiteSpace: 'nowrap',
  },
  rootClickable: {
    '&$rootSuggested:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: ({ color = theme.palette.tagChip }) =>
        darken(color, 0.05),
    },
    cursor: 'pointer',
    transition: 'background-color .25s ease-out',
  },
  rootSuggested: {
    boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
  },
}));

export type TagChipProps = {
  className?: string;
  color?: string;
  label: string;
  numberOfDocuments?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: TagChipVariant;
};

const TagChip = (
  {
    className,
    color,
    label,
    numberOfDocuments,
    onClick,
    onDelete,
    variant = 'default',
    ...props
  }: TagChipProps,
  ref: React.Ref<HTMLSpanElement>
) => {
  const validatedColor = useMemo(
    () => (validateHTMLColor(color ?? '') ? color : undefined),
    [color]
  );
  const { t } = useTranslation('tags');
  const classes = useStyles({ color: validatedColor });
  const isSuggested = variant === 'suggested';

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete(event);
    }
  };

  return (
    <span
      {...props}
      aria-label={
        isSuggested
          ? t('tagChip.suggested.ariaLabel')
          : t('tagChip.default.ariaLabel')
      }
      className={clsx(
        classes.root,
        className,
        isSuggested && classes.rootSuggested,
        onClick && classes.rootClickable
      )}
      ref={ref}
      role={onClick && 'button'}
      onClick={handleClick}
    >
      {isSuggested && <SuggestedIcon className={classes.prependIcon} />}

      <span className={classes.label}>{label}</span>

      {typeof numberOfDocuments === 'number' && (
        <span className={classes.docNumber}>
          <DescriptionIcon fontSize="small" />
          {numberOfDocuments}
        </span>
      )}

      {onDelete && (
        <button
          className={classes.appendButton}
          data-testid="tagDeleteButton"
          title={t<string>('tagChip.deleteButton')}
          type="button"
          onClick={handleDelete}
        >
          <CancelIcon className={classes.icon} />
        </button>
      )}
    </span>
  );
};

export default React.forwardRef(TagChip);
