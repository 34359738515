import {
  UserDocumentAccessRights,
  UserDocumentForm,
} from '@zarn/vendor/dist/user-documents';

import { serializeDocResource } from 'common/utils/documents';
import { getAuthorsFullNames } from 'containers/RetrievalUnit/retrievalUnit.utils';

import { CreateExternalDocumentPayload } from './externalDocApi.types';

export const serializeCreateExternalDocumentPayload = ({
  abstractContent,
  authors,
  date,
  numberOfCitations,
  resources = [],
  searchEngine,
  source,
  title,
  uri,
  year,
}: CreateExternalDocumentPayload): UserDocumentForm => {
  return {
    access_roles: [UserDocumentAccessRights.Own],
    document_metadata: {
      authors: getAuthorsFullNames({ authors }),
      cited_by_count: numberOfCitations ?? null,
      date: date || new Date().toISOString(),
      description: abstractContent,
      resources: resources?.map(serializeDocResource),
      search_engine: searchEngine,
      source: source,
      title: title,
      year: year ?? undefined,
    },
    uri: uri ?? undefined,
  };
};
