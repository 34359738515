import { useCallback, useContext } from 'react';

import { HomePageContext } from 'pages/HomePage/HomePageContextProvider';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useSavedPropertyFromNote } from 'common/hooks/useSavedPropertyFromNote';
import { extractUrlParamValueParamPair } from 'common/utils/urlString';

import { useRetrievalUnitFromNote } from './useRetrievalUnitFromNote';

interface Props {
  noteContent: string;
  noteId: number;
  onOpenChatClick?: () => void;
}

export const useOpenQAChat = ({
  noteContent,
  noteId,
  onOpenChatClick,
}: Props) => {
  const navigation = useNavigationWithState();
  const { getSavedPropFromNote } = useSavedPropertyFromNote(noteContent);

  const homeContext = useContext(HomePageContext);
  const { getRetrievalUnitFromNote } = useRetrievalUnitFromNote(noteContent);

  const openQAChat = useCallback(async () => {
    onOpenChatClick?.();
    const originalUrl = await getSavedPropFromNote('originalUrl');

    const retrievalUnit = await getRetrievalUnitFromNote();
    if (retrievalUnit) {
      homeContext?.setRetrievalUnit(retrievalUnit);
    }

    if (originalUrl) {
      const fsPart = extractUrlParamValueParamPair(originalUrl, 'fs');
      const commonPart = `tab=chat&noteId=${noteId}`;
      const newUrl = fsPart ? `/?${fsPart}&${commonPart}` : `/?${commonPart}`;
      navigation.push(newUrl);
    }
  }, [
    onOpenChatClick,
    getRetrievalUnitFromNote,
    homeContext,
    getSavedPropFromNote,
    navigation,
    noteId,
  ]);

  return { openQAChat };
};
