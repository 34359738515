import { useMemo } from 'react';

import { captureException } from '@sentry/react';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { sendInvite } from 'api/invitationsApi';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { InviteUserFormValues } from '../InviteUser.types';

export const initialValues: InviteUserFormValues = {
  email: '',
  firstName: '',
};

export type UseLoginFormProps = {
  onReset?: () => void;
  onSubmit?: (values: InviteUserFormValues) => void;
};

export const useInviteUserForm = ({ onReset, onSubmit }: UseLoginFormProps) => {
  const { t } = useTranslation('common');
  const { tenant } = useParsedHostname();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        firstName: Yup.string(),
      }),
    []
  );

  const handleReset = () => {
    onReset?.();
  };

  const handleSubmit = async (
    values: InviteUserFormValues,
    { setSubmitting }: FormikHelpers<InviteUserFormValues>
  ): Promise<void> => {
    try {
      setSubmitting(true);

      await sendInvite(tenant, values);

      onSubmit?.(values);

      enqueueSnackbar(t('inviteUser.form.successMessage'));
    } catch (error) {
      captureException(error);
      const err = deserializeAxiosError(error);
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return {
    initialValues,
    onReset: handleReset,
    onSubmit: handleSubmit,
    validationSchema,
  };
};
