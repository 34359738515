import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useGoogleScholarTabAvailability = () => {
  const { search } = useLocation();
  const isGoogleScholarTab = useMemo(
    () => new URLSearchParams(search).get('fs') === 'gs',
    [search]
  );

  return {
    isGoogleScholarTab,
  };
};
