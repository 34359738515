import React from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DocumentMetadataButton from 'common/components/DocumentMetadataButton/DocumentMetadataButton';
import { addSuffixToDocId } from 'common/utils/documents';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';

export type DocPDFAssetProps = {
  documentId: string;
};

const DocPDFAsset = ({ documentId }: DocPDFAssetProps) => {
  const { tenant } = useParsedHostname();
  const { t } = useTranslation('common');
  const history = useHistory();
  const docId = addSuffixToDocId(documentId);

  const action = useLoggedInFeature<boolean>(() => {
    history.push(`/pdf/${docId}`, {
      background: history.location,
    });
  });

  const handleClick = () => {
    action(true);
    posthogPDFReader.open(tenant, docId);
  };

  return (
    <DocumentMetadataButton
      color="secondary"
      startIcon={<PictureAsPdfIcon color="secondary" />}
      onClick={handleClick}
    >
      {t('retrievalUnit.actions.pdfAsset')}
    </DocumentMetadataButton>
  );
};

DocPDFAsset.displayName = 'DocPDFAsset';

export default DocPDFAsset;
