import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import {
  TaggedResourceGroupBy,
  TaggedResourceItem,
} from '@zarn/vendor/dist/saved-results';

import {
  deleteTaggedResource_depr,
  getGroupedTaggedResources,
  saveTaggedResource,
} from 'api/taggedResourcesApi';
import { deserializeAxiosError } from 'common/utils/error';

import {
  GetTaggedResourcesArgs,
  GroupedTaggedResources,
  TaggedResource,
  TaggedResourcesData,
  TagResourcesArgs,
  UntagResourcesArgs,
  UntagResourcesPayload,
} from '../TaggedResources.interface';
import {
  filterUntaggedResources,
  getFulfilledTaggedResources,
  getFulfilledUntaggedResources,
  groupTaggedResources,
  reduceGroupedTaggedResources,
} from '../taggedResources.utils';
import { TaggedResourceType } from '../TaggedResourceType.enum';

const SLICE_NAME = 'taggedPeople';

export interface TaggedResourcesState {
  data: TaggedResourcesData;
  error: string | null;
  fetchState: FiniteStatesType;
}

export const initialState: TaggedResourcesState = {
  data: {
    favoriteTag: {},
    ownTags: {},
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getTaggedPeople = createAsyncThunk<
  GroupedTaggedResources,
  GetTaggedResourcesArgs,
  { state: RootState }
>(`${SLICE_NAME}/get`, async (payload: GetTaggedResourcesArgs) => {
  try {
    const { data: taggedPeople } = await getGroupedTaggedResources({
      groupBy: TaggedResourceGroupBy.ExternalResourceId,
      pageSize: 10000,
      resourceTypes: [TaggedResourceType.PERSON],
      ...payload,
    });

    return taggedPeople.results.reduce(reduceGroupedTaggedResources, {});
  } catch (err) {
    throw deserializeAxiosError(err);
  }
});

export const tagPeople = createAsyncThunk<
  TaggedResourceItem[],
  TagResourcesArgs,
  { state: RootState }
>(`${SLICE_NAME}/add`, async ({ resourceId, tagIds }: TagResourcesArgs) => {
  try {
    const promises = tagIds.map((tagId) =>
      saveTaggedResource({
        external_resource_id: resourceId,
        external_resource_type: TaggedResourceType.PERSON,
        tag_id: tagId,
      })
    );

    const responses = await Promise.allSettled(promises);

    const newResources = getFulfilledTaggedResources(responses);

    return newResources;
  } catch (err) {
    throw deserializeAxiosError(err);
  }
});

export const untagPeople = createAsyncThunk<
  UntagResourcesPayload,
  UntagResourcesArgs,
  { state: RootState }
>(`${SLICE_NAME}/delete`, async ({ ids, resourceId }: UntagResourcesArgs) => {
  try {
    const promises = ids.map((id) => deleteTaggedResource_depr(id));

    const responses = await Promise.allSettled(promises);

    responses.map((r) => r.status === 'fulfilled' && r.value.request);

    const deletedResourcesIds = getFulfilledUntaggedResources(responses, ids);

    return {
      deletedResourcesIds,
      resourceId,
    };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
});

const taggedPeople = createSlice({
  extraReducers: (builder) => {
    // Get tagged people
    builder
      .addCase(getTaggedPeople.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getTaggedPeople.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error =
          action.error.message || 'Sorry, unexpected error happened';
      })
      .addCase(getTaggedPeople.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.ownTags = { ...state.data.ownTags, ...payload };
        state.error = null;
      });
    builder.addCase(tagPeople.fulfilled, (state, { payload }) => {
      state.data.ownTags = payload.reduce(
        groupTaggedResources,
        state.data.ownTags
      );
      state.error = null;
    });
    builder.addCase(untagPeople.fulfilled, (state, { payload }) => {
      state.data.ownTags = filterUntaggedResources(
        state.data.ownTags,
        payload.resourceId,
        payload.deletedResourcesIds
      );
      state.error = null;
    });
  },
  initialState,
  name: 'taggedPeople',
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
});

const taggedPeopleSelector = (state: RootState) => state.taggedResources.people;

export const selectTaggedPeople = createSelector(
  taggedPeopleSelector,
  ({ data }) => data.ownTags
);

export const selectTaggedPersonByID = (id: string) =>
  createSelector(
    taggedPeopleSelector,
    ({ data }): TaggedResource | null => data.ownTags[id] ?? null
  );

export const selectFavouriteTaggedPeople = createSelector(
  taggedPeopleSelector,
  ({ data }) => data.favoriteTag
);

export default taggedPeople.reducer;
