import React, { FC, useState } from 'react';

import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

import { FirstStep } from './FirstStep/FirstStep';
import { LastStep } from './LastStep/LastStep';
import { SecondStep } from './SecondStep/SecondStep';
import { SkipWizard } from './SkipWizard/SkipWizard';

export interface WizardStepsProps {
  onClose: () => void;
}

export const WizardSteps: FC<WizardStepsProps> = ({ onClose }) => {
  const { push } = useNavigationWithState();
  const { t } = useTranslation('onboardingWizard');
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    onClose();
    push('/tags', { exploding: true });
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>{t('onboardingWizard:firstStep.label')}</StepLabel>
          <StepContent>
            <FirstStep onNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('onboardingWizard:secondStep.label')}</StepLabel>
          <StepContent>
            <SecondStep onBack={handleBack} onNext={handleNext} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>{t('onboardingWizard:lastStep.label')}</StepLabel>
          <StepContent>
            <LastStep onNext={handleFinish} />
          </StepContent>
        </Step>
      </Stepper>
      {activeStep < 2 && <SkipWizard onSkip={onClose} />}
    </>
  );
};
