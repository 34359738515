import React, { FC, useMemo } from 'react';

import { Box } from '@mui/material';

import {
  BotType,
  BotTypeMessage,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { getBotsModes } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';

import { ModeBotButton } from '../ModeBotButton/ModeBotButton';

export interface ModeButtonsProps {
  disabled?: boolean;
  onClick: (botMessage: BotTypeMessage) => void;
  supportedBotTypes: BotType[];
}

export const ModeButtons: FC<ModeButtonsProps> = ({
  disabled,
  onClick,
  supportedBotTypes,
}) => {
  const { tenantSettings } = useAssertTenantSettings();

  const botsModes = useMemo(
    () =>
      getBotsModes(tenantSettings.chat).filter((bm) =>
        supportedBotTypes.includes(bm)
      ),
    [tenantSettings, supportedBotTypes]
  );

  return (
    <Box
      data-testid="mode-buttons"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.5rem',
      }}
    >
      {botsModes.map((botType) => (
        <ModeBotButton
          botType={botType}
          disabled={disabled}
          key={botType}
          onClick={onClick}
        />
      ))}
    </Box>
  );
};
