import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { Scalar } from 'common/interfaces/general.types';
import { Nullable } from 'common/utils/assert';

import { useNavigationWithState } from './useNavigationWithState';

export const useQuerySearchParams = () => {
  const history = useHistory();
  const { push } = useNavigationWithState();

  const getParams = useCallback(
    (params: string[]) => {
      const urlParams = new URLSearchParams(history.location.search);
      return params.reduce(
        (acc, key) => ({ ...acc, [key]: urlParams.get(key) ?? null }),
        {} as Record<string, Nullable<string>>
      );
    },
    [history.location.search]
  );

  const setParams = useCallback(
    (params: Record<string, string>, state: Record<string, Scalar> = {}) => {
      const urlParams = new URLSearchParams(history.location.search);
      Object.keys(params).forEach((key) => urlParams.set(key, params[key]));

      push(`${history.location.pathname}?${urlParams}`, state);
    },
    [history.location.pathname, history.location.search, push]
  );

  const deleteParams = useCallback(
    (params: string[]) => {
      const urlParams = new URLSearchParams(history.location.search);
      params.forEach((param) => urlParams.delete(param));

      push(`${history.location.pathname}?${urlParams}`);
    },
    [history.location.pathname, history.location.search, push]
  );

  return { deleteParams, getParams, setParams };
};
