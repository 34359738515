import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  SearchUrlParamsEnum,
  SearchTermEnum,
  SearchTermEnumType,
} from 'common/enums';

type AdditionalParam = {
  name: string;
  value: string;
};

type SearchByTermReturn = {
  searchByTerm: (
    term: SearchTermEnumType,
    id: string,
    searchParams?: AdditionalParam[]
  ) => void;
};

export function useSearchByTerm(): SearchByTermReturn {
  const history = useHistory();

  const searchByTerm = useCallback(
    (
      term: SearchTermEnumType,
      id: string,
      additionalParams: AdditionalParam[] = []
    ): void => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set(SearchUrlParamsEnum.Term, `${term as string},${id}`);

      searchParams.delete(SearchUrlParamsEnum.RetrievalMethod);
      searchParams.delete(SearchUrlParamsEnum.Page);
      searchParams.delete(SearchUrlParamsEnum.RetrievalUnit);
      searchParams.delete(SearchUrlParamsEnum.Date);
      searchParams.delete(SearchUrlParamsEnum.DateRange);
      searchParams.delete(SearchUrlParamsEnum.SimilarTo);
      searchParams.delete(SearchUrlParamsEnum.DocSources);

      if (term !== SearchTermEnum.HasConcept) {
        searchParams.delete(SearchUrlParamsEnum.Query);
      }

      additionalParams.forEach(({ name, value }) => {
        searchParams.set(name, value);
      });

      history.push(`/?${searchParams}`);
    },
    [history.location.search]
  );

  return { searchByTerm };
}
