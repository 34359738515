import { RoleDB } from '@zarn/vendor/dist/auth';

import { UserRole } from './Auth.interface';
import { RoleEnum } from './enums';

export const deserializeUserRole = (rd: RoleDB): UserRole => ({
  accountId: rd.account_id,
  accountName: rd.account_name,
  name: rd.name,
  permission: rd.permission as RoleEnum,
  userRoleId: rd.user_role_id,
});

/**
 * Parse user permission data
 */

enum PermissionsKey {
  accountId = 'account_id',
  accountName = 'account_name',
  permission = 'permission',
  userRoleId = 'user_role_id',
}

type PermissionDataMapType = {
  [key: string]: string;
};

const mapPermissionData = (role: string) => {
  if (role === RoleEnum.Admin) {
    return {
      [PermissionsKey.permission]: RoleEnum.Admin,
      [PermissionsKey.userRoleId]: '1',
    } as PermissionDataMapType;
  }

  return role.split(',').reduce((acc, element) => {
    const [key, value] = element.split(':');
    acc[key] = value;
    return acc;
  }, {} as PermissionDataMapType);
};

export const validateUserRole = (role: string): boolean => {
  const permissionDataMap = mapPermissionData(role);
  return (
    !!permissionDataMap[PermissionsKey.userRoleId] &&
    !!permissionDataMap[PermissionsKey.permission]
  );
};

export const mapUserPermission = (role: string) => {
  const permissionDataMap = mapPermissionData(role);

  return {
    accountId: Number(permissionDataMap[PermissionsKey.accountId]) ?? null,
    accountName: permissionDataMap[PermissionsKey.accountName] ?? null,
    name: null,
    permission: permissionDataMap[PermissionsKey.permission] ?? null,
    userRoleId: Number(permissionDataMap[PermissionsKey.userRoleId]),
  };
};
