import React from 'react';

import { useTranslation } from 'react-i18next';

import { ErrorPageContent } from 'common/components/ErrorPageContent/ErrorPageContent';

export const Page404 = () => {
  const { t } = useTranslation(['pages', 'common']);

  return (
    <ErrorPageContent
      description={t('pages:404.desc')}
      title={t('pages:404.title')}
    />
  );
};
