import React, { FC } from 'react';

import { CardProps } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';
import { DocumentItem } from '@zarn/vendor/dist/saved-results';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { SearchEngineEnum } from 'common/enums';
import { FeedbackData } from 'containers/Feedback/Feedback.interface';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { DocCardComposition } from 'containers/User/User.enum';

import { DocAction } from './DocActions/DocActions.interface';
import RetrievalUnitCardCompressed from './RetrievalUnitCard/RetrievalUnitCardCompressed';
import RetrievalUnitCardDefault from './RetrievalUnitCard/RetrievalUnitCardDefault';

// TODO: Check component variants and unused props
export interface RetrievalUnitProps {
  cardComposition?: DocCardComposition;
  cardProps?: CardProps;
  classes?: {
    root?: string;
  };

  // common
  data: RetrievalUnitData;
  // variant 1
  evidenceNumber?: number;
  explanation?: string;

  feedback?: FeedbackData;
  // unused props
  highlighted?: boolean;

  isCardBackground?: boolean;
  isEvidence?: boolean;
  mapDocIndex?: number;
  moreActions?: DocAction[];

  onDocumentAdd?: (addedDocuments: DocumentItem[]) => any;
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
  pageNumber?: number;
  resultType?: ResultType;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  // variant 2
  suggestedTags?: TagDetailsBase[];
  withExplicitFeedback?: boolean;
  withImage?: boolean;
  // variant 3
  withSharedNotes?: boolean;
  withTagging?: boolean;
}

const RetrievalUnit: FC<RetrievalUnitProps> = ({
  cardComposition = DocCardComposition.Default,
  cardProps,
  classes: inputClasses,
  data,
  evidenceNumber,
  explanation,
  feedback,
  highlighted,
  isCardBackground,
  isEvidence,
  mapDocIndex,
  moreActions,
  onPrivateDocDelete,
  onPrivateDocEdit,
  pageNumber,
  resultType,
  searchEngine,
  searchId,
  suggestedTags,
  withExplicitFeedback,
  withImage = true,
  withSharedNotes,
  withTagging = true,
}) => {
  const {
    document: { id: docId },
  } = data;

  return cardComposition === DocCardComposition.Compressed ? (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Document card', docId }}
    >
      <RetrievalUnitCardCompressed
        cardProps={cardProps}
        classes={inputClasses}
        data={data}
        evidenceNumber={evidenceNumber}
        explanation={explanation}
        feedback={feedback}
        highlighted={highlighted}
        isCardBackground={isCardBackground}
        isEvidence={isEvidence}
        mapDocIndex={mapDocIndex}
        moreActions={moreActions}
        pageNumber={pageNumber}
        resultType={resultType}
        searchEngine={searchEngine}
        searchId={searchId}
        suggestedTags={suggestedTags}
        withExplicitFeedback={withExplicitFeedback}
        withImage={withImage}
        withSharedNotes={withSharedNotes}
        withTagging={withTagging}
        onPrivateDocDelete={onPrivateDocDelete}
        onPrivateDocEdit={onPrivateDocEdit}
      />
    </TrackedActionContext.Provider>
  ) : (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Document card', docId }}
    >
      <RetrievalUnitCardDefault
        cardProps={cardProps}
        classes={inputClasses}
        data={data}
        evidenceNumber={evidenceNumber}
        explanation={explanation}
        feedback={feedback}
        highlighted={highlighted}
        mapDocIndex={mapDocIndex}
        moreActions={moreActions}
        pageNumber={pageNumber}
        resultType={resultType}
        searchEngine={searchEngine}
        searchId={searchId}
        suggestedTags={suggestedTags}
        withExplicitFeedback={withExplicitFeedback}
        withImage={withImage}
        withSharedNotes={withSharedNotes}
        withTagging={withTagging}
        onPrivateDocDelete={onPrivateDocDelete}
        onPrivateDocEdit={onPrivateDocEdit}
      />
    </TrackedActionContext.Provider>
  );
};

export default RetrievalUnit;
