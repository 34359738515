import React, { useCallback, useEffect } from 'react';

import NotesIcon from '@mui/icons-material/Notes';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { selectDocumentsNotes } from 'containers/SavedNotes/DocumentsNotes/documentsNotes.slice';
import { DocCardComposition } from 'containers/User/User.enum';

type DocNotesToggleProps = {
  actionComposition?: DocCardComposition;
  docId: string;
  handleCloseDocActionsMenu?: () => void;
  notesExpanded: boolean;
  setNotesExpanded: (state: boolean) => void;
  withSharedNotes?: boolean;
};

const DocNotesToggle = ({
  actionComposition,
  docId,
  handleCloseDocActionsMenu,
  notesExpanded,
  setNotesExpanded,
}: DocNotesToggleProps) => {
  const { t } = useTranslation('common');
  const { isAuthenticated } = useAuth();
  const { [docId]: savedNote } = useSelector(selectDocumentsNotes);
  const handleToggleExpand = useCallback(() => {
    setNotesExpanded(!notesExpanded);
    if (
      actionComposition === DocCardComposition.Compressed &&
      handleCloseDocActionsMenu
    ) {
      handleCloseDocActionsMenu();
    }
  }, [setNotesExpanded, notesExpanded]);
  const numberOfNotes = savedNote?.numberOfNotes ?? 0;
  const clickAction = useLoggedInFeature(handleToggleExpand);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      clickAction(event.currentTarget);
    },
    [clickAction]
  );

  useEffect(() => {
    if (!isAuthenticated && notesExpanded) {
      handleToggleExpand();
    }
  }, [isAuthenticated, handleToggleExpand, notesExpanded]);

  return (
    <TrackedButton
      color={numberOfNotes ? 'secondary' : 'primary'}
      eventName={TrackEventName.DocumentNotesToggleClicked}
      eventProps={{ docId }}
      size="small"
      startIcon={<NotesIcon />}
      style={
        actionComposition === DocCardComposition.Compressed
          ? {
              alignItems: 'start',
              alignSelf: 'start',
              justifyContent: 'start',
              width: '100%',
            }
          : undefined
      }
      onClick={handleClick}
    >
      {t('retrievalUnit.actions.notes')}
      {numberOfNotes > 0 && (
        <Chip
          aria-label="number of notes"
          label={numberOfNotes}
          size="small"
          sx={{ ml: 0.5 }}
        />
      )}
    </TrackedButton>
  );
};

export default DocNotesToggle;
