import { useMemo } from 'react';

import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useTranslation } from 'react-i18next';

import { AddPrivateDocsOption } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocs.interface';
import { AddPrivateDocsMethodEnum } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocsMethod.enum';

export const useAddPrivateDocsOptions = (): AddPrivateDocsOption[] => {
  const { t } = useTranslation('privateDocs');

  return useMemo<AddPrivateDocsOption[]>(
    () => [
      {
        icon: NoteAddIcon,
        method: AddPrivateDocsMethodEnum.SinglePaper,
        primaryText: t('options.singleDoc.primaryText'),
      },
      {
        icon: ImportContactsIcon,
        method: AddPrivateDocsMethodEnum.BatchUpload,
        primaryText: t('options.batchUpload.primaryText'),
      },
    ],
    [t]
  );
};
