import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export type FullscreenControlProps = {
  fullscreen: boolean;
  handleFullscreen: () => void;
};

const FullscreenControl = ({
  fullscreen,
  handleFullscreen,
}: FullscreenControlProps) => {
  const label = fullscreen ? 'Exit fullscreen' : 'Fullscreen';
  const icon = fullscreen ? (
    <FullscreenExitIcon fontSize="small" />
  ) : (
    <FullscreenIcon fontSize="small" />
  );
  return (
    <Tooltip title={label}>
      <IconButton aria-label={label} onClick={handleFullscreen} size="large">
        {icon}
      </IconButton>
    </Tooltip>
  );
};

FullscreenControl.displayName = 'FullscreenControl';

export default FullscreenControl;
