import React, { useEffect, useLayoutEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, Theme } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  alertRoot: {
    marginBottom: theme.spacing(2),
  },
}));

type AlertAutoDismissProps = {
  dismissIn: number;
  onDismiss: () => void;
  text: string;
} & AlertProps;

const AlertAutoDismiss = React.memo(
  ({ dismissIn, onDismiss, text, ...rest }: AlertAutoDismissProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    useLayoutEffect(() => setOpen(true), []);

    useEffect(() => {
      if (dismissIn && open) {
        const dismissTimeout = setTimeout(handleClose, dismissIn);
        return () => {
          clearTimeout(dismissTimeout);
        };
      }
      return undefined;
    }, [dismissIn, handleClose, open]);

    return (
      <Collapse in={open} unmountOnExit onExited={onDismiss}>
        <Alert
          {...rest}
          action={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          classes={{ root: classes.alertRoot }}
        >
          {text}
        </Alert>
      </Collapse>
    );
  }
);

AlertAutoDismiss.displayName = 'AlertAutoDismiss';

export default AlertAutoDismiss;
