import React, { ReactNode } from 'react';

import {
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
  Tooltip,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import makeStyles from '@mui/styles/makeStyles';
import { errorChipStyles } from 'app/theme/styles/chip';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

export const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    ...errorChipStyles(theme),
    marginLeft: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
}));

export type NotificationLinkItemProps = {
  appendIcon?: ReactNode;
  chipLabel?: ReactNode;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  nested?: boolean;
  onClick: () => void;
  selected?: boolean;
  text: ReactNode;
  to?: string;
};

const NotificationLinkItem = ({
  appendIcon,
  chipLabel,
  icon: Icon,
  nested,
  onAction,
  onClick,
  selected,
  text,
  to,
}: WithTrackedActionWrappedProps<NotificationLinkItemProps>) => {
  const classes = useStyles();

  const handleClick = (e: any) => {
    onAction();
    if (!to) {
      e.preventDefault();
    }
    onClick();
  };

  return (
    <Tooltip placement="left" title={text}>
      <ListItem
        selected={selected}
        button
        {...{ component: RouterLink, to }}
        className={clsx(classes.root, nested && classes.nested)}
        onClick={handleClick}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>

        <ListItemText
          primary={text}
          primaryTypographyProps={{
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />

        {chipLabel && (
          <Chip className={classes.chip} label={chipLabel} size="small" />
        )}

        {appendIcon}
      </ListItem>
    </Tooltip>
  );
};

export default withTrackedAction<
  NotificationLinkItemProps & WithTrackedActionProps
>(NotificationLinkItem);
