import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const chipOverrides: ComponentsOverrides<Theme>['MuiChip'] = {
  deleteIconSmall: {
    marginLeft: -6,
    marginRight: 1,
  },
  iconSmall: {
    height: 12,
    marginRight: -6,
    width: 12,
  },
  label: ({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
  labelSmall: {
    lineHeight: '18px',
  },
  outlined: {
    '& .MuiChip-avatar': {
      marginLeft: 1,
    },
  },
  root: {
    '& .MuiChip-avatar': {
      height: 22,
      marginLeft: 2,
      width: 22,
    },
    height: 26,
  },
  sizeSmall: {
    fontSize: '.75rem',
    height: 18,
  },
};
