import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const typographyOverrides: ComponentsOverrides<Theme>['MuiTypography'] =
  {
    h5: {
      lineHeight: 1.4,
    },
    h6: ({ theme }) => ({
      fontSize: theme.typography.fontSize * 1.25,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.4,
    }),
  };
