import React from 'react';

import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { getTag } from 'api/tagsApi';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { tagsQueryKeys } from '../tags.utils';

import { useDeleteTagDetails } from './useDeleteTagDetails';
import { useUpdateTagDetails } from './useUpdateTagDetails';

export type UseTagDetailsProps = {
  options?: UseQueryOptions<TagDetails, BaseError>;
  tagId: number;
  tagType: TagType;
};

export const useTagDetails = ({
  options,
  tagId,
  tagType,
}: UseTagDetailsProps) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');
  const { tenant } = useParsedHostname();

  const queryResult = useQuery<TagDetails, BaseError>(
    tagsQueryKeys.detail(tagId),
    async () => {
      try {
        return (await getTag(tagId, tenant, tagType)).data;
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      ...QUERY_OPTIONS,
      ...options,
    }
  );

  const updateMutation = useUpdateTagDetails({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onSuccess: (updatedData: TagDetails) => {
      queryClient.setQueryData(
        tagsQueryKeys.detail(updatedData.id),
        updatedData
      );

      enqueueSnackbar(<>{t('tags.updateTagDetails.successMessage')}</>);
    },
  });

  const deleteMutation = useDeleteTagDetails({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onSuccess: (deletedTagId) => {
      queryClient.removeQueries(tagsQueryKeys.detail(deletedTagId));

      enqueueSnackbar(<>{t('tags.removeTagDetails.successMessage')}</>);
    },
  });

  return {
    deleteMutation,
    queryResult,
    updateMutation,
  };
};
