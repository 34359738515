import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const tabOverrides: ComponentsOverrides<Theme>['MuiTab'] = {
  root: ({ theme }) => ({
    minWidth: 'auto',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      minWidth: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 'auto',
    },
  }),
};
