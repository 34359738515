export const normalizeRegexSpecialChar = (str: string) => {
  const specials = [
    '-',
    '[',
    ']',
    '/',
    '{',
    '}',
    '(',
    ')',
    '*',
    '+',
    '?',
    '.',
    '\\',
    '^',
    '$',
    '|',
  ];

  return str.replace(RegExp('[' + specials.join('\\') + ']', 'g'), '\\$&');
};
