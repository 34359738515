import React from 'react';

import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import DocPDFAsset from 'containers/RetrievalUnit/DocPDFAsset';
import ExternalDocPDFAsset from 'containers/RetrievalUnit/ExternalDocPDFAsset';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export type NoteCardDocInfoActionProps = {
  docData: RetrievalUnitData;
};

const NoteCardDocInfoAction = ({ docData }: NoteCardDocInfoActionProps) => {
  const docAsset =
    docData.privateAsset || docData.publicAsset || docData.externalPublicAsset;

  if (!docAsset) return null;

  if (docAsset.assetType === DocAssetTypeEnum.ExternalPublicAsset) {
    return <ExternalDocPDFAsset docData={docData} />;
  }

  return <DocPDFAsset documentId={docData.documentId} />;
};

export default NoteCardDocInfoAction;
