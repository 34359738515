import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightActions: {
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
}));
