import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';

import { ConfirmDialogProps } from './ConfirmDialog.types';

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  cancelButtonLabel,
  message,
  onCancel,
  onConfirm,
  successButtonLabel,
  title,
}: ConfirmDialogProps) => {
  const { t } = useTranslation('common');

  return (
    <Dialog data-testid="confirmationDialog" open={true} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {cancelButtonLabel ?? t('buttons.cancel')}
        </Button>
        <SubmitButton
          text={successButtonLabel ?? t('buttons.confirm')}
          autoFocus
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
