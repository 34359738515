import React, { useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';

import { TagDetails } from 'api/tagsApi/tagsApi.types';

import TagSettingsDialog from '../TagSettingsForm/TagSettingsDialog/TagSettingsDialog';

type TagItemSettingsButtonProps = {
  tag: TagDetails;
};

const TagItemSettingsButton = ({ tag }: TagItemSettingsButtonProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        aria-label="edit tag"
        edge="end"
        size="small"
        onClick={handleOpen}
      >
        <SettingsIcon fontSize="small" />
      </IconButton>

      <TagSettingsDialog open={isOpen} setOpen={setOpen} tag={tag} />
    </>
  );
};

TagItemSettingsButton.displayName = 'TagItemSettingsButton';

export default TagItemSettingsButton;
