import { PartialRecord } from 'common/utils/types.helpers';

import { WhitelabelSettings } from './WhitelabelSettings.types';

export interface SearchFilters {
  codeFilterEnabled: boolean;
  countryFilterEnabled: boolean;
  dateFilterEnabled: boolean;
  organizationFilterEnabled: boolean;
  ownerFilterEnabled: boolean;
  sourceFilterEnabled: boolean;
  tagFilterEnabled: boolean;
  typeFilterEnabled: boolean;
}

export type WidgetName =
  | 'QA'
  | 'expertSearch'
  | 'queryAnalysis'
  | 'vosViewer'
  | 'analytics'
  | 'searchResults'
  | 'findSimilarDocument'
  | 'findAuthoredBy';

export interface LayoutColumnSettings {
  sizes?: {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
    xxl?: number;
  };
  widgets: WidgetName[];
}

export interface WidgetLayoutSettings {
  columns: LayoutColumnSettings[];
  topFullWidthWidgets: WidgetName[];
}

export interface Widgets {
  analyticsWidgetEnabled: boolean;
  expertSearchWidgetEnabled: boolean;
  findAuthoredByWidgetEnabled: boolean;
  findSimilarDocumentWidgetEnabled: boolean;
  layout: WidgetLayoutSettings;
  qaWidgetEnabled: boolean;
  queryAnalysisWidgetEnabled: boolean;
  searchResultsWidgetEnabled: boolean;
  vosViewerWidgetEnabled: boolean;
}

export interface Tab {
  enabled: boolean;
  tabTitle: string;
}

export interface Tabs {
  default: Tab;
  google: Tab;
  googleScholar: Tab;
}

export interface OidcEnabled {
  authority: string;
  clientId: string;
  enabled: true;
}

export interface OidcDisabled {
  authority?: string;
  clientId?: string;
  enabled: false;
}

export type Oidc = OidcDisabled | OidcEnabled;

export type BotType =
  | 'summary'
  | 'default'
  | 'quizbot'
  | 'chat_with_pdf'
  | 'chat_with_tag'
  | 'chat_with_qa_widget';

export type DefaultBotTypes =
  | 'chat_with_pdf'
  | 'chat_with_tag'
  | 'chat_with_qa_widget';

export interface BotConfiguration {
  tenantBotType: string;
}

export interface ChatSettings {
  botCapabilities: BotType[];
  botsConfiguration: PartialRecord<BotType, BotConfiguration>;
  defaultBot?: DefaultBotTypes;
  qaDefaultBot?: DefaultBotTypes;
  tagDefaultBot?: DefaultBotTypes;
}

export enum SearchFilterDynamicsType {
  Checkbox,
  // TODO: Implement it
  DatesRange,
}

export interface DynamicSettingsAbstract {
  faceted: boolean;
  fieldName: string;
  filterType: SearchFilterDynamicsType;
  filterTypeSettings: never;
  label: string;
  urlParam: string;
}

export interface CheckboxDynamicSettings
  extends Omit<DynamicSettingsAbstract, 'filterTypeSettings'> {
  filterType: SearchFilterDynamicsType.Checkbox;
  filterTypeSettings: {
    values: Array<{
      label: string;
      value: string;
    }>;
  };
}

// TODO: Implement it
export interface DatesRangeDynamicSettings
  extends Omit<DynamicSettingsAbstract, 'filterTypeSettings'> {
  filterType: SearchFilterDynamicsType.DatesRange;
  filterTypeSettings: {
    max: string;
    min: string;
  };
}

export type DynamicSettings =
  | CheckboxDynamicSettings
  | DatesRangeDynamicSettings;

/** @deprecated */
export interface ApiDocumentMetadataFields {
  field_name?: string;
  icon?: string;
  label?: string;
  list_field_name?: string;
  type?: string;
  url_field_name?: string;
}

/** @deprecated */
export interface ApiDisplayConfiguration {
  created_by_field?: string;
  date_field?: string;
  description_field?: string;
  document_metadata_fields?: ApiDocumentMetadataFields[];
  image_url_field?: string;
  source_field?: string;
  title_field?: string;
  url_field?: string;
}

export interface DocumentMetadataFields {
  fieldName?: string;
  icon?: string;
  label?: string;
  listFieldName?: string;
  type?: string;
  urlFieldName?: string;
}

export interface DisplayConfiguration {
  createdByField?: string;
  dateField?: string;
  descriptionField?: string;
  documentMetadataFields?: DocumentMetadataFields[];
  imageUrlField?: string;
  sourceField?: string;
  titleField?: string;
  urlField?: string;
}

export interface FeaturesSettings {
  documentUploads?: { status: 'active' | 'disabled' | 'hidden' };
  people?: { status: 'active' | 'disabled' | 'hidden' };
  recommendations?: { status: 'active' | 'disabled' | 'hidden' };
}

export interface SortingOptionsSettings {
  title: string;
  urlParam?: string;
  value: string;
}

export interface SearchSortingSettings {
  chunk?: SortingOptionsSettings[];
  document?: SortingOptionsSettings[];
}

export interface TenantSettings {
  chat: ChatSettings;
  displayConfiguration?: DisplayConfiguration | null;
  document: {
    exports?: string[];
    sharing?: string[];
  };
  features: FeaturesSettings;
  indexCluster: string;
  oidc: Oidc;
  searchFilters: SearchFilters;
  searchFiltersDynamic?: DynamicSettings[];
  searchSorting?: SearchSortingSettings;
  tabs: Tabs;
  tag: {
    exports?: string[];
    sharing?: string[];
  };
  whitelabel?: WhitelabelSettings;
  widgets: Widgets;
}

export interface BotTypeMessageWithoutMessage<T> {
  botType: T;
}

export interface BotTypeMessageWithMessage<T>
  extends BotTypeMessageWithoutMessage<T> {
  image?: string | null;
  message: string;
}

export interface BotTypeMessageWithOptionalMessage<T>
  extends BotTypeMessageWithoutMessage<T> {
  image?: string | null;
  message?: string;
}

export type DefaultBotTypeMessage = BotTypeMessageWithMessage<DefaultBotTypes>;
export type SummaryBotTypeMessage = BotTypeMessageWithMessage<'summary'>;
export type QuizbotBotTypeMessage = BotTypeMessageWithoutMessage<'quizbot'>;
export type TagOnboardingBotTypeMessage =
  BotTypeMessageWithOptionalMessage<'chat_with_tag'>;

export type BotTypeMessage =
  | DefaultBotTypeMessage
  | SummaryBotTypeMessage
  | QuizbotBotTypeMessage
  | TagOnboardingBotTypeMessage;
