import { AxiosResponse } from 'axios';
import { FileWithPath } from 'react-dropzone';

import {
  Configuration,
  UserDocumentItem,
  UserDocumentsApi,
} from '@zarn/vendor/dist/user-documents';

import { Nullable } from 'common/utils/assert';
import { blobToBase64 } from 'common/utils/fileHelpers';
import { PrivateDocFormValues } from 'containers/PrivateDocs/PrivateDocForm/PrivateDocForm.interface';
import {
  deserializeUserDocumentItem,
  serializeUserDocumentFormValues,
} from 'containers/PrivateDocs/privateDocs.utils';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';

export const userDocumentsApi = new UserDocumentsApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const userDocumentsFilter = async (
  page: number,
  pageSize: number,
  tenant: string,
  docIds?: string[],
  deleted?: boolean,
  indexId?: string
) => {
  const { data, ...res } = await userDocumentsApi.filterUserDocuments({
    deleted,
    documentIdIn: docIds,
    includeDocumentStatus: true,
    includeSharedDocuments: false,
    indexId,
    page,
    pageSize,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userTenants: '',
  });

  return {
    ...res,
    data: {
      ...data,
      results: data.results.map(deserializeUserDocumentItem),
    },
  };
};

export const userDocumentRetrieve = async (
  id: string,
  tenant: string,
  indexId?: string
): Promise<AxiosResponse<UserDocumentItem>> =>
  userDocumentsApi.retrieveUserDocument({
    documentId: id,
    indexId,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userTenants: '',
  });

export const userDocumentCreate = async (
  values: PrivateDocFormValues,
  fileToUpload: Nullable<FileWithPath>,
  tenant: string,
  useOpenai?: boolean,
  indexId?: string
): Promise<AxiosResponse<RetrievalUnitData>> => {
  const base64Content: string | undefined =
    fileToUpload && fileToUpload.path
      ? await blobToBase64(fileToUpload)
      : undefined;
  const fileName = fileToUpload?.name;

  const { data, ...res } = await userDocumentsApi.createUserDocument({
    indexId,
    requesterUuid: '',
    tenant,
    useOpenai,
    userDocumentForm: serializeUserDocumentFormValues({
      ...values,
      base64Content,
      fileName,
    }),
    userRoles: '',
    userTenants: '',
  });

  return {
    ...res,
    data: deserializeUserDocumentItem(data),
  };
};

export const userDocumentUpdate = async (
  id: string,
  values: PrivateDocFormValues,
  tenant: string,
  useOpenai?: boolean,
  indexId?: string
): Promise<AxiosResponse<RetrievalUnitData>> => {
  const fileToUpload = values.file[0];
  const base64Content: string | undefined =
    fileToUpload && fileToUpload.path
      ? await blobToBase64(fileToUpload)
      : undefined;
  const fileName = fileToUpload?.name;

  await userDocumentsApi.updateUserDocument({
    documentId: id,
    indexId,
    requesterUuid: '',
    tenant,
    useOpenai,
    userDocumentPatch: serializeUserDocumentFormValues({
      ...values,
      base64Content,
      fileName,
    }),
    userRoles: '',
    userTenants: '',
  });

  const { data, ...res } = await userDocumentsApi.retrieveUserDocument({
    documentId: id,
    indexId,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userTenants: '',
  });

  return {
    ...res,
    data: deserializeUserDocumentItem(data),
  };
};

export const userDocumentDelete = async (
  id: string,
  tenant: string,
  indexId?: string
) =>
  userDocumentsApi.deleteUserDocument({
    documentId: id,
    indexId,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userTenants: '',
  });

export const userDocumentContentRetrieve = async (
  id: string,
  tenant: string,
  indexId?: string
) => {
  const content = await userDocumentsApi.retrieveUserDocumentContent(
    {
      documentId: id,
      indexId,
      requesterUuid: '',
      tenant,
      userRoles: '',
      userTenants: '',
    },
    { responseType: 'arraybuffer' }
  );

  return {
    content: {
      data: content.data,
    },
    fileName: '',
  };
};
