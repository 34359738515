import { useReducer } from 'react';

import { Highlight, HighlightingMousePos, HighlightingState } from '../types';
import { groupIntermediateHighlightByPage } from '../utils';

export type HighlightingAction =
  | { type: 'updateHighlightSelection'; payload: Highlight }
  | { type: 'startHighlightSelection' }
  | { type: 'endHighlightSelection'; payload?: HighlightingMousePos }
  | { type: 'clearHighlightSelection' };

export const initialHighlightingState: HighlightingState = {
  mousePos: {
    x: 0,
    y: 0,
  },
  highlight: null,
  groupedHighlight: {},
  status: 'idle',
};

const highlightingReducer = (
  state: HighlightingState,
  action: HighlightingAction
): HighlightingState => {
  switch (action.type) {
    case 'clearHighlightSelection':
      return initialHighlightingState;
    case 'startHighlightSelection':
      return {
        ...initialHighlightingState,
        status: 'start',
      };
    case 'updateHighlightSelection':
      return {
        ...state,
        status: 'edit',
        highlight: action.payload,
        groupedHighlight: groupIntermediateHighlightByPage(action.payload),
      };
    case 'endHighlightSelection':
      const mousePos = action.payload;

      return {
        ...state,
        status: 'end',
        ...(mousePos ? { mousePos } : {}),
      };
    default:
      return state;
  }
};

export const useHighlighting = () => {
  const [state, dispatch] = useReducer(
    highlightingReducer,
    initialHighlightingState
  );

  return { state, dispatch };
};
