import React from 'react';

import HighlightTooltip from '../../HighlightTooltip';
import HighlightRectItem from '../HighlightRectItem';
import HighlightRectsWrapper from '../HighlightRectsWrapper';
import HighlightTooltipCreateAction from '../../HighlightTooltip/HighlightTooltipCreateAction';
import HighlightTooltipAddNoteAction from '../../HighlightTooltip/HighlightTooltipAddNoteAction';
import HighlightTooltipCopyAction from '../../HighlightTooltip/HighlightTooltipCopyAction';
import HighlightTooltipAction from '../../HighlightTooltip/HighlightTooltipAction';
import HighlightTooltipDeleteAction from '../../HighlightTooltip/HighlightTooltipDeleteAction';
import { IntermediateHighlightItemProps } from './types';
import { useIntermediateHighlightItem } from './useIntermediateHighlightItem';

const IntermediateHighlightItem = React.memo(
  <T extends string>(props: IntermediateHighlightItemProps<T>) => {
    const {
      highlight,
      highlightingStatus,
      zoomLevel,
      handleClose,
      showTooltip,
      anchorPos,
      handleCreate,
      handleAddNote,
      hasCopy,
      handleCopy,
      customActions,
      handleActionClick,
      handleDelete,
    } = useIntermediateHighlightItem<T>(props);

    return (
      <div>
        <HighlightRectsWrapper highlightColor={highlight.color}>
          {highlight.content.type === 'image' &&
            highlightingStatus === 'end' && (
              <HighlightRectItem
                {...highlight.rects[0]}
                zoom={zoomLevel}
                color={highlight.color}
              />
            )}
        </HighlightRectsWrapper>

        <HighlightTooltip
          onClose={handleClose}
          open={showTooltip}
          anchorTop={anchorPos.top}
          anchorLeft={anchorPos.left}
        >
          <HighlightTooltipCreateAction onCreate={handleCreate} />

          <HighlightTooltipAddNoteAction onAddNote={handleAddNote} />

          {hasCopy && (
            <HighlightTooltipCopyAction
              onCopy={handleCopy}
              contentBody={highlight.content.body}
            />
          )}

          {customActions.map(({ action, icon, title }) => (
            <HighlightTooltipAction<string>
              key={action}
              title={title}
              icon={icon}
              action={action}
              onClick={handleActionClick}
            />
          ))}

          <HighlightTooltipDeleteAction onDelete={handleDelete} />
        </HighlightTooltip>
      </div>
    );
  }
);

IntermediateHighlightItem.displayName = 'IntermediateHighlightItem';

export default IntermediateHighlightItem;
