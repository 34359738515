import React, { FC, useMemo } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { ErrorPageContent } from 'common/components/ErrorPageContent/ErrorPageContent';
import { PageLoader } from 'common/components/Loaders/PageLoader/PageLoader';
import { useTenantSettings } from 'common/hooks/useTenantSettings';

import { buildTheme } from './theme';

export const AppTheme: FC = ({ children }) => {
  const { error, tenantSettings } = useTenantSettings();

  const theme = useMemo(
    () => buildTheme(tenantSettings ?? null),
    [tenantSettings]
  );

  if (error) {
    return (
      <ErrorPageContent
        description={error.message}
        title={`Error ${error.status}`}
      />
    );
  }

  if (!tenantSettings) {
    return <PageLoader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
