export const getPathSegment = (url: string, segment = 0) => {
  const urlObject = new URL(url);
  const pathSegments = urlObject.pathname
    .split('/')
    .filter((seg) => seg !== '');
  if (pathSegments.length > 0) {
    return pathSegments[segment];
  } else {
    return urlObject.search.substring(1);
  }
};

export const extractUrlParamValueParamPair = (
  url: string,
  param: string
): string | null => {
  const parsedUrl = new URL(url);
  const paramValue = parsedUrl.searchParams.get(param);
  return paramValue ? `${param}=${paramValue}` : null;
};
