import { createContext } from 'react';
import {
  initialPDFViewerState,
  PDFViewerAction,
} from '../../hooks/usePDFViewer';
import { PDFViewerState } from '../../types';

export interface PDFViewerContextValue {
  state: PDFViewerState;
  dispatch: (actionType: PDFViewerAction) => void;
}

export const initContext: PDFViewerContextValue = {
  state: initialPDFViewerState,
  dispatch: () => {},
};

export const PDFViewerContext =
  createContext<PDFViewerContextValue>(initContext);
