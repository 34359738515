import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  activeTab: {
    '&[disabled]': {
      backgroundColor: theme.palette.divider,
    },
  },
  content: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  viewer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));
