import { WidgetLayoutSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';

export const DEFAULT_WIDGET_LAYOUT: WidgetLayoutSettings = {
  columns: [
    {
      sizes: { lg: 8, md: 7, sm: 6, xl: 8, xs: 12, xxl: 8 },
      widgets: ['QA', 'searchResults'],
    },
    {
      sizes: { lg: 4, md: 5, sm: 6, xl: 4, xs: 12, xxl: 4 },
      widgets: [
        'findSimilarDocument',
        'findAuthoredBy',
        'vosViewer',
        'analytics',
        'expertSearch',
      ],
    },
  ],
  topFullWidthWidgets: [],
};
