import React, { FC } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import { useTranslation } from 'react-i18next';

import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import NotificationLinkItem from 'containers/Notifications/NotificationLinkItem';
import { NotificationLinkItemExpandable } from 'containers/Notifications/NotificationLinkItemExpandable';

import { useTagsNotifications } from './useTagsNotifications';

interface Props {
  onClose: () => void;
}

export const TagsNotifications: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('common');
  const { hasTagsUpdates, isAuthenticated, tagSubItems, tagsUpdatesCount } =
    useTagsNotifications();

  if (!isAuthenticated || !hasTagsUpdates) {
    return null;
  }

  return (
    <NotificationLinkItemExpandable
      chipLabel={tagsUpdatesCount}
      icon={BookmarkIcon}
      renderItem={(item) => (
        <NotificationLinkItem
          chipLabel={item.notifications}
          eventName={TrackEventName.TagUpdateNotificationClicked}
          eventProps={{ tagId: item.id }}
          icon={SupervisedUserCircleRoundedIcon}
          key={item.id}
          text={item.label}
          to={item.to}
          nested
          onClick={onClose}
        />
      )}
      subItems={tagSubItems}
      text={t<string>('navBar.tags')}
    />
  );
};
