import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Page } from 'react-pdf';
import { useInViewEffect } from 'react-hook-inview';
import { debounce } from '@mui/material';
import clsx from 'clsx';

import { DEFAULT_HIGHLIGHT_COLOR } from '../../const/defaults.const';
import { useStyles } from './style';
import { HighlightMode } from '../../types';
import { PDFPageContext } from './PDFPage.context';
import { usePDFPage, usePDFPageDataUrl } from '../../hooks';
import { HighlightingContext } from '../Highlighting/Highlighting.context';
import PDFPageShadowImage from '../PDFPageShadowImage';
import PDFPageSpinner from '../PDFPageSpinner';

export type PDFPageProps = {
  pageNumber: number;
  highlightMode: HighlightMode;
  zoomLevel: number;
  originalWidth: number;
  originalHeight: number;
  highlightColor?: string;
  children?: React.ReactNode;
};

export const PDFPage = React.memo(
  ({
    pageNumber,
    highlightMode,
    children,
    highlightColor = DEFAULT_HIGHLIGHT_COLOR,
    originalWidth,
    originalHeight,
    zoomLevel,
  }: PDFPageProps) => {
    const pageRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const { state, dispatch } = usePDFPage({
      originalPageWidth: originalWidth,
      originalPageHeight: originalHeight,
    });
    const {
      state: { status: highlightingStatus },
    } = useContext(HighlightingContext);
    const classes = useStyles({
      color: highlightColor,
      width: `${Math.round(originalWidth * zoomLevel)}px`,
      height: `${Math.round(originalHeight * zoomLevel)}px`,
    });
    const dataUrl = usePDFPageDataUrl({
      canvasRef,
      rendered: state.rendered,
      pageWidth: originalWidth,
      pageHeight: originalHeight,
    });
    const areaHighlightMode = highlightMode === 'area';
    const isHighlighting = highlightingStatus === 'edit';

    const [zooming, setZooming] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetZooming = useCallback(debounce(setZooming, 200), [
      setZooming,
    ]);

    useEffect(() => {
      dispatch({ type: 'setRendered', payload: false });
      setZooming(true);
      debouncedSetZooming(false);

      return debouncedSetZooming.clear;
    }, [dispatch, zoomLevel, debouncedSetZooming]);

    const handleRenderSuccess = () => {
      dispatch({ type: 'setRendered', payload: true });
    };

    const anchorRef = useInViewEffect(
      ([entry]) => {
        if (entry.isIntersecting) {
          dispatch({ type: 'setVisibility', payload: true });
          return;
        }

        if (!entry.isIntersecting) {
          dispatch({ type: 'setVisibility', payload: false });
        }
      },
      {
        threshold: 0,
      }
    );

    return (
      <Page
        inputRef={pageRef}
        canvasRef={canvasRef}
        pageNumber={pageNumber}
        renderTextLayer={state.isVisible && !areaHighlightMode && !zooming}
        renderAnnotationLayer={state.isVisible && !isHighlighting}
        scale={zoomLevel}
        className={clsx(
          classes.root,
          state.rendered && classes.rootRendered,
          isHighlighting && classes.rootHighlighting
        )}
        onRenderSuccess={handleRenderSuccess}
        onRenderError={(e) => console.log(e)}
        width={originalWidth}
        renderMode={zooming ? 'none' : 'canvas'}
      >
        <div
          aria-hidden="true"
          ref={anchorRef}
          className={classes.pageVisibilityAnchor}
        />

        <PDFPageContext.Provider
          value={{
            dispatch,
            state: { ...state, canvasRef, pageRef, pageNumber },
          }}
        >
          {!state.rendered && state.isVisible && (
            <PDFPageShadowImage dataUrl={dataUrl} />
          )}

          {!state.rendered && !dataUrl && <PDFPageSpinner />}

          {state.isVisible && children}
        </PDFPageContext.Provider>
      </Page>
    );
  }
);

PDFPage.displayName = 'PDFPage';

export default PDFPage;
