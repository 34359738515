import { Configuration, UsersApi } from '@zarn/vendor/dist/auth';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';

import { CreateUserPayload } from './usersApi.types';
import {
  serializeRegistrationData,
  serializeUserUpdateData,
} from './usersApi.utils';

export const usersApi = new UsersApi(
  new Configuration({ baseOptions: BASE_HEADERS, basePath: SERVICE_URL }),
  SERVICE_URL,
  axiosInstance
);

export const createUser = (values: CreateUserPayload, tenant: string) => {
  return usersApi.createUser({
    registrationData: serializeRegistrationData(values),
    requesterUuid: '',
    tenant,
    userRoles: '',
  });
};

export const updateUser = (
  userUuid: string,
  values: CreateUserPayload,
  tenant: string
) => {
  return usersApi.updateUser({
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUpdateData: serializeUserUpdateData(values),
    userUuid,
  });
};

export const deleteUser = (userId: string, tenant: string) =>
  usersApi.deleteUser({
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUuid: userId,
  });

export const getUserApiKeys = (userUuid: string, tenant: string) =>
  usersApi.listApiKeys({
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUuid,
  });

export const createUserApiKey = (userUuid: string, tenant: string) =>
  usersApi.createApiKey({
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUuid,
  });

export const deleteUserApiKey = (
  apiKeyId: string,
  userUuid: string,
  tenant: string
) =>
  usersApi.deleteApiKey({
    apiKeyId,
    requesterUuid: '',
    tenant,
    userRoles: '',
    userUuid,
  });
