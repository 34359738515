import React from 'react';

import { FormGroup, FormGroupProps, Theme, Typography } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  group: {
    '& .MuiFormControl-root + .MuiFormControl-root': {
      marginTop: theme.spacing(2),
    },
    '& .MuiFormControlLabel-root + .MuiFormControlLabel-root': {
      marginTop: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    '& + $root': {
      marginTop: theme.spacing(2),
    },
    justifyContent: 'flex-end',
  },
  title: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography
      .fontWeightMedium as CSSProperties['fontWeight'],
    marginBottom: theme.spacing(2),
  },
}));

type FormSectionProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
} & FormGroupProps;

export const FormSection = ({ children, title, ...rest }: FormSectionProps) => {
  const classes = useStyles();

  return (
    <FormGroup className={classes.root} {...rest}>
      {title && <Typography className={classes.title}>{title}</Typography>}

      <div className={classes.group}>{children}</div>
    </FormGroup>
  );
};
