import React, { FC, useMemo } from 'react';

import { Box } from '@mui/material';

import { NoteDetails, NoteObjectId } from 'api/notesApi/notesApi.types';
import {
  getAnnotationSearchQuery,
  getTextAnnotationContent,
} from 'containers/DocNotes/docNotes.utils';
import { useSearchPopper } from 'containers/SearchResults/SearchPopper/hooks/useSearchPopper';
import SearchWithNoteOrHighlightDialog from 'containers/SearchWithNoteOrHighlight';

import { ExplainNoteAction } from '../../ExplainNoteAction/ExplainNoteAction';
import NoteItemActions from '../../NoteItemActions';
import { NoteItemShareableAction } from '../../NoteItemShareableAction';
import { OpenChatNoteButton } from '../../OpenChatNoteButton/OpenChatNoteButton';

interface Props {
  docId?: string | undefined;
  editable: boolean;
  isChatConversation: boolean;
  isChatEnabled: boolean;
  isExplainVisible: boolean;
  note: NoteDetails;
  onDelete: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onEditModeToggle: () => void;
  onOpenChatNote: () => void;
  onToggleShareable: () => Promise<void>;
}

export const Actions: FC<Props> = ({
  docId,
  editable,
  isChatConversation,
  isChatEnabled,
  isExplainVisible,
  note,
  onDelete,
  onEditModeToggle,
  onOpenChatNote,
  onToggleShareable,
}) => {
  const { dialogOpen, onClose, onOpen } = useSearchPopper();

  const { annotationHighlight } = note;

  const annotationContent = getTextAnnotationContent(
    annotationHighlight?.content.type,
    annotationHighlight?.content.body
  );

  const searchQuery = useMemo(
    () => getAnnotationSearchQuery(annotationContent, note.content),
    [annotationContent, note.content]
  );

  const canSearch = note.content || annotationContent;
  const isActionEnabled = canSearch || docId;

  const handleSearchDialogOpen = () => {
    onOpen(searchQuery);
  };

  const EditableChat = editable ? (
    <OpenChatNoteButton noteId={note.id} onClick={onOpenChatNote} />
  ) : null;

  const ExplainAction = isExplainVisible ? (
    <ExplainNoteAction
      annotationContentBody={annotationHighlight?.content.body}
      docId={docId}
      noteContent={note.content}
      noteId={note.id}
    />
  ) : null;

  const Chat = isChatConversation ? EditableChat : ExplainAction;

  return (
    <Box flex="none" ml={1}>
      {isActionEnabled && isChatEnabled && Chat}

      {editable && (
        <>
          <NoteItemShareableAction
            noteId={note.id}
            shareable={note.shareable}
            onToggleShareable={onToggleShareable}
          />

          <NoteItemActions
            isChatConversation={isChatConversation}
            noteId={note.id}
            objectId={note.objectId}
            onDelete={onDelete}
            onEdit={onEditModeToggle}
            onOpenSearchDialog={handleSearchDialogOpen}
          />

          <SearchWithNoteOrHighlightDialog
            docId={docId}
            searchPopperOpen={dialogOpen}
            searchQuery={searchQuery}
            onSearchPopperClose={onClose}
          />
        </>
      )}
    </Box>
  );
};
