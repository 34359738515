import React, { useMemo } from 'react';

import PersonPinIcon from '@mui/icons-material/PersonPin';
import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PageDrawerMenu } from 'common/components/PageDrawer/PageDrawerMenu';
import {
  selectFollowingTags,
  selectFollowingTagsLoading,
  selectFollowingTagsSortSettings,
  updateFollowingTagOrder,
} from 'containers/Tags/followingTagsSlice/followingTags.slice';
import TagItemTitle from 'containers/Tags/TagsList/TagItemTitle';
import { TagDragItemEnum } from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.enum';
import { TagListDragItem } from 'containers/Tags/TagsList/TagsListOrdering/TagListDragItem';
import { useTagsListOrdering } from 'containers/Tags/TagsList/TagsListOrdering/useTagsListOrdering';
import { TagsListSkeleton } from 'containers/Tags/TagsList/TagsListSkeleton';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';

import { TagsListType } from '../TagsListType.enum';

import { FollowingTagsListActions } from './FollowingTagsListActions';

export type FollowingTagsListProps = {
  filteredTagName: string;
  listExpanded: boolean;
  onToggleListStateChange: (listType: TagsListType) => Promise<void>;
};

export const FollowingTagsList = ({
  filteredTagName,
  listExpanded,
  onToggleListStateChange,
}: FollowingTagsListProps) => {
  const { t } = useTranslation('tags');
  const followingTags = useSelector(selectFollowingTags);
  const followingTagsLoading = useSelector(selectFollowingTagsLoading);
  const { sortBy } = useSelector(selectFollowingTagsSortSettings);
  const draggable = useMemo(
    () => !filteredTagName && sortBy === TagsSortByEnum.PriorityOrder,
    [filteredTagName, sortBy]
  );
  const { handleDrop, handleMove, listItems } = useTagsListOrdering({
    filteredTagName,
    sortBy,
    tags: followingTags,
    updateTagOrderAction: updateFollowingTagOrder,
  });

  const handleValueChange = async () => {
    await onToggleListStateChange(TagsListType.Following);
  };

  if (followingTagsLoading && followingTags.length === 0) {
    return (
      <PageDrawerMenu
        expanded={listExpanded}
        icon={<PersonPinIcon />}
        sectionTitle={t('followingTags.text')}
        subheaderActions={<FollowingTagsListActions />}
        onValueChange={handleValueChange}
      >
        <TagsListSkeleton />
      </PageDrawerMenu>
    );
  }

  if (followingTags.length > 0) {
    return (
      <PageDrawerMenu
        expanded={listExpanded}
        icon={<PersonPinIcon />}
        sectionTitle={t('followingTags.text')}
        subheaderActions={<FollowingTagsListActions />}
        onValueChange={handleValueChange}
      >
        <List aria-label={t('followingTags.list.ariaLabel')} disablePadding>
          {listItems.map((tag) => (
            <TagListDragItem
              draggable={draggable}
              dragType={TagDragItemEnum.Following}
              dropTypes={[TagDragItemEnum.Following]}
              key={tag.id}
              tag={tag}
              title={<TagItemTitle tag={tag} />}
              to={`/tags/${tag.id}`}
              onMove={handleMove}
              onTagDrop={handleDrop}
            />
          ))}
        </List>
      </PageDrawerMenu>
    );
  }

  return null;
};
