import React from 'react';

import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import { NoteCreate } from 'common/components/Notes/NoteCreate/NoteCreate';
import { HitType, SearchEngineEnum } from 'common/enums';
import { useExternalDocCreate } from 'containers/ExternalDocs/hooks/useExternalDocCreate';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useSavedResultsSettings } from 'containers/SavedResultsSettings/useSavedResultsSettings';

import { useNoteApi } from '../hooks/useNoteApi';

export type DocNoteCreateProps = {
  docData: RetrievalUnitData;
  docId: string;
  searchEngine?: SearchEngineEnum;
};

const DocNoteCreate = ({
  docData,
  docId,
  searchEngine,
}: DocNoteCreateProps) => {
  const { state } = useSavedResultsSettings();
  const noteApi = useNoteApi(docId);
  const { mutateAsync: externalDocCreate } = useExternalDocCreate();
  const isExternalDoc =
    searchEngine && docData?.document.type === HitType.ExternalDocument;

  const handleCreate = async (
    content: string,
    annotationHighlight: AnnotationHighlight | null = null
  ) => {
    await noteApi.create(
      content,
      annotationHighlight,
      state.data.notesDefaultSharingRole
    );

    if (isExternalDoc) {
      await externalDocCreate({ ...docData, searchEngine });
    }
  };

  return <NoteCreate sx={{ mb: 2 }} onAdd={handleCreate} />;
};

export default DocNoteCreate;
