import React, { useState } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useInViewEffect } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import DocumentMetadataButton from 'common/components/DocumentMetadataButton/DocumentMetadataButton';
import { SearchEngineEnum } from 'common/enums';
import { useIsMounted } from 'common/hooks/useIsMounted/useIsMounted';
import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { DocPDFViewerDialog } from 'containers/DocPDFViewerDialog';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export type ExternalDocPDFAssetProps = {
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
};

const ExternalDocPDFAsset = ({
  docData,
  searchEngine,
}: ExternalDocPDFAssetProps) => {
  const { tenant } = useParsedHostname();
  const { t } = useTranslation('common');
  const docId = docData.document.id;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [canAccess, setCanAccess] = useState<boolean>(false);
  const pdfUri = docData.externalPublicAsset?.assetValue;
  const isMounted = useIsMounted();

  const handleClick = () => {
    setDialogOpen(true);
    posthogPDFReader.open(tenant, docId);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const ref = useInViewEffect(
    ([entry], observer) => {
      if (!pdfUri) return;

      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
      }

      const controller = new AbortController();

      // Check whether we have an access to the PDF due to CORS issues
      // If not, then hide the PDFViewer button
      fetch(pdfUri, { method: 'GET', signal: controller.signal })
        .then(() => {
          controller.abort();
          isMounted.current && setCanAccess(true);
        })
        .catch(() => isMounted.current && setCanAccess(false));
    },
    { threshold: 0 },
    [pdfUri, isMounted]
  );

  if (!canAccess) return <div ref={ref} />;

  return (
    <>
      <DocumentMetadataButton
        color="secondary"
        startIcon={<PictureAsPdfIcon color="secondary" />}
        onClick={handleClick}
      >
        {t('retrievalUnit.actions.pdfAsset')}
      </DocumentMetadataButton>
      <DocPDFViewerDialog
        docData={docData}
        docId={docId}
        open={dialogOpen}
        searchEngine={searchEngine}
        onClose={handleCloseDialog}
      />
    </>
  );
};

ExternalDocPDFAsset.displayName = 'ExternalDocPDFAsset';

export default ExternalDocPDFAsset;
