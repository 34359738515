import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/state/store';

import { getQueryFromURL } from 'common/utils/searchQueryParams';

export type SearchState = {
  queryValue: string;
};

export const initialState: SearchState = {
  queryValue: getQueryFromURL(window.location.search),
};

const searchSlice = createSlice({
  initialState,
  name: 'searchResults',
  reducers: {
    setQueryValue: (state, action: PayloadAction<string>) => {
      state.queryValue = action.payload;
    },
  },
});

const searchSelector = (state: RootState): SearchState => state.search;

export const selectSearchQueryValue = createSelector(
  searchSelector,
  ({ queryValue }) => queryValue
);

export const { setQueryValue } = searchSlice.actions;

export default searchSlice.reducer;
