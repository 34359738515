import React, { FC, ReactNode, useState } from 'react';

import { PageContext } from './Page.context';
import { usePageDrawerMinified } from './usePageDrawerMinified';

interface Props {
  children: ReactNode;
}

export const PageProvider: FC<Props> = ({ children }) => {
  const [pageDrawerMobileOpen, setPageDrawerMobileOpen] = useState(false);
  const { pageDrawerMinified, setPageDrawerMinified } = usePageDrawerMinified();

  return (
    <PageContext.Provider
      value={{
        pageDrawerMinified,
        pageDrawerMobileOpen,
        setPageDrawerMinified,
        setPageDrawerMobileOpen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
