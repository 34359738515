import { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { DocAction } from '../DocActions.interface';

type PrivateDocDialog = boolean | 'edit' | 'delete';

export type UsePrivateDocActionsReturn = {
  handlePrivateDocDelete?: (id: string) => void;
  handlePrivateDocEdit?: (data: RetrievalUnitData) => void;
  privateDocActions: DocAction[];
  privateDocDialog: PrivateDocDialog;
  setPrivateDocDialog: (v: PrivateDocDialog) => void;
};

export type UsePrivateDocActionsProps = {
  onPrivateDocDelete?: (id: string) => void;
  onPrivateDocEdit?: (data: RetrievalUnitData) => void;
};

export const usePrivateDocActions = ({
  onPrivateDocDelete,
  onPrivateDocEdit,
}: UsePrivateDocActionsProps) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const [privateDocDialog, setPrivateDocDialog] =
    useState<PrivateDocDialog>(false);

  const privateDocActions = useMemo<DocAction[]>(
    () => [
      ...(onPrivateDocEdit
        ? [
            {
              Icon: EditIcon,
              action: () => setPrivateDocDialog('edit'),
              title: t('common:buttons.edit'),
            },
          ]
        : []),
      ...(onPrivateDocDelete
        ? [
            {
              Icon: DeleteIcon,
              action: () => setPrivateDocDialog('delete'),
              title: t('common:buttons.delete'),
            },
          ]
        : []),
    ],
    [t, setPrivateDocDialog]
  );

  const handlePrivateDocDelete = useCallback(
    (id: string) => {
      if (onPrivateDocDelete) {
        onPrivateDocDelete(id);
      }

      setPrivateDocDialog(false);
    },
    [onPrivateDocDelete]
  );

  const handlePrivateDocEdit = useCallback(
    (data: RetrievalUnitData) => {
      if (onPrivateDocEdit) {
        onPrivateDocEdit(data);
      }

      setPrivateDocDialog(false);
    },
    [onPrivateDocEdit]
  );

  return {
    handlePrivateDocDelete,
    handlePrivateDocEdit,
    privateDocActions,
    privateDocDialog,
    setPrivateDocDialog,
  };
};
