import {
  RetrievalMethodEnum,
  RetrievalUnitEnum,
  SearchSortMethodEnum,
} from 'common/enums';

import { DynamicFilterValue } from '../DynamicSearchPayload.interface';
import { SearchPayloadDocType } from '../SearchPayload.interface';

export enum SearchVisibilityFilterValue {
  'All' = 'all',
  'Private' = 'private',
  'Public' = 'public',
}

export interface SearchFiltersValue {
  dateFilter: string;
  dateRange: [string, string];
  docSources: string[];
  docTypes: SearchPayloadDocType[];
  dynamicFilters?: DynamicFilterValue[];
  locationCountries: string[];
  organizations: string[];
  retrievalMethod: RetrievalMethodEnum;
  retrievalUnit: RetrievalUnitEnum;
  sort: SearchSortMethodEnum;
  tagsFilter: string[];
  visibility: SearchVisibilityFilterValue;
  withCode: boolean;
}
