export const INITIAL_QUERY_KEY = 'initialQuery';

export const compareSearchUrlParams = (
  source: string,
  target: string
): boolean => {
  const sourceParams = new URLSearchParams(source);
  const targetParams = new URLSearchParams(target);
  const [lo, hi] =
    Array.from(sourceParams.keys()).length <
    Array.from(targetParams.keys()).length
      ? [sourceParams, targetParams]
      : [targetParams, sourceParams];

  for (const [key, value] of hi) {
    if ((value || null) !== lo.get(key)) {
      return false;
    }
  }

  return true;
};
