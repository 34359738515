import { useCallback, useMemo } from 'react';

import { captureException } from '@sentry/react';
import { LocationState } from 'pages/routes.config';
import { useHistory, useLocation } from 'react-router-dom';

import { Scalar } from 'common/interfaces/general.types';

export const useNavigationWithState = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const locationState = useMemo(() => location.state, [location.state]);

  const push = useCallback(
    (url: string, state: Record<string, Scalar> = {}) =>
      history.push(`${url}`, {
        ...locationState,
        ...state,
        // background: history.location,
      }),
    [history, locationState]
  );

  const replace = useCallback(
    (url: string, state: Record<string, Scalar> = {}) =>
      history.replace(`${url}`, { ...locationState, ...state }),
    [history, locationState]
  );

  const getStateParams = useCallback(
    <T>(param: string): T | null => {
      const value = (locationState as Record<string, string>)?.[param];
      try {
        return value ? (JSON.parse(value) as T) : null;
      } catch (error) {
        captureException(error);
        return (value as T) ?? null;
      }
    },
    [locationState]
  );

  const { pathname, search, state } = history.location;

  return {
    getStateParams,
    locationState,
    pathname,
    push,
    replace,
    search,
    state: state as LocationState,
  };
};
