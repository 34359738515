import { useCallback, useContext } from 'react';

import { PDFViewerContext } from '../../components/PDFViewer/PDFViewer.context';

export const useFullscreen = () => {
  const {
    state: { fullscreen },
    dispatch,
  } = useContext(PDFViewerContext);

  const setFullscreen = useCallback(
    (value: boolean) => {
      dispatch({ type: 'setFullscreen', payload: value });
    },
    [dispatch]
  );

  return {
    fullscreen,
    setFullscreen,
  };
};
