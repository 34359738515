import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    color: theme.palette.text.secondary,
    display: 'inline-flex',
    minWidth: 26,
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  toggleButton: {
    ...theme.typography.body2,
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    height: 28,
    overflow: 'hidden',
    padding: 0,
    width: '100%',
  },
  toggleIcon: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    display: 'flex',
    margin: 0,
  },
  tooltipPopper: {
    display: 'none',
  },
  wrapper: {
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
    paddingBottom: 1,
    paddingTop: 1,
    top: 0,
    zIndex: 1,
  },
  wrapperActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
}));

export type PageDrawerSectionItemProps = {
  actions?: React.ReactNode;
  expanded?: boolean;
  icon?: React.ReactNode;
  minified?: boolean;
  onToggle?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  sectionPath?: string;
  text: string;
};

export const PageDrawerSectionItem = React.forwardRef(
  (
    {
      actions,
      expanded,
      icon,
      minified,
      onToggle,
      sectionPath,
      text,
    }: PageDrawerSectionItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { pathname } = useLocation();
    const classes = useStyles();
    const activeSection = sectionPath && pathname.startsWith(sectionPath);

    return (
      <Tooltip
        classes={{ popper: clsx(!minified && classes.tooltipPopper) }}
        placement="right"
        title={text}
      >
        <div
          className={clsx(
            classes.wrapper,
            activeSection && classes.wrapperActive
          )}
          ref={ref}
        >
          <button
            aria-label={`${text} toggle`}
            className={classes.toggleIcon}
            type="button"
            onClick={onToggle}
          >
            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </button>

          <button
            aria-label={text}
            className={classes.toggleButton}
            type="button"
            onClick={onToggle}
          >
            {icon && <div className={classes.icon}>{icon}</div>}

            {!minified ? <div className={classes.text}>{text}</div> : null}
          </button>

          <div className={classes.actions}>{actions}</div>
        </div>
      </Tooltip>
    );
  }
);

PageDrawerSectionItem.displayName = 'PageDrawerSectionItem';
