import React from 'react';

import { IconButton, IconButtonProps } from '@mui/material';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = IconButtonProps;

const TrackedIconButton = (
  {
    children,
    onAction,
    onClick,
    ...rest
  }: WithTrackedActionWrappedProps<Props>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <IconButton {...rest} ref={ref} onClick={handleClick}>
      {children}
    </IconButton>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  React.forwardRef(TrackedIconButton)
);
