import React, { useEffect, useState } from 'react';
import {
  Box,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutIcon from '@mui/icons-material/ZoomOutRounded';

import { useStyles } from './style';
import {
  floatToPercentage,
  getNextZoomValue,
  percentageToFloat,
} from '../../../utils';

export type ZoomControlProps = {
  zoom: number;
  minZoom: number;
  maxZoom: number;
  onZoom: (zoom: number) => void;
};

const ZoomControl = React.memo(
  ({ onZoom, minZoom, maxZoom, zoom }: ZoomControlProps) => {
    const classes = useStyles();
    const [value, setValue] = useState(floatToPercentage(zoom));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number(e.currentTarget.value));
    };

    useEffect(() => {
      setValue(floatToPercentage(zoom));
    }, [zoom]);

    const handleZoom = () => {
      onZoom(percentageToFloat(value));
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code !== 'Enter') return;

      handleZoom();
    };

    const handleZoomIn = () => {
      onZoom(percentageToFloat(getNextZoomValue(value, 'asc')));
    };

    const handleZoomOut = () => {
      onZoom(percentageToFloat(getNextZoomValue(value, 'desc')));
    };

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton
          onClick={handleZoomOut}
          color="inherit"
          aria-label="Zoom out"
          disabled={zoom <= minZoom}
          size="large"
        >
          <ZoomOutIcon fontSize="small" />
        </IconButton>

        <Hidden mdDown>
          <TextField
            id="zoomInput"
            size="small"
            value={value}
            variant="filled"
            onChange={handleChange}
            onBlur={handleZoom}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              classes: {
                input: classes.toolbarInput,
              },
            }}
          />
        </Hidden>

        <Hidden mdUp>
          <Box mx={-1}>{value}%</Box>
        </Hidden>

        <IconButton
          onClick={handleZoomIn}
          color="inherit"
          aria-label="Zoom in"
          disabled={zoom >= maxZoom}
          size="large"
        >
          <ZoomInIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  }
);

ZoomControl.displayName = 'ZoomControl';

export default ZoomControl;
