import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { TagType } from '@zarn/vendor/dist/saved-results';

import { DocumentUserData } from 'api/documentsApi/DocumentsApi.types';

import {
  FavoriteDocument,
  SavedDocument,
  SavedDocumentsState,
} from './SavedDocuments.interface';
import { groupSavedDocuments } from './savedDocuments.utils';
import {
  addTagToDocumentThunk,
  getSavedDocumentsThunk,
  removeDocumentFromFavouritesThunk,
  removeTagFromDocumentThunk,
  saveDocumentToFavouritesThunk,
  updateDocumentTagsThunk,
} from './savedDocumentsSlice.utils';

export const initialState: SavedDocumentsState = {
  data: {
    favourites: {},
    own: {},
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const getSavedDocuments = createAsyncThunk(
  'savedDocuments/get',
  getSavedDocumentsThunk
);

export const updateDocumentTags = createAsyncThunk(
  'savedDocuments/updateDocumentTags',
  updateDocumentTagsThunk
);

export const addTagToDocument = createAsyncThunk(
  'savedDocuments/addTagToDocument',
  addTagToDocumentThunk
);

export const removeTagFromDocument = createAsyncThunk(
  'savedDocuments/removeTagFromDocument',
  removeTagFromDocumentThunk
);

export const saveDocumentToFavourites = createAsyncThunk(
  'savedDocuments/saveDocumentToFavourites',
  saveDocumentToFavouritesThunk
);

export const removeDocumentFromFavourites = createAsyncThunk(
  'savedDocuments/removeDocumentFromFavourites',
  removeDocumentFromFavouritesThunk
);

const savedDocuments = createSlice({
  extraReducers: (builder) => {
    // Get saved documents
    builder.addCase(getSavedDocuments.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getSavedDocuments.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Sorry, unexpected error happened';
    });
    builder.addCase(getSavedDocuments.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.favourites = {
        ...state.data.favourites,
        ...payload.favourites,
      };
      state.data.own = { ...state.data.own, ...payload.own };
      state.error = null;
    });
    // Add/delete documents
    builder.addCase(updateDocumentTags.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(updateDocumentTags.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Sorry, unexpected error happened';
    });
    builder.addCase(updateDocumentTags.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data.own = { ...state.data.own, ...payload };
      state.error = null;
    });
    builder.addCase(
      addTagToDocument.fulfilled,
      (state, { payload: { docId, docType, tagId, tagType } }) => {
        const permission = tagType === TagType.Shared ? 'modify' : 'write';

        state.fetchState = FiniteStates.Success;
        state.data.own[docId] = [
          ...(state.data.own[docId] ?? []),
          {
            documentId: docId,
            documentType: docType,
            tag: { id: tagId, name: '', permission, type: tagType },
          },
        ];
        state.error = null;
      }
    );
    builder.addCase(
      removeTagFromDocument.fulfilled,
      (state, { payload: { deletedTagId, docId } }) => {
        state.fetchState = FiniteStates.Success;
        state.data.own[docId] = (state.data.own[docId] ?? []).filter(
          ({ tag }) => tag.id !== deletedTagId
        );
        state.error = null;
      }
    );
    // Save document to favourites
    builder.addCase(
      saveDocumentToFavourites.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.favourites = { ...state.data.favourites, ...payload };
        state.error = null;
      }
    );
    // Remove document from favourites
    builder.addCase(
      removeDocumentFromFavourites.fulfilled,
      (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.favourites = payload;
        state.error = null;
      }
    );
  },
  initialState,
  name: 'savedDocuments',
  reducers: {
    setSavedDocumentsData: (
      state,
      action: PayloadAction<DocumentUserData[]>
    ) => {
      state.data.own = groupSavedDocuments(action.payload);
    },
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
});

const getSavedDocumentsSelector = (state: RootState) => state.savedDocuments;

export const selectSavedDocuments = createSelector(
  getSavedDocumentsSelector,
  ({ data }) => data.own
);

export const selectFavouriteDocuments = createSelector(
  getSavedDocumentsSelector,
  ({ data }) => data.favourites
);

export const selectSavedDocByOrganizeDocId = (organizeDocId: string) =>
  createSelector(
    getSavedDocumentsSelector,
    ({ data }): SavedDocument[] => data.own[organizeDocId] ?? []
  );

export const selectFavouriteDocByOrganizeDocId = (organizeDocId: string) =>
  createSelector(
    getSavedDocumentsSelector,
    ({ data }): FavoriteDocument | null =>
      data.favourites[organizeDocId] ?? null
  );

export const { setSavedDocumentsData } = savedDocuments.actions;

export default savedDocuments.reducer;
