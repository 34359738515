const SERVICE_DEP_REGEX = /^svc_(.+)\[(.*)\]$/gi;
const SERVICE_KEY_PREFIX = 'svc_';
const GATEWAY_KEY = 'gw';

export interface ApiConfigParams {
  gw?: string;
  services: ApiConfigParamsService;
}

export interface ApiConfigParamsService {
  [key: string]: ApiConfigParamsDep;
}

export interface ApiConfigParamsDep {
  [key: string]: string;
}

export interface ApiConfigParamsSerialized {
  gwPrefix: string;
  serviceDepsHeaders: { 'X-Service-Deps'?: string };
}

function setApiServiceParamsToSS(config: ApiConfigParams): void {
  window.sessionStorage.setItem('apiServiceDeps', JSON.stringify(config));
}

function getApiServiceParamsFromSS(): ApiConfigParams | null {
  const config = window.sessionStorage.getItem('apiServiceDeps');
  return config ? JSON.parse(config) : null;
}

const SEARCH_PARAMS = new URLSearchParams(window.location.search);

function getConfigParams(): {
  configParams: ApiConfigParams;
  configParamsKeys: string[];
} {
  const configParamsKeys: string[] = [];
  const configParams: ApiConfigParams = { services: {} };

  // eslint-disable-next-line no-restricted-syntax
  for (const entry of SEARCH_PARAMS.entries()) {
    const key: string = entry[0];
    const val: string = entry[1];

    if (key === GATEWAY_KEY) {
      configParams[key] = val;
      configParamsKeys.push(key);
    }

    if (key.startsWith(SERVICE_KEY_PREFIX)) {
      const [serviceKey, depKey] = key
        .replace(SERVICE_DEP_REGEX, '$1:$2')
        .split(':');
      if (serviceKey && depKey) {
        configParams.services[serviceKey] = {
          ...(configParams.services[serviceKey] || {}),
          [depKey]: val,
        };
        configParamsKeys.push(key);
      }
    }
  }

  return { configParams, configParamsKeys };
}

export function getApiServiceParamsFromUrl(): ApiConfigParams | null {
  const { configParams, configParamsKeys } = getConfigParams();

  if (configParamsKeys.length > 0) {
    configParamsKeys.forEach((param) => {
      SEARCH_PARAMS.delete(param);
    });
    const searchParamsString = SEARCH_PARAMS.toString();
    const path = `${window.location.pathname}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`;

    window.history.replaceState({}, document.title, path);
  }

  if (configParams.gw || Object.keys(configParams.services).length > 0) {
    setApiServiceParamsToSS(configParams);
    return configParams;
  }

  return null;
}

export function getSerializedApiServiceParams(
  params: ApiConfigParams | null
): ApiConfigParamsSerialized {
  const serializedParams = { gwPrefix: '', serviceDepsHeaders: {} };

  if (!params) {
    return serializedParams;
  }

  const { gw, services } = params;
  const hasServices = Object.keys(services).length > 0;
  serializedParams.gwPrefix = gw ? `-${gw}` : '';

  if (hasServices) {
    serializedParams.serviceDepsHeaders = {
      'X-Service-Deps': JSON.stringify(params.services),
    };
  }

  return serializedParams;
}

export function getApiServiceParams(
  apiServiceParams: ApiConfigParams | null
): ApiConfigParamsSerialized {
  const apiServiceParamsFromSS = getApiServiceParamsFromSS();

  if (apiServiceParams) {
    return getSerializedApiServiceParams(apiServiceParams);
  }

  if (apiServiceParamsFromSS) {
    return getSerializedApiServiceParams(apiServiceParamsFromSS);
  }

  return { gwPrefix: '', serviceDepsHeaders: {} };
}
