import { useHighlightColor, useZoomLevel } from '../../hooks';
import { useCallback, useContext } from 'react';
import { PDFPageContext } from '../PDFPage/PDFPage.context';
import { HighlightingContext } from '../Highlighting/Highlighting.context';
import { Highlight, HighlightRect, RectBase } from '../../types';
import {
  getHighlightAbsolutePosition,
  makeCanvasScreenshot,
} from '../../utils';
import { debounce } from '@mui/material';
import { AreaSelectionEndPayload } from '../AreaSelection';

export const useAreaSelection = () => {
  const { zoomLevel } = useZoomLevel();
  const { highlightColor } = useHighlightColor();
  const {
    state: { pageRef, canvasRef, pageNumber },
  } = useContext(PDFPageContext);
  const {
    onClearHighlight,
    onStartHighlight,
    onEndHighlight,
    onUpdateHighlight,
  } = useContext(HighlightingContext);

  const handleSelectionUpdate = useCallback(
    (selectionRect: RectBase, contentBody: string = '') => {
      if (!pageRef?.current) return;

      const { height: pageHeight } = pageRef.current.getBoundingClientRect();
      const { x1, y1, x2, y2, width, height } = selectionRect;

      const position = getHighlightAbsolutePosition({
        pageHeight: pageHeight / zoomLevel,
        pageNumber,
        y1,
      });

      const rect: HighlightRect = {
        x1: x1 / zoomLevel,
        y1: y1 / zoomLevel,
        x2: x2 / zoomLevel,
        y2: y2 / zoomLevel,
        pageNumber,
        width: width / zoomLevel,
        height: height / zoomLevel,
      };

      const payload: Highlight = {
        id: -1,
        position,
        color: highlightColor,
        actions: ['addNote', 'delete', 'update'],
        content: {
          type: 'image',
          body: contentBody,
        },
        rects: [rect],
      };

      onUpdateHighlight(payload);
    },
    [pageRef, zoomLevel, highlightColor, pageNumber, onUpdateHighlight]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectionChangeDebounced = useCallback(
    debounce(handleSelectionUpdate, 100),
    [handleSelectionUpdate]
  );

  const handleSelectionEnd = useCallback(
    ({ x, y, selection }: AreaSelectionEndPayload) => {
      if (canvasRef?.current) {
        handleSelectionChangeDebounced.clear();

        handleSelectionUpdate(
          selection,
          makeCanvasScreenshot(canvasRef.current, selection)
        );
      }

      onEndHighlight({ x, y });
    },
    [
      onEndHighlight,
      handleSelectionChangeDebounced,
      handleSelectionUpdate,
      canvasRef,
    ]
  );

  return {
    parentEl: pageRef?.current,
    highlightColor,
    handleSelectionChangeDebounced,
    handleSelectionEnd,
    handleSelectionStart: onStartHighlight,
    handleSelectionClear: onClearHighlight,
  };
};
