import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  fallBackIcon: {
    marginLeft: 'auto',
    width: '50%',
  },
  grid: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  img: {
    display: 'block',
    width: '100%',
  },
  imgWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    width: '100%',
  },
  imgWrapperNoImage: {
    overflow: 'hidden',
  },
  lazyLoad: {
    '&.is-visible': {
      opacity: 1,
    },
    opacity: 0,

    transition: 'all .5s ease-in-out',
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    width: '100%',
  },
  wrapper: {
    minHeight: '100%',
    position: 'relative',
  },
}));
