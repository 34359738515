import React, { useMemo } from 'react';

import { List, ListItemSecondaryAction, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DocumentDragItemEnum } from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import {
  selectOwnTags,
  selectOwnTagsLoading,
  selectOwnTagsSortSettings,
  updateOwnTagOrder,
} from 'containers/Tags/ownTagsSlice/ownTags.slice';
import TagItemSettingsButton from 'containers/Tags/TagItemSettingsButton/TagItemSettingsButton';
import TagItemTitle from 'containers/Tags/TagsList/TagItemTitle';
import { TagDragItemEnum } from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.enum';
import { TagListDragItem } from 'containers/Tags/TagsList/TagsListOrdering/TagListDragItem';
import { useTagsListOrdering } from 'containers/Tags/TagsList/TagsListOrdering/useTagsListOrdering';
import { TagsListSkeleton } from 'containers/Tags/TagsList/TagsListSkeleton';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';

import { useDocumentDragTagging } from '../TagsListOrdering/useDocumentDragTagging';

import { OwnTagsEmptyState } from './OwnTagsEmptyState';

export const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  actionButtonSelected: {
    opacity: 1,
  },
  addTagButton: {
    borderStyle: 'dashed',
  },
  addTagIconButton: {
    padding: theme.spacing(0),
  },
}));

export type OwnTagsListProps = {
  filteredTagName: string;
};

export const OwnTagsList = ({ filteredTagName }: OwnTagsListProps) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const { pathname } = useLocation();
  const ownTags = useSelector(selectOwnTags);
  const { sortBy } = useSelector(selectOwnTagsSortSettings);
  const ownTagsLoading = useSelector(selectOwnTagsLoading);
  const dropTypes = useMemo(() => {
    return !filteredTagName && sortBy === TagsSortByEnum.PriorityOrder
      ? [TagDragItemEnum.Own, DocumentDragItemEnum.Document]
      : [DocumentDragItemEnum.Document];
  }, [filteredTagName, sortBy]);
  const { handleDocumentDrop } = useDocumentDragTagging();
  const { handleDrop, handleMove, listItems } = useTagsListOrdering({
    filteredTagName,
    sortBy,
    tags: ownTags,
    updateTagOrderAction: updateOwnTagOrder,
  });

  if (ownTagsLoading && ownTags.length === 0) {
    return <TagsListSkeleton />;
  }

  if (ownTags.length > 0) {
    return (
      <List aria-label={t('myTags.list.ariaLabel')} disablePadding>
        {listItems.map((tag) => (
          <TagListDragItem
            draggable={sortBy === TagsSortByEnum.PriorityOrder}
            dragType={TagDragItemEnum.Own}
            dropTypes={dropTypes}
            key={tag.id}
            tag={tag}
            title={<TagItemTitle tag={tag} />}
            to={`/tags/${tag.id}`}
            onDocumentDrop={handleDocumentDrop}
            onMove={handleMove}
            onTagDrop={handleDrop}
          >
            <ListItemSecondaryAction
              className={clsx(
                classes.actionButton,
                pathname.startsWith(`/tags/${tag.id}`) &&
                  classes.actionButtonSelected
              )}
              data-testid="ownTagSettingsButton"
            >
              <TagItemSettingsButton tag={tag} />
            </ListItemSecondaryAction>
          </TagListDragItem>
        ))}
      </List>
    );
  }

  return <OwnTagsEmptyState />;
};
