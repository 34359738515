import React, { Suspense } from 'react';

import store from 'app/state/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { PageLoader } from 'common/components/Loaders/PageLoader/PageLoader';

import App from './app/App';

import './index.css';

ReactDOM.render(
  <Suspense fallback={<PageLoader />}>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
