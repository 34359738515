import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  ClickAwayListener,
  Collapse,
  Fade,
  Paper,
  Popper,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import {
  PageDrawerSectionItem,
  PageDrawerSectionItemProps,
} from 'common/components/PageDrawer/PageDrawerSectionItem';

const useStyles = makeStyles<
  Theme,
  {
    popoverMaxHeight: number;
    popoverWidth: string | number;
  }
>((theme: Theme) => ({
  collapseEntered: {
    '& > .MuiCollapse-wrapper': {
      height: '100%',
    },
  },
  popoverPaper: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: ({ popoverMaxHeight }) => popoverMaxHeight,
    width: ({ popoverWidth }) => popoverWidth,
  },
  popper: {
    position: 'absolute',
    zIndex: -1,
  },
  popperOpen: {
    zIndex: theme.zIndex.drawer,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
  },
  rootMinified: {},
  wrapperInner: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export type DrawerSectionProps = {
  children: React.ReactNode;
  minified?: boolean;
  placement?: 'left' | 'right';
  popoverClassName?: string;
  popoverWidth?: string | number;
} & PageDrawerSectionItemProps;

const DrawerSection = ({
  children,
  minified,
  placement = 'right',
  popoverClassName,
  popoverWidth = 300,
  ...rest
}: DrawerSectionProps) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  const getMaxHeight = useCallback(() => {
    const { top } = itemRef.current?.getBoundingClientRect() ?? { top: 500 };
    return window.innerHeight - top - 20;
  }, []);

  const [popoverMaxHeight, setPopoverMaxHeight] = useState<number>(
    getMaxHeight()
  );
  const [open, setOpen] = useState(!minified);

  const classes = useStyles({
    popoverMaxHeight,
    popoverWidth,
  });

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const resizeCb = () => {
      setPopoverMaxHeight(getMaxHeight());
    };

    window.addEventListener('resize', resizeCb);

    setTimeout(() => {
      resizeCb();
    }, 1000);

    return () => {
      window.removeEventListener('resize', resizeCb);
    };
  }, [getMaxHeight, itemRef, setPopoverMaxHeight]);

  useEffect(() => {
    setOpen(!minified);
  }, [minified]);

  const handleClickAway = () => {
    if (minified) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={clsx(classes.root, minified && classes.rootMinified)}
        data-testid="DrawerSection"
      >
        <PageDrawerSectionItem
          {...rest}
          expanded={open}
          minified={minified}
          ref={itemRef}
          onToggle={handleToggle}
        />

        {minified ? (
          <Popper
            anchorEl={itemRef.current}
            className={clsx(open ? classes.popperOpen : classes.popper)}
            data-testid="drawerSectionPopper"
            modifiers={[
              {
                enabled: false,
                name: 'hide',
              },
              {
                enabled: false,
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                },
              },
            ]}
            open={open}
            placement={`${placement}-start`}
            disablePortal
            keepMounted
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={clsx(classes.popoverPaper, popoverClassName)}>
                  {open && children}
                </Paper>
              </Fade>
            )}
          </Popper>
        ) : (
          <Collapse
            classes={{
              entered: classes.collapseEntered,
              wrapperInner: classes.wrapperInner,
            }}
            data-testid="drawerSectionCollapse"
            in={open}
            mountOnEnter
          >
            {open && children}
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

DrawerSection.displayName = 'DrawerSection';

export default DrawerSection;
