import { useContext, useCallback } from 'react';

import { PDFViewerContext } from '../../components/PDFViewer/PDFViewer.context';

export const useHighlightsVisibility = () => {
  const {
    state: { highlightsVisible },
    dispatch,
  } = useContext(PDFViewerContext);

  const setHighlightsVisibility = useCallback(
    (value: boolean) => {
      dispatch({ type: 'setHighlightVisibility', payload: value });
    },
    [dispatch]
  );

  return {
    highlightsVisible,
    setHighlightsVisibility,
  };
};
